// USA
export const locale = {
    "lang": "en",
    "data": {
        "TRANSLATOR": {
            "SELECT": "Select your language",
        },
        "MENU": {
            "MONITORING": {
                "MAIN": "Monitoring",
                "CHILD": {
                    "DASHBOARD": "Dashboard",
                    "DASHBOARD_DOWNTIME": "Dashboard Downtime",
                    "DASHBOARD_LASTTEST": "Dashboard Load Test",
                    "TERMINAL": "Terminal",
                    "TERMINALS": "Terminals",
                    "COMPONENT_OVERVIEW": "Component Overview",
                    "MAINTENANCE_OVERVIEW": "Maintenance Overview",
                    "TERMINAL_IDENTIFIER": "Terminal Identifiers",
                },
            },
            "LOGS": {
                "MAIN": "Logs",
                "CHILD": {
                    "TRANSACTION": "Transaction Log",
                    "ERROR": "Error Log",
                    "OPERATOR_ACCESS": "Operator Access Log",
                    "CONFIGURATION_CHANGE": "Configuration Change Log",
                    "EVENT": "Event Log",
                    "MEDIA": "Media Log",
                    "ART_EVENT": "ART Event Log",
                    "MAINTENANCE": "Maintenance Log",
                }
            },
            "STATISTICS": {
                "MAIN": "Statistics",
                "CHILD": {
                    "MEDIA_STATISTICS": "Media Statistics",
                    "TERMINAL_STATISTICS": "Terminal Statistics",
                    "UTILIZATION_REPORT": "Utilization",
                    "TRANSACTION_COUNT": "Transaction Counts",
                    "TRANSACTION_STATISTICS": "Transaction Statistics",
                    "TERMINAL_AVAILABILITY": "Terminal Availability",
                    "TERMINAL_STATE": "Terminal State",
                    "MEDIA_CASH": "Cash Statistics",
                    "MEDIA_CARD": "Card Statistics",
                    "MEDIA_CARD_TURNOVER": "Card Turnover",
                    "MEDIA_CHIP_TURNOVER": "Chip Turnover",
                    "MEDIA_TICKET_OPERATOR": "Ticket Statistics per Operator",
                    "MEDIA_TICKET_PERIOD": "Ticket Statistics per Period",
                    "MEDIA_ONLINE_SYSTEMS": "Online System Statistics",
                    "ART_OVERVIEW": "ART Overview",
                    "QUARTERLY_DASHBOARD": "Quarterly Dashboard",
                    "MAP_VIEW": "Map View",
                    "STOCK_OVERVIEW": "Stock Overview",
                    "COMPONENT_OVERVIEW": "Component Overview",
                    "MAINTENANCE_OVERVIEW": "Maintenance Overview",
                    "PAYMENT_REPORTS": "Payment Reports",
                    "DONATIONS": "Donation Reports",
                    "END_OF_SHIFT": "End of Shift Reports"
                },
            },
            "BALANCING": {
                "MAIN": "Balancing",
                "CHILD": {
                    "BALANCING_REPORTS": "Balancing Reports",
                }
            },
            "MANAGEMENT": {
                "MAIN": "Management",
                "CHILD": {
                    "OPERATORS": "Operators",
                    "USER_MANAGEMENT": "Users",
                    "PLAYER_INFORMATION": "Players",
                    "PAYMENT_CUSTOMER": "Customers",
                    "TERMINAL_LIFECYCLE": "Terminal Lifecycle",
                    "COMPUTER_SERIAL": "Computer Registration",
                    "LICENSE_KEY_USAGE_OVERVIEW": "License Key Overview",
                    "JACKPOT_PAYOUTS": "Jackpot Forms",
                    "MANUFACTURING_ORDER_OVERVIEW": "Manufacturing Order Overview",
                    "MANUFACTURING_ORDERS": "Manufacturing Orders",
                    "KIOSK_REGISTRATION": "Kiosk Registration"
                },
            },
            "SETTINGS": {
                "MAIN": "Settings",
                "GENERAL_SETTINGS": "General Settings",
                "CHILD": {
                    "CONFIGURATION_MANAGEMENT": "Configuration Entries"
                },
            },
            "USER": {
                "TERMINAL_CHOOSER": "Choose Terminals"
            }
        },
        "AUTH": {
            "GENERAL": {
                "OR": "Or",
                "SUBMIT_BUTTON": "Submit",
                "NO_ACCOUNT": "Don't have an account?",
                "SIGNUP_BUTTON": "Signup",
                "SIGN_IN_BUTTON": "Sign-in",
                "FORGOT_BUTTON": "Forgot Password",
                "BACK_BUTTON": "Back",
                "PRIVACY": "Privacy",
                "LEGAL": "Legal",
                "CONTACT": "Contact",
                "TITLE": "Sign in to ART Server",
                "TITLE_GROUP_SERVER": "Sign in to ART Group Server",
                "TITLE_GROUP_SERVER_WORLD": "Sign in to CountR Telemetry Server",
                "MONTHS": {
                    "JANUARY": "January",
                    "FEBRUARY": "February",
                    "MARCH": "March",
                    "APRIL": "April",
                    "MAI": "Mai",
                    "JUNE": "June",
                    "JULY": "July",
                    "AUGUST": "August",
                    "SEPTEMBER": "September",
                    "OCTOBER": "October",
                    "NOVEMBER": "November",
                    "DECEMBER": "December"
                }
            },
            "LOGIN": {
                "TITLE": "Create Account",
                "BUTTON": "Sign In",
                "RESPONSE": {
                    "WEB_CLIENT": {
                        "LOGIN_NOT_POSSIBLE": {
                            "SUMMARY": "Login not possible",
                            "DETAIL": "Could not log in to system."
                        },
                        "LOGIN_DENIED": {
                            "SUMMARY": "Login denied",
                            "DETAIL": "Login is denied."
                        },
                        "INVALID_PASSWORD": {
                            "SUMMARY": "Login incorrect",
                            "DETAIL": "Invalid Password."
                        },
                        "INVALID_USERNAME": {
                            "SUMMARY": "Login incorrect",
                            "DETAIL": "Invalid Username."
                        },
                        "LOGIN_DISABLED": {
                            "SUMMARY": "Login disabled",
                            "DETAIL": "Login is disabled."
                        },
                        "NOT_LOGGED_IN": {
                            "SUMMARY": "Not logged in",
                            "DETAIL": "You are not logged in."
                        },
                        "SESSION_COUNT_EXCEEDED": {
                            "SUMMARY": "Session count exceeded",
                            "DETAIL": "Allowed session count is exceeded."
                        },
                        "ACCOUNT_TYPE_NOT_SUPPORTED": {
                            "SUMMARY": "Account type not supported",
                            "DETAIL": "Your Account type is not supported."
                        },
                    },
                    "GROUP_WEB_CLIENT": {
                        "LOGIN_DENIED": {
                            "SUMMARY": "Login denied",
                            "DETAIL": "Login is denied."
                        },
                        "LOGIN_DISABLED": {
                            "SUMMARY": "Login disabled",
                            "DETAIL": "Login is disabled."
                        },
                        "LOGIN_INCORRECT": {
                            "SUMMARY": "Login incorrect",
                            "DETAIL": "Login was not correct."
                        },
                        "LOGIN_CORRECT": {
                            "SUMMARY": "Login correct",
                            "DETAIL": "Login was correct."
                        },
                        "INVALID_PASSWORD": {
                            "SUMMARY": "Login incorrect",
                            "DETAIL": "Invalid Password."
                        },
                        "INVALID_USERNAME": {
                            "SUMMARY": "Login incorrect",
                            "DETAIL": "Invalid Username."
                        },
                    },
                }
            },
            "FORGOT": {
                "TITLE": "Forgotten Password?",
                "DESC": "Enter your email to reset your password",
            },
            "REGISTER": {
                "TITLE": "Sign Up",
                "DESC": "Enter your details to create your account",
                "SUCCESS": "Your account has been registered successfully. Please use your registered account to login."
            },
            "INPUT": {
                "EMAIL": "Email",
                "FULLNAME": "Login Name",
                "PASSWORD": "Password",
                "CONFIRM_PASSWORD": "Confirm Password",
            },
            "VALIDATION": {
                "INVALID": "{{name}} is not valid",
                "REQUIRED": "{{name}} is required",
                "MIN_LENGTH": "{{name}} minimum length is {{min}}",
                "AGREEMENT_REQUIRED": "Accepting terms & conditions are required",
                "NOT_FOUND": "The requested {{name}} is not found",
                "INVALID_LOGIN": "The login detail is incorrect",
                "TOAST": {
                    "INSUFFICIENT_RIGHTS": "Insufficient Rights - not allowed to view {{component}}",
                    "ACCESS_DENIED": "Access Denied!",
                }
            }
        },
        "BASE": {
            "TERMINAL": "Terminal",
            "TERMINALS": "Terminals",
            "PROPERTY": "Property",
            "PROPERTIES": "Properties",
            "TERMINALS_ALL": "All Terminals",
            "DATE": "Date",
            "OPERATOR": "Operator",
            "STATE": "State",
            "TYPE": "Type",
            "DETAILS": "Details",
            "DESCRIPTION": "Description",
            "CURRENCY": "Currency",
            "NAME": "Name",
            "ACTION": "Action",
            "AMOUNT": "Amount",
            "ID": "ID",
            "AVERAGE": "Average",
            "ENTRIES": "Entries",
            "ENTRIES_COUNT": "Entries: {{count}}",
            "ENTRIES_COUNT_2": "Entries: {{filteredCount}}/{{totalCount}}",
            "SURNAME": "Surname",
            "LAST_USED": "Last used",
            "PHONE_NUMBER": "Phone #",
            "BIRTHDAY": "Birthday",
            "CATEGORY": "Category",
            "TIME": "Time",
            "PERCENTAGE": "Percentage",
            "COUNT": "Count",
            "VERSION": "Version",
            "NOT_AVAILABLE": "N/A",
            "SAVE_AS": "Save as...",
            "UNKNOWN_TYPE": "Unknown Type ({{type}})",
            "UNKNOWN_TRANSLATION": "Missing Translation ({{text}})",
            "PIECES": "Pieces",
            "SUM": "Sum",
            "TERMINAL_BLOCK_UNBLOCK": "Terminal Block/Unblock",
            "HISTORY": "History",
            "MONTHS": {
                "JANUARY": "January",
                "FEBRUARY": "February",
                "MARCH": "March",
                "APRIL": "April",
                "MAY": "May",
                "JUNE": "June",
                "JULY": "July",
                "AUGUST": "August",
                "SEPTEMBER": "September",
                "OCTOBER": "October",
                "NOVEMBER": "November",
                "DECEMBER": "December"
            },
            "WEEK_DAY": {
                "MONDAY": "Montag",
                "TUESDAY": "Dienstag",
                "WEDNESDAY": "Mittwoch",
                "THURSDAY": "Donnerstag",
                "FRIDAY": "Freitag",
                "SATURDAY": "Samstag",
                "SUNDAY": "Sunday,"
            },
            "BUTTONS": {
                "LOAD": "Load",
                "SELECT_ALL": "Select all",
                "SELECT_NONE": "Select none",
                "OK": "OK",
                "REFRESH": "Refresh",
                "EDIT": "Edit",
                "SAVE": "Save",
                "ADD": "Add",
                "UPDATE": "Update",
                "CANCEL": "Cancel",
                "LEGEND": "Show Legend",
                "SEARCH": "Search",
                "TRANSACTION_SEARCH": "Search Transactions",
                "DELETE": "Delete",
                "BLOCK_USER": "Block User",
                "UNBLOCK_USER": "Unblock User",
                "REGISTRATION_DATE": "Registration Date",
                "DOWNLOAD": "Download",
                "DOWNLOAD_PDF": "Download PDF",
                "DOWNLOAD_EXCEL": "Download Excel",
                "PRINT": "Print",
                "CLOSE": "Close",
                "SYNC_V_TRADE": "Synchronize VTrade",
                "AUTO_CYCLE": "Auto Cycle",
                "AUTO_REFRESH": "Auto Refresh",
                "YES": "Yes",
                "NO": "No",
                "GENERATE": "Generate",
                "COPY": "Copy",
                "COPY_TO_CLIPBOARD": "Copy to Clipboard",
                "MAXIMIZE": "Maximize",
                "TABLE": "Table"
            },
            "FILTER": {
                "TITLE": "Filter",
                "CASINO_DAY": "Casino Day",
                "DATE": "Date & Time (From/To)",
                "TERMINALS": "Terminals",
                "OPERATOR": "Operator",
                "CURRENCY": "Currency",
                "ALL": "All",
                "PERIOD": "Period",
                "TODAY": "Today",
                "DAY": "Day",
                "WEEK": "Week",
                "MONTH": "Month",
                "YEAR": "Year",
                "TIME": "Time",
                "TERMINAL": "Terminal",
                "FROM": "From",
                "TO": "To",
                "SUMMARY_ONLY": "Summary Only",
                "UTC": "Date on Client",
                "PAGE": "Page",
                "ENTRIES_PER_PAGE": "Entries per page",
                "OF": "of"
            },
            "SEARCH": {
                "FIND": "Find",
                "EXCLUDE": "Exclude",
            },
            "LOADING": {
                "TITLE": "Please wait! Loading..."
            },
            "MESSAGES": {
                "WEBSOCKET_ERROR": "The websocket is not connected yet. The connection is established in the background. Live changes are currently not displayed.",
            },
            "TOASTS": {
                "EXPORT": {
                    "NO_VALID_TYPE": {
                        "TITLE": "No Valid Export Type",
                        "TEXT": "No valid export type"
                    }
                },
                "TIMESPAN": {
                    "INVALID": {
                        "TITLE": "Invalid timespan",
                        "TEXT": "Please choose a 'from' date lower than the 'to' date."
                    }
                },
                "CONNECTION": {
                    "NO": {
                        "TITLE": "No Response",
                        "TEXT": "No response from backend."
                    }
                }
            }
        },
        "USER": {
            "MENU": {
                "ART_WEB_CLIENT_VERSION": "ART Web Client",
                "ART_SERVER_VERSION": "ART Server",
                "ART_GROUP_SERVER_VERSION": "ART Group Server",
                "ART_SERVER_URL": "ART Server URL",
                "ART_GROUP_WEB_CLIENT_VERSION": "ART Group Web Client",
                "LOGOUT": "Logout",
                "CHOOSE_LANGUAGE": "Choose language...",
                "CURRENT_USER_SESSIONS": "Current User Sessions ",
                "ART_ABOUT": "About ART",
                "USER_PROFILE": "User Profile",
                "WEBSITE": "Website",
                "UPDATE_GROUP_CLIENT": "Update Group Client",
            },
            "MODAL": {
                "UPDATE": "Update ART Group Web Client",
                "SELECT": "Please select the ART Group Web Client update file (*.zip)",
                "START_UPDATE": "Start Update",
                "SUCCESS_UPDATED": "ART Group Web Client successfully updated.",
                "UPDATE_SUCCESS": "Update successful",
                "SERVER_EXCEPTION": "Server exception, please try again.",
                "UPDATE_EXCEPTION": "Update exception",
            },
            "CURRENT_OPERATOR_SESSIONS": {
                "REFRESH": "Refresh",
                "ID": "ID",
                "OPERATOR": "Operator",
                "LOGGED_IN_SINCE": "Logged-in Since",
                "CLIENT_TYPE": "Client Type",
                "ENDPOINT": "Endpoint",
                "COMPUTER": "Computer",
            },
            "USER_PROFILE": {
                "TITLE": "Profile",
                "USER_INFORMATION": {
                    "GROUP": {
                        "TITLE": "User Information",
                        "USERNAME": "Username",
                    },
                    "TITLE": "User Information",
                    "OPERATOR_ID": "Operator ID",
                    "OPERATOR_NAME": "Operator Name"
                },
                "PASSWORD": {
                    "TITLE": "Password",
                    "OLD_PASSWORD": "Old Password",
                    "NEW_PASSWORD": "New Password",
                    "CONFIRM_PASSWORD": "Confirm Password",
                    "BUTTON": {
                        "SAVE_PASSWORD": "Save Password"
                    }
                },
                "INDIVIDUAL_CONFIGURATION": {
                    "TITLE": "Customization",
                    "ENTRY_PAGE": "Entry Page",
                    "SELECT_AN_ENTRY_PAGE_PLACEHOLDER": "Select an Entry Page",
                    "LANGUAGE": "Language",
                    "CURRENCY": "Currency",
                    "CURRENCY_SYMBOL": "Currency Symbol",
                    "NUMBER_FORMAT": "Number Format",
                    "DATE_LONG": "Date Format long",
                    "DATE_MIDDLE": "Date Format middle",
                    "DATE_SHORT": "Date Format short",
                    "DATE_HOUR": "Date Format hour",
                    "LANGUAGE_CHANGED": "Your language has changed",
                    "ENTRY_PAGE_CHANGED": "Your entry page has changed",
                }
            }
        },
        "MONITORING": {
            "MAP_OVERVIEW": {
                "TITLE": "ART Distribution Overview",
                "FILTER": {
                    "PROPERTY_NAME": "Property Name"
                },
                "BUTTONS": {
                    "INSTALLATIONS": "Installations",
                    "ART_INSTALLATIONS": "ART Installations",
                    "TERMINAL_TYPE": "Terminal Type"
                },
                "BOARD": {
                    "TITLE": "Terminals per Installation"
                },
                "SETTINGS": {
                    "TITLE": "Map Overview Settings",
                    "SHOW_ICONS": "Show Icons",
                    "SATELLITE": "Natural View",
                    "SHOW_COLOR_BOARD": "Show Colorboard",
                    "SHOW_INSTALLATIONS": "Show ART Installations",
                    "SHOW_PROPERTIES": "Show Properties",
                    "SHOW_TERMINALS": "Show Terminals",
                    "SHOW_MODE": "Show Modes",
                }
            },
            "STOCK_OVERVIEW": {
                "TITLE": "Stock Overview",
                "TABLE": {
                    "TOTAL": "Total",
                    "TERMINAL_ID": "Terminal ID",
                    "TERMINAL": "Terminal",
                    "INSTALLATION": "ART Installation",
                    "STOCK_DATE": "Stock Date",
                    "MEDIA_IN": "Tickets In",
                    "MEDIA_OUT": "Tickets Out",
                    "MEDIA_REJECT": "Reject Box",
                    "COIN_ACCEPTOR_TOTAL": "Coin Acceptor Total",
                    "COIN_DISPENSER_TOTAL": "Coin Dispenser Total",
                    "BILL_ACCEPTOR_TOTAL": "Bill Acceptor Total",
                    "BILL_DISPENSER_TOTAL": "Bill Dispenser Total",
                    "COIN_ACCEPTOR_PER_DENO": "Coin Acceptor per Deno",
                    "COIN_DISPENSER_PER_DENO": "Coin Dispenser per Deno",
                    "BILL_ACCEPTOR_PER_DENO": "Bill Acceptor per Deno",
                    "BILL_DISPENSER_PER_DENO": "Bill Dispenser per Deno",
                }
            },
            "COMPONENT_OVERVIEW": {
                "TITLE": "Component Overview",
                "TABLE": {
                    "SOURCE": "Source",
                    "DATE": "Date",
                    "SEVERITY": "Severity",
                    "EVENT": "Event"
                },
                "LABEL": {
                    "TERMINALS_WITH_ISSUES": "Terminals with Issues ",
                    "TERMINALS_WITHOUT_ISSUES": "Terminals without Issues ",
                    "TERMINAL_WITH_ISSUES": "Terminal with Issues ",
                    "TERMINAL_WITHOUT_ISSUES": "Terminal without Issues "
                },
                "EVENT": {},
                "SEVERITY": {
                    "WARNING": "Warning",
                    "ERROR": "Error",
                    "INFORMATION": "Information",
                    "FATAL": "Fatal"
                },
                "SOURCE": {
                    "COMPONENT": "Component",
                },
                "FILTER_BAR": {
                    "SINCE_LAST_STARTUP": "Only Since Last Start-up",
                    "INCLUDE_GLOBAL_EVENTS": "Include Global Events",
                    "INCLUDE_SUBCOMPONENT_EVENT": "Include Sub Component Events",
                },
            },
            "DASHBOARD": {
                "TOOLTIP": {
                    "DATA_SHOWN_FOR_THE_PAST_TIME": "Data shown for the past",
                    "REFRESHES_EVERY": "Refreshes every",
                    "LIVE_DATA": "Live Data",
                    "DAY": "Day",
                    "WEEK": "Week",
                    "MONTH": "Month",
                    "PRESENTATION_MODE": "Presentation Mode"
                },
                "WIDGET": {
                    "TITLE": "Widget",
                    "MONEY_SERVICE": {
                        "MODAL": {
                            "TABLE": {
                                "TERMINAL_NAME": "Terminal Name",
                                "MONEY_SERVICE_COUNT": "Money Service count",
                                "FIRST_MONEY_SERVICE": "First Money Service - Last 7 Days",
                                "LAST_MONEY_SERVICE": "Last Money Service - Last 7 Days"
                            }
                        }
                    },
                    "MONEY_SERVICE_ACTION_REQUIRED": {
                        "MODAL": {
                            "TABLE": {
                                "TERMINAL_NAME": "Terminal Name",
                                "REJECT_BOX": "Reject Box",
                                "CASSETTE": "Cassette",
                                "COIN_HOPPER": "Coin Hopper",
                                "BILL_ACCEPTOR": "Bill Acceptor",
                                "CARD_STACKER": "Card Stacker",
                                "EMPTY": "Empty",
                                "LOW": "Low",
                                "OK": "Ok",
                                "THRESHOLD": "Threshold",
                                "FULL": "Full"
                            }
                        }
                    },
                    "TERMINAL_OUT_OF_OPERATION": {
                        "MODAL": {
                            "TABLE": {
                                "NONE": "None",
                                "YES": "Yes",
                                "OUT_OF_OPERATION": "Out of operation",
                                "OFFLINE_BY_OPERATOR": "Offline by operator",
                                "COMPONENT_IN_ERROR_STATE": "Component in error state",
                                "MONEY_SERVICE_ACTION_REQUIRED": "Money service action required"
                            }
                        }
                    },
                    "TRANSACTION_TYPES": {
                        "SWITCHER": {
                            "TOOLBAR": "Group Transaction Types",
                        }
                    },
                    "TERMINAL_ISSUES": {
                        "MODAL": {
                            "TABLE": {
                                "TERMINAL_NAME": "Terminal",
                                "COMPONENT_WARNINGS": "Component Warnings",
                                "COMPONENT_ERRORS": "Component Errors"
                            }
                        }
                    },
                    "TERMINAL_UPTIME": {
                        "MODAL": {
                            "TITLE": "Terminal Uptime for ",
                            "GRAPH": "Graph",
                            "TERMINAL_UPTIME": "Terminal Uptime",
                            "ART_INSTALLATION_AVERAGE_UPTIME": "ART Installation average uptime",
                            "PROPERTY_AVERAGE_UPTIME": "Property average uptime"
                        }
                    },
                    "SOFTWARE_DISTRIBUTION": {
                        "OTHERS": "Others",
                        "BUTTONS": {
                            "MAXIMIZE_WIDGET": "Maximize Widget"
                        },
                        "SWITCHER": {
                            "TOOLBAR": "Show Only Top {{x}}",
                        },
                    },
                    "SOFTWARE_DISTRIBUTION_OS": {
                        "BUTTONS": {
                            "MAXIMIZE_WIDGET": "Maximize Widget"
                        },
                        "SWITCHER": {
                            "TOOLBAR": "Show Only Top {{x}}",
                        },
                    }
                }
            },
            "DASHBOARD_DOWNTIME": {
                "TITLE": "Dashboard - Downtime",
                "TABLE": {
                    "KIOSK": "Kiosk",
                    "COMPONENT_DOWNTIME": "Component Downtime",
                    "OPERATIONAL_DOWNTIME": "Operational Downtime",
                    "TOTALS": "Totals",
                    "STOCK": "Stock",
                    "TERMINAL_NAME": "Terminal Name",
                    "TERMINAL_TYPE": "Terminal Type",
                    "HARDWARE_ERRORS": "Technical Errors",
                    "SET_OFFLINE": "Set Offline",
                    "LOGGED_IN": "Logged In",
                    "MONEY_SERVICE_REQUIRED": "Money Service Required",
                    "PERFORMING_MONEY_SERVICE": "Performing Money Service",
                    "TURNED_OFF": "Turned Off",
                    "DOWNTIME": "Downtime",
                    "UPTIME": "Uptime",
                    "LOW_ON_STOCK": "Low on Stock",
                    "SITE_AVERAGE": "Site Average",
                    "MONEY_SERVICE": "Money Service"
                }
            }
        },
        "TERMINAL": {
            "BASE": {
                "VIEW_SWITCH": {
                    "LIST": "Terminal List View",
                    "ICON": "Terminal Icon View",
                    "FILL_LEVEL": "Fill Level View"
                },
                "FILTER": {
                    "CONNECTION": {
                        "MAIN": "Connection"
                    },
                    "TERMINAL_STATE": {
                        "TITLE": "Terminal State",
                        "MAIN": "State",
                        "CONNECTED": "Connected",
                        "NOT_CONNECTED": "Not Connected",
                        "FAILURE": "Failure",
                        "COMPONENT_STATE": "Component State",
                    },
                    "PROPERTY": {
                        "MAIN": "Property",
                    },
                    "TERMINAL": {
                        "MAIN": "Terminal",
                    },
                    "TERMINAL_TYPE": {
                        "MAIN": "Type",
                    },
                    "BUTTONS": {
                        "CLEAR": "Clear",
                    },
                    "OF": "of",
                },
                "TERMINAL_STATUS": {
                    "CONNECTED": "Connected",
                    "DISCONNECTED": "Disconnected",
                },
            },
            "LOGFILES": {
                "MODAL": {
                    "COMPONENT": "Please select the component you wish to send an error report for:",
                    "TIMESPAN": "Select the time of the error occurrence. Note: choose at least one hour before occurrence:"
                },
            },
            "CONFIRMATION_TOASTS": {
                "HARDWARE_RESTART": {
                    "SUMMARY_FIRST": "Are you sure to restart the hardware of terminal",
                    "SUMMARY_LAST": "",
                    "DETAIL": "Confirm to proceed",
                    "IDLE": "Hardware restart when Idle",
                    "IMMEDIATELY": "Hardware restart Immediately"
                },
                "SOFTWARE_RESTART": {
                    "SUMMARY_FIRST": "Are you sure to restart the software of terminal",
                    "SUMMARY_LAST": "",
                    "DETAIL": "Confirm to proceed",
                    "IDLE": "Software restart when idle",
                    "IMMEDIATELY": "Software restart immediately"
                },
                "SHUTDOWN": {
                    "SUMMARY_FIRST": "Are you sure to shutdown terminal",
                    "SUMMARY_LAST": "",
                    "DETAIL": "Confirm to proceed",
                    "IDLE": "Shutdown when Idle",
                    "IMMEDIATELY": "Shutdown Immediately"
                },
                "GENERAL": {
                    "CANCEL": "Cancel"
                }
            },
            "CONTEXT_MENU": {
                "SHOW_TERMINAL_DASHBOARD": "Show Terminal Dashboard",
                "SHOW_COMPONENT_OVERVIEW": "Show Component Overview",
                "SHOW_FEATURE_OVERVIEW": "Show Feature Overview",
                "SET_ONLINE": "Set Online",
                "SET_OFFLINE": "Set Offline",
                "SOFTWARE_RESTART": "Software Restart",
                "TERMINAL_RESTART": "Terminal Restart",
                "SHUT_DOWN": "Shut Down...",
                "SHOW_TRANSACTIONS": "Show Transactions",
                "LOGFILES": "Request Log Files...",
                "REMOTE_ACCESS": "Start Remote Access",
                "REMOTE_ACCESS_OTK": "Remote Access with OTK",
                "REMOTE_ACCESS_SSL_REQUIRED": "Start Remote Access (SSL required)"
            },
            "DETAILS": {
                "NO_OPERATOR_LOGGED_IN": "No operator logged in",
                "OPERATOR_LOGGED_IN": "Operator logged in",
                "SHOW_STOCK_FOR": "Show Stock for",
                "BUTTONS": {
                    "CLOSE_DETAILS": "Close details",
                    "SHOW_DETAILS": "Show details",
                    "SHOW_MORE": "Show more",
                },
                "VERSION": {
                    "MAIN": {
                        "TITLE": "Versions",
                        "SOFTWARE": "Software",
                        "CONFIGURATION": "Configuration",
                        "COS": "COS",
                        "ICOS": "I-COS",
                    },
                },
                "STOCK": {
                    "MAIN": "Stock",
                    "BILL_ACCEPTOR": "Bill acceptor",
                    "BILL_DISPENSER": "Bill dispenser",
                    "COIN_ACCEPTOR": "Coin acceptor",
                    "COIN_DISPENSER": "Coin dispenser",
                    "TOTAL_CASH": "Total cash",
                    "TICKETS_IN": "Tickets In",
                    "TICKETS_OUT": "Tickets Out",
                    "CARDS_IN": "Cards In",
                    "CARDS_OUT": "Cards Out",
                    "CHIPS_IN": "Chips In",
                    "CHIPS_OUT": "Chips Out",
                    "NOTES_IN": "Notes In",
                },
                "TITA_CATA": {
                    "TITLES": {
                        "TICKETS": "Tickets",
                        "CHIPS": "Chips",
                        "COUPONS": "Coupons",
                        "MARKERS": "Markers",
                        "BILLS": "Bills",
                        "BILL_ACCEPTOR": "Bill Acceptor",
                        "CARDS": "Cards",
                    },
                    "WITHHELD": "Withheld",
                    "PAID": "Paid",
                },
                "BILL_ACCEPTOR": {
                    "MAIN": "Bill Acceptor",
                    "TOTAL_BILLS": "Total Bills",
                    "TOTAL_ACCEPTOR": "Total Acc.",
                    "TICKETS_IN": "Tickets in",
                    "TICKETS_OUT": "Tickets out"
                },
                "BILL_DISPENSER": {
                    "MAIN": "Bill Dispenser",
                    "TOTAL_BILLS": "Total Bills",
                    "REJECT": "Reject",
                },
                "COIN_DISPENSER": {
                    "MAIN": "Coin Dispenser",
                    "TOTAL_COINS": "Total Coins",
                },
                "CARDS": {
                    "MAIN": "Cards",
                    "STACKER": "Stacker",
                    "CATCHING_BIN": "Catching bin",
                },
                "SAM": {
                    "MAIN": "SAM",
                    "VALUE": "Value",
                },
                "ROUNDING_DIFFERENCES": {
                    "MAIN": "Rounding differences for",
                    "TICKETS": "Tickets",
                    "PLAYER_CARDS": "Player Cards",
                    "BONUS_CARDS": "Bonus Cards",
                    "JACKPOT": "Jackpot",
                    "BILLS": "Bills",
                    "TOTAL": "Total",
                },
                "MEDIA": {
                    "MAIN": "Media",
                    "WITHHELD": "Withheld",
                    "PAID": "Paid",
                    "DIFFERENCES": "Differences",
                },
                "MEDIA_DEPOSIT": {
                    "MAIN": "Media Deposit",
                    "WITHHELD": "Withheld",
                    "PAID": "Paid",
                    "DIFFERENCES": "Differences",
                },
                "COIN_ACCEPTOR": {
                    "MAIN": "Coin Acceptor",
                    "TOTAL_COINS": "Total Coins",
                },
                "TRANSACTIONS": {
                    "MAIN": "Transactions",
                }
            },
            "SINGLE_DETAILS": {
                "TABS": {
                    "STATUS": "Status",
                    "SETTINGS": "Settings",
                    "ADDRESS": "Address",
                    "TRANSACTIONS": "Transactions",
                    "ERRORS": "Errors",
                    "EVENTS": "Events",
                    "MEDIA": "Media",
                    "MAINTENANCE": "Maintenance",
                    "OPERATOR_ACCESS": "Operator Access",
                    "CONFIGURATION_CHANGES": "Configuration Changes",
                    "STOCK_VERSION": "Stock / Version",
                    "IDENTIFIERS": "Identifiers"
                }
            },
            "GENERAL": {
                "REQUEST_LOG_FILES": "Log Files (Error Report)"
            },
            "TERMINAL_ICON": {
                "GENERAL": {
                    "OPEN_DETAIL": "Open terminal detail view",
                },
                "MODULE": {
                    "JACKPOT_ONLINE_READY": "Module is online and ready to use",
                    "JACKPOT_NOT_CONNECTED": "Module is not connected",
                    "ATM_ONLINE_READY": "Module is online and ready to use",
                    "ATM_NOT_CONNECTED": "Module is not connected",
                    "ATM_CONNECTED_NOT_LOGGED_IN": "Module is connected but not logged in",
                    "ATM_CONNECTED_NO_KEYS": "Module is logged in, but keys are not exchanged yet",
                    "ATM_DEACTIVATED": "ATM functionality is deactivated",
                    "ATM_NO_INFORMATION": "No information about the ATM functionality is available",
                    "COS_ONLINE_READY": "Module is online and ready to use",
                    "COS_NOT_CONNECTED": "Module is not connected",
                },
                "CONNECTION": {
                    "TERMINAL_OFFLINE": "Terminal is not connected to ART",
                    "TERMINAL_CONNECTED": "Terminal is connected to ART",
                },
                "STATUS": {
                    "TERMINAL_TECHNICIAN": "A service technician is logged on",
                    "TERMINAL_OPERATOR": "A operator is logged on",
                },
                "TRANSACTION": {
                    "ACTIVE": "The terminal is currently processing a transaction",
                },
            },
            "TERMINAL_IDENTIFIER": {
                "TITLE": "Terminal Identifiers",
                "EMERGENCY_PIN": "Emergency PIN",
                "TABLE": {
                    "TERMINAL_ID": "Terminal ID",
                    "IP_ADDRESS": "IP Address",
                    "SERIAL_NUMBER": "Serial #",
                    "MAC_ADDRESS": "MAC Address",
                    "CPU_ARCH_HARDWARE": "CPU Arch Hardware",
                    "CPU_ARCH_SOFTWARE": "CPU Arch Software",
                    "NAME": "Name",
                    "LOCATION": "Location",
                    "TIME_ZONE": "Time Zone",
                    "SYSTEM_CURRENCY": "System Currency",
                    "PROPERTY_NUMBER": "Property #",
                    "BANK_TERMINAL_ID": "Bank Terminal ID",
                    "KIOSK_ID": "Kiosk ID",
                    "OPERATING_SYSTEM": "Operating System",
                    "RUNTIME_ENVIRONMENT": "Runtime Environment",
                    "SOFTWARE_VERSION": "Software Version",
                    "CONFIG_VERSION": "Configuration Version",
                    "ONLINE_SYSTEM": "Online System",
                    "DISTRIBUTOR_NUMBER": "Distributor #",
                    "CUSTOMER_NUMBER": "Customer #",
                    "LOCATION_ID": "Location ID",
                    "VPN_STATE": "VPN State"
                },
            },
            "TERMINAL_LIST": {
                "TABLE": {
                    "NAME": "Name",
                    "COS": "COS",
                    "JOS": "JOS",
                    "ATM": "ATM",
                    "STATUS": "Status",
                    "TOTAL": "Total",
                    "COIN_ACCEPTOR": "Coin Acceptor",
                    "COIN_DISPENSER": "Coin Dispenser",
                    "ACCEPTOR": "Acceptor",
                    "NOTES_IN": "Notes In",
                    "TICKETS_IN": "Tickets In",
                    "DISPENSER": "Dispenser",
                    "REJECT_BOX": "Reject Box",
                    "MEDIA_IN": "Media In",
                    "MEDIA_OUT": "Media Out",
                    "MEDIA_DIFF": "Media Diff",
                    "DEPOSIT": "Deposit"
                }
            },
            "TERMINAL_ADDRESS": {
                "SPECIFIC_LOCATION": {
                    "TITLE": "Specific Location",
                    "LOCATION_NAME": "Location Name"
                },
                "SPECIFIC_POSTAL_ADDRESS": {
                    "TITLE": "Specific Postal Address",
                    "ADDRESS_LINE_2": "Address Line 2",
                    "ADDRESS_LINE_1": "Address Line 1",
                    "COUNTY": "County",
                    "CITY": "City",
                    "COUNTRY": {
                        "TITLE": "Country",
                        "NOT_SPECIFIED": "(Not Specified)",
                    },
                    "STATE": "State",
                    "ZIP_CODE": "ZIP Code"
                },
                "SPECIFIC_CONTACT_INFORMATION": {
                    "TITLE": "Specific Contact Information",
                    "PHONE": "Phone",
                    "EMAIL_ADDRESS": "E-Mail Address",
                },
                "SPECIFIC_CONTACT_PERSON": {
                    "TITLE": "Specific Contact Information",
                    "CONTACT_NAME": "Contact Name",
                },
                "SPECIFIC_TIME_ZONE": {
                    "TITLE": "Specific Time Zone",
                    "TIME_ZONE": "Time Zone",
                },
            }
        },
        "MANAGEMENT": {
            "JACKPOT_PAYOUTS": {
                "TITLE": "Jackpot Forms",
                "MODAL": {
                    "ADD_NEW_JACKPOT_PAYOUT": "Add Jackpot Payout Form W-2G",
                    "ADD_NEW_JACKPOT_PAYOUT_1042": "Add Jackpot Payout Form 1042-S",
                    "ADD_NEW_JACKPOT_PAYOUT_SIGNATURE": "Add new jackpot payout signature",
                    "ADD_NEW_JACKPOT_PAYOUT_CHECK": "Confirm Jackpot",
                    "ADD_NEW_JACKPOT_PAYOUT_GENERAL_INFORMATION": "New jackpot payout general information",
                    "VIEW_JACKPOT_PAYOUT": "View Jackpot Payout",
                    "ADD_NEW_JACKPOT_PAYOUT_TIN_CHECK": "TIN Check failed",
                    "ADD_NEW_JACKPOT_PAYOUT_JACKPOT_SYSTEM_LOGIN": "Light & Wonder Login",
                    "ADD_NEW_JACKPOT_PAYOUT_JACKPOT_SYSTEM_LIST": "Light & Wonder Jackpot List",
                    "ADD_NEW_JACKPOT_PAYOUT_VALIDITY_CHECK_ERROR": "Jackpot Validation Errors",
                    "ADD_NEW_JACKPOT_PAYOUT_VALIDITY_CHECK": "Additional Validation Parameters",
                },
                "BUTTONS": {
                    "TAKE_PICTURE": "Take a picture",
                    "SWITCH_CAMERA": "Switch the camera",
                    "PICK_PICTURE": "Pick a picture",
                    "SIGNATURE_NEW": "Take new signature",
                    "SIGNATURE_CLEAR": "Clear signature",
                    "CLOSE": "Close",
                    "SAVE": "Save",
                    "CONFIRM_SAVE": "Confirm and save",
                    "CANCEL": "Cancel",
                    "NEXT": "Next",
                    "BACK": "Back",
                    "CHECK": "Check",
                    "SCAN": "Scan Barcode",
                    "CHECK_TIN": "Start TIN Check",
                    "VALIDITY_CHECK": "Start Validity Check",
                },
                "FIELDS": {
                    "GENERAL": {
                        "KEYBACK_AMOUNT": "Keyback Amount",
                        "PAY_AS_TICKET_AMOUNT": "Pay as Ticket Amount",
                        "PAY_AS_CHECK_AMOUNT": "Pay as Check Amount",
                        "PAY_AS_WALLET_AMOUNT": "Pay as Wallet Amount",
                        "PAY_AS_WIRE_ACH_AMOUNT": "Pay as Wire/ACH Amount",
                        "LICENSE_FRONT": "License Front",
                        "LICENSE_REAR": "License Rear",
                        "WINNING_COMBINATION": "Winning Combination",
                        "SECONDARY_ID_FRONT": "Secondary ID Front",
                        "SECONDARY_ID_REAR": "Secondary ID Rear",
                        "SIGNATURE": "Signature"
                    },
                    "W2G": {
                        "REPORTABLE_WINNINGS": "Reportable winnings",
                        "DATE_WON": "Date won",
                        "TYPE_OF_WAGER": "Type of wager",
                        "FEDERAL_INCOME_TAX_WITHHELD": "Federal income tax withheld",
                        "TRANSACTION": "Transaction",
                        "RACE": "Race",
                        "WINNINGS_FROM_IDENTICAL_WAGERS": "Winnings from identical wagers",
                        "CASHIER": "Cashier",
                        "WINNERS_TAXPAYER_IDENTIFICATION_NUMBER": "Winner's taxpayer identification no.",
                        "WINDOW": "Window",
                        "FIRST_IDENTIFICATION": "First identification",
                        "SECOND_IDENTIFICATION": "Second identification",
                        "STATE_PAYERS_STATE_IDENTIFICATION_NUMBER": "State/Payer's state identification no.",
                        "STATE_WINNINGS": "State winnings",
                        "STATE_INCOME_TAX_WITHHELD": "State income tax withheld",
                        "LOCAL_WINNINGS": "Local winnings",
                        "LOCAL_INCOME_TAX_WITHHELD": "Local income tax withheld",
                        "NAME_OF_LOCALITY": "Name of locality",
                        "NAME": "Name",
                        "NUMBER_STREET": "Number/street",
                        "CITY_OR_TOWN": "City or town",
                        "STATE": "State",
                        "ZIP_CODE": "ZIP code",
                        "FEDERAL_IDENTIFICATION_NUMBER": "Federal identification number",
                        "TELEPHONE_NUMBER": "Telephone number",
                        "EMAIL": "E-Mail Address"
                    }
                },
                "HEADLINES": {
                    "GENERAL_INFORMATION": "General information",
                    "PAYERS_INFORMATION": "Payer's information",
                    "WINNERS_INFORMATION": "Winner's information",
                    "SETTLEMENT_INFORMATION": "Settlement information",
                    "DOCUMENT_INFORMATION": "Document information"
                },
                "STEPS": {
                    "GENERAL": {
                        "GENERAL": "General",
                        "TAX": "Tax",
                        "AMOUNTS": "Amounts",
                        "DOCUMENTS": "Documents"
                    },
                    "1042": {
                        "GENERAL_INFORMATION": "General",
                        "WITHHOLDING_INFORMATION": "Withholding",
                        "RECIPIENT_INFORMATION": "Recipient",
                        "PRIMARY_WITHHOLDING_INFORMATION": "Primary withholding",
                        "INTERMEDIARY_INFORMATION": "Intermediary",
                        "PAYER_INFORMATION": "Payer",
                        "ADDITIONAL_INFORMATION": "Additional",
                        "CONFIRM": "Confirm"
                    },
                    "W2G": {
                        "GENERAL_INFORMATION": "General",
                        "PAYER_INFORMATION": "Payer",
                        "WINNER_INFORMATION": "Winner",
                        "ADDITIONAL_INFORMATION": "Secondary ID",
                        "CONFIRM": "Confirm"
                    }
                }
            },
            "COMPUTER_REGISTRATION": {
                "TITLE": "Computer Registration",
                "TABLE": {
                },
                "MODALS": {
                    "ADD_COMPUTER": "Add Computer Registration",
                    "COMPUTER": "Computer",
                    "COMPONENT": "Component"
                }
            },
            "KIOSK_REGISTRATION": {
                "ADD": {
                    "TERMINAL_SERIAL_NUMBER": "Terminal Serial Number",
                    "TERMINAL_HARD_DISK_I_D": "Terminal Hard Disk ID",
                    "TERMINAL_M_A_C_ADDRESS": "Terminal MAC Address",
                    "DISTRIBUTOR_NUMBER": "Distributor Number",
                    "TERMINAL_REGISTRATION_TYPE": "Terminal Registration Type",
                    "REGISTRATION_TIMESTAMP": "Registration Timestamp",
                    "TERMINAL_REGISTRATION_VALIDITY_AFTER_DATE": "Registration Valid From",
                    "TERMINAL_REGISTRATION_VALIDITY_UNTIL_DATE": "Registration Valid To",
                    "TERMINAL_TYPE": "Terminal Type",
                    "COMMENTS": "Comments"
                },
                "TOOLBAR": {
                    "PREFIX": "Prefix",
                    "SERIAL_NUMBER": "Serial#",
                    "SERIAL_NUMBER_START": "Serial Number From",
                    "SERIAL_NUMBER_END": "Serial Number To",
                }
            },
            "MANUFACTURING_ORDER_OVERVIEW": {
                "TITLE": "Manufacturing Order Overview",
                "FILTER": {
                    "SERIAL_NUMBER_START": "Serial Number From",
                    "SERIAL_NUMBER_END": "Serial Number To",
                    "ORDER_ID": "Order ID",
                    "ORDERS": "Orders",
                    "ORDER": "Order",
                    "MODE": "Choose Mode",
                    "FIND_BY_SERIAL_NUMBER": "Find by Terminal Serial #",
                    "FIND_BY_ORDER_ID": "Find by Manufacturing Order ID",
                },
                "TOOLBAR": {
                    "SUCCESSFUL": "Successful",
                    "INCOMPLETE": "Incomplete"
                },
                "TABLE": {
                    "LAST_TEST_RUN_RESULT": "Last Test Run Result",
                },
            },
            "MANUFACTURING_ORDERS": {
                "TABLE": {
                    "ART_ID": "Factory",
                    "PRODUCTION_DATE": "Production Date",
                    "PRODUCTION_ID": "Production ID",
                    "ORDER_NUMBER": "Order #",
                    "PART_NUMBER": "Part #",
                    "PART_DESCRIPTION": "Part Description",
                    "SERIAL_NUMBER_FROM": "S/N From",
                    "SERIAL_NUMBER_TO": "S/N To",
                    "SERIAL_NUMBERS": "Serial Numbers",
                    "PARTS": {
                        "TITLE": "Parts",
                        "ID": "Part ID",
                        "NUMBER": "Part Number",
                    },
                    "FILES": {
                        "TITLE": "Files",
                        "FILE_ID": "File ID",
                        "FILE_TYPE": "File Type",
                        "FILE_NAME": "File Name",
                        "LOAD_NEWEST": "Load Newest",
                        "INSTALLATION_PRIORITY": "Installation Priority",
                    },
                    "VALUE": "Value",
                    "PROPERTIES": "Properties",
                },
                "DETAILS": "Order Details",
            },
            "USER_MANAGEMENT": {
                "TITLE": "Users",
                "TABLE": {
                    "FIRST_NAME": "First Name",
                    "SECOND_NAME": "Last Name",
                    "EMAIL": "E-Mail Address",
                    "LOGIN_NAME": "Login Name",
                    "RIGHTS": "Rights",
                    "ACTIONS": "Actions",
                    "LAST_UPDATE": "Last Update",
                    "CREATION_DATE": "Creation Date"
                },
                "MODALS": {
                    "ADD_USER_TITLE": "Add User",
                    "EDIT_USER_TITLE": "Edit User",
                    "EDIT_PASSWORD_TITLE": "Edit Password",
                    "PASSWORD": "Password",
                    "PASSWORD_CONFIRM": "Confirm Password"
                },
                "BUTTONS": {
                    "ADD_USER": "Add User",
                    "DELETE_USER": "Delete User",
                    "EDIT_USER": "Edit User",
                    "EDIT_PASSWORD": "Edit Password",
                    "CANCEL": "Cancel",
                    "SAVE": "Save"
                },
                "TOOLTIPS": {
                    "SAVE_MODE_ACTIVE": "Please save the open password areas first"
                },
                "TOAST": {
                    "DO_YOU_WANT_TO_DELETE_USER": "Do you really want to delete user '{{firstName}} {{lastName}}'",
                    "CONFIRM_TO_PROCEED": "Confirm to proceed",
                    "USER_ADDED": {
                        "TITLE": "User added",
                        "DETAILS": "User '{{username}}' has been added!"
                    },
                    "USER_UPDATED": {
                        "TITLE": "User updated",
                        "DETAILS": "User '{{username}}' has been updated!",
                    },
                    "USER_DELETED": {
                        "TITLE": "User deleted",
                        "DETAILS": "User '{{username}}' has been deleted!"
                    },
                    "PASSWORD": {
                        "SUCCESSFUL": "Password Updated successfully",
                        "UPDATED": "Password has been Updated",
                        "NOT_SUCCESSFUL": "Password update was not successful"
                    },
                    "NOT_SUCCESSFUL": {
                        "TITLE": "User {{state}} was not successful",
                        "DETAILS": {
                            "USER_KEY_NOT_VALID": "User '{{username}}' - Your session key is not valid",
                            "USER_HAS_NO_ENOUGH_RIGHTS": "User '{{username}}' - You don't have enough rights to {{state}} new users!",
                            "USERNAME_REQUIRED": "User '{{username}}' - Username required!",
                            "FIRSTNAME_REQUIRED": "User '{{username}}' - First name required!",
                            "LASTNAME_REQUIRED": "User '{{username}}' - Last name required!",
                            "EMAIL_INVALID": "User '{{username}}' - E-Mail address is invalid!",
                            "USERNAME_ALREADY_EXIST": "User '{{username}}' - Username already exists!",
                            "PASSWORD_REQUIRED": "User '{{username}}' - Password required!",
                            "PASSWORD_TOO_SHORT": "User '{{username}}' - Password too short!",
                            "PASSWORD_IS_RECENT_PASSWORD": "User '{{username}}' - Password is recent password!",
                            "ADMIN_CANT_BE_DELETED": "User '{{username}}' - Admin cannot be deleted!",
                            "ADMIN_MUST_HAVE_ADMIN_RIGHTS": "User '{{username}}' - Admin must have \"Admin\" right!"
                        }
                    },
                },
            },
            "OPERATORS": {
                "TITLE": "Operators",
                "FILTER": {
                    "OPERATOR_ROLE": "Role",
                },
                "EXPORT": {
                    "EXPORT_ALL_OPERATOR": "Export All Operators",
                    "ADD_NEW_OPERATOR": "Add Operator",
                    "EXPORT_SELECTED_OPERATOR": "Export Selected Operator",
                },
                "PASSWORD_RULES": {
                    "HELP": "Current password restrictions:",
                    "STANDARD": "No empty password",
                    "STRONG": "Minimum a digit and a special character (! # $ % ' ( ) + , - . / : = ? @ €)",
                    "DOD": "Minimum a digit, a special character (! # $ % ' ( ) + , - . / : = ? @ €), an upper-case letter & a lower-case letter"
                },
                "TOASTS": {
                    "PASSWORD": {
                        "MATCHES": {
                            "TITLE": "Password matches",
                            "TEXT": "Password matches."
                        },
                        "CHANGE_SUCCESS": {
                            "TITLE": "Password matches",
                            "TEXT": "Password is stored in operator please save the operator, when ready."
                        },
                        "TO_LOW": {
                            "TITLE": "'Operator password to low",
                            "TEXT": "Please check your password again. The security level is low."
                        },
                        "INVALID": {
                            "TITLE": "Password invalid",
                            "TEXT": "Password is too weak or not matching."
                        },
                    }
                },
                "TABLE": {
                    "LOGIN_NAME": "Login Name",
                    "OPERATOR_ROLE": "Operator Role",
                    "ENTRY_CREATED": "Entry Created",
                    "LAST_MODIFIED": "Last Modified",
                    "BUTTONS": {
                        "EDIT": "Edit this Operator",
                        "DELETE": "Delete this Operator",
                    },
                },
                "MODAL": {
                    "ASSIGNED_PROPERTIES": "Assigned Properties",
                    "ART_RIGHTS": "ART Rights",
                    "ART_RIGHTS_X": "ART Rights extended",
                    "RIGHTS": "Device Rights",
                    "ADD_NEW_OPERATOR": "Add New Operator",
                    "EDIT_OPERATOR": "Edit Operator",
                    "DESCRIPTION": "Description",
                    "NAME": "Name",
                    "NR": "Nr",
                    "DELETE_OPERATOR": "Delete Operator",
                    "DELETE_OPERATORS": "Delete Operators",
                },
                "BUTTONS": {
                    "SAVE": "Save",
                    "DELETE": "Delete",
                    "CANCEL": "Cancel",
                    "ADD": "Add",
                    "DELETE_OPERATOR_LIST": "Delete selected operators",
                    "NEXT": "Next",
                    "BACK": "Back",
                    "MANUALLY_ACCEPT": "Manually accept"
                },
                "EXPANDED": {
                    "DASHBOARD_RIGHTS": {
                        "TITLE": "Dashboard Rights",
                        "PROFILE": "Dashboard Rights Profiles"
                    },
                    "OPERATOR_SETTINGS": {
                        "TITLE": "Operator Settings",
                        "LOGIN_ALLOWED": "Login Allowed",
                        "LOGIN_NAME": "Login Name",
                        "EMAIL": "E-Mail Address",
                        "OPERATOR_ROLE": "Operator Role",
                        "ID": "ID",
                        "NAME": "Name",
                        "POSITION_TITLE": "Position",
                        "PAGER": "Pager",
                        "ONLINE_SYSTEM": "Online System",
                        "PASSWORD": "Password",
                        "PASSWORD_CONFIRMATION": "Confirm Password",
                        "PASSWORD_LAST_CHANGE": "Password last change",
                        "PASSWORD_CHANGE_REQUIRED": "Password change required",
                        "EXTERNAL_ID": "External ID",
                        "EXTERNAL_ID_TYPE": "External ID Type",
                        "BUTTONS": {
                            "CHANGE_PASSWORD": "Change Password",
                            "ART_RIGHTS": "ART Rights",
                            "ASSIGNED_PROPERTIES": "Assigned Properties",
                            "SAVE_PASSWORD": "Save password"
                        },
                    },
                    "LOGIN_SETTINGS": "Login",
                    "OPERATOR_DEVICE_SETTINGS": {
                        "TITLE": "Operator Device Settings",
                        "OPERATOR_LEVEL": "Operator Level",
                        "SEPARATE_PASSWORD_DEVICE": "Separate password for devices",
                        "SAFE_DOOR_PIN": "Safe door PIN",
                        "PIN": "PIN",
                        "PIN_CONFIRMATION": "PIN Confirmation",
                        "FINGERPRINTS_NONE": "No fingerprints registered!",
                        "PALMSCANS_NONE": "No palm scans registered!",
                        "FINGERPRINTS_SUCCESS": "",
                        "PALMSCANS_SUCCESS": "",
                        "BUTTONS": {
                            "EDIT": "Edit",
                            "REASSIGN": "Reassign",
                            "RIGHTS": "Rights",
                            "SAVE_PIN": "Save PIN",
                        },
                    },
                },
            },
            "PLAYER_INFORMATION": {
                "TITLE": "Players",
                "DETAILS": {
                    "NAME": "Name",
                    "SURNAME": "Surname",
                    "GENDER": "Gender",
                    "NATIONALITY": "Nationality",
                    "ID_TYPE": "ID-Type",
                    "ID_NUMBER": "ID #",
                    "BIRTHDAY": "Birthday",
                    "REGISTRATION_DATE": "Registration Date",
                    "LAST_USED": "Last Used",
                    "MEDIA_NUMBER": "Media #",
                    "STATE": "State",
                    "NO_DATA": "No Player Detail Data",
                },
            },
            "PAYMENT_CUSTOMER": {
                "TITLE": "Customers",
                "CUSTOMER_BLOCK_TITLE": "Block this customer?",
                "CUSTOMER_UNBLOCK_TITLE": "Unblock this customer?",
                "CUSTOMER_DELETE_TITLE": "Delete this customer?",
                "FIRST_NAME": "First Name",
                "MIDDLE_NAME": "Middle Name",
                "LAST_NAME": "Last Name",
                "GENDER": "Gender",
                "BIRTHDAY": "Birthday",
                "EYE_COLOR": "Eye color",
                "HAIR_COLOR": "Hair color",
                "PHONE_NUMBER": "Phone #",
                "ADDRESS": "Address",
                "EXTERNAL_ID": "External IDs",
                "PROVIDER": "Provider",
                "ID": "ID #",
                "ID_TYPE": "ID Type",
                "IDENTITY_DOCUMENT": "Identity Document",
                "TYPE": "Type",
                "NUMBER": "Number",
                "ISSUER": "Issuer",
                "ISSUE_DATE": "Issue Date",
                "EXPIRATION_DATE": "Expiration Date",
                "NO_CUSTOMER_DETAIL_DATA": "No Customer Detail Data",
                "REGISTRATION_DATE": "Registration Date",
                "REGISTERED_AT": "Registered At",
                "CUSTOMER_SEARCH": "Search Customers",
                "SSN": "SSN",
                "COUNTRY": "Country",
                "COUNTY": "State",
                "TOWN": "City",
                "STREET": "Street",
                "ZIP_CODE": "Zip",
                "ADDRESS_EXTRA": "Address extra",
                "EMAIL": "E-Mail Address"
            },
            "TERMINAL_LIFECYCLE": {
                "TITLE": "Terminal Lifecycle",
                "FILTER": {
                    "SERIAL_NUMBER": "Serial Number",
                    "PART_NUMBER": "Part Number",
                },
                "CARD": {
                    "MACHINE_TYPE": "Machine Type",
                    "FACTORY": "Factory",
                    "CUSTOMER": "Customer",
                    "LIFECYCLE_CREATED": "Lifecycle Created",
                    "RECORD_TYPE": "Record Type",
                    "ART_INSTALLATION": "ART Installation",
                    "DEVICE_OVERVIEW": "Device Overview",
                    "DEVICES": "Devices",
                    "BUILD_LOCATION": "Build Location",
                    "TEST_RUN": "Test Run",
                    "SHOW_TEST_RUN": "Show Test Runs ({{nr}})",
                },
                "TABLE": {
                    "ART_INSTALLATION_ID": "ART Installation ID",
                    "LIFECYCLE_CREATED": "Entry Created",
                    "LIFECYCLE_TYPE": "Lifecycle Type",
                    "TEST_RUN_MAIN": {
                        "TEST_RUN_TYPE": "Test Run Type",
                        "CREATED": "Created",
                        "COMPLETED": "Completed",
                        "OPERATOR": "Operator",
                        "PREVIOUS_OPERATOR": "Previous Operator",
                        "SEALED": "Sealed",
                        "TEST_RESULT": "Test Result",
                        "MESSAGES": "Messages",
                        "TEST_CASES": "Test Cases",
                    },
                    "TEST_RUN_MESSAGES": {
                        "MESSAGE_CODE": "Message Code",
                        "MESSAGE_ID": "Message ID",
                        "CREATED": "Created",
                        "SEVERITY": "Severity",
                        "MESSAGE_ENG": "Message (EN)",
                    },
                    "TEST_CASES": {
                        "NAME": "Name",
                        "TEST_RESULT": "Test Result",
                        "STARTED": "Started",
                        "COMPLETED": "Completed",
                        "DEVICE_IDENTIFIER": "Device Identifier",
                        "DEVICE_ID": "Device ID",
                        "COMPONENT_ID": "Component ID",
                        "DEVICE_INFORMATION": "Device Information",
                        "DEVICE_NAME": "Device Name",
                        "DEVICE_CONNECTION_TYPE": "Device Connection Type",
                        "DEVICE_DETAILS": "Device Details",
                        "TEST_CASE_STEPS": "Test Case Steps",
                        "MESSAGES": "Messages",
                    },
                    "TEST_CASES_STEPS": {
                        "NAME": "Name",
                        "TEST_RESULT": "Test Result",
                        "STARTED": "Started",
                        "COMPLETED": "Completed",
                        "DEVICE_IDENTIFIER": "Device Identifier",
                        "OPERATOR": "Operator",
                        "MESSAGES": "Messages",
                    }
                },
                "DIALOG": {
                    "TITLE": "Lifecycle for Machine"
                },
                "TOAST": {
                    "NO_ENTRIES_FOUND": {
                        "TITLE": "No entries found",
                        "BODY": "No entries found for the given serial number."
                    },
                }
            },
            "LICENSE_KEY_USAGE_OVERVIEW": {
                "TABLE": {
                    "LICENSE_KEY": "License Key",
                    "ART_INSTALLATIONS_COUNT": "Installation Count",
                    "INSTALLATION_NAME": "Installation Name",
                    "TERMINAL_COUNT": "Terminal Count",
                },
            }
        },
        "LOGS": {
            "TRANSACTION": {
                "TITLE": "Transaction Log",
                "FILTER": {
                    "TRANSACTION": "Transaction",
                    "TRANSACTION_CATEGORY": {
                        "BY_OPERATOR": "by Operator",
                        "BY_CUSTOMER": "by Customer",
                        "ALL_ATM_TRANSACTIONS": "All ATM Transactions",
                        "ALL_JACKPOT_TRANSACTIONS": "All Jackpot Transactions",
                        "ALL_MAGNETIC_CARD_TRANSACTIONS": "All Magnetic Card Transactions",
                        "ALL_SMART_CARD_TRANSACTIONS": "All Smart Card Transactions",
                        "ALL_TICKET_TRANSACTIONS": "All Ticket Transactions",
                        "ALL_LOANS_TRANSACTIONS": "All Loans Transactions",
                        "ALL_E_WALLET_TRANSACTIONS": "All eWallet Transactions",
                        "ALL_PAYMENT_TRANSACTIONS": "All Payment Transactions"
                    },
                    "FOOTER": {
                        "MEDIA_NUMBER": "Media #",
                        "CURRENCY_ALL": "Currency All",
                        "CLEAR_FILTER": "Clear Filter",
                        "FULL_PERIOD": "full Period"
                    }
                },
                "CONTEXT_MENU": {
                    "SHOW_DETAILS": "Show details",
                    "SHOW_EVENTS": "Show events"
                },
                "TABLE": {
                    "MEDIA_NUMBER": "Media #",
                    "TOTAL_IN": "Total in",
                    "TOTAL_OUT": "Total out",
                    "ACTION": "Action",
                    "FLAGS": "Flags",
                    "SHOW_DETAILS": "Show details",
                    "SEARCH_TRANSACTION_BY": "Search transaction by"
                },
                "MODAL": {
                    "GENERAL_INFO": {
                        "TITLE": "General Info",
                        "TERMINAL": "Terminal",
                        "DATE": "Date",
                        "TRANSACTION_TYPE": "Transaction Type",
                        "TRANSACTION_NUMBER": "Transaction Number",
                        "TOTAL_MEDIA_IN": "Total Media In",
                        "TOTAL_MEDIA_OUT": "Total Media Out",
                        "OPERATOR": "Operator",
                        "FLAGS": "Flags"
                    },
                    "MEDIA_IN": {
                        "TITLE": "Media In",
                        "TABLE": {
                            "MEDIUM": "Medium",
                            "MEDIA_NUMBER": "Media #",
                            "PIECES": "Pieces",
                            "AMOUNT": "Amount",
                        }
                    },
                    "MEDIA_OUT": {
                        "TITLE": "Media Out",
                        "TABLE": {
                            "MEDIUM": "Medium",
                            "MEDIA_NUMBER": "Media #",
                            "PIECES": "Pieces",
                            "AMOUNT": "Amount",
                        }
                    },
                    "ADDITIONAL_INFO": {
                        "TITLE": "Additional Info",
                        "TABLE": {
                            "VALUE": "Value",
                            "TYPE": "Type"
                        },
                        "MODAL": {
                            "GIVEN_NAME": "Given name",
                            "SURNAME": "Surname",
                            "BIRTHDAY": "Birthday",
                            "MEDIA_NUMBER": "Media #",
                            "I_D_NUMBER": "ID #",
                            "I_D_TYPE": "ID type",
                            "GENDER": "Gender",
                            "NATIONALITY": "Nationality",
                            "STATUS": "Status"
                        }
                    },
                    "EXCHANGE_RATE": {
                        "TITLE": "Exchange Rates",
                        "TABLE": {
                            "SOURCE": "Source",
                            "TARGET": "Target",
                            "EXCHANGE_RATE": "Exchange Rate",
                        }
                    },
                    "IMAGES": "Images",
                },
                "SEARCH": {
                    "TITLE": "Search for Transactions",
                    "TABLE": {
                        "TRANSACTION_NUMBER": "Transaction #"
                    },
                    "CRITERION": {
                        "TRANSACTION_NUMBER": "Transactions #",
                        "MEDIA_NUMBER": "Media #",
                        "MEDIA_NUMBER_MULTI_MEDIA": "Media # (Multi Media)",
                        "MEDIA_NUMBER_MULTI_TICKET_IN": "Media # (Multi-Ticket In)",
                        "MEDIA_NUMBER_MULTI_TICKET_OUT": "Media # (Multi-Ticket Out)",
                        "SLOT_MACHINE_NUMBER": "Slot Machine #",
                        "ONLINE_TRANSACTION_NUMBER": "Online Transaction #",
                        "JACKPOT_TRANSACTION_NUMBER": "Jackpot Transaction #",
                        "JACKPOT_TICKET_NUMBER": "Jackpot Ticket #",
                        "CEC": "CEC",
                        "BANK_TRANSACTION_NUMBER": "Bank Transaction #",
                        "ISSUER": "Issuer",
                        "EXTERNAL_TRANSACTION_NUMBER": "External Transaction #"
                    }
                }
            },
            "ERROR": {
                "TITLE": "Error Log",
                "FILTER": {},
                "TABLE": {},
            },
            "OPERATOR_ACCESS": {
                "TITLE": "Operator Access Log",
                "FILTER": {},
                "TABLE": {
                    "OPERATOR_ID": "Operator ID",
                    "OPERATOR_NAME": "Operator Name",
                    "ACCESS_FROM": "Access from",
                    "ACCESS_ON": "Access on",
                },
            },
            "CONFIGURATION_CHANGE": {
                "TITLE": "Configuration Change Log",
                "FILTER": {
                    "GENERAL_CONFIGURATION": "General Configuration",
                    "CUSTOM_SELECTION": "Custom Selection"
                },
                "TABLE": {
                    "CONFIGURATION": "Configuration",
                    "CONFIGURATION_KEY": "Config Key",
                    "OLD_VALUE": "Old Value",
                    "NEW_VALUE": "New Value",
                    "CHANGE_DATE": "Change Date",
                    "RESPONSIBLE_OPERATOR_ID": "Responsible Operator ID",
                    "RESPONSIBLE_OPERATOR": "Responsible Operator",
                },
                "TERMINALS_ALL": "All",
                "TERMINAL_UNDEFINED": "Unknown",
            },
            "EVENT": {
                "TITLE": "Event Log",
                "FILTER": {
                    "EVENT_TYPE": "Event type:",
                    "SOURCE": "Source",
                    "SEVERITY": "Severity"
                },
                "TABLE": {
                    "MESSAGE": "Message",
                    "SOURCE": "Source",
                    "CODE": "Code",
                    "LEVEL": "Severity",
                },
            },
            "MEDIA": {
                "TITLE": "Media Log",
                "FILTER": {},
                "TABLE": {
                    "TRANSACTION_NUMBER": "Transaction #",
                    "AMOUNT": "Amount",
                    "CURRENCY": "Currency",
                    "MEDIA": "Media",
                    "MEDIA_NUMBER": "Media #",
                    "DIRECTION": "Direction"
                },
                "MEDIUM_ACTION": {
                    "NONE": "None",
                    "REDEEMED": "Redeemed",
                    "STACKED": "Stacked",
                    "REJECTED": "Rejected",
                    "DISPENSED": "Dispensed",
                    "ISSUED": "Issued",
                    "UNABLE_TO_PAY": "Unable to pay",
                },
            },
            "ART_EVENT": {
                "TITLE": "ART Event Log",
                "FILTER": {
                    "EVENT_TYPE": {
                        "TITLE": "Severity",
                        "INFORMATION": "Information",
                        "WARNING": "Warning",
                        "FAILURE": "Failure",
                    },
                    "EVENT_SOURCE": {
                        "TITLE": "Event source"
                    },
                },
                "TABLE": {
                    "DATE": "Event date",
                    "EVENT": "Event",
                    "SOURCE": "Source",
                    "SEVERITY": "Severity",
                    "LEVEL": "Level",
                },
            },
            "MAINTENANCE": {
                "TITLE": "Maintenance Log",
                "FILTER": {},
                "TABLE": {
                    "ACTIVITY": "Activity",
                    "COMPONENT": "Component",
                    "RANK": "Rank",
                    "COMMENT": "Comment",
                },
            },
        },
        "SETTINGS": {
            "CONFIGURATION_MANAGEMENT": {
                "TITLE": "Configuration Entries",
                "TABLE": {
                    "CONFIGURATION_KEY": "Configuration Key",
                    "CONFIGURATION_VALUE": "Configuration Value",
                    "DEFAULT_VALUE": "Default Value",
                    "CATEGORY": "Category"
                },
                "BUTTONS": {
                    "RESET_ALL_TO_DEFAULT": "Reset All Changes to Default",
                    "SAVE_ALL_CHANGES": "Save All Changes",
                    "RESET_ALL_CHANGES": "Reset All Changes"
                },
                "MODALS": {
                    "TITLE": "Confirm Configuration Changes",
                    "TABLE": {
                        "CONFIGURATION_KEY": "Configuration Key",
                        "CONFIGURATION_VALUE_OLD": "Old Value",
                        "CONFIGURATION_VALUE_NEW": "New Value",
                        "DEFAULT_VALUE": "Default Value",
                        "ACTION": "Action"
                    },
                    "BUTTONS": {
                        "CANCEL": "Cancel",
                        "RESET_ALL_CHANGES": "Reset All Changes",
                        "SAVE_ALL_CHANGES": "Save All Changes"
                    }
                },
            }
        },
        "BALANCING": {
            "TITLE": "Balancing Report",
            "FILTER": {},
            "SNAPSHOT_TYPES": {
                "MODAL": {
                    "TITLE": "Select end snapshot"
                }
            },
            "MONEY_SERVICE_LIST": {
                "TITLE": "Money Service List",
                "FULL": "Full"
            },
            "MONEY_SERVICE_REMOVAL": {
                "DATE": "Date",
                "SAVED_BY": "Saved By",
                "OF": "of",
                "HOPPERS": "Hoppers",
                "DISPENSER_BOXES": "Dispenser Boxes",
                "ACCEPTOR": "Acceptor",
                "REJECT_BOX": "Reject Box",
                "ENTRY": "Entry",
                "COUNT": "Count",
                "TOTAL": "Total"
            },
            "REPORT": {
                "OPENING_BALANCE": "Opening Balance",
                "CLOSING_BALANCE": "Closing Balance",
                "CUSTOMER_TURNOVER": "Customer Turnover",
                "OPERATOR_TURNOVER": "Operator Turnover",
                "CASH_BALANCE": "Cash Balance",
                "CARD_FLOW": "Card Flow",
                "REMOVED_FROM_MACHINE": "Removed from Machine",
                "INSERTED_TO_MACHINE": "Inserted to Machine",
                "OPENING": "Opening",
                "BALANCE": "Balance",
                "CLOSING": "Closing",
                "COIN_DISPENSER": "Coin Dispenser",
                "COIN_ACCEPTOR": "Coin Acceptor",
                "BILL_DISPENSER": "Bill Dispenser",
                "CASSETTES": "Cassettes",
                "REJECT_BOX": "Reject Box",
                "BILL_ACCEPTOR": "Bill Acceptor",
                "BILLS": "Bills",
                "TICKETS": "Tickets",
                "TOTAL": "Total",
                "COINS": "Coins",
                "TOTAL_CASH": "Total Cash",
                "TICKS": "Tickets",
                "MEDIA_CHARGED": "Media charged",
                "DEPOSIT_WITHHELD": "Deposit withheld",
                "CREDITS_CLEARED": "Credits cleared",
                "ROUNDING_DIFFERENCES": "Rounding differences",
                "ATM": "ATM",
                "ATM_FEES": "ATM Fees",
                "POS_FEES": "POS Fees",
                "IOU": "IOU",
                "DONATIONS": "Donations",
                "LOANS_PAID_BACK": "Loans paid back",
                "TAX_WITHHELD": "Tax withheld",
                "DEPOSIT_PAID": "Deposit paid",
                "JACKPOT": "Jackpot",
                "JACKPOT_OTHER": "Jackpot (other)",
                "POS": "POS",
                "CEC": "CEC",
                "COINS_DISPENSER": "Coins (Dispenser)",
                "COINS_ACCEPTOR": "Coins (Acceptor)",
                "BILLS_DISPENSER": "Bills (Dispenser)",
                "BILLS_ACCEPTOR": "Bills (Acceptor)",
                "HOPPER_DIFFERENCE": "Hopper difference",
                "CUSTOMER_INSERTED": "Customer inserted",
                "CUSTOMER_REMOVED": "Customer removed",
                "OPERATOR_INSERTED": "Operator inserted",
                "OPERATOR_REMOVED": "Operator removed",
                "COUNT_DIFF": "Count diff",
                "CARDS_IN_STACKER": "Cards in stacker",
                "CARDS_IN_BIN": "Cards in Bin",
                "OPENING_STOCK": "Opening stock",
                "INSERTED_TO_BIN": "Inserted to bin",
                "REMOVED_FROM_BIN": "Removed from bin",
                "INSERTED_TO_STACKER": "Inserted to stacker",
                "REMOVED_FROM_STACKER": "Removed from stacker",
                "NA": "N/A"
            },
        },
        "STATISTICS": {
            "ART_OVERVIEW": {
                "TITLE": "ART Overview",
                "ICONS": {
                    "TERMINAL_COUNT": "Terminal Count",
                    "LAST_SYNCHRONISATION": "Last Synchronization",
                    "ADDRESS": "Address",
                    "OPERATORS_COUNT": "Operator Count",
                    "PROPERTIES_COUNT": "Property Count",
                    "INSTALLATION_MODE": "Installation Mode",
                },
                "BUTTONS": {
                    "QUARTERLY_DASHBOARD": "Quarterly Dashboard",
                    "ART_DETAILS": "ART Details",
                    "ART_INSTALLATION_MODE": "Installation Mode",
                    "CONNECTION": "Connection"
                },
                "ART_CONNECTION": {
                    "RECENTLY_SEEN": "Recently seen",
                    "RECENTLY_SYNCHRONIZED": "Recently synchronized"
                },
                "SWITCHER": {
                    "CARD_VIEW": "Card View",
                    "TABLE_VIEW": "Table View"
                },
                "ART_INSTALLATION_MODE": {
                    "IN_DEVELOPMENT": "In Development",
                    "IN_QUALITY_ASSURANCE": "In Quality Assurance",
                    "IN_TEST_LAB_USE": "In Test Lab Use",
                    "IN_PRODUCTION_USE": "In Production Use",
                    "DECOMMISSIONED": "Decommissioned",
                    "NEW": "New",
                    "SHOW_ROOM": "Showroom",
                    "FACTORY": "Factory"
                },
                "MONTHLY": {
                    "TOTAL": "Total",
                    "TOTAL_BILL_BREAK": "Total Bill Break",
                    "TOTAL_REDEMPTION": "Total Redemption",
                    "TOTAL_CHARGE": "Total Charge",
                    "TOTAL_CLEARED_CREDIT": "Total Cleared Credit",
                    "TOTAL_DONATIONS": "Total Donations"
                },
                "MODALS": {
                    "GO_TO_WEBSITE": "Go To Website: {{ Website }}",
                    "BUTTONS": {
                        "EDIT_ART_INSTALLATION": "Edit ART Installation",
                        "SHOW_WEBSITE": "Show Website"
                    },
                    "CARD_TITLES": {
                        "LOCATION": "Location",
                        "ART_STATISTICS": "ART Statistics",
                        "ART_CONFIGURATION": "ART Configuration",
                        "ENVIRONMENT_INFORMATION": "Environment Information",
                        "BASE_LICENSES": "Base License",
                        "MODULE_LICENSES": "Module Licenses",
                    },
                    "LOCATION": {
                        "ADDRESS": "Address",
                        "COUNTRY": "Country",
                        "STATE": "State",
                        "ZIP_CODE": "Zip Code",
                        "STREET": "Address Line 1",
                        "CITY": "City",
                        "ADDRESS_EXTENDED": "Address Line 2",
                        "COUNTY": "County"
                    },
                    "ART_STATISTICS": {
                        "TERMINAL_COUNT": "Terminal Count",
                        "TERMINAL_COUNT_DATA": '{{ ConnectedTerminalsCount }} / {{ TerminalsCount }} Terminals',
                        "OPERATORS_COUNT": "Operators Count",
                        "OPERATORS_COUNT_DATA": "{{ LoggedInOperatorsCount }} / {{ OperatorsCount }} Operators ({{ CurrentSessionsCount }} Sessions)",
                        "LAST_SYNCHRONISATION": "Last Synchronization",
                        "FIRST_SYNCHRONISATION": "First Synchronization",
                        "OPERATORS": "Operators",
                        "SESSIONS": "Sessions"
                    },
                    "ART_CONFIGURATION": {
                        "VERSION": "Version",
                        "CURRENCY_CODE": "Currency Code",
                        "ID": "ID",
                    },
                    "ENVIRONMENT_INFORMATION": {
                        "RUNTIME_VERSION": "Runtime Version",
                        "DOT_NET_VERSION": ".NET Version",
                        "OS_BUILD_VERSION": "OS Build Version",
                        "OS_NAME": "OS Name",
                        "OS_VERSION": "OS Version",
                        "SQL_SERVER_VERSION": "SQL Server Version",
                        "COMPUTER_NAME": "Computer Name",
                        "IP_ADDRESS": "IP Address"
                    },
                    "BASE_LICENSES": {
                        "REGISTERED": "Registered",
                        "TERMINAL_COUNT": "Terminal Count",
                        "ART_CLIENT_COUNT": "ART Client Count",
                        "PROPERTY_NUMBER": "Property #",
                        "BASE_LICENSE_NUMBER": "Base License #",
                        "UNLIMITED": "Unlimited",
                        "TRIAL_LICENSE": "Trial Licence"
                    },
                    "MODULE_LICENSES": {
                        "ADVERTISING": "Advertising",
                        "PREVENTIVE_MAINTENANCE": "Preventive Maintenance",
                        "PROPERTY_MANAGEMENT": "Property Management",
                        "CARD_BURNING": "Card Burning",
                        "SHOP_MANAGEMENT": "Shop Management",
                        "CURRENCY_COUNTING": "Currency Counting",
                        "MULTI_CURRENCY": "Multi Currency",
                        "REMOTE_ACCESS": "Remote Access",
                        "DEBIT_ACCOUNTS": "Debit Accounts",
                        "FACE_RECOGNITION": "Face Recognition",
                        "STATES": {
                            "ACTIVE": "Active",
                            "NOT_ACTIVE": "Not Active",
                            "PROPERTIES": "Properties",
                            "SHOPS": "{{x}} Shops",
                            "ALLOWED_TERMINALS": "{{x}} Allowed Terminals",
                        }
                    },
                    "TERMINALS": {
                        "TABLE": {
                            "ID": "ID",
                            "TERMINAL": "Terminal",
                            "TERMINAL_TYPE": "Terminal Type",
                            "VERSIONS": "Versions",
                            "SCVERSION": "Software Version / Revision #"
                        },
                        "VERSIONS": {
                            "TITLE": "Versions",
                            "TABLE": {
                                "NAME": "Name",
                                "VERSION_TYPE": "Version Type",
                                "VERSION": "Version"
                            },
                        }
                    },
                    "TERMINAL_STATISTICS": {
                        "TITLE": "Terminal Statistics",
                        "FIRST_SEEN": "First Seen",
                        "LAST_SEEN": "Last Seen",
                        "LIFE_CYCLE_STEP": "Lifecycle Step"
                    },
                    "TERMINAL_CONFIGURATION": {
                        "TITLE": "Terminal Configuration",
                        "ID": "ID",
                        "TYPE": "Type",
                        "NAME": "Name",
                        "TIMEZONE": "Time Zone",
                        "MAC_ADDRESS": "MAC Address",
                        "SERIAL_NUMBER": "Serial Number",
                        "SYSTEM_CURRENCY": "System Currency",
                        "DISTRIBUTOR_NUMBER": "Distributor #",
                        "CUSTOMER_NUMBER": "Customer #",
                        "PROPERTY_NUMBER": "Property #",
                        "LOCATION_ID": "Location ID",
                    },
                    "CONTACT_INFORMATION": {
                        "TITLE": "Contact Information",
                        "EMAIL_ADDRESS": "E-Mail Address",
                        "PHONE_NUMBER": "Phone Number",
                        "TIMEZONE": "Time Zone",
                        "LOCATION_NAME": "Location Name"
                    },
                    "CONTACT_PERSON": {
                        "TITLE": "Contact Person",
                        "NAME": "Name",
                    },
                    "PROPERTY_INFORMATION": {
                        "TITLE": "Property Information",
                        "NO": "Number",
                        "NAME": "Name",
                        "DESCRIPTION": "Description",
                        "DEFAULT": "Default"
                    }
                }
            },
            "CHIP_TURNOVER": {
                "TITLE": "Chip Turnover",
                "MAIN_TABLE": {
                    "TERMINAL": "Terminal",
                    "START_TIME": "Start Time",
                    "END_TIME": "End Time",
                    "WIN_OR_LOSS": "Win/Loss",
                    "OPENING_INV": "Opening Inv.",
                    "FILLS": "Fills",
                    "CREDITS": "Credits",
                    "CLOSING_INV": "Closing Inv.",
                    "TOTAL": "Total"
                },
                "DETAILS": {
                    "TABLE": {
                        "ENTRY_DATE": "Date",
                        "TYPE": "Type",
                        "VALUES": "Values",
                        "SUM": "Sum",
                        "RESPONSIBLE_OPERATOR_ID": "Responsible Operator",
                        "ENTRY_SOURCE": "Source"
                    }
                }
            },
            "MAINTENANCE_OVERVIEW": {
                "TITLE": "Maintenance Overview",
                "CLEANINGS": "Cleanings",
                "MAINTENANCES": "Maintenances",
                "CLEANING": "Cleaning",
                "MAINTENANCE": "Maintenance",
                "TERMINALS": "Terminals",
                "TERMINALS_COUNT": "Terminals Count",
                "SHOW_DETAILS": "Show Details",
                "DETAILS": {
                    "DAYS_SINCE_MAINTENANCE": "Days since Maintenance",
                    "TIME_WARNING": "Time Warning",
                    "TIME_MANDATORY": "Time Mandatory",
                    "METER_VALUE": "Meter Value",
                    "METER_WARNING": "Meter Warning",
                    "METER_MANDATORY": "Meter Mandatory"
                }
            },
            "QUARTERLY_DASHBOARD": {
                "TITLE": "Quarterly Dashboard",
                "TABLE": {
                    "UNIT": "Unit",
                    "INSTALLATION": "Installation"
                },
                "TITLES": {
                    "UPTIME": "Uptime Terminal for ",
                    "TOTAL_TRANSACTIONS": "Total Transaction for",
                    "AVERAGE_TRANSACTIONS": "Average Transactions for"
                },
                "SWITCHER": {
                    "YEAR": "Year",
                    "UPTIME": "Uptime",
                    "TRANSACTION_TOTALS": "Transaction Totals",
                    "TRANSACTION_AVERAGES": "Transaction Averages"
                },
                "BUTTONS": {
                    "LOAD": "Load"
                }
            },
            "ONLINE_SYSTEM_STATISTICS": {
                "TITLE": "Online System Statistics",
                "MAIN_TABLE": {
                    "TITLE": "Sums per Online System",
                    "REDEEMED_TICKETS_COUNT": "Redeemed Tickets Count",
                    "REDEEMED_TICKETS_AMOUNT": "Redeemed Tickets Amount",
                    "ISSUED_TICKETS_COUNT": "Issued Tickets Count",
                    "ISSUED_TICKETS_AMOUNT": "Issued Tickets Amount",
                    "REDEEMED_CARDS_COUNT": "Redeemed Cards Count",
                    "REDEEMED_CARDS_AMOUNT": "Redeemed Cards Amount",
                    "CHARGED_CARDS_COUNT": "Charged Cards Count",
                    "CHARGED_CARDS_AMOUNT": "Charged Cards Amount",
                },
                "DETAIL_TABLE": {
                    "TITLE": "Media",
                    "TRANSACTION_NUMBER": "Transaction #",
                    "TRANSACTION_DATE": "Date",
                    "MEDIUM_TYPE": "Media",
                    "MEDIA_NUMBER": "Media#",
                    "AMOUNT": "Amount",
                    "MEDIUM_DIRECTION": "Media Direction",
                }
            },
            "TRANSACTION_COUNTS": {
                "TITLE": "Transaction Counts",
                "TABLE": {
                    "TERMINAL": "Terminal",
                    "COUNT_OF_TRANSACTIONS": "Transaction Count",
                    "DURATION_FULL_TIME": "Total Average",
                    "DAYS_FULL_TIME": "Days Total",
                    "DURATION_ACTIVE_TIME": "Active Average",
                    "DAYS_ACTIVE": "Days Active",
                },
            },
            "TRANSACTION_STATISTIC": {
                "TITLE": "Transaction Statistics",
                "BUTTONS": {
                    "GENERAL": "General",
                    "JACKPOT": "Jackpot",
                    "CUSTOMER_TRANSACTIONS": "Customer Transactions",
                    "MONEY_SERVICE": "Money Service",
                    "OPERATOR_TRANSACTIONS": "Operator Transactions",
                },
                "TABLE": {
                    "TITLE": "Sums per Transaction Category/Type",
                    "TYPE": "Transaction Type",
                    "QTY": "Qty",
                    "INSERTIONS": "Insertions",
                    "REMOVALS": "Removals",
                },
                "CARDS": {
                    "INSERTIONS_TYPES": "Insertions - Types",
                    "REMOVALS_TYPES": "Removal - Types",
                    "INSERTIONS_CUSTOMER_TRANSACTIONS": "Insertions - Customer Transactions",
                    "REMOVALS_CUSTOMER_TRANSACTIONS": "Removals - Customer Transactions",
                    "INSERTIONS_JACKPOTS": "Insertions - Jackpot",
                    "REMOVALS_JACKPOTS": "Removals - Jackpot",
                    "INSERTIONS_MONEY_SERVICE": "Insertion - Money Service",
                    "REMOVALS_MONEY_SERVICE": "Removals - Money Service",
                    "INSERTIONS_OPERATOR_TRANSACTIONS": "Insertions - Operator Transactions",
                    "REMOVALS_OPERATOR_TRANSACTIONS": "Removals - Operator Transactions"
                },
                "CATEGORIES": {
                    "NONE": "Non-categorized",
                    "CUSTOMER_TRANSACTIONS": "Customer Transactions",
                    "JACKPOT": "Jackpot",
                    "MONEY_SERVICE": "Money Service",
                    "OPERATOR_TRANSACTIONS": "Operator Transactions",
                    "INTERNAL": "Internal"
                }
            },
            "TERMINAL_AVAILABILITY": {
                "TITLE": "Terminal Availability",
                "FILTER": {
                    "GRAPH_FOR_EVERY_TERMINAL": "Show Graphs for Individual Terminals",
                    "GRAPH_SEPARATED_OPERATOR": "Separate Series for Operator Usage",
                    "PERIOD_DAY": "Choose the Day",
                },
                "GRAPH": {
                    "AVAILABLE": "Available",
                    "ERROR": "Error",
                    "OFF": "Off",
                    "REFERENCE": "Reference",
                    "AVERAGE": "Average",
                    "OPERATOR": "Operator"
                }
            },
            "TERMINAL_STATE": {
                "TITLE": "Terminal State",
            },
            "MEDIA_CASH": {
                "TITLE": "Cash Statistics",
                "FILTER": {
                    "WITH_OPERATOR_TRANSACTION": "With Operator Transaction"
                },
                "CARD_TITLES": {
                    "BILLS_PAID_OUT": "Bills Paid Out",
                    "BILLS_KEPT_IN": "Bills Kept In",
                    "COINS_PAID_OUT": "Coins Paid Out",
                    "COINS_KEPT_IN": "Coins Kept In",
                },
                "TABLE": {
                    "CURRENCY": "Currency",
                    "DENOMINATION": "Denomination",
                    "PIECES": "Pieces",
                },
            },
            "MEDIA_CARD": {
                "TITLE": "Card Statistics",
                "CARD_TITLES": {
                    "MAGNETIC_CARD": "Magnetic Card",
                    "SMART_CARD": "Smart Card",
                    "RFID_CARDS": "RFID Cards",
                    "PREPAID_CARDS": "Prepaid Cards",
                },
                "TABLE": {
                    "STATUS": "Status",
                    "COUNT": "Count",
                    "AMOUNT": "Amount",
                    "CURRENCY": "Currency",
                },
            },
            "CARD_TURNOVER": {
                "TITLE": "Card Turnover",
                "TABLE": {
                    "TERMINAL": "Terminal",
                    "START_TIME": "Start Time",
                    "END_TIME": "End Time",
                    "CARD_IN_DIFF": "Card In Diff",
                    "CARD_OUT_DIFF": "Card Out Diff",
                    "DEPOSIT_IN_DIFF": "Deposit In Diff",
                    "DEPOSIT_OUT_DIFF": "Deposit Out Diff"
                }
            },
            "TICKET_STATISTICS_OPERATOR": {
                "TITLE": "Ticket Statistics per Operator",
            },
            "TICKET_STATISTICS_PERIOD": {
                "TITLE": "Ticket Statistics per Period",
            },
            "PAYMENT_KIOSK": {
                "REPORTS": {
                    "TITLE": "Payment Reports",
                    "TOOLBAR": {
                        "REPORT_TYPE": "Report Type",
                        "MODE": "Mode"
                    },
                    "FILE": {
                        "REPORT": "Report"
                    }
                }
            },
            "UTILIZATION_REPORT": {
                "TITLE": "Utilization"
            },
            "DONATIONS_REPORT": {
                "TITLE": "Donation Report",
                "TABLE": {
                    "ACCOUNT": "Account",
                    "ACCOUNT_NAME": "Account Name",
                }
            },
            "END_OF_SHIFT_REPORTS": {
                "TITLE": "End of Shift Reports",
                "PRINTED_BY": "Printed By",
                "PIECES": "Pieces",
                "TOTAL": "Total",
                "TABLE": {
                    "BILL_ACCEPTOR_EMPTIED": "Bill Acceptor Emptied",
                    "BILL_DISPENSER_EMPTIED": "Bill Dispenser Emptied",
                    "BILL_DISPENSER_REFILLED": "Bill Dispenser Refilled",
                    "BILLS_ACCEPTED": "Bills Accepted",
                    "BILLS_DISPENSED": "Bills Dispensed",
                    "BILLS_REJECTED": "Bills Rejected",
                    "COIN_ACCEPTOR_EMPTIED": "Coin Acceptor Emptied",
                    "COIN_DISPENSER_EMPTIED": "Coin Dispenser Emptied",
                    "COIN_DISPENSER_REFILLED": "Coin Dispenser Refilled",
                    "COINS_ACCEPTED": "Coins Accepted",
                    "COINS_DISPENSED": "Coins Dispensed",
                    "REJECT_BOX_EMPTIED": "Reject Box Emptied",
                    "TICKETS_ACCEPTED": "Tickets Accepted",
                    "TICKETS_EMPTIED": "Tickets Emptied",
                    "TICKETS_PRINTED": "Tickets Printed"
                }
            }
        },
        "ENUMS": {
            "ACCEPTOR_MEDIUM": {},
            "ACCESS_POINT": {},
            "ACCESS_STATE": {
                "NONE": "Unknown type (None)",
                "INVALID_LOGIN_NAME": "Invalid Login Name",
                "INVALID_PASSWORD": "Invalid Password",
                "ACCOUNT_LOCKED": "Account Locked",
                "SESSION_COUNT_EXCEEDED": "Session Count Exceeded",
                "ACCOUNT_TYPE_NOT_SUPPORTED": "Account Type Not Supported",
                "LOGGED_IN": "Logged In",
                "LOGGED_OUT": "Logged Out",
                "RFID_CARD": "RFID Card",
                "REMOTE_ACCESS": "Remote Access"
            },
            "ACCESS_TYPE": {
                "NONE": "",
                "LOGIN_NAME": "Login Name",
                "OPERATOR_CARD": "Operator Card",
                "FINGERPRINT": "Fingerprint",
                "PALM_SCAN": "Palm Scan",
                "USB_STICK": "USB Stick",
                "USER_I_D_GIVEN": "User ID Given",
                "FACE_SCAN": "Face scan",
                "RFID_CARD": "RFID card",
            },
            "ART_CLIENT_TYPE": {
                "ART_CLIENT": "ART Client",
                "ART_WEB_CLIENT": "ART Web Client",
                "TERMINAL": "Terminal"
            },
            "ART_EVENT_SEVERITY": {
                "INFORMATION": "Information",
                "WARNING": "Warning",
                "ERROR": "Error"
            },
            "ART_EVENT_SOURCE": {
                "BIN_FILE_IMPORT": "ATM BIN file import",
                "RECON_FILE_IMPORT": "ATM Reconciliation file import",
                "EZ_PAY_TICKET_INTERFACE": "EZPay ticket interface",
                "ART_BACKUP_TASK": "ART Backup task",
                "ART_CLEANUP_TASK": "ART Cleanup task",
                "ART_PROPERTY_MANAGEMENT": "ART Property Management module",
                "ART_REPLICATION": "ART Replication module",
                "ADJUSTMENT_FILE_EXPORT": "ATM Adjustment file export",
                "ART_NOTIFICATION_SERVICE": "ART Notification Service",
                "ATRONIC_JACKPOT_INTERFACE": "ARTRONIC jackpot interface",
                "OPERATOR_MANAGEMENT": "Operator Management",
                "SAFE_ACCESS_PERIODS_TRANSFER": "Safe Access Periods Transfer",
                "USER_INTERFACE": "User Interface",
                "ATRONIC_OPERATOR_SYNC": "ATRONIC operator sync",
                "EXCHANGE_RATES_SYNC": "Exchange rates sync",
                "TERMINAL_MANAGEMENT": "Terminal Management",
                "PLAYER_MANAGEMENT": "Player Management",
                "ART_ONLINE_SYSTEM": "ART Online System",
                "FILE_TRANSFER": "File Transfer",
                "CUSTOMER_MANAGEMENT": "Customer Management",
                "ART_SERVER_LIFECYCLE": "ART Server Lifecycle"
            },
            "ART_EVENT_TYPE": {
                "BIN_IMPORT_SUCCEEDED": "ATM BIN file import succeeded!",
                "BIN_MAXIMUM_EXCEEDED": "ATM BIN file maximum exceeded!",
                "BIN_FILE_READ_ERROR": "ATM BIN file could not be read!",
                "BIN_FILE_DOWNLOAD_ERROR": "ATM BIN file could not be downloaded!",
                "EZ_PAY_IMPORT_SUCCESS": "Ticket redemptions import succeeded!",
                "EZ_PAY_IMPORT_ERROR": "Ticket redemptions import error!",
                "ART_BACKUP_SUCCESS": "ART Server Backup completed!",
                "ART_BACKUP_RECENT": "No need to create ART Server Backup!",
                "ART_BACKUP_ERROR": "ART Server Backup failed!",
                "ART_BACKUP_CLEANUP_FAILED": "Cleanup up ART Server backups failed!",
                "ART_CLEANUP_SUCCESS": "ART Cleanup completed!",
                "ART_CLEANUP_ERROR": "ART Cleanup failed!",
                "ART_CLEANUP_PARTIAL": "ART Cleanup partially completed!",
                "ART_CLEANUP_SUB_TASK_ERROR": "ART Cleanup sub task failed!",
                "RECON_IMPORT_SUCCEEDED": "ATM Reconciliation import succeeded!",
                "RECON_IMPORT_ERROR": "ATM Reconciliation import error!",
                "RECON_FTP_SERVER_ERROR": "ATM Reconciliation FTP server error!",
                "RECON_FILE_INVALID": "Invalid ATM Reconciliation file!",
                "ADJUSTMENT_FILE_CREATE_ERROR": "ATM Adjustment file could not be created!",
                "ADJUSTMENT_FILE_SEND_ERROR": "ATM Adjustment file could not be sent!",
                "ADJUSTMENT_FILE_UPDATE_ERROR": "ATM Adjustment file could not update database!",
                "NOTIFICATION_SENT": "Notification for event {0} sent to {1} operators!",
                "COULD_NOT_SEND_NOTIFICATIONS": "Could not send notification to {0}! Reason: {1}.",
                "ATRONIC_SYNC_SUCCESS": "ATRONIC jackpot sync succeeded!",
                "ATRONIC_SYNC_ERROR": "ATRONIC jackpot sync failed!",
                "ATRONIC_SYNC_PROPERTY_FAILED": "ATRONIC jackpot sync property failed!",
                "OPERATORS_MODIFIED": "Operators have been modified!",
                "OPERATORS_RECEIVED": "{0} operator(s) received from terminal {1}!",
                "OPERATORS_SENT": "{0} operator(s) have been sent to terminal {1}!",
                "SAFE_ACCESS_PERIODS_MODIFIED": "{0} safe access period(s) have been modified by {1}!",
                "SAFE_ACCESS_PERIODS_SENT": "{0} safe access period(s) have been sent to terminal {1}!",
                "VIEW_IDLE": "No user interaction",
                "OPERATOR_ACCOUNT_LOCKED": "Operator account locked (password tries exceeded)!",
                "EXCHANGE_RATES_SYNC_SUCCESS": "Exchange rates sync succeeded!",
                "EXCHANGE_RATES_SYNC_ERROR": "Exchange rates sync error!",
                "DYNAMIC_DATA_RESET": "Dynamic data reset!",
                "ATRONIC_OPERATOR_SYNC_SUCCESS": "ATRONIC operator sync succeeded!",
                "ATRONIC_OPERATOR_SYNC_PROPERTY_FAILED": "ATRONIC operator sync property failed!",
                "ATRONIC_OPERATOR_SYNC_ERROR": "ATRONIC operator sync error!",
                "ATRONIC_OPERATOR_SYNC_NO_UPDATES": "ATRONIC operator sync has no updates!",
                "PLAYER_IS_BANNED": "Player is banned",
                "ONLINE_SYSTEM_HASH_MISMATCH": "Online System Hash Mismatch",
                "FILE_TRANSFER_MINIMUM_REQUIRED_SPEED_NOT_AVAILABLE": "File transfer minimum required speed not available",
                "CUSTOMER_DELETED": "Customer has been deleted!",
                "ART_SERVICE_STARTED": "ART Service started!",
                "ART_SERVICE_FORCED_RESTART": "ART Service forced restart!",
                "ART_SERVICE_STOPPED": "ART Service stopped!",
                "SMS_DELIVERY_FAILED": "SMS delivery failed!"
            },
            "GROUP_ART_SELECTION": {
                "TITLE": "ART Group",
            },
            "ART_INSTALLATION_MODE": {
                "IN_DEVELOPMENT": "In Development",
                "IN_QUALITY_ASSURANCE": "In Quality Assurance",
                "IN_TEST_LAB_USE": "In Test Lab Use",
                "IN_PRODUCTION_USE": "In Production Use",
                "DECOMMISSIONED": "Decommissioned",
                "NEW": "New",
                "SHOWROOM": "Showroom",
                "FACTORY": "Factory",
            },
            "ART_RIGHT": {
                "ADMINISTRATION": "Administration",
                "CONFIGURATION": "Configuration",
                "ACCOUNTING": "Accounting",
                "MANAGE_ADVERTISEMENTS": "Manage Ads",
                "LOGS": "Logs",
                "LOGIN": "Login",
                "SECURITY": "Security",
                "TERMINAL_OUT_OF_ORDER": "Online/Offline",
                "OPERATOR_MANAGEMENT": "Manage Operators",
                "SUSPEND_NOTIFICATIONS": "Suspend Terminal Notifications",
                "VIEW_TRANSACTION_PICTURES": "View transaction Pictures",
                "REQUEST_ONE_TIME_MAINTENANCE_ACCESS": "One-Time-Maintenance",
                "SHOW_PIN_ALERT": "Show Pin Alerts",
                "REMOTE_CONTROL": "Remote Control",
                "APPROVE_TRANSACTIONS": "Approve Transactions",
                "TERMINAL_RESTART": "Terminal Restart/Shutdown",
                "ADJUST_TRANSACTIONS": "Adjust Transactions",
                "REMOTE_ACCESS": "Remote Access",
                "MANAGE_DEBIT_ACCOUNTS": "Manage Debit Accounts",
                "DONATION_MANAGEMENT": "Manage Donations",
                "VIEW_OPERATOR_ACCESSES": "View Operator Accesses",
                "PLAYER_MANAGEMENT": "Manage Players",
                "VIEW_I_D_SCANS": "View ID Scans",
                "EXCHANGE_RATE_MANAGEMENT": "Manage Exchange Rates",
                "MANAGE_ONLINE_SYSTEM_MEDIA": "Manage Online System Media",
                "MANAGE_CUSTOMERS": "Manage Customers",
                "VIEW_COMMISSION_REPORT": "View Commission Report",
                "VIEW_DISTRIBUTOR_REPORT": "View Distributor Report",
                "VIEW_STORE_REPORT": "View Store Report",
                "VIEW_INSTALLATION_DASHBOARD": "View ART Dashboard"
            },
            "ART_RIGHT_X": {
                "GLOBAL": "Global",
                "TERMINAL_OPERATION_STATE": "Terminal Operation State",
                "TERMINAL_RESTART": "Terminal Restart",
                "VIEW_SECURITY_ALERTS": "View Security Alerts",
                "SUSPEND_NOTIFICATIONS": "Suspend Notifications",
                "MONITORING": "Monitoring",
                "DASHBOARD": "Dashboard",
                "DASH_INSTALLATION": "Installation Scope",
                "DI_TRANSACTION_COUNTS": "",
                "DI_TRANSACTION_AVERAGES": "",
                "DI_TRANSACTION_TOTALS": "",
                "DI_TRANSACTION_TYPES": "",
                "DI_DONATION_COUNTS": "",
                "DASH_PROPERTY": "Property Scope",
                "DP_TRANSACTION_COUNTS": "",
                "DP_TRANSACTION_AVERAGES": "",
                "DP_TRANSACTION_TOTALS": "",
                "DP_TRANSACTION_TYPES": "",
                "DP_DONATION_COUNTS": "",
                "DASH_KIOSK": "Kiosk Scope",
                "DK_TRANSACTION_COUNTS": "",
                "DK_TRANSACTION_AVERAGES": "",
                "DK_TRANSACTION_TOTALS": "",
                "DK_TRANSACTION_TYPES": "",
                "DK_DONATION_COUNTS": "",
                "DASHBOARD_DOWNTIME": "Dashboard Downtime",
                "TERMINALS": "Terminals",
                "TERM_ICON_VIEW": "Terminal Icon View",
                "TERM_LIST_VIEW": "Terminal List View",
                "TERM_FILL_LEVEL_VIEW": "Fill Level View",
                "COMPONENT_OVERVIEW": "Component Overview",
                "TERMINAL_IDENTIFIERS": "Terminal Identifiers",
                "STOCK_OVERVIEW": "Stock Overview",
                "TERMINAL_HARDWARE": "Terminal Hardware",
                "MAINTENANCE_OVERVIEW": "Maintenance Overview",
                "LOG_VIEWS": "Logs",
                "LV_TRANSACTION_LOG": "Transaction Log",
                "LV_ERROR_LOG": "Error Log",
                "LV_EVENT_LOG": "Event Log",
                "LV_MEDIA_LOG": "Media Log",
                "LV_MAINTENANCE_LOG": "Maintenance log",
                "LV_OPERATOR_ACCESS_LOG": "Operator Access Log",
                "LV_CONFIGURATION_CHANGE_LOG": "Configuration Change Log",
                "LV_ART_EVENT_LOG": "Art Event Log",
                "BALANCING": "Balancing",
                "BALANCE_REPORT": "Balance Report",
                "ATM_RECONCILIATION": "ATM Reconciliation",
                "TICKET_RECONCILIATION": "Ticket Reconciliation",
                "STATISTICS": "Statistics",
                "TRANSACTION_STATISTICS": "Transaction Statistics",
                "TRANSACTION_COUNTS": "Transaction Counts",
                "TRANSACTION_REPORT": "Transaction Report",
                "TERMINAL_STATISTICS": "Terminal Statistics",
                "UTILIZATION_REPORT": "Utilization Report",
                "TERMINAL_AVAILABILITY": "Terminal Availability",
                "SLA_REPORT": "SLA Report",
                "MACHINE_REPORT": "Machine Report",
                "LIFETIME_METERS": "Lifetime Meters",
                "TERMINAL_STATE": "Terminal State",
                "OPERATOR_STATISTICS": "Operator Statistics",
                "OPERATOR_ACCOUNT_LOCKS": "Operator Account Locks",
                "OPERATOR_LOGOUTS": "Operator Logouts",
                "DEBIT_SHIFTS": "Debit Shift",
                "PLAYER_STATISTICS": "Player Statistics",
                "PLAYER_TURNOVER": "Player Turnover",
                "PLAYER_BANS": "Player Bans",
                "MEDIA_STATISTICS": "Media Statistics",
                "CASH_STATISTICS": "Cash Statistics",
                "CARD_STATISTICS": "Card Statistics",
                "CARD_TURNOVER": "Card Turnover",
                "CHIP_TURNOVER": "Chip Turnover",
                "ONLINE_SYSTEM_STATISTICS": "Online System Statistics",
                "DONATION_REPORT": "Donation report",
                "CURRENCY_EXCHANGE_PROFIT_REPORT": "Currency Exchange Profits Report",
                "END_OF_SHIFT_REPORT": "End of Shift Reports",
                "TICKET_STATISTICS_PER_OPERATOR": "Ticket Statistics per Operator",
                "TICKET_STATISTICS_PER_PERIOD": "Ticket Statistics per Period",
                "PAYMENT_STATISTICS": "Payment Statistics",
                "PS_COMMISSION_REPORT": "Commission Report",
                "PS_DISTRIBUTOR_REPORT": "Distributor Report",
                "PS_STORE_REPORT": "Store Report",
                "PS_TRANSACTION_REPORT": "Transaction Report",
                "PS_CUSTOMER_REPORT": "Customer Report",
                "PS_ABORTED_TRANSACTIONS_REPORT": "Aborted Transaction Report",
                "KIOSK_STATISTICS": "Kiosk Statistics",
                "KS_WAGERING_INSTRUMENT_REPORT": "Wagering Instrument Transaction Report",
                "KS_FILL_REPORT": "Fill Report",
                "KS_CASH_OUT_REPORT": "Cash Out Report",
                "KS_DROP_REPORT": "Drop Report",
                "KS_EXCEPTION_REPORT": "Exception Report",
                "KS_JACKPOT_OPEN_CREDIT_REPORT": "Jackpot Open Credit Report",
                "KS_TICKET_LIABILITY_REPORT": "Ticket Liability Report",
                "KS_TICKET_DROP_VARIANCE_REPORT": "Ticket Drop Variance Report",
                "MANAGEMENT": "Management",
                "OPERATOR_MANAGEMENT": "Operator Management",
                "PLAYER_MANAGEMENT": "Player Management",
                "CUSTOMER_MANAGEMENT": "Customer Management",
                "JACKPOT_FORMS_MANAGEMENT": "Jackpot Forms Management",
                "ADVERTISING": "Advertising",
                "SHOP_MANAGEMENT": "Shop Management",
                "ONLINE_SYSTEM_MEDIA": "Online System Media",
                "BONUS_SYSTEM_MEDIA": "Bonus System Media",
                "JACKPOT_SYSTEM_JACKPOTS": "Jackpot System Jackpots",
                "WORK_ACCOUNT_MANAGEMENT": "Work Account Management",
                "PRODUCTION_ORDERS": "Production Orders",
                "LIFECYCLE_RECORDS": "Lifecycle Records",
                "ONLINE_SYSTEM_SERVER_STATISTICS": "Online System Server Statistics",
                "OS_TICKET_LIABILITY_REPORT": "Ticket Liability Report",
                "OS_TICKET_DROP_VARIANCE_REPORT": "Ticket Drop Variance Report",
                "OS_EGM_REVENUE_REPORT": "EGM Revenue Report"
            },
            "ATM_STATUS": {
                "UNKNOWN": "ATM status not defined!",
                "ATM_DISABLED": "ATM disabled. Proxy: {0}",
                "ATM_ENABLED_BUT_NOT_CONNECTED": "Terminal is not connected to the ATM Server! Proxy: {0}",
                "ATM_CONNECTED": "Terminal connected to the ATM Server. Waiting for login and key exchange! Proxy: {0}",
                "ATM_LOGGED_IN": "Terminal connected and logged into the ATM Server. Waiting for key exchange! Proxy: {0}",
                "ATM_KEY_EXCHANGED": "Terminal connected, logged in and exchanged keys with the ATM Server. FULLY CONNECTED! Proxy: {0}",
                "NO_ATM": "",
            },
            "BALANCING_TYPE": {
                "NONE": "None",
                "MONEY_SERVICE": "Money Service",
                "FULL_SERVICE": "Full Service",
                "PREDEFINED_SERVICE": "Predefined Service",
                "MANUAL_SERVICE": "Manual Service"
            },
            "BALANCE_SNAPSHOT_TYPE": {
                "HOURLY": "Hourly",
                "REPORT1": "Report 1",
                "REPORT2": "Report 2",
                "MONEY_SERVICE_START": "Money Service Start",
                "MONEY_SERVICE_MID": "Money Service Mid",
                "MONEY_SERVICE_END": "Money Service End",
                "CASINO_DAY_END": "Casino Day End",
                "MONEY_SERVICE_FULL": "Money Service Full",
                "FILL_LEVEL": "Fill Level",
                "START_OF_SHIFT": "Start of Shift",
                "HOURLY_SNAPSHOT": "Hourly Snapshot"
            },
            "CASHIO_RIGHTS": {
                "COIN_HOPPER": "Coin hopper",
                "BILL_ACCEPTOR": "Bill acceptor",
                "BILL_DISPENSER": "Bill dispenser",
                "CARD_READER": "Card reader",
                "ADMINISTRATION": "Administration",
                "CONFIGURATION": "Configuration",
                "REPORTS": "Reports",
                "MONEY_SERVICE": "Money service",
                "SHUTDOWN": "Shut down",
                "LOGIN_WITHOUT_PASSWORD": "Login without password",
                "MAINTENANCE": "Maintenance",
                "LOST_MONEY": "Lost money",
                "BURN_CARD": "Burn card",
                "VIEW_TRANSACTION_PICTURES": "View transaction pictures",
                "CHANGE_ATM_BANK_KEY": "Change ATM bank key",
                "SAFE_DOOR": "Safe door",
                "SAFE_EMERGENCY": "Safe emergency",
                "TICKET_HAND_PAY": "Ticket hand pay",
                "COUNT_ONLY": "Count Only",
                "DEBIT_ACCOUNT": "Debit account",
                "CASHIER": "Cashier",
                "APPROVE_TRANSACTIONS": "Approve Transactions",
                "RDP_ACCESS": "RDP Access"
            },
            "CCS_RIGHT": {
                "CONFIGURATION": "Configuration",
                "ADMINISTRATION": "Administration",
                "END_OF_SHIFT": "End of Shift",
                "BALANCING": "Balancing",
            },
            "COMPONENT_SEVERITY": {
                "NONE": "None",
                "INFORMATION": "Information",
                "WARNING": "Warning",
                "ERROR": "Error",
                "FATAL": "Fatal",
            },
            "COMPONENT_DEVICE_STATE": {
                "NONE": "(Not Specified)",
                "START_UP": "Start up",
                "WORKING": "Working",
                "WARNING": "Warning",
                "ERROR": "Error",
            },
            "COMPONENT_TYPE": {
                "NONE": "Global Event",
                "MACHINE_INTEGRITY": "Machine Integrity",
                "ACCEPTOR_BOX": "Acceptor Box",
                "ART_SERVER": "ART Server",
                "ATM": "ATM",
                "BILL_ACCEPTOR": "Bill Acceptor",
                "BILL_DISPENSER": "Bill Dispenser",
                "CAPTURE_BIN": "Capture Bin",
                "CARD_DISPENSER": "Card Dispenser",
                "CARD_READER": "Card Reader",
                "CASSETTE": "Cassette",
                "COIN_ACCEPTOR": "Coin Acceptor",
                "COIN_DISPENSER": "Coin Dispenser",
                "ONLINE_SYSTEM": "Online System",
                "FINGERPRINT_READER": "Fingerprint Reader",
                "HOPPER_CHANNEL": "Hopper",
                "COMBINED_ACCEPTOR": "Bill/Ticket Acceptor",
                "TICKET_ISSUER": "Ticket Issuer",
                "CARD_SYSTEM": "Card System",
                "BARCODE_SCANNER": "Barcode Scanner",
                "JACKPOT_SYSTEM": "Jackpot System",
                "KEYBOARD": "Keyboard",
                "POS": "POS",
                "RECEIPT_PRINTER": "Receipt Printer",
                "REJECT_BOX": "Reject Box",
                "SAM": "SAM",
                "SENSOR_BOARD": "Sensor Board",
                "SENSOR": "Sensor Rank 0",
                "CARD_STACKER": "Card Stacker",
                "TICKET_ACCEPTOR": "Ticket Acceptor",
                "TICKET_PRINTER": "Ticket Printer",
                "TOWER_LIGHT": "Tower Light",
                "UPS": "UPS",
                "VSI": "Video Surveillance Interface",
                "MONITORING_SYSTEM": "Monitoring System",
                "ONLINE_CASINO": "Online Casino",
                "DISPLAY": "Display",
                "ATM_PROXY_CONNECTION": "ATM Proxy Connection",
                "IDS_CONNECTION": "IDS Connection",
                "PIN_KEY": "PIN Key",
                "MASTER_KEY": "Master Key",
                "BONUS_SYSTEM": "Bonus System",
                "ATS": "Cash-N-Go ATS",
                "REWARD_SYSTEM": "Reward System",
                "COS_PROXY_MODULE": "COS Proxy Module",
                "COS_PROXY_CONNECTION": "COS Proxy Connection",
                "TIME_SOURCE": "Time Source",
                "DISPENSER_SHUTTER": "Dispenser Shutter",
                "FISCAL_CONNECTION": "Fiscal Connection",
                "ADVERTISING_SERVER": "Advertising Server",
                "TRANSACTION_APPROVAL": "Transaction Approval",
                "EMV": "EMV",
                "EMV_PROXY": "EMV Proxy Connection",
                "EMV_PIN_KEY": "EMV PIN Key",
                "EMV_MASTER_KEY": "EMV Master Key",
                "I_D_SCANNER": "ID Scanner",
                "HANDHELD": "Handheld",
                "EMV_BANK_LINK": "EMV Bank Link",
                "PAYMENT_SYSTEM": "Payment System",
                "FACE_RECOGNITION": "Face Recognition",
                "CONFIGURATION_VALIDITY": "Configuration Validity",
                "PALM_SCANNER": "Palm Scanner",
                "CABLE_TREE": "Cable Tree",
                "CRYPTO_KEYS": "Crypto Keys",
                "SIGN_IN_SIGN_UP_SYSTEM": "Sign in Sign up System",
                "BILL_PAY_SYSTEM": "Bill Pay",
                "CHECK_CASHING_SYSTEM": "Check Cashing",
                "MOBILE_TOP_UP_SYSTEM": "Mobile Top Up System",
                "GIFT_CARD_SALE_SYSTEM": "Gift Card Sale System",
                "MANUAL_INPUT": "Manual Input",
                "WIRE_TRANSFER_SYSTEM": "Wire Transfer System",
                "GIFT_CARD_BUY_SYSTEM": "Gift Card Buy System",
                "CRYPTO_CURRENCY_SYSTEM": "Crypto Currency System",
                "PREPAID_CARD_SYSTEM": "Prepaid Card System",
                "E_WALLET": "eWallet",
                "E_WALLET_SYSTEM": "EWallet System",
                "TCD_SYSTEM": "Teller Cash Dispenser System",
                "OTHERS": "Other Components",
                "UNDEFINED": " Undefined",
                "MAINBOARD": "Mainboard",
                "CENTRAL_PROCESSING_UNIT": "Central Processing Unit (CPU)",
                "MEMORY_MODULE": "Memory Module (RAM)",
                "SOLID_STATE_DISK": "Solid State Disk (SSD)",
                "CPU_FAN": "CPU Fan",
                "CASE_FAN": "Case Fan",
                "CAMERA": "Camera",
                "POWER_SUPPLY": "Power Supply",
                "MONITOR": "Monitor",
                "MAIN_CONTROLLER": "Main Controller",
                "NETWORK_CONNECTIVITY": "Network Connectivity",
                "NETWORK_INTERFACE": "Network Interface",
                "ADVERTISING_STREAM_PLAYER": "Advertising Stream Player",
                "DISPENSE_REQUEST_SERVICE_MODULE": "Dispense Request Service Module",
                "MAC_MASTER_KEY": "MAC Master Key",
                "MAC_WORKING_KEY": "MAC Working Key",
                "EMV_MAC_WORKING_KEY": "EMV MAC Working Key",
                "CARD_POSITION": "Card Position",
                "TAX_CONTROLLING_SYSTEM": "Tax Controlling System",
                "TABLE_SYSTEM": "Table System"
            },
            "DASHBOARD_WIDGET": {
                "TRANSACTION_COUNTS": "Transaction Counts",
                "TRANSACTION_AVERAGES": "Transaction Averages",
                "TRANSACTION_TOTALS": "Transaction Totals",
                "TRANSACTION_TYPES": "Transaction Types",
                "TERMINALS_CONNECTED": "Terminals Connected",
                "TERMINAL_ISSUES": "Terminal Issues",
                "TERMINAL_UPTIME": "Terminal Uptime",
                "SOFTWARE_DISTRIBUTION": "Software Distribution",
                "SOFTWARE_UPDATES": "Software Updates",
                "DISK_USAGE": "Disk Usage",
                "TERMINAL_LOCATIONS": "Terminal Locations",
                "TERMINAL_UPTIME_TIME_SLOT": "Terminal Uptime Time Slots",
                "TERMINAL_COMPONENT_MONITOR": "Terminal Component Monitor",
                "TERMINAL_UPTIME_EXTENDED": "Terminal Downtime",
                "LOGGED_IN_OPERATOR": "Logged-in Operators",
                "MONEY_SERVICE_COUNT": "Money Services Performed",
                "MAINTENANCE_CALLS": "Maintenance Calls",
                "REPLACED_PARTS": "Replaced Parts",
                "NEXT_MAINTENANCE": "Next Maintenance",
                "DONATION_COUNTS": "Donation Count",
                "SOFTWARE_DISTRIBUTION_OS": "Software Distribution OS",
                "TOTAL_UPTIME": "Total Uptime",
                "TOTAL_DOWNTIME": "Total Downtime",
                "DOWNTIME": "Downtime",
                "DOWNTIME_TECHNICAL_ERRORS": "(Technical Errors)",
                "DOWNTIME_OPERATIONAL": "(Operational)",
                "REGISTERED_CUSTOMERS": "Registered Customers",
                "REGISTERED_PLAYERS": "Registered Players",
                "TERMINAL_OUT_OF_OPERATION": "Out of Operation",
                "MONEY_SERVICE_ACTION_REQUIRED": "Money Service Action Required",
                "MODALS": {
                    "TERMINALS_DISCONNECTED": "Disconnected Terminals",
                    "TERMINALS_ISSUES": "Terminals with Issues",
                    "TERMINALS_UPTIME": "Terminal Uptime",
                    "MONEY_SERVICES": "Money Services",
                    "CURRENT_OPERATORS": "Currently Logged-in Operator Sessions",
                    "TERMINALS_OUT_OF_OPERATION": "Terminals Out of Operation",
                    "MONEY_SERVICE_ACTION_REQUIRED": "Money Service Overview"
                },
            },
            "DEVICE_CONNECTION_TYPE": {
                "SERIAL": "Serial",
                "USB": "USB",
                "PS2": "PS2",
                "NETWORK": "Network",
                "OTHER": "Other",
                "UNKNOWN": "Unknown"
            },
            "DEVICE_INFORMATION_TYPE": {
                "SERIAL_NUMBER": "Serial Number",
                "FIRMWARE_VERSION": "Firmware Version",
                "COM_PORT": "COM Port",
                "ERROR_INFORMATION": "Error Information",
                "UNKNOWN": "Unknown",
                "UNDEFINED": "Unknown",
                "OTHER": "Other"
            },
            "DEVICE_TYPE": {
                "UNDEFINED": "Undefined",
                "NONE": "",
                "DEBUG": "Debug",
                "BILL_ACCEPTOR_WBA": "JCM",
                "BILL_ACCEPTOR_SANWA": "Sanwa",
                "BILL_ACCEPTOR_WBA_IRQ": "JCM IRQ",
                "BILL_ACCEPTOR_CASHFLOW": "MEI Cashflow-SC/SCN",
                "BILL_ACCEPTOR_NV200": "Inno.Tech. NV200",
                "BILL_ACCEPTOR_CASHCODE": "CashCode",
                "BILL_ACCEPTOR_JCM_I_PRO": "JCM iPro",
                "BILL_ACCEPTOR_JCM_I_VIZION": "JCM iVizion",
                "BILL_ACCEPTOR_JCM_TBV": "JCM TBV",
                "BILL_DISPENSER_ITAUTEC": "Itautec",
                "BILL_DISPENSER_DELARUE_NMD100": "Glory NMD100",
                "BILL_DISPENSER_DELARUE_NMD50": "Glory NMD50",
                "BILL_DISPENSER_DELARUE_MULTIMECH": "Glory MultiMech",
                "BILL_DISPENSER_FUJITSU_F610": "Fujitsu F610",
                "BILL_DISPENSER_FUJITSU_F53": "Fujitsu F53",
                "BILL_DISPENSER_GRG_CDM8240": "GRG CDM8240",
                "COIN_ACCEPTOR_CCTALK": "CCTalk",
                "COIN_ACCEPTOR_PRO_COIN": "ProCoin",
                "COIN_DISPENSER_ITAUTEC": "Itautec",
                "COIN_DISPENSER_PERCONTA": "Perconta",
                "COIN_DISPENSER_CCTALK": "CCTalk",
                "COIN_DISPENSER_SMART": "Smart Hopper",
                "CASSETTE_ITAUTEC": "Itautec",
                "CASSETTE_DELARUE_NMD100": "Glory NMD100",
                "CASSETTE_DELARUE_NMD50": "Glory NMD50",
                "CASSETTE_DELARUE_MULTIMECH": "Glory MultiMech",
                "CASSETTE_FUJITSU_F610": "Fujitsu F610",
                "CASSETTE_FUJITSU_F53": "Fujitsu F53",
                "CASSETTE_GRG_CDM8240": "GRG CDM8240",
                "REJECT_BOX_ITAUTEC": "Itautec",
                "REJECT_BOX_DELARUE_NMD100": "Glory NMD100",
                "REJECT_BOX_DELARUE_NMD50": "Glory NMD50",
                "REJECT_BOX_DELARUE_MULTIMECH": "Glory MultiMech",
                "REJECT_BOX_FUJITSU_F610": "Fujitsu F610",
                "REJECT_BOX_FUJITSU_F53": "Fujitsu F53",
                "REJECT_BOX_GRG_CDM8240": "GRG CDM8240",
                "SHUTTER_GRG": "Shutter GRG",
                "HOPPER_ITAUTEC": "Itautec",
                "HOPPER_PERCONTA": "Perconta",
                "HOPPER_CCTALK": "CCTalk",
                "HOPPER_SMART": "Smart Hopper",
                "TICKET_PRINTER_FUTURELOGIC_GEN2": "FutureLogic GEN2",
                "TICKET_PRINTER_TRANSACT_EPIC950": "Transact EPIC950",
                "CARD_READER_ICM300": "Itautec ICM-300",
                "CARD_READER_CIM1000": "CIM-1000",
                "CARD_READER_CRT288": "Quio CRT-288",
                "CARD_READER_KYT6_XXX": "KYT6XXX",
                "CARD_READER_HS862": "HS862t",
                "CARD_READER_ICM330": "Sankyo ICM-330",
                "CARD_READER_HS910": "HS910",
                "CARD_READER_MAGTEK_SWIPE": "MagTek Swipe",
                "CARD_READER_HS855": "HS855",
                "CARD_READER_KYTRONICS_CHR1000": "Kytronics CHR-1000",
                "CARD_READER_SANKYO_ICM370": "Sankyo ICM-370",
                "CONTROLLER_ITAUTEC": "Itautec",
                "CONTROLLER_SENSOR_ONLY": "Sensors Only",
                "UPS_PULSAR": "Pulsar",
                "UPS_APC": "APC",
                "UPS_EATON": "Eaton USB",
                "UPS_EATON_HID": "Eaton HID",
                "KEYBOARD_STANDARD": "Standard",
                "KEYBOARD_ITAUTEC": "TripleDES Itautec",
                "KEYBOARD_SAGEM4220": "Sagem 4220",
                "KEYBOARD_SAGEM4242": "Sagem 4242",
                "KEYBOARD_CRYPTERA1215": "Cryptera 1215",
                "TOUCHSCREEN3_M": "3M",
                "TOUCHSCREEN_ELO": "Elo TouchSystems",
                "TOUCHSCREEN_PERCONTA": "Perconta",
                "RECEIPT_PRINTER_TG1280": "tg-1280",
                "RECEIPT_PRINTER_TG2480": "tg-2480",
                "RECEIPT_PRINTER_FUTURELOGIC_GEN2": "FutureLogic GEN2",
                "RECEIPT_PRINTER_TRANSACT_EPIC950": "Transact EPIC950",
                "RECEIPT_PRINTER_FISCAL_BOX": "Fiscal Box",
                "BIOMETRIC_DEVICE_TOUCH_CHIP": "Eikon TouchChip",
                "BIOMETRIC_DEVICE_PALM_SECURE": "Fujitsu PalmSecure",
                "VIDEO_TEXT_STANDARD": "Standard",
                "VIDEO_TEXT_GTD": "GTD",
                "REAR_CONTROL_STANDARD": "Standard",
                "REAR_CONTROL_EXTENDED": "Extended",
                "BARCODE_SCANNER_STANDARD": "Standard",
                "BARCODE_SCANNER_HID": "HID",
                "POS_INGENICO": "Ingenico",
                "POS_CCV": "CCV",
                "BADGE_GD32_CUSTOM": "GD32 Custom",
                "BADGE_SSD1322": "SSD 1322",
                "FISCAL_CONNECTION_PAY_ONLINE": "Fiscal Box PayOnline",
                "I_D_SCANNER_ARH_SMART_KIOSK": "ARH SmartKiosk",
                "HANDHELD_PT7003": "PT7003",
                "HANDHELD_GT_S7500": "GT-S7500",
                "HANDHELD_SM_J320_FN": "SM-J320FN",
                "TICKET_ACCEPTOR_AM_TOTE": "AmTote",
                "BARCODE_SCANNER_DATALOGIC_GRYPHON": "Datalogic Gryphon",
                "I_D_SCANNER_PERTECH6000": "Pertech 6000",
                "FINGERPRINT_READER_TOUCH_CHIP": "Fingerprint Reader Touch Chip",
                "PALM_SCANNER_PALM_SECURE": "Palm Scanner Palm Secure",
                "GENERIC_CASHIO_POWER_SUPPLY": "Generic",
                "CASHIO_POWER_SUPPLY12_V": "12V",
                "CASHIO_POWER_SUPPLY24_V": "24V",
                "GENERIC_CASHIO_MONITOR": "Generic",
                "GENERAL_TOUCH_MONITOR": "General Touch",
                "GREEN_TOUCH_MONITOR": "Green Touch",
                "GENERIC_CASHIO_MAIN_CONTROLLER": "Generic",
                "BILL_DISPENSER_FUJITSU_F510": "Fujitsu F510",
                "BILL_DISPENSER_GRG_CDM6240": "GRG CDM6240",
                "CASSETTE_FUJITSU_F510": "Fujitsu F510",
                "CASSETTE_GRG_CDM6240": "GRG CDM6240",
                "CARD_READER_EFLOOR_CR7": "eFLOOR CR7",
                "CARD_READER_ICT3_V5": "ICT3V5",
                "CARD_READER_ACR39": "ACR 39",
                "KEYBOARD_CRYPTERA1215_PCI5": "Cryptera 1215 PCI5"
            },
            "ERROR_TYPE": {
                "OPEN_WITHOUT_LOGIN": "Open without login",
                "LOGIN_FAILED_WRONG_PASSWORD": "Login failed (wrong password entered 3 times)",
                "COIN_ACCEPTOR_NOT_IN_END_POSITION": "Coin Acceptor not in end position",
                "COIN_DISPENSER_NOT_IN_END_POSITION": "Coin Dispenser not in end position",
                "ACCEPTOR_REMOVED_WHILE_DOOR_CLOSED": "Acceptor removed while door is closed",
                "DISPENSER_REMOVED_WHILE_DOOR_CLOSED": "Dispenser removed while door is closed",
                "HOPPER_UNDER_RUNS_MINIMAL_VALUE": "Hopper under-runs minimal value",
                "DISPENSER_UNDER_RUNS_MINIMAL_VALUE": "Dispenser under minimal value ",
                "ACCEPTOR_EXCEEDS_MAXIMUM_CAPACITY": "Acceptor exceeds maximum capacity",
                "TIME_SYNC_TOO_OFTEN": "Time was set more than 3 times within 24 hours",
                "ALREADY_ASSIGNED_TERMINAL_ID": "Terminal tries to log on with an already assigned ID",
                "MAXIMUM_NUMBER_OF_TERMINALS_REACHED": "Maximum # of terminals reached",
                "COMMUNICATION_PROBLEM": "Communication Problem",
                "DOOR_OPENED": "Door opened",
                "SERVER_TIME_AND_TERMINAL_TIME_DIFFER": "Server time and terminal time differ more than 12h. Please adjust or accounting can be incorrect.",
                "INVALID_PROPERTY_NUMBER": "Invalid property #",
                "PIN_ALERT": "Pin alert",
                "TRANSACTION_APPROVAL_REQUIRED": "Transaction approval required",
                "TRANSACTION_APPROVED": "Transaction approved",
                "REMOTE_ACCESS_CONFIRMATION": "Remote access confirmation",
                "MAGNETICAL_CARD_READER_WRITE_ERROR": "Card reader error",
                "CARD_JAMMED": "Card jammed",
            },
            "EVENT_SEVERITY": {
                "NONE": "None",
                "INFORMATION": "Information",
                "WARNING": "Warning",
                "ERROR": "Error",
                "FATAL": "Fatal"
            },
            "FEATURE_TYPE": {
                "NONE": "None",
                "BILL_BREAK": "Bill Break",
                "TICKET_CREATION": "Ticket Creation",
                "TICKET_REDEMPTION": "Ticket Redemption",
                "PLAYER_CARD_CREATION": "Player Card Creation",
                "PLAYER_CARD_REDEMPTION": "Player Card Redemption",
                "PLAYER_CARD_CHARGING": "Player Card Charging",
                "EMPTY_CARD_DISPENSE": "Empty Card Dispense",
                "ATM": "ATM",
                "POS": "POS",
                "CEC": "CEC",
                "JACKPOT": "Jackpot",
                "CASH_CHANGE": "Cash Change",
                "VOUCHER_REDEMPTION": "Voucher Redemption",
                "MARKER_REDEMPTION": "Marker Redemption",
                "CURRENCY_EXCHANGE": "Currency Exchange",
                "DEBIT_ACCOUNT_RETURN": "Debit Return",
                "DEBIT_ACCOUNT_PAYOUT": "Debit Payout",
                "CASHIER_CASH_PAYOUT": "Cashier Cash Payout",
                "CASHIER_CASH_RETURN": "Cashier Cash Return",
                "ADVERTISING_SERVER": "Advertising Server",
                "ADVERTISING": "Advertising",
                "TRANSACTION_APPROVAL": "Transaction Approval",
                "REWARD_SYSTEM": "Reward System",
                "POS_TO_TICKET": "POS to Ticket",
                "POS_TO_CARD": "POS to Card",
                "E_WALLET_WITHDRAWAL": "eWallet Redemption",
                "E_WALLET_DEPOSIT": "eWallet Charging",
                "CRYPTO_KEYS": "Crypto Keys",
                "CASH_TO_TICKET": "Cash to Ticket",
                "BONUS_CARD_CREATION": "Bonus Card Creation",
                "BONUS_CARD_REDEMPTION": "Bonus Card Redemption",
                "BONUS_CARD_CHARGING": "Bonus Card Charging",
                "SIGN_IN": "Sign In",
                "SIGN_UP": "Sign Up",
                "BILL_PAY": "Bill Pay",
                "CHECK_CASHING": "Check Cashing",
                "GIFT_CARD_SALE": "Gift Card Sale",
                "MOBILE_TOP_UP": "Mobile Top Up",
                "WIRE_TRANSFER": "Wire Transfer",
                "GIFT_CARD_BUY": "Gift Card Buy",
                "CRYPTO_CURRENCY_BUY": "Crypto Currency Buy",
                "PREPAID_CARD": "Prepaid Card",
                "CRYPTO_CURRENCY_SALE": "Crypto Currency Sale",
                "ADVERTISING_STREAM": "Advertising (Streaming)",
                "TABLE_SYSTEM": "Table System"
            },
            "FILL_LEVEL_DEVICE": {
                "NOTE_CASSETTE": "Cassette",
                "COIN_HOPPER": "Coin Hopper",
                "BILL_ACCEPTOR": "Bill Acceptor",
                "COIN_ACCEPTOR": "Coin Acceptor",
                "REJECT_BOX": "Reject Box",
                "CARD_STACKER": "Card Stacker",
                "CARD_CATCHING_BIN": "Card Stacker Bin",
            },
            "FILL_LEVEL_STATE": {},
            "GENDER": {
                "NONE": "None",
                "UNKNOWN": "Unknown",
                "MALE": "Male",
                "FEMALE": "Female",
                "DIVERSE": "Diverse"
            },
            "IDENTITY_DOCUMENT_TYPE": {
                "NONE": "None",
                "IDENTITY_CARD": "Identity Card",
                "PASSPORT": "Passport",
                "DRIVERS_LICENSE": "Driver License",
                "ID_BOOK_SA": "ID Book SA",
                "MEXICAN_CONSULAR_ID": "Mexican Consular ID"
            },
            "INFO_TYPE": {
                "ATM_RBS_LYNK_SEQUENCE_NUMBER": "Bank Sequence #",
                "ATM_RBS_LYNK_DATE_TIME": "Bank Date",
                "ATM_CURRENT_BANK_TERM_I_D": "Bank Terminal ID",
                "SLOT_NUMBER": "Slot #",
                "JACKPOT_TRANSACTION_NUMBER": "Jackpot System ID",
                "DEV_S_I_TRACKING_NR": "Online System Tracking #",
                "ATM_CURRENT_BANK_TRANS_NUMBER": "Bank Transaction #",
                "ATM_CURRENT_BANK_SEQUENCE_NUMBER": "Bank Sequence #",
                "TERMINAL_SEQUENCE_NUMBER": "Terminal Sequence #",
                "CARDS_TO_STACKER": "Cards to Stacker",
                "CARDS_TO_BIN": "Cards to Bin",
                "CARDS_FROM_STACKER": "Cards from Stacker",
                "CARDS_FROM_BIN": "Cards from Bin",
                "TICKET_OUT_MEDIA_NUMBER": "Ticket Out Media #",
                "TICKET_IN_MEDIA_NUMBER": "Ticket In Media #",
                "WARNING": "Terminal Message",
                "ATI_JACKPOT_FILL_I_D": "Jackpot Fill ID",
                "ATI_JACKPOT_TICKET_NUMBER": "Jackpot Ticket #",
                "CEC_TRANSACTION_I_D": "CEC Transaction ID",
                "CREDIT_CARD_HOLDER": "Credit Card Holder",
                "PIN_ALERT": "Pin Alert",
                "APPROVING_OPERATOR_I_D": "Approved by",
                "BILL_ACCEPTOR_RANK": "Bill Acceptor Rank",
                "HOPPER_EMPTY_MODE": "Hopper Empty Mode",
                "JACKPOT_NAME": "Jackpot Name",
                "TERMINAL_INFO": "Terminal Info",
                "COMPONENT_MESSAGE": "Component Message",
                "PLAYER_MEDIA_DATA": "Player Information",
                "POS_JOURNAL": "POS Journal",
                "EMV_ATM_TRANSACTION_TYPE": "EMV ATM Transaction Type",
                "ATM_ACQUIRER_RESPONSE_CODE": "ATM Acquirer Response Code",
                "ATM_ACCOUNT": "ATM Account",
                "EMV_ATM_APPLICATION_I_D": "EMV ATM Application ID",
                "EMV_ATM_APPLICATION_NAME": "EMV ATM Application Name",
                "EMV_ATM_AUTHORIZATION_RESPONSE_CODE": "EMV ATM Authorization Reponse Code",
                "EMV_ATM_AUTH_CODE": "EMV ATM Auth Code",
                "ATM_SEQUENCE_NUMBER": "ATM Sequence #",
                "TRANSACTION_ABORT_STEP": "Transaction Abort Step",
                "EMV_KIOSK_TRANSACTION_OUTCOME": "EMV Kiosk Transaction Outcome",
                "EXTERNAL_TERMINAL_I_D": "External Terminal ID",
                "JACKPOT_ADDITIONAL_INFO": "Jackpot Additional Info",
                "GROSS_AMOUNT_IN": "Gross Amount In"
            },
            "LICENSE_COMPONENT": {
                "LARGE_CASSETTES": "Large Cassettes",
                "ATM": "ATM",
                "BARCODE_SCANNER": "Barcode Scanner",
                "BONUS_SYSTEM": "Bonus System",
                "CARD_READER": "Card Reader",
                "CARD_READER_SECONDARY": "Card Reader Secondary",
                "CARD_DISPENSER": "Card Dispenser",
                "CASINO_ONLINE_SYSTEM": "Casino Online System",
                "COIN_DISPENSER": "Coin Dispenser",
                "COIN_ACCEPTOR": "Coin Acceptor",
                "CRYPTO_KEYBOARD": "Crypto Keyboard",
                "DOCUMENT_SCANNER": "Document Scanner",
                "FINGER_PRINT": "Finger Print",
                "FISCAL_CONNECTION": "Fiscal Connection",
                "JACKPOT_DISPENSE": "Jackpot Dispense",
                "NOTE_ACCEPTOR": "Note Acceptor",
                "NOTE_DISPENSER": "Note Dispenser",
                "PALM_SENSOR": "Palm Sensor",
                "POS": "POS",
                "PS2_KEYBOARD": "PS/2 Keyboard",
                "RECEIPT_PRINTER": "Receipt Printer",
                "RECYCLER": "Recycler",
                "SENSOR_BOARD": "Sensor Board",
                "SHUTTER": "Shutter",
                "TAC_MODS": "TAC Mods",
                "TCD": "TCD",
                "TICKET_PRINTER": "Ticket Printer",
                "UPS": "UPS",
                "RANDOM_DATA": "Random Data"
            },
            "LIFE_CYCLE_RECORD_TYPE": {
                "NONE": "None",
                "MACHINE_ACCEPTANCE_RESULTS": "Machine Acceptance Results",
                "COMPONENT_REPLACED": "Component Replaced",
                "PRODUCTION_ORDER": "Production Order",
                "SYSTEM_INFORMATION": "System Information",
                "MANUFACTURER_BLOCKING": "Manufacturer Blocking"
            },
            "LIFE_CYCLE_STEP": {
                "NONE": "None",
                "ASSEMBLED": "Assembled",
                "COMPONENTS_TESTED": "Components Tested",
                "READY_FOR_PRODUCTION": "Ready For Production",
                "IN_TEST_LAB_USE": "In Test Lab Use",
                "IN_PRODUCTION_USE": "In Production Use",
                "SUSPENDED": "Suspended",
                "DECOMMISSIONED": "Decommissioned"
            },
            "LOGIN_STATE": {
                "NO_LOGIN": "No operator logged in",
                "CARD_INSERTED": "Operator {0} card inserted",
                "LOGGED_IN": "Operator {0} logged in",
                "INVALID_PASSWORD": "Operator {0} password incorrect",
                "SERVICE_LOGIN": "Service login",
                "NONE": "Operator {0} state N/A",
            },
            "MACHINE_STATE": {},
            "MAINTENANCE_TYPE": {
                "CLEANING": "Cleaning",
                "MAINTENANCE": "Maintenance",
            },
            "MEDIA_COUNT_SOURCE": {
                "NONE": " ",
                "TERMINAL": "Terminal",
                "SERVER": "Server"
            },
            "MEDIA_COUNT_TYPE": {
                "None": " ",
                "OPENING": "Opening Inventory",
                "CLOSING": "Closing Inventory",
                "CURRENT": "Current",
                "FILLS": "Fills",
                "CREDIT": "Credits",
                "ROLL": "Roll"
            },
            "MEDIA_DIRECTION": {
                "IN": "In",
                "OUT": "Out"
            },
            "MEDIUM_STATE": {
                "NONE": "None",
                "MEDIA_ISSUED": "Media issued",
                "MEDIA_CHARGED": "Media charged",
                "MEDIA_REDEEMED": "Media redeemed",
                "MEDIA_CAPTURED": "Media captured",
            },
            "MEDIUM_TYPE": {
                "NONE": "Unknown",
                "REJECTED": "Rejected",
                "COIN": "Coin",
                "BILL": "Bill",
                "TICKET": "Ticket",
                "MAGNETIC_CARD": "Magnetic Card",
                "SMART_CARD": "Smart Card",
                "RFID_CARD": "RFID Card",
                "CHIP": "Chip",
                "ATM": "ATM",
                "ATM_FEE1": "ATM Fee 1",
                "ATM_FEE2": "ATM Fee 2",
                "ATM_FEE3": "ATM Fee 3",
                "DEPOSIT": "Deposit",
                "ROUNDING": "Rounding",
                "SAM": "SAM",
                "COUPON": "Coupon",
                "CEC": "CEC",
                "CEC_CASINO_FEE": "CEC Casino Fee",
                "CEC_PROVIDER_FEE": "CEC Provider Fee",
                "DONATION": "Donation",
                "LOAN_VALUE": "Loan",
                "LOAN_REPAY_VALUE": "Loan Repay",
                "CREDIT_CARD": "Credit Card",
                "CLEARED_CREDIT": "Cleared Credit",
                "MARKER": "Marker",
                "TAX": "Tax",
                "POS": "POS",
                "POS_MERCHANT_FEE": "POS Merchant Fee",
                "POS_PROVIDER_FEE": "POS Provider Fee",
                "E_WALLET_VALUE": "eWallet",
                "BILL_PAY": "Bill Pay",
                "BILL_PAY_FEE": "Bill Pay Fee",
                "INVOICE": "Invoice",
                "CHECK": "Check",
                "CHECK_FEE": "Check Fee",
                "GIFT_CARD": "Gift Card",
                "GIFT_CARD_FEE": "Gift Card Fee",
                "MOBILE_TOP_UP": "Mobile Top-Up",
                "MOBILE_TOP_UP_FEE": "Mobile Top-Up Fee",
                "WIRE_TRANSFER": "Wire Transfer",
                "WIRE_TRANSFER_FEE": "Wire Transfer Fee",
                "FEE": "Fee",
                "PREPAID_CARD": "Prepaid Card",
                "CRYPTO_CURRENCY": "Crypto Currency",
                "CASH_ADVANCE_RECEIPT": "Cash Advance Receipt",
                "JACKPOT_VALUE": "Jackpot",
                "IOU_RECEIPT": "IOU Receipt"
            },
            "MODULE_LICENSE": {
                "ADVERTISING": "Advertising",
                "GRCC_PAGING": "Grcc Paging",
                "PROPERTY_MANAGEMENT": "Property Management",
                "REPLICATION": "Replication",
                "CARD_BURNING": "Card Burning",
                "SHOP_MANAGEMENT": "Shop Management",
                "CURRENCY_COUNTING": "Currency Counting",
                "TERMINAL_LICENSING": "Terminal Licensing",
                "MULTI_CURRENCY": "Multi Currency",
                "REMOTE_ACCESS": "Remote Access",
                "DEBIT_ACCOUNTS": "Debit Accounts",
                "PREVENTIVE_MAINTENANCE": "Preventive Maintenance",
                "FACE_RECOGNITION": "Face Recognition",
                "ART_CLIENT_LICENSING": "ART Client Licensing",
                "FACTORY_SUPPORT": "Factory Support",
                "ART_CONNECT": "ART Connect",
            },
            "ONLINE_SYSTEM": {
                "NONE": "None",
                "MANUAL": "Manual",
                "ATRONIC": "Atronic"
            },
            "OPERATOR_ROLE": {
                "OPERATOR": "Operator",
                "COUNT_ONLY": "Count Only",
                "POINT_OF_SALE": "Point of Sale",
                "AD_MANAGEMENT": "Ad Management",
                "CASHIER": "Cashier",
                "PIT_BOSS": "Pit Boss",
                "BROKER": "Broker",
            },
            "PLAYER_MEDIUM_STATE": {
                "NONE": "None",
                "REGISTERED": "Registered",
                "CAPTURED": "Captured",
                "ONE_TIME_USE": "One time use",
            },
            "PAYMENT_KIOSK_REPORT_TYPE": {
                "COMMISSION": "Commission",
                "TRANSACTION": "Transaction",
                "CUSTOMER": "Customer",
                "SIMPLE_TRANSACTION": "Transaction",
                "DISTRIBUTOR": "Distributor",
                "STORE": "Store"
            },
            "REGISTRATION_TYPE": {
                "UNKNOWN": "Unknown",
                "SELF_OWNED": "Self Owned",
                "RENTED": "Rented"
            },
            "SENSOR_RANK": {
                "UPPER_DOOR": "Upper Door",
                "SAFE_DOOR": "Safe Door",
                "COIN_DISPENSER": "Coin Dispenser",
                "BILL_DISPENSER": "Bill Dispenser",
                "BILL_ACCEPTOR": "Bill Acceptor",
                "CARD_READER": "Card Reader",
                "RECEIPT_PRINTER": "Receipt Printer",
                "SAFE_DOOR_KEY": "Safe Door Key"
            },
            "SEVERITY": {
                "INFO": "Info",
                "WARNING": "Warning",
                "ERROR": "Error"
            },
            "SPLIT_AMOUNT_TYPE": {
                "CASH_PAYOUT_AMOUNT": "Cash Payout Amount",
                "KEYBACK_AMOUNT": "Keyback Amount",
                "TICKET_PAYOUT_AMOUNT": "Ticket Payout Amount",
                "CHECK_PAYOUT_AMOUNT": "Check Payout Amount",
                "WALLET_PAYOUT_AMOUNT": "Wallet Payout Amount",
                "WIRE_ACH_PAYOUT_AMOUNT": "Wire/ACH Payout Amount"
            },
            "TERMINAL_CONNECTION_STATE": {
                "ALL": "All",
                "CONNECTED": "Connected",
                "NOT_CONNECTED": "Not Connected",
                "RECENTLY_SEEN": "Recently Seen"
            },
            "TERMINAL_EVENT": {
                "OPEN_WITHOUT_LOGIN": "Door opened without login",
                "LOGIN_FAILED_WRONG_PASSWORD": "Login failed with wrong password",
                "COIN_ACCEPTOR_NOT_IN_END_POSITION": "Coin acceptor not in end position",
                "COIN_DISPENSER_NOT_IN_END_POSITION": "Coin dispenser not in end position",
                "ACCEPTOR_REMOVED_WHILE_DOOR_CLOSED": "Acceptor removed while door was closed",
                "DISPENSER_REMOVED_WHILE_DOOR_CLOSED": "Dispenser removed while door was closed",
                "HOPPER_UNDER_RUNS_MINIMAL_VALUE": "Hopper under runs minimal value",
                "DISPENSER_UNDER_RUNS_MINIMAL_VALUE": "Dispenser under runs minimal value",
                "ACCEPTOR_EXCEEDS_MAXIMUM_CAPACITY": "Acceptor exceeds maximum capacity",
                "TIME_SYNC_TO_OFTEN": "Time sync to often",
                "ALREADY_ASSIGNED_TERMINAL_ID": "Already assigned terminal ID",
                "MAXIMUM_NUMBER_OF_TERMINALS_REACHED": "Maximum number of terminals reached",
                "COMMUNICATION_PROBLEM": "Communication problem",
                "DOOR_OPENED": "Door opened",
                "SERVER_TIME_AND_TERMINAL_TIME_DIFFER": "Server time and terminal time has are different",
                "INVALID_PROPERTY_NUMBER": "Invalid property number",
                "PIN_ALERT": "Pin alert",
                "TRANSACTION_APPROVAL_REQUIRED": "Transaction approval is required",
                "TRANSACTION_APPROVED": "Transaction approved",
                "REMOTE_ACCESS_CONFIRMATION": "Remote access confirmation",
                "PLAYER_IS_BANNED": "Player is banned",
                "PORT_FORWARDING_STATUS": "Port forwarding status",
                "ONLINE_SYSTEM_MEDIA_INVALID_HASH": "Online system media invalid hash",
                "MAGNETICAL_CARD_READER_WRITE_ERROR": "Magnetical card reader write error",
                "CARD_JAMMED": "Card jammed",
                "ATTENDANCE_REQUIRED": "Call Attendant"
            },
            "TERMINAL_STATE": {
                "OUT_OF_SERVICE": "Out of Service",
                "READY": "Ready",
                "IN_TRANSACTION": "In Transaction",
                "FAILURE": "Failure",
                "FAILURE_DUE_OPERATOR": "Failure due Operator",
                "BLOCKED": "Blocked",
                "MANUFACTURED_BLOCKED": "Manufactured blocked",
                "SHUT_OFF": "Shut off",
                "ERROR": "Error",
                "NONE": "Unknown",
            },
            "TERMINAL_UTILIZATION": {
                "NO_DATA": "No Data",
                "IDLE": "Idle",
                "CUSTOMER_USAGE": "Customer usage",
                "CALL_OPERATOR": "Call operator",
                "OUT_OF_OPERATION": "Out of operation",
                "OPERATOR_USAGE": "Operator usage",
                "MONEY_SERVICE": "Money Service",
                "JACKPOT_PAYOUT": "Jackpot payout",
                "SHUT_OFF": "Off",
                "OUT_OF_OPERATION_DUE_ERROR": "Out of operation (Error)",
                "OUT_OF_OPERATION_DUE_OPERATOR": "Out of operation (Operator)",
                "OUT_OF_OPERATION_ERROR_DEV_MAINT_REQUIRED": "Dev Maint Required",
                "OUT_OF_OPERATION_ERROR_PRIMARY_CARD_READER": "Primary Card Reader",
                "OUT_OF_OPERATION_ERROR_SECONDARY_CARD_READER": "Secondary Card Reader",
                "OUT_OF_OPERATION_ERROR_I_O_CONTROLLER": "I/O Controller",
                "OUT_OF_OPERATION_ERROR_BILL_DISPENSER": "Bill Dispenser",
                "OUT_OF_OPERATION_ERROR_COIN_DISPENSER": "Coin Dispenser",
                "OUT_OF_OPERATION_ERROR_MONEY_SERVICE_REQUIRED": "Money Service Required",
                "OUT_OF_OPERATION_ERROR_BILL_ACCEPTOR": "Bill Acceptor",
                "OUT_OF_OPERATION_ERROR_NETWORK_COMMUNICATION": "Network Communication",
                "OUT_OF_OPERATION_ERROR_ONLINE_SYSTEM": "Online System",
                "OUT_OF_OPERATION_ERROR_ART_SERVER": "ART Server",
                "OUT_OF_OPERATION_OPERATOR_PUT_OFFLINE": "Put Offline",
                "OUT_OF_OPERATION_OPERATOR_LOGGED_IN": "Logged In",
                "REWARD_SYSTEM": "Reward System",
            },
            "TERMINAL_UTILIZATION_DASHBOARD": {
                "MONEY_SERVICE": "Performing Money Service",
                "OUT_OF_OPERATION_OPERATOR_PUT_OFFLINE": "Set Offline",
                "SHUT_OFF": "Turned Off"
            },
            "TEST_RESULT": {
                "FAILED": "Failed",
                "NOT_EXECUTED": "Not Executed",
                "SKIPPED": "Skipped",
                "SUCCESS": "Success",
                "UNKNOWN": "Unknown"
            },
            "TEST_RUN_TYPE": {
                "FACTORY": "Factory",
                "DISTRIBUTOR": "Distributor",
                "CUSTOMER": "Customer",
                "UNKNOWN": "Unknown"
            },
            "TITA_RIGHT": {
                "LOGIN": "Login",
                "CONFIGURATION": "Configuration",
                "APPROVE_TRANSACTIONS": "Approve Transactions",
                "ADMINISTRATION": "Administration",
                "MAINTENANCE": "Maintenance"
            },
            "TRANSACTION_FLAG": {
                "ROUNDING": "Rounding",
                "WARNING": "Warning",
                "ERROR": "Error",
                "CREDIT_CLEARED": "Open credit cleared",
                "PIN_ALERT": "Pin Alert",
                "ADJUSTED": "Adjusted",
                "ABORTED": "Aborted",
                "CURRENCY_EXCHANGE": "Currency Exchange",
                "VOIDED": "Voided",
                "DECLINED": "Declined"
            },
            "TRANSACTION_TYPE": {
                // special entries for transactions categories
                "NON_CATEGORIZED": "Non-categorized",
                "CUSTOMER_TRANSACTIONS": "Customer Transactions",
                "JACKPOT": "Jackpot",
                "MONEY_SERVICE": "Money Service",
                "OPERATOR_TRANSACTIONS": "Operator Transactions",
                "INTERNAL": "Internal",
                "CARD_REDEMPTION": "Card redemption",
                "CARD_CHARGED": "Card charged",
                // individual entries for transaction types
                "UNDEFINED": "Undefined",
                "NONE": "None",
                "COIN_REDEMPTION": "Coin redemption",
                "BILL_BREAKER": "Bill breaker",
                "TICKET_REDEMPTION": "Ticket redemption",
                "MAGNETIC_CARD_REDEMPTION": "Magnetic card redemption",
                "SMART_CARD_REDEMPTION": "Smart card redemption",
                "JACKPOT_PAYOUT": "Jackpot payout",
                "FILL_BAG_FRONT": "Fill bag front",
                "EMPTY_HOPPER": "Empty hopper",
                "JACKPOT_REAR": "Jackpot rear",
                "FILL_BACK_REAR": "Fill bag rear",
                "CASSETTE_REMOVED": "Cassette removed",
                "CASSETTE_INSERTED": "Cassette inserted",
                "BILL_VALIDATOR_EMPTIED": "Bill validator emptied",
                "COINS_INSERTED": "Coins inserted",
                "REJECT_BOX_EMPTIED": "Reject box emptied",
                "OPEN_CREDIT_CLEARED": "Open credit cleared",
                "TEST_HOPPER": "Test hopper",
                "TICKET_PRINTED": "Ticket issued",
                "MAGNETIC_CARD_CHARGED": "Magnetic card charged",
                "SMART_CARD_CHARGED": "Smart card charged",
                "COINS_DISPENSED": "Coins dispensed",
                "MEDIA_OUT_EMPTIED": "Media out emptied",
                "MEDIA_IN_EMPTIED": "Media in emptied",
                "CASH_DIFFERENCE": "Cash difference",
                "CASHBOX_FILLED": "Cashbox filled",
                "CASHBOX_WITHDRAWN": "Cashbox withdrawn",
                "CASHBOX_RELOAD": "Cashbox reload",
                "ATM_WITHDRAWAL": "ATM withdrawal",
                "ATM_CASH_ADVANCE": "ATM cash advance",
                "MEDIA_REFILL": "Media refill",
                "ATM_BALANCE_INQUIRY": "ATM balance inquiry",
                "DEPOSIT_WITHHELD": "Deposit withheld",
                "DEPOSIT_PAID": "Deposit paid",
                "ROUNDING_DIFFERENCE_CLEARED": "Rounding difference cleared",
                "SAM_TO_SUPER_SAM": "SAM-SUPERSAM",
                "JACKPOT_CANCEL_CREDIT": "JP Cancel Credit",
                "JACKPOT_SHORT_PAY": "JP Short Pay",
                "G_M_HOPPER_REFILL": "GM Hopper Refill",
                "PROGRESSIVE_JACKPOT": "Progressive Jackpot",
                "CAPTURE_BIN_EMPTIED": "Capture bin emptied",
                "STACKER_STOCK_ADDED": "Stacker stock added",
                "PRINTED_TICKETS_CLEARED": "Printed tickets cleared",
                "LOST_MONEY": "Lost money",
                "LOST_MONEY_CLEARED": "Lost money cleared",
                "JACKPOT_ADDITIONAL": "JP Additional",
                "COIN_ACCEPTOR_EMPTIED": "Coin acceptor emptied",
                "POS_WITHDRAWAL": "POS withdrawal",
                "CHIP_REDEMPTION": "Chip redemption",
                "POS_CLEARED": "POS cleared",
                "CHIPS_PAID_OUT": "Cash in",
                "COUPON_REDEMPTION": "Coupon redemption",
                "CHECK_CASHING": "CEC Check cashing",
                "CHECK_CASHING_CLEARED": "CEC Check cashing cleared",
                "ATM_CLEARED": "ATM cleared",
                "TICKET_HAND_PAY_CLEARED": "Ticket hand pay cleared",
                "DROP_BOX_EMPTIED": "Drop box emptied",
                "LOAN_RAISED": "Loan Raised",
                "LOAN_REPAID_CASH": "Loan Repaid Cash",
                "LOAN_REPAID_CARD": "Loan Repaid Card",
                "LOANS_CLEARED": "Loans Cleared",
                "DONATIONS_CLEARED": "Donations Cleared",
                "MARKER_REDEMPTION": "Marker redemption",
                "TAX_WITHHELD_CLEARED": "Tax withheld cleared",
                "DEBIT_PAYOUT": "Debit payout",
                "DEBIT_RETURN": "Debit return",
                "CASHIER_CASH_PAYOUT": "Cashier cash payout",
                "CASHIER_CASH_RETURN": "Cashier cash return",
                "STACKER_EMPTIED": "Stacker emptied",
                "E_WALLET_WITHDRAWAL": "eWallet redemption",
                "E_WALLET_DEPOSIT": "eWallet charged",
                "E_WALLET_WITHDRAWAL_CLEARED": "eWallet redemption cleared",
                "E_WALLET_DEPOSIT_CLEARED": "eWallet charged cleared",
                "REJECTED_BILL": "Rejected Bill",
                "REJECTED_TICKET": "Rejected Ticket",
                "REJECTED_CARD": "Rejected Card",
                "TICKET_RECREATED": "Ticket recreated",
                "BONUS_CARD_REDEMPTION": "Bonus card redemption",
                "BONUS_CARD_CHARGED": "Bonus card charged",
                "BILL_PAY": "Bill pay",
                "CHECK_CASHING_PAYMENT": "Check cashing",
                "MOBILE_TOP_UP": "Mobile top-up",
                "GIFT_CARD_SALE": "Gift card sale",
                "BILL_PAY_CLEARED": "Bill pay cleared",
                "CHECK_CASHING_PAYMENT_CLEARED": "Check cashing cleared",
                "MOBILE_TOP_UP_CLEARED": "Mobile top-up cleared",
                "GIFT_CARD_SALE_CLEARED": "Gift card sale cleared",
                "WIRE_TRANSFER": "Wire transfer",
                "WIRE_TRANSFER_CLEARED": "Wire transfer cleared",
                "DEBIT_CARD_SALE": "Debit card sale",
                "DEBIT_CARD_SALE_CLEARED": "Debit card sale cleared",
                "GIFT_CARD_BUY": "Gift Card Buy",
                "CRYPTO_CURRENCY_BUY": "Crypto Currency Buy",
                "PREPAID_CARD_CHARGED": "Prepaid Card Charged",
                "GIFT_CARD_BUY_CLEARED": "Gift Card buy cleared",
                "CRYPTO_CURRENCY_BUY_CLEARED": "Crypto Currency buy cleared",
                "PREPAID_CARD_CHARGED_CLEARED": "Prepaid Card charged cleared",
                "I_D_SCANNER_CLEARED": "ID Scanner cleared",
                "CRYPTO_CURRENCY_SALE": "Crypto currency sale",
                "CRYPTO_CURRENCY_SALE_CLEARED": "Crypto currency sale cleared",
                "BOOKING_ACCOUNT_CLEARED": "Booking account cleared",
                "PREPAID_CARD_ISSUED": "Prepaid Card issued",
                "FEE_WITHHELD_CLEARED": "Fee withheld cleared",
                "POS_CASH_ADVANCE": "POS Cash Advance",
                "IOU_RECEIPT_REDEMPTION": "IOU Receipt Redemption",
                "PRINTED_IOU_RECEIPTS_CLEARED": "Printed IOU Receipts Cleared",
                "RFID_CARD_REDEMPTION": "RFID card redemption",
                "RFID_CARD_CHARGED": "RFID card charged",
            },
            "UPDATE_TERM_VIEW_EVENT_TYPE": {},
            "UPDATE_VIEW_EVENT_TYPE": {},
            "USER_RIGHTS": {
                "ADMINISTRATION": "Administration",
                "ART_OVERVIEW": "ART Overview",
                "USERS_VIEW": "User Management",
                "CONFIGURATION_VIEW": "Configuration Entries",
                "MAINTENANCE_OVERVIEW": "Maintenance Overview",
                "DASHBOARD_VIEW": "Dashboard",
                "QUARTERLY_DASHBOARD_VIEW": "Quarterly Dashboard",
                "TERMINAL_LIFECYCLE_VIEW": "Terminal Lifecycle",
                "COMPUTER_REGISTRATION_VIEW": "Computer Registration",
                "MANUFACTURING_ORDER_OVERVIEW": "Manufacturing Order Overview",
                "LICENSE_KEY_USAGE_OVERVIEW": "License Key Usage Overview",
                "MAP_VIEW": "Map View",
                "LOGIN_ENABLED": "Login Enabled",
                "STOCK_OVERVIEW": "Stock Overview",
                "COMPONENT_OVERVIEW": "Component Overview",
                "FILL_LEVEL_VIEW": "Fill Level View",
                "MANUFACTURING_ORDERS": "Manufacturing Orders",
                "KIOSK_REGISTRATION": "Kiosk Registration",
            },
            "VERSION": {
                "NONE": "None",
                "SOFTWARE": "Software",
                "CONFIGURATION": "Configuration",
                "SURVEILLANCE_PROTOCOL": "Surveillance Protocol",
                "COS_INTERFACE": "COS Interface",
                "COS": "COS",
                "JOS_INTERFACE": "JOS Interface",
                "JOS": "JOS",
                "MON_INTERFACE": "MON Interface",
                "MON": "MON",
                "SOFTWARE_LIB": "CLib",
                "OPERATING_SYSTEM": "Operating System",
                "RUNTIME_ENVIRONMENT": "Runtime Environment",
                "TABLE_I_D": "Table ID",
                "ATM_PROXY": "ATM Proxy",
                "COS_PROXY": "COS Proxy",
                "COS_MOD_COS_INTERFACE": "COSMOD COS Interface",
                "COMPONENT_TEST": "Component Testing",
                "REMOTE_DESKTOP_SERVICE": "Remote Desktop Service",
                "SERVICE_CARDS_FILE": "Service Cards File"
            },
        },
        "DEVMESSAGES": {
            "MSG_1": "Machine started",
            "MSG_2": "Customer animation started",
            "MSG_3": "Machine out of operation",
            "MSG_4": "Operator attendance required",
            "MSG_5": "Operator logged in ({0}, {1})",
            "MSG_6": "Enter money service",
            "MSG_7": "Leaving money service",
            "MSG_8": "Enter maintenance",
            "MSG_9": "Leaving maintenance",
            "MSG_10": "Operator logged out",
            "MSG_11": "Machine returned online",
            "MSG_12": "Machine returned offline",
            "MSG_13": "Software restart requested",
            "MSG_14": "Terminal restart requested",
            "MSG_15": "Shutdown requested",
            "MSG_16": "Power lost detected",
            "MSG_17": "Software update performed",
            "MSG_18": "Machine terminated",
            "MSG_19": "Transaction started",
            "MSG_20": "Transaction accept end",
            "MSG_21": "Transaction dispense end",
            "MSG_22": "Transaction closed",
            "MSG_23": "Transaction aborted",
            "MSG_24": "Transaction type updated to {0}",
            "MSG_27": "Attendance required",
            "MSG_28": "Maintenance required",
            "MSG_998": "Command parameter invalid",
            "MSG_999": "Unknown error",
            "MSG_1000": "No communication with device",
            "MSG_1001": "Communication error",
            "MSG_1002": "Command not defined",
            "MSG_1003": "Communication framing error",
            "MSG_1004": "Device is busy",
            "MSG_1005": "Communication restored",
            "MSG_1006": "Device not found at start-up",
            "MSG_1007": "File not found ({0})",
            "MSG_1008": "External application error ({0})",
            "MSG_1009": "Communication established to {0} ({1})",
            "MSG_1010": "Dependant component {0} changed its status to {1}",
            "MSG_1011": "{0}",
            "MSG_1012": "Communication error with {0}",
            "MSG_1013": "Device not initialized",
            "MSG_1100": "Acceptor is in power up status",
            "MSG_1101": "Power up with bill in acceptor",
            "MSG_1102": "Power up with bill stacked",
            "MSG_1103": "Communication error during power up",
            "MSG_1104": "Stacking error. Note or ticket may be returned: {0:X2}",
            "MSG_1105": "Stacking error. Note or ticket may be stacked: {0:X2}",
            "MSG_1106": "Stacker box full",
            "MSG_1107": "Stacker box removed",
            "MSG_1108": "Jam in acceptor",
            "MSG_1109": "Jam in stacker",
            "MSG_1110": "Acceptor cheated",
            "MSG_1120": "Stack motor failure",
            "MSG_1121": "Transport motor speed failure",
            "MSG_1122": "Transport motor failure",
            "MSG_1123": "Cash box failure",
            "MSG_1124": "Validator head removed",
            "MSG_1125": "Boot ROM failure",
            "MSG_1126": "External ROM failure",
            "MSG_1127": "ROM failure",
            "MSG_1128": "Failure writing external ROM",
            "MSG_1129": "Firmware changed, please check note references and restart software",
            "MSG_1130": "Acceptor head is open",
            "MSG_1440": "Notes in Bundle Output Unit",
            "MSG_1453": "Diverter failure",
            "MSG_1454": "Failure to feed from cassette",
            "MSG_1455": "Transmission error",
            "MSG_1456": "Illegal command or command sequence",
            "MSG_1457": "Jam in Note Qualifier",
            "MSG_1458": "Cassette not present or properly installed",
            "MSG_1460": "No notes retracted",
            "MSG_1463": "RV not present or properly installed",
            "MSG_1464": "Delivery failure",
            "MSG_1465": "Reject failure",
            "MSG_1466": "Too many notes requested",
            "MSG_1467": "Jam in Note Feeder Transport",
            "MSG_1468": "Reject Vault almost full",
            "MSG_1469": "Cassette internal failure",
            "MSG_1470": "Main Motor failure",
            "MSG_1471": "Rejected cheque",
            "MSG_1473": "Note Qualifier faulty",
            "MSG_1474": "Note Feeder exit sensor failure",
            "MSG_1475": "Shutter failure",
            "MSG_1477": "Notes in Delivery Throat",
            "MSG_1478": "Communication Timeout",
            "MSG_1480": "Cassette not identified",
            "MSG_1481": "Reject Vault Full",
            "MSG_1487": "Error in Throat",
            "MSG_1491": "Sensor error or Sensor covered",
            "MSG_1496": "NMD internal failure",
            "MSG_1497": "Cassette Lock Faulty",
            "MSG_1498": "Error in note stacking area",
            "MSG_1499": "Module need service",
            "MSG_1501": "No message to resend",
            "MSG_1504": "Error in note transport",
            "MSG_1506": "No data available",
            "MSG_1601": "Cassette removed",
            "MSG_1602": "Cassette inserted but unlocked",
            "MSG_1603": "Cassette inserted but blocked",
            "MSG_1604": "Cassette inserted and locked",
            "MSG_1608": "Cassette status okay",
            "MSG_1609": "Cassette level low",
            "MSG_1610": "Cassette empty",
            "MSG_1611": "Diverter failure",
            "MSG_1612": "Failure to feed",
            "MSG_1613": "Cassette not present",
            "MSG_1614": "Reject box not present",
            "MSG_1615": "Cassette internal failure",
            "MSG_1616": "Exit sensor failure",
            "MSG_1617": "Cassette lock faulty",
            "MSG_1618": "Module need service",
            "MSG_1619": "Unspecific cassette error",
            "MSG_1620": "Less than two cassettes working",
            "MSG_1621": "Cannot break second smallest note",
            "MSG_1622": "Too many feed errors, set internal error",
            "MSG_1623": "Money service required",
            "MSG_1624": "Cassette not initialized",
            "MSG_1631": "Rejected in learning sequence",
            "MSG_1632": "Rejected note was double detected",
            "MSG_1633": "Rejected note was too skewed",
            "MSG_1634": "Rejected note was too long at DD sensor A",
            "MSG_1635": "Rejected note was too long at DD sensor B",
            "MSG_1636": "Rejected note was too short at DD sensor A",
            "MSG_1637": "Rejected note was too short at DD sensor B",
            "MSG_1638": "Rejected note was too close to previous note",
            "MSG_1639": "Rejects during transaction abort due to a fatal status",
            "MSG_1640": "Rejected note seen at DD but not by NF exit sensor",
            "MSG_1641": "Rejected note was detected as thin",
            "MSG_1642": "Notes rejected because diverter not movable from reject to deliver",
            "MSG_1643": "Notes are too close, both rejected",
            "MSG_1644": "Notes are too close, both rejected",
            "MSG_1645": "Notes rejected because diverter not movable from deliver to reject",
            "MSG_1646": "Note rejected in learning sequence",
            "MSG_1647": "Notes rejected unknown",
            "MSG_1648": "Rejected note was too late at the note qualifier",
            "MSG_1649": "Rejected during a close cassette command",
            "MSG_1650": "Rejected note long on channel A but two notes on channel B",
            "MSG_1651": "Rejected note long on channel B but two notes on channel A",
            "MSG_1652": "Rejected note has not passed the note qualifier",
            "MSG_1653": "Rejected during a test move forward command",
            "MSG_1654": "Notes rejected unknown",
            "MSG_1710": "Card reader stacker is empty",
            "MSG_1711": "Card reader stacker is usable",
            "MSG_1712": "Card jam in card reader",
            "MSG_1713": "No card in card reader",
            "MSG_1714": "Card already in card reader",
            "MSG_1715": "Card reader RTC error",
            "MSG_1716": "Too many cards in card reader",
            "MSG_1717": "Card error",
            "MSG_1718": "Card dispenser error",
            "MSG_1719": "No cards in stacker",
            "MSG_1720": "No MSRW reader unit present",
            "MSG_1721": "MSRW write error",
            "MSG_1722": "MSRW read error",
            "MSG_1723": "IC contact error",
            "MSG_1724": "IC control error",
            "MSG_1725": "IC chip communication aborted",
            "MSG_1760": "Atronic card key system mismatch {0}",
            "MSG_1761": "Atronic card currency mismatch {0}",
            "MSG_1762": "Atronic card expired {0}",
            "MSG_1763": "Atronic card locked {0}",
            "MSG_1764": "Atronic card casino ID mismatch {0}",
            "MSG_1765": "Atronic reset casino ID failure {0}",
            "MSG_1766": "Atronic SAM credit failure {0}",
            "MSG_1767": "Atronic SAM debit failure {0}",
            "MSG_1768": "Atronic user card credit failure {0}",
            "MSG_1769": "Atronic user card debit failure {0}",
            "MSG_1770": "Atronic card with no value captured {0}",
            "MSG_1771": "Atronic card amount over limit {0}",
            "MSG_1772": "Atronic card with bonus value returned {0}",
            "MSG_1773": "Atronic card black listed {0}",
            "MSG_1780": "Card amount not payable {0}",
            "MSG_1900": "Hopper error",
            "MSG_2000": "Ticket printer out of paper",
            "MSG_2001": "Ticket printer paper low",
            "MSG_2002": "Ticket printer paper good",
            "MSG_2003": "Ticket printer malfunction",
            "MSG_2100": "Out of paper",
            "MSG_2101": "Paper low",
            "MSG_2102": "Paper good",
            "MSG_2103": "Printer offline",
            "MSG_2104": "Printer powered off",
            "MSG_2105": "Printer disconnected",
            "MSG_2106": "Printer head is open",
            "MSG_2107": "Printer flag error",
            "MSG_2108": "Printer flag selected",
            "MSG_2109": "Printer flag timeout",
            "MSG_2110": "Printer file I/O error",
            "MSG_2111": "Printer file not found",
            "MSG_2112": "Printer net I/O error",
            "MSG_2113": "Printer net socket error",
            "MSG_2114": "Printer parallel event buffer",
            "MSG_2115": "Printer parallel event error",
            "MSG_2116": "Printer port I/O error",
            "MSG_2117": "XML Printer not found",
            "MSG_2300": "Safe door open",
            "MSG_2301": "Safe door closed",
            "MSG_2302": "Upper door open",
            "MSG_2303": "Upper door closed",
            "MSG_2310": "Hopper module moved out",
            "MSG_2311": "Hopper module in position",
            "MSG_2312": "Note dispenser moved out",
            "MSG_2313": "Note dispenser in position",
            "MSG_2314": "Note acceptor moved out",
            "MSG_2315": "Note acceptor in position",
            "MSG_2316": "Card reader moved out",
            "MSG_2317": "Card reader in position",
            "MSG_2318": "Printer moved out",
            "MSG_2319": "Printer in position",
            "MSG_2320": "Safe door key unlocked",
            "MSG_2321": "Safe door key locked",
            "MSG_2330": "Cable tree configuration error",
            "MSG_2340": "Kiosk blocked due to location change {0} -> {1}",
            "MSG_2341": "Kiosk has benn unblocked by {0} (ID: {1})",
            "MSG_2380": "No time source available",
            "MSG_2400": "Power restored",
            "MSG_2401": "Power loss detected",
            "MSG_2402": "Battery low",
            "MSG_2500": "Key exchanged",
            "MSG_2501": "Key not exchanged",
            "MSG_2502": "Key insecure, only a DES/DES2 key was transmitted",
            "MSG_2503": "Emtpy/invalid key received from bank",
            "MSG_2504": "Crypto Keyboard not activated",
            "MSG_2505": "Crypto Keyboard not pre-activated",
            "MSG_2506": "Crypto Keyboard: removal sensors not engaged",
            "MSG_2507": "Crypto Keyboard failed to generate new DUKPT key on request",
            "MSG_2508": "Key found",
            "MSG_2600": "No casino ID provided, please restart software",
            "MSG_2700": "ATM (EMV) startup failed",
            "MSG_2701": "ATM (EMV) not configured",
            "MSG_2702": "Acquirer connection failed",
            "MSG_2703": "Invalid transaction start",
            "MSG_2704": "Invalid dispense event",
            "MSG_2705": "Transaction aborted in quasi cash print",
            "MSG_2706": "ATM (EMV) timeout while printing",
            "MSG_2707": "ATM (EMV) abnormal screen release",
            "MSG_2708": "Illegal dispense request",
            "MSG_2709": "Transaction aborted after dispense",
            "MSG_2710": "ATM-Proxy login error",
            "MSG_2711": "ATM-Proxy connection error",
            "MSG_2712": "Acquirer connection established",
            "MSG_2713": "Acquirer connection status unknown",
            "MSG_2714": "Customer was declined but still charged. Contact acquirer.",
            "MSG_2715": "ATM-Proxy connected and logged in",
            "MSG_2800": "Transaction refused",
            "MSG_2801": "PIN pad not ready",
            "MSG_2802": "Card reader not ready",
            "MSG_2803": "Maintenance mode",
            "MSG_2804": "Initialization error",
            "MSG_2805": "Currency {0} not supported",
            "MSG_2806": "Invalid card",
            "MSG_2807": "Invalid PIN",
            "MSG_2808": "Customer abort",
            "MSG_2809": "Connection timeout",
            "MSG_2810": "Settlement error",
            "MSG_2811": "Too many settlement errors",
            "MSG_2900": "{0} of {1} COSMODs connected",
            "MSG_2901": "Communication to {0} interrupted",
            "MSG_2902": "Communication to {0} established",
            "MSG_3000": "Unknown error",
            "MSG_3001": "Full",
            "MSG_3002": "Nearly full",
            "MSG_3003": "Nearly empty",
            "MSG_3004": "Empty",
            "MSG_3005": "Not inserted",
            "MSG_3006": "Not usable",
            "MSG_3007": "Error state reported ({0})",
            "MSG_3008": "Payment error",
            "MSG_3009": "Machine integrity breached",
            "MSG_3010": "No SAM found",
            "MSG_3011": "No valid media ID",
            "MSG_3012": "Open",
            "MSG_3013": "Moved",
            "MSG_3014": "Opto error (possible coin jam)",
            "MSG_3015": "Closed",
            "MSG_3016": "Moved back",
            "MSG_3200": "Watchdog error",
            "MSG_3201": "(PR)Internal error",
            "MSG_3202": "Internal error",
            "MSG_3203": "Bill pool section home position error",
            "MSG_3204": "Bill pool section upper position error",
            "MSG_3205": "No bill in pool section",
            "MSG_3209": "Error during device reset",
            "MSG_3210": "Medium remain",
            "MSG_3290": "Internal contradiction",
            "MSG_3291": "Not start of DC motor",
            "MSG_3292": "Internal contradiction",
            "MSG_3300": "JAM PSn - BPxS",
            "MSG_3360": "JAM BPS - GSS",
            "MSG_3370": "JAM GSS - CPS",
            "MSG_3380": "JAM GSS - RJS",
            "MSG_3390": "JAM POS - EJS1",
            "MSG_3410": "JAM Bill Retrieval",
            "MSG_3418": "JAM occurred between GSS and RJS",
            "MSG_3420": "Skew error for cassette {0}",
            "MSG_3430": "Long bill error for cassette {0}",
            "MSG_3440": "Short bill error for cassette {0}",
            "MSG_3449": "Thickness abnormal",
            "MSG_3450": "Pick from another safe for cassette {0}",
            "MSG_3460": "Spacing between picked notes is less than specified",
            "MSG_3461": "Bill count mismatch 00",
            "MSG_3462": "Bill count mismatch 01 (CPS was turned ON when there was no bill)",
            "MSG_3463": "Bill count mismatch 02 (number of measured thickness values > number of notes)",
            "MSG_3464": "Bill count mismatch 03 (medium passed through CPS while clearing jam)",
            "MSG_3470": "Potentiometer error {0}",
            "MSG_3471": "Potentiometer fatal error {0}",
            "MSG_3500": "Front shutter open error",
            "MSG_3501": "Front shutter open error (SCS does not change)",
            "MSG_3502": "Front shutter open error (SOS does not change)",
            "MSG_3503": "Front shutter open error (Simultaneous sensor detection)",
            "MSG_3504": "Front shutter close error",
            "MSG_3505": "Front shutter close error (SOS does not change)",
            "MSG_3506": "Front shutter close error (SCS does not change)",
            "MSG_3507": "Front shutter close error (Simultaneous sensor detection)",
            "MSG_3508": "No bills in the ejection opening",
            "MSG_3509": "Open shutter",
            "MSG_3510": "Reject box full",
            "MSG_3520": "RAS command undefined",
            "MSG_3521": "Parameter not registered",
            "MSG_3522": "(PR)Print date error",
            "MSG_3523": "No bill information",
            "MSG_3524": "Specification error",
            "MSG_3525": "Parameter ISO error",
            "MSG_3526": "Bill length/thickness information error",
            "MSG_3527": "Parameter error",
            "MSG_3528": "Command format error",
            "MSG_3540": "BD not set",
            "MSG_3541": "Pulse motor overcurrent",
            "MSG_3542": "DC motor overcurrent",
            "MSG_3543": "Log data checksum error",
            "MSG_3544": "Sensor error",
            "MSG_3545": "Illegal operation error",
            "MSG_3546": "Power off during count",
            "MSG_3547": "Option setup is unusual",
            "MSG_3548": "Command execution is impossible",
            "MSG_3549": "FS error",
            "MSG_3550": "No bills in the rear ejection opening",
            "MSG_3551": "Rear shutter open",
            "MSG_3552": "Illegal operation error due to non-notification of data",
            "MSG_3590": "Rear shutter open error",
            "MSG_3591": "Rear shutter open error (SCSR does not change)",
            "MSG_3592": "Rear shutter open error (SOSR does not change)",
            "MSG_3593": "Rear shutter open error (Simultaneous sensor detection)",
            "MSG_3594": "Rear shutter close error",
            "MSG_3595": "Rear shutter close error (SOSR does not change)",
            "MSG_3596": "Rear shutter close error (SCSR does not change)",
            "MSG_3597": "Rear shutter close error (Simultaneous sensor detection)",
            "MSG_3601": "1st cassette denomination differs, perform bill diagnosis",
            "MSG_3602": "2nd cassette denomination differs, perform bill diagnosis",
            "MSG_3603": "3rd cassette denomination differs, perform bill diagnosis",
            "MSG_3604": "4th cassette denomination differs, perform bill diagnosis",
            "MSG_3605": "5th cassette denomination differs, perform bill diagnosis",
            "MSG_3606": "6th cassette denomination differs, perform bill diagnosis",
            "MSG_3800": "Notes are delivered out when purging the transport",
            "MSG_3801": "Notes are retracted when purging the transport",
            "MSG_3802": "Returned log data is not complete",
            "MSG_3803": "Log data is read",
            "MSG_3804": "Log data is null",
            "MSG_3805": "Fail to write EEPROM when dispensing cash",
            "MSG_3806": "No note at stacking mechanism",
            "MSG_3807": "No note in PCS hold position",
            "MSG_3808": "No note at presenter",
            "MSG_3809": "Note enters the note reject vault when purging the transport",
            "MSG_3810": "Note is removed from exit during retraction",
            "MSG_3811": "FCS1 is dirty",
            "MSG_3812": "FCS2 is dirty",
            "MSG_3813": "FCS3 is dirty",
            "MSG_3814": "FCS4 is dirty",
            "MSG_3815": "FCS5 is dirty",
            "MSG_3816": "FCS6 is dirty",
            "MSG_3817": "SLS is dirty",
            "MSG_3818": "MEDS is dirty",
            "MSG_3819": "SRFS/RFS is dirty",
            "MSG_3820": "TES is dirty",
            "MSG_3821": "BRFS/IRFS is dirty",
            "MSG_3822": "SCS is dirty",
            "MSG_3823": "PCS is dirty",
            "MSG_3824": "BRCS is dirty",
            "MSG_3825": "PES is dirty",
            "MSG_3830": "Internal pointer error",
            "MSG_3831": "Note pointer error",
            "MSG_3832": "Command frame error",
            "MSG_3833": "Invalid command",
            "MSG_3834": "Frame check error",
            "MSG_3835": "Unauthorized",
            "MSG_3836": "Check error of EEPROM data",
            "MSG_3837": "Operation error of EEPROM",
            "MSG_3838": "ID error of Flash chip",
            "MSG_3839": "Error of erasing Flash chip",
            "MSG_3840": "Operation error of Flash chip",
            "MSG_3841": "Error of writing Flash chip",
            "MSG_3842": "DAC chip error",
            "MSG_3843": "Internal error of CDM8240N",
            "MSG_3870": "Invalid ID of cassette, or no cassette in position",
            "MSG_3871": "Cash dispenser internal cassette error",
            "MSG_3875": "Single/retract box is full",
            "MSG_3876": "Bundle/internal reject vault is full",
            "MSG_3880": "Feeding timeout",
            "MSG_3881": "Notes are too close",
            "MSG_3882": "Excessive number of notes retracted in one time",
            "MSG_3883": "Single/retract diverter fails to move up",
            "MSG_3884": "Single/retract diverter fails to move up",
            "MSG_3885": "Note exists in MEDS",
            "MSG_3886": "Note exists in TES",
            "MSG_3887": "Bundle/internal reject diverter fails to move down",
            "MSG_3888": "Bundle/internal reject diverter fails to move up",
            "MSG_3889": "Note delivery/pre-delivery fails",
            "MSG_3890": "Note exists in PES",
            "MSG_3891": "Abnormal note delivery: note exists in PES",
            "MSG_3892": "Error of note presence in PCS",
            "MSG_3893": "Error of note presence in SCS",
            "MSG_3894": "Main motor is jammed",
            "MSG_3895": "Notes are jammed at FCS1",
            "MSG_3896": "Notes are jammed between FCS1 and SLS",
            "MSG_3897": "Notes are jammed at FCS2",
            "MSG_3898": "Notes are jammed between FCS2 and SLS",
            "MSG_3899": "Notes are jammed at FCS3",
            "MSG_3900": "Notes are jammed between FCS3 and SLS",
            "MSG_3901": "Notes are jammed at FCS4",
            "MSG_3902": "Notes are jammed between FCS4 and SLS",
            "MSG_3903": "Notes are jammed at FCS5",
            "MSG_3904": "Notes are jammed between FCS5 and SLS",
            "MSG_3905": "Notes are jammed at FCS6",
            "MSG_3906": "Notes are jammed between FCS6 and SLS",
            "MSG_3907": "Notes are jammed at SLS",
            "MSG_3908": "Notes are jammed between SLS and MEDS",
            "MSG_3909": "Notes are jammed at MTS",
            "MSG_3910": "Notes are jammed at MEDS",
            "MSG_3911": "Notes are jammed between MEDS and TES",
            "MSG_3912": "Notes are jammed at TES",
            "MSG_3913": "Notes are jammed at SRFS/RFS",
            "MSG_3914": "Notes are jammed between MEDS and SRFS/RFS",
            "MSG_3915": "Notes are jammed at BRFS/IRFS",
            "MSG_3916": "Notes are jammed at SCS",
            "MSG_3917": "Notes are jammed at PES",
            "MSG_3918": "Notes are jammed at PCS",
            "MSG_3919": "Stacker motor is jammed",
            "MSG_3920": "Notes are jammed between PES or PCS and BRFS/IRFS",
            "MSG_3921": "Notes are jammed between PCS and PES",
            "MSG_3922": "Notes are jammed between SCS and PCS",
            "MSG_3923": "Notes are jammed between MEDS and BRFS/IRFS",
            "MSG_3924": "Notes are jammed between SCS and MEDS",
            "MSG_3925": "Notes are jammed between PES and PCS",
            "MSG_3926": "Notes are jammed between PCS and MEDS",
            "MSG_3927": "Notes are jammed between MEDS and PCS",
            "MSG_3928": "Notes are jammed at BRCS",
            "MSG_3930": "FCS1 fails",
            "MSG_3931": "FCS2 fails",
            "MSG_3932": "FCS3 fails",
            "MSG_3933": "FCS4 fails",
            "MSG_3934": "FCS5 fails",
            "MSG_3935": "FCS6 fails",
            "MSG_3936": "SLS fails",
            "MSG_3937": "MEDS fails",
            "MSG_3938": "SRFS/RFS fails",
            "MSG_3939": "TES fails",
            "MSG_3940": "BRFS/IRFS fails",
            "MSG_3941": "SCS fails",
            "MSG_3942": "PCS fails",
            "MSG_3943": "BRCS fails",
            "MSG_3944": "PES fails",
            "MSG_3950": "Invalid parameter error",
            "MSG_3951": "Number of notes exceeds the maximum",
            "MSG_3952": "Note dispensing mode is wrong",
            "MSG_3953": "Error of cassette parameter (note width)",
            "MSG_3954": "Error of cassette parameter (note length)",
            "MSG_3955": "Error of cassette parameter (note thickness)",
            "MSG_3960": "Thickness zero value error",
            "MSG_3961": "Self-calibration fails",
            "MSG_3962": "Error of duplicate ID",
            "MSG_3963": "Reset of stacking mechanism fails",
            "MSG_3964": "Error during RFID reading/writing",
            "MSG_3965": "First-level RFID label is damaged",
            "MSG_3966": "Second-level RFID label is damaged",
            "MSG_3967": "Third-level RFID label is damaged",
            "MSG_3968": "Fourth-level RFID label is damaged",
            "MSG_3969": "Fifth-level RFID label is damaged",
            "MSG_3970": "Sixth-level RFID label is damaged",
            "MSG_3971": "Frame format error of infrared sensor 1",
            "MSG_3972": "Frame format error of infrared sensor 2",
            "MSG_3973": "Frame format error of infrared sensor 3",
            "MSG_3974": "Frame format error of infrared sensor 4",
            "MSG_3975": "Frame format error of infrared sensor 5",
            "MSG_3976": "Frame format error of infrared sensor 6",
            "MSG_3977": "Communication timeout of infrared sensor 1",
            "MSG_3978": "Communication timeout of infrared sensor 2",
            "MSG_3979": "Communication timeout of infrared sensor 3",
            "MSG_3980": "Communication timeout of infrared sensor 4",
            "MSG_3981": "Communication timeout of infrared sensor 5",
            "MSG_3982": "Communication timeout of infrared sensor 6",
            "MSG_3983": "Stacking mechanism sensor failure",
            "MSG_4000": "Enable communication of NV times out",
            "MSG_4001": "Enable of NV fails",
            "MSG_4002": "Enable check of NV is wrong",
            "MSG_4003": "Timeout during NV communication",
            "MSG_4004": "Failure during NV communication",
            "MSG_4005": "Check error during NV communication",
            "MSG_4006": "Disable communication of enabling NV times out",
            "MSG_4007": "Disable of enabling NV fails",
            "MSG_4008": "Disable check of enabling NV is wrong",
            "MSG_4009": "NV communication times out",
            "MSG_4010": "Note sequence number does not match with NV",
            "MSG_4011": "Format of command received by NV is wrong",
            "MSG_4012": "Format of enabling command received by NV is wrong",
            "MSG_4013": "Format of disabling command received by NV is wrong",
            "MSG_4014": "Note counting of NV does not match number of dispensed notes",
            "MSG_4015": "An invalid command is received during NV counting",
            "MSG_4016": "Check error of NV counting",
            "MSG_4017": "NV counting times out",
            "MSG_4018": "The NV receives other data during communication",
            "MSG_4019": "NV is not enabled",
            "MSG_4020": "Error in receiving command when resetting NVUSB",
            "MSG_4021": "Return checking error of resetting NVUSB",
            "MSG_4022": "Resetting NVUSB times out",
            "MSG_4023": "Resetting NVUSB fails",
            "MSG_4024": "NV hardware failure (algorithm error)",
            "MSG_4025": "BIT error of SDRAM of NV",
            "MSG_4026": "BIT error of RAM of NV",
            "MSG_4027": "Configuration of AD in NV fails",
            "MSG_4028": "BIT error of OCR template of NV",
            "MSG_4029": "Check error of NV BIT command",
            "MSG_4030": "NV BIT times out",
            "MSG_4031": "An invalid command is received during NV BIT",
            "MSG_4032": "NV reset switch is not closed",
            "MSG_4033": "NV reset switch is not closed",
            "MSG_4034": "Error in setting note mode of NV",
            "MSG_4035": "Invalid data is received in the note mode set in NV",
            "MSG_4036": "Check error in setting note mode of NV",
            "MSG_4037": "Setting note mode of NV times out",
            "MSG_4038": "NV returns sequence number error when dispensing notes",
            "MSG_4039": "Number of rejected notes exceeds the maximum due to NV hardware failure",
            "MSG_4040": "NV parameter setting error",
            "MSG_4100": "Sensor broken",
            "MSG_4101": "Error during open, door still closed",
            "MSG_4102": "Error during open, jam, door half open",
            "MSG_4103": "Safe sensor broken",
            "MSG_4104": "Error during close, motor failure, door completely open",
            "MSG_4105": "Error during close, jam, door half open",
            "MSG_4106": "Error during close, jam, door completely open",
            "MSG_4200": "Calibration failed",
            "MSG_4201": "Calibration failed - payout flap sensor",
            "MSG_4202": "Calibration failed - exit sensor",
            "MSG_4203": "Calibration failed - coil 1 sensor",
            "MSG_4204": "Calibration failed - coil 2 sensor",
            "MSG_4205": "Calibration failed - unit not initialized",
            "MSG_4206": "Calibration failed - checksum error",
            "MSG_4207": "Calibration failed - recalibration by command required",
            "MSG_4208": "Calibration failed - motor opto slot error (1)",
            "MSG_4209": "Calibration failed - motor opto slot error (2)",
            "MSG_4210": "Calibration failed - exit sensor error 2",
            "MSG_4300": "Blocked due to incorrect input of tax inspector password",
            "MSG_4301": "Waiting for date input",
            "MSG_4302": "Decimal point changing is allowed",
            "MSG_4303": "Technological reset mode",
            "MSG_4304": "Test run",
            "MSG_4305": "Printing full fiscal report",
            "MSG_4306": "Waiting to load",
            "MSG_4307": "Shift open but 24 hours did pass",
            "MSG_4308": "Shift is open",
            "MSG_4309": "Shift not opened",
            "MSG_4310": "Shift closed",
            "MSG_5000": "Waiting for authentication",
            "MSG_5001": "Kiosk functionality enabled",
            "MSG_5002": "Kiosk functionality disabled",
            "MSG_5003": "Message pending, kiosk functionality disabled",
            "MSG_5004": "Not authenticated",
            "MSG_5010": "Waiting for certificate",
            "MSG_5500": "Server / Server Protocol Version or Casino ID does not match the config file.",
            "MSG_6000": "Unknown error",
            "MSG_6001": "Stream not running",
            "MSG_6002": "Stream starting",
            "MSG_6003": "Stream start requested",
            "MSG_6004": "Stream running",
            "MSG_6005": "Stream not running. App restart necessary",
            "MSG_6006": "Stream not running. App restart now",
            "MSG_13001": "Could not connect to advertising server ({0}).",
            "MSG_13002": "Empty advertisement list received.",
            "MSG_13003": "Advertising module license has expired (valid until: {0}).",
        },
    },
};
