<!--<terminal-details-block [terminal]='terminal'></terminal-details-block>-->
<div class="close-button-terminal-detail" *ngIf="sourceComponent == 'terminal-icon'">
    <span class="centerabsolute" (click)="closeDetailOpen()">X</span>
</div>

<div class="" *ngIf="stockDetailViewFull">
    <div class="content-right-inner m-dropdown m-dropdown--arrow">
        <div class="m-dropdown__wrapper">
            <div class="menu_arrow"></div>
            <span class="m-dropdown__arrow m-dropdown__arrow--center"></span>
        </div>
        <div class="terminal-name-text">{{stockForTerminal.TerminalData.TerminalName}}</div>
        <div class="terminal-detail-inner terminal-id-{{stockForTerminal.TerminalData.TerminalID}}">
            <div class="terminal-state">
                <div class="inner">
                    <span class="circle no-operator"
                          *ngIf="stockForTerminal.TerminalData.OperatorLoginState === 0"></span><span
                        *ngIf="stockForTerminal.TerminalData.OperatorLoginState === 0">{{'TERMINAL.DETAILS.NO_OPERATOR_LOGGED_IN' | translate}}</span>
                    <span class="circle operator"
                          *ngIf="stockForTerminal.TerminalData.OperatorLoginState === 1"></span><span
                        *ngIf="stockForTerminal.TerminalData.OperatorLoginState === 1">{{'TERMINAL.DETAILS.OPERATOR_LOGGED_IN' | translate}}</span>

                </div>
                <div *ngIf="printable" style="cursor: pointer;"><span (click)="stockDateStateChange()">{{currentStockDateView}}</span> <p-button  class="send-button single-icon" icon="pi pi-print" (click)="printStock()"></p-button></div>
                <div *ngIf="!printable"><span>&nbsp;</span></div>
                <div *ngIf="stockDateState">
                    <p-toolbar>
                        <div class="p-toolbar-group-left">
                            <p-checkbox [(ngModel)]="customData" [binary]="true" inputId="binary" (onChange)="changeToCustomData(stockForTerminal.TerminalData.TerminalID)" label="{{'TERMINAL.DETAILS.SHOW_STOCK_FOR' | translate}}:"></p-checkbox>
                            <ng-container *ngIf="customData">
                                <p-button class="send-button single-icon" icon="pi pi-caret-left" (click)="getTerminalStock(stockForTerminal.TerminalData.TerminalID, StockQueryType.PreviousStockDate)"></p-button>
                                <p-calendar showTime="true" [style]="{marginLeft: '10px'}" styleClass="calendar-input-field" hourFormat="24"
                                            dateFormat="{{globalCacheVariable.DefaultTimeFormatPNG}}" name="dateFrom" required appendTo="body"
                                            [showIcon]="true" inputId="icon"
                                            (onSelect)="getTerminalStock(stockForTerminal.TerminalData.TerminalID, StockQueryType.SelectedDate)"
                                            [(ngModel)]="currentStockDate" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"></p-calendar>
                                <p-button class="send-button single-icon" icon="pi pi-caret-right" (click)="getTerminalStock(stockForTerminal.TerminalData.TerminalID, StockQueryType.NextStockDate)"></p-button>
                            </ng-container>
                            <ng-container *ngIf="!customData">
                                <p-button class="send-button single-icon" disabled="true" icon="pi pi-caret-left" ></p-button>
                                <p-calendar showTime="true" [style]="{marginLeft: '10px'}" styleClass="calendar-input-field" hourFormat="24"
                                            [showIcon]="true" inputId="icon" disabled="true"
                                            dateFormat="{{globalCacheVariable.DefaultTimeFormatPNG}}" name="dateFrom" required appendTo="body"
                                            [(ngModel)]="currentStockDate" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"></p-calendar>
                                <p-button class="send-button single-icon" disabled="true"  icon="pi pi-caret-right"></p-button>
                            </ng-container>

                        </div>
                    </p-toolbar>
                </div>
            </div>
            <!-- STOCK AREA -->
            <div class="stock parent" *ngIf="stockForTerminal.TerminalData.TerminalType !== 17">
                <div class="parent-inner">
                    <h3>{{'TERMINAL.DETAILS.STOCK.MAIN' | translate}}</h3>
                    <table>
                        <tbody>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.STOCK.BILL_ACCEPTOR' | translate}}:</td>
                            <td>{{(stockDetailInformations.billAcceptorTotalSum + stockDetailInformations.ticketInAmount) | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.STOCK.BILL_DISPENSER' | translate}}:</td>
                            <td>{{stockDetailInformations.dispenserTotalSum | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.STOCK.COIN_ACCEPTOR' | translate}}:</td>
                            <td>{{stockDetailInformations.coinAcceptorTotalSum | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.STOCK.COIN_DISPENSER' | translate}}:</td>
                            <td>{{stockDetailInformations.coinDispenserTotalSum | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        <tr>
                            <td class="text-bold">{{'TERMINAL.DETAILS.STOCK.TOTAL_CASH' | translate}}:</td>
                            <td>{{((stockDetailInformations.billAcceptorTotalSum + stockDetailInformations.coinAcceptorTotalSum + stockDetailInformations.ticketInAmount) + (stockDetailInformations.dispenserTotalSum) + (stockDetailInformations.coinDispenserTotalSum)) | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="show-more-button show-details" (click)="toggleStockDetails()">
                    <div class="show-more-button-inner">
                        <span>{{'TERMINAL.DETAILS.BUTTONS.SHOW_DETAILS' | translate}}</span>
                    </div>
                </div>
                <div class="show-more-button close-details" (click)="toggleStockDetails()">
                    <div class="show-more-button-inner">
                        <span>{{'TERMINAL.DETAILS.BUTTONS.CLOSE_DETAILS' | translate}}</span>
                    </div>
                </div>
            </div>


            <!-- STOCKDETAILS AREA -->
            <div class="stock-details" style="display:none;" *ngIf="stockForTerminal.TerminalData.TerminalType !== 17">
                <div class="bill-acceptor">
                    <h3>{{'TERMINAL.DETAILS.BILL_ACCEPTOR.MAIN' | translate}}</h3>
                    <table>
                        <tbody *ngIf="stockForTerminal.BillAcceptor !== null">
                        <tr *ngFor="let billAcceptorData of stockForTerminal.BillAcceptor">
                            <td>{{billAcceptorData.Type | stockMediumType}} {{billAcceptorData.Amount | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                            <td style="text-align:right;">{{billAcceptorData.Pieces}}</td>
                            <td style="text-align:right;">{{billAcceptorData.Sum | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        <tr class="text-bold">
                            <td>{{'TERMINAL.DETAILS.BILL_ACCEPTOR.TOTAL_BILLS' | translate}}:</td>
                            <td style="text-align:right;">{{stockDetailInformations.billAcceptorTotalCount}}</td>
                            <td style="text-align:right;">{{stockDetailInformations.billAcceptorTotalSum | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        </tbody>
                    </table>

                    <div class="tickets-in">
                        <table>
                            <tbody *ngIf="stockDetailInformations.ticketInCount !== 0">
                            <tr>
                                <td class="text-bold">{{'TERMINAL.DETAILS.BILL_ACCEPTOR.TICKETS_IN' | translate}}:</td>
                                <td style="text-align:right;">{{stockDetailInformations.ticketInCount}}</td>
                                <td style="text-align:right;">{{stockDetailInformations.ticketInAmount | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="total-account">
                        <table>
                            <tbody>
                            <tr>
                                <td class="text-bold">{{'TERMINAL.DETAILS.BILL_ACCEPTOR.TOTAL_ACCEPTOR' | translate}}:
                                </td>
                                <td style="text-align:right;">{{(stockDetailInformations.ticketInCount + stockDetailInformations.billAcceptorTotalCount)}}</td>
                                <td style="text-align:right;">{{(stockDetailInformations.ticketInAmount + stockDetailInformations.billAcceptorTotalSum) | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="tickets-out">
                        <table>
                            <tbody *ngIf="stockDetailInformations.ticketOutCount !== 0">
                            <tr>
                                <td class="text-bold">{{'TERMINAL.DETAILS.BILL_ACCEPTOR.TICKETS_OUT' | translate}}:</td>
                                <td style="text-align:right;">{{stockDetailInformations.ticketOutCount}}</td>
                                <td style="text-align:right;">{{stockDetailInformations.ticketOutAmount | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="bill-dispenser">
                    <h3>{{'TERMINAL.DETAILS.BILL_DISPENSER.MAIN' | translate}}</h3>
                    <table>
                        <tbody *ngIf="stockForTerminal.Dispenser !== null">
                        <tr *ngFor="let billDispenserData of stockForTerminal.Dispenser">
                            <td *ngIf="billDispenserData.Type !== 62">{{billDispenserData.Type | stockMediumType}} {{billDispenserData.Amount | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                            <td *ngIf="billDispenserData.Type === 62">{{'TERMINAL.DETAILS.BILL_DISPENSER.REJECT' | translate}}</td>
                            <td style="text-align:right;">{{billDispenserData.Pieces}}</td>
                            <td *ngIf="billDispenserData.Type !== 62" style="text-align:right;">{{billDispenserData.Sum | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                            <td *ngIf="billDispenserData.Type === 62" style="text-align:right;">{{billDispenserData.Amount | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        <tr>
                            <td class="text-bold">{{'TERMINAL.DETAILS.BILL_DISPENSER.TOTAL_BILLS' | translate}}:</td>
                            <td style="text-align:right;">{{stockDetailInformations.dispenserTotalCount}}</td>
                            <td style="text-align:right;">{{stockDetailInformations.dispenserTotalSum | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="coin-dispenser">
                    <h3>{{'TERMINAL.DETAILS.COIN_DISPENSER.MAIN' | translate}}</h3>
                    <table>
                        <tbody *ngIf="stockForTerminal.Hopper !== null">
                        <tr *ngFor="let coinDispenserData of stockForTerminal.Hopper">
                            <td>{{coinDispenserData.Type | stockMediumType}} {{coinDispenserData.Amount | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                            <td style="text-align:right;">{{coinDispenserData.Pieces}}</td>
                            <td style="text-align:right;">{{coinDispenserData.Sum | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        <tr>
                            <td class="text-bold">{{'TERMINAL.DETAILS.COIN_DISPENSER.TOTAL_COINS' | translate}}:</td>
                            <td style="text-align:right;">{{stockDetailInformations.coinDispenserTotalCount}}</td>
                            <td style="text-align:right;">{{stockDetailInformations.coinDispenserTotalSum | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="cards">
                    <h3>{{'TERMINAL.DETAILS.CARDS.MAIN' | translate}}</h3>
                    <table>
                        <tbody>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.CARDS.STACKER' | translate}}:</td>
                            <td style="text-align:right;">{{stockDetailInformations.cardsStacker}}</td>
                        </tr>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.CARDS.CATCHING_BIN' | translate}}:</td>
                            <td style="text-align:right;">{{stockDetailInformations.cardsBin}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="rounding-differences">
                    <h3>{{'TERMINAL.DETAILS.ROUNDING_DIFFERENCES.MAIN' | translate}}:</h3>
                    <table>
                        <tbody>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.ROUNDING_DIFFERENCES.TICKETS' | translate}}:</td>
                            <td style="text-align:right;">{{stockDetailInformations.roundingTicket | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.ROUNDING_DIFFERENCES.PLAYER_CARDS' | translate}}:</td>
                            <td style="text-align:right;">{{stockDetailInformations.roundingPlayerCards | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}} </td>
                        </tr>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.ROUNDING_DIFFERENCES.BONUS_CARDS' | translate}}:</td>
                            <td style="text-align:right;">{{stockDetailInformations.roundingBonusCards | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.ROUNDING_DIFFERENCES.JACKPOT' | translate}}:</td>
                            <td style="text-align:right;">{{stockDetailInformations.roundingJackpots | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.ROUNDING_DIFFERENCES.BILLS' | translate}}:</td>
                            <td style="text-align:right;">{{stockDetailInformations.roundingBills | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        <tr>
                            <td class="text-bold">{{'TERMINAL.DETAILS.ROUNDING_DIFFERENCES.TOTAL' | translate}}:</td>
                            <td style="text-align:right;">{{stockDetailInformations.roundingTotal | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="sam">
                    <h3>{{'TERMINAL.DETAILS.SAM.MAIN' | translate}}:</h3>
                    <table>
                        <tbody>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.SAM.VALUE' | translate}}:</td>
                            <td style="text-align:right;">{{stockDetailInformations.sam | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="media-stock">
                    <h3>{{'TERMINAL.DETAILS.MEDIA.MAIN' | translate}}</h3>
                    <table>

                        <tbody>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.MEDIA.WITHHELD' | translate}}:</td>
                            <td style="text-align:right;">{{stockDetailInformations.mediaInWithheldCount}}</td>
                            <td style="text-align:right;">{{stockDetailInformations.mediaInWithheldAmount | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.MEDIA.PAID' | translate}}:</td>
                            <td style="text-align:right;">{{stockDetailInformations.mediaOutPaidCount}}</td>
                            <td style="text-align:right;">{{stockDetailInformations.mediaOutPaidAmount | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        <tr>
                            <td class="text-bold">{{'TERMINAL.DETAILS.MEDIA.DIFFERENCES' | translate}}:</td>
                            <td style="text-align:right;">{{(stockDetailInformations.mediaInWithheldCount - stockDetailInformations.mediaOutPaidCount)}}</td>
                            <td style="text-align:right;">{{(stockDetailInformations.mediaInWithheldAmount - stockDetailInformations.mediaOutPaidAmount) | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="media-deposit">
                    <h3>{{'TERMINAL.DETAILS.MEDIA_DEPOSIT.MAIN' | translate}}</h3>
                    <table>
                        <tbody>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.MEDIA_DEPOSIT.WITHHELD' | translate}}:</td>
                            <td style="text-align:right;">{{stockDetailInformations.depositWithheld | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.MEDIA_DEPOSIT.PAID' | translate}}:</td>
                            <td style="text-align:right;"> {{stockDetailInformations.depositPaid | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        <tr>
                            <td class="text-bold">{{'TERMINAL.DETAILS.MEDIA_DEPOSIT.DIFFERENCES' | translate}}:</td>
                            <td style="text-align:right;">{{(stockDetailInformations.depositWithheld - stockDetailInformations.depositPaid) | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="acceptor">
                    <h3>{{'TERMINAL.DETAILS.COIN_ACCEPTOR.MAIN' | translate}}</h3>
                    <table>
                        <tbody *ngIf="stockForTerminal.CoinAcceptor !== null">
                        <tr *ngFor="let coinAcceptorData of stockForTerminal.CoinAcceptor">
                            <td>{{coinAcceptorData.Type | stockMediumType}} {{coinAcceptorData.Amount | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                            <td style="text-align:right;">{{coinAcceptorData.Pieces}}</td>
                            <td style="text-align:right;">{{coinAcceptorData.Sum | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        <tr>
                            <td class="text-bold">{{'TERMINAL.DETAILS.COIN_ACCEPTOR.TOTAL_COINS' | translate}}:</td>
                            <td style="text-align:right;">{{stockDetailInformations.coinAcceptorTotalCount}}</td>
                            <td style="text-align:right;">{{stockDetailInformations.coinAcceptorTotalSum | artCurrency:stockForTerminal.TerminalData.SystemCurrency:'code'}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="details-footer">
                    <div class="print"></div>
                </div>
            </div>

            <!-- TRANSACTION AREA -->
            <ng-container *ngIf="stockForTerminal.TerminalData.TerminalType !== 17">
                <ng-container *ngIf="sourceComponent !== 'terminal-single-detail'">
                    <div class="transactions parent">
                        <div class="parent-inner">
                            <h3>{{'TERMINAL.DETAILS.TRANSACTIONS.MAIN' | translate}}</h3>
                            <table>
                                <tbody>
                                <tr class="last-transactions" *ngFor="let transaction of lastTransactions"
                                    (click)="openTransactionDetailDialog(transaction.TransactionID, transaction.TerminalID)">
                                    <td>{{'ENUMS.TRANSACTION_TYPE.'+(transaction.TransactionType | transactionType) | rewriteEnumEntriesForTranslation : 'ENUMS.TRANSACTION_TYPE.'| uppercase | translate }}</td>
                                    <td>{{transaction.TransactionDate | date: globalCacheVariable.globalFormatSettings.dateFormatLong}}</td>
                                    <td style="text-align:right;">{{transaction.TotalIn | artCurrency:transaction.SystemCurrency:'code'}}</td>
                                    <td style="text-align:right;">{{transaction.TotalOut | artCurrency:transaction.SystemCurrency:'code'}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="show-more-button" routerLink="/transaction-log/{{stockForTerminal.TerminalData.TerminalID}}"
                             routerLinkActive="active">
                            <div class="show-more-button-inner">
                                <span>{{'TERMINAL.DETAILS.BUTTONS.SHOW_MORE' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <!-- VERSION AREA -->
            <div class="version parent" >
                <div class="parent-inner">
                    <h3>{{'TERMINAL.DETAILS.VERSION.MAIN.TITLE' | translate}}</h3>
                    <table>
                        <tbody>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.VERSION.MAIN.SOFTWARE' | translate}}:</td>
                            <td>{{terminalVersionsMain?.Software}}</td>
                        </tr>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.VERSION.MAIN.CONFIGURATION' | translate}}:</td>
                            <td>{{terminalVersionsMain?.Configuration}}</td>
                        </tr>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.VERSION.MAIN.COS' | translate}}:</td>
                            <td>{{terminalVersionsMain?.COS}}</td>
                        </tr>
                        <tr>
                            <td>{{'TERMINAL.DETAILS.VERSION.MAIN.ICOS' | translate}}:</td>
                            <td>{{terminalVersionsMain?.ICOS}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="show-more-button show-details-version" (click)="toggleVersionDetails()">
                    <div class="show-more-button-inner">
                        <span>{{'TERMINAL.DETAILS.BUTTONS.SHOW_DETAILS' | translate}}</span>
                    </div>
                </div>
                <div class="show-more-button close-details-version" (click)="toggleVersionDetails()">
                    <div class="show-more-button-inner">
                        <span>{{'TERMINAL.DETAILS.BUTTONS.CLOSE_DETAILS' | translate}}</span>
                    </div>
                </div>
            </div>
            <div class="version-details" style="display:none;" *ngIf="terminalVersions !== 17">
                <div class="bill-acceptor">
                    <table>
                        <tbody *ngIf="terminalVersions !== null">
                        <tr *ngFor="let version of terminalVersions">
                            <td>{{'ENUMS.VERSION.'+(version.Type | versionType) | rewriteEnumEntriesForTranslation : 'ENUMS.VERSION.'| uppercase | translate }}</td>
                            <td>{{version.ComponentName}} {{version.Version}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog styleClass="transaction-dialog-view" appendTo="body" [baseZIndex]=10000 [closeOnEscape]="true" [resizable]="false" [draggable]="false"
          (onShow)="onShow($event,transactionDetailsDialog)" #transactionDetailsDialog
          [closable]="false" [(visible)]="displayTransactionLogDetail" *ngIf="transactionLogDetailView === true"
          [modal]="true" [style]="{width: '100vw', 'min-height': '100vh'}">
    <p-header>{{currentTransactionLogDetailTerminalID | terminalName}}
        <i class="close-button fa fa-times" (click)="displayTransactionLogDetail=false"></i></p-header>
    <transaction-log-detail [transactionID]='currentTransactionID' [entryPoint]="entryPoint"></transaction-log-detail>
</p-dialog>
