import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalCacheVariable } from '../_globals/globals';
import { map } from 'rxjs/operators';
import * as moment from "moment";
import { ApiDataConverter } from "../_helpers/api-data-converter";
import { ApiCallType } from "../_enums/apiCallType";

@Injectable()
export class GroupServerService {
    public currentGroupUser: any;

    constructor(
        private http: HttpClient,
        public globalCacheVariable: GlobalCacheVariable
    ) { }

    private static extractBridge(res, apiType) {
        let response = ApiDataConverter.extractData(res, apiType);
        return response || {};
    }

    buildHeaderInformation() {
        this.currentGroupUser = JSON.parse(localStorage.getItem('currentGroupUser'));
        /*
        if(localStorage.getItem('currentGroupUser')){
            this.currentGroupUser = JSON.parse(localStorage.getItem('currentUser'));
            return {
                headers: new HttpHeaders({'Content-Type': 'application/json', 'token': this.currentGroupUser.token})
            };
        }*/
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.currentGroupUser.Token }),
        };

    }

    buildFileHeaderInformations() {
        this.currentGroupUser = JSON.parse(localStorage.getItem('currentGroupUser'));
        /*
        if(localStorage.getItem('currentGroupUser')){
            this.currentGroupUser = JSON.parse(localStorage.getItem('currentUser'));
            return {
                headers: new HttpHeaders({'Content-Type': 'application/json', 'token': this.currentGroupUser.token})
            };
        }*/
        return {
            headers: new HttpHeaders({ 'token': this.currentGroupUser.Token })
        };

    }

    //All Administration RestServices

    getUserSessions() {
        try {
            let body = {};
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Administration/' + 'GetUserSessions', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetUserSessionsGroup);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getUsers() {
        try {
            let body = {};
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Administration/' + 'GetUsers', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetUsers);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    userPasswordChange(passwordData) {
        try {
            let body = {
                OldPassword: passwordData.oldPassword,
                NewPassword: passwordData.password,
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Administration/' + 'UpdateUserPassword', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.UpdateUserPasswordGroup);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    addUser(userData) {
        try {
            let rights: number = 0;
            if (userData.rights !== 0) {
                rights = userData.rights;
            }
            let body = {
                Username: userData.username,
                Password: userData.password,
                FirstName: userData.firstName,
                LastName: userData.lastName,
                Rights: rights,
                Email: userData.email,
                LoginEnabled: userData.loginEnabled
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Administration/' + 'AddUser', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.AddUser);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    updateUser(userData) {
        try {
            let body = {};
            let rights: number = 0;
            if (userData.rights !== 0) {
                rights = userData.rights;
            }
            if (userData.password === undefined || userData.password === null || userData.password === '') {
                body = {
                    Username: userData.username,
                    FirstName: userData.firstName,
                    LastName: userData.secondName,
                    Rights: rights,
                    EMail: userData.email,
                    LoginEnabled: userData.loginEnabled
                };
            } else {
                body = {
                    Username: userData.username,
                    FirstName: userData.firstName,
                    LastName: userData.secondName,
                    Password: userData.password,
                    Rights: rights,
                    EMail: userData.email,
                    LoginEnabled: userData.loginEnabled
                };
            }
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Administration/' + 'UpdateUser', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.UpdateUser);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    deleteUser(userData) {
        try {
            let body = '"' + userData.loginName + '"';
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Administration/' + 'DeleteUser', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.DeleteUser);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    SynchronizeManufacturersOrders() {
        try {
            let body = {};
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Administration/' + 'SynchronizeManufacturersOrders', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.SynchronizeManufacturersOrders);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    updateGroupClient(fd) {
        try {
            let body = {};
            let httpOptionsData = this.buildFileHeaderInformations();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Administration/' + 'UpdateWebClient', fd, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.UpdateWebClient);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getArtGroupServerVersion() {
        try {
            let body = {};
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Administration/' + 'GetArtGroupServerVersion', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetArtGroupServerVersion);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    updateArtInstallationMode(ArtId, Mode) {
        try {
            let body = {
                ArtId: ArtId,
                ArtInstallationMode: Number(Mode),
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Administration/UpdateArtInstallationMode', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.UpdateArtInstallationMode);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    AddKioskRegistration(kioskRegistration) {
        try {
            console.log("kioskRegistration", kioskRegistration);
            let body = {
                DistributorNumber: kioskRegistration.DistributorNumber,
                HardDiskID: kioskRegistration.HardDiskID,
                LicensedComponents: kioskRegistration.LicensedComponents,
                MacAddress: kioskRegistration.MacAddress,
                RegistrationTimestamp: kioskRegistration.RegistrationTimestamp,
                RegistrationType: kioskRegistration.RegistrationType,
                ValidityEndDate: kioskRegistration.ValidityEndDate,
                ValidityStartDate: kioskRegistration.ValidityStartDate,
                TerminalType: kioskRegistration.TerminalType,
                SerialNumber: kioskRegistration.SerialNumber,
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Administration/' + 'AddKioskRegistration', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.AddKioskRegistration);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    GetKioskRegistrations() {
        try {
            let body = {

            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Administration/' + 'GetKioskRegistrations', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetKioskRegistrations);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    GetUPD2File(serial) {
        try {
            let body = serial;
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Administration/' + 'GetKioskRegistrationsDataUpd2', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetKioskRegistrations);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    GetKioskRegistrationsRangeUpd2(serialArray) {
        try {

            let body = serialArray;
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Administration/' + 'GetKioskRegistrationsRangeUpd2', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetKioskRegistrations);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    //All Acquisition Restservices
    BasicSearch(searchString) {
        try {
            let body = {
                searchText: searchString,
                options: {
                    ExactMatch: false,
                    CaseSensitive: false
                }
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/' + 'BasicSearch', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.BasicSearch);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    DeleteComputerRegistration(computerSerial) {
        try {
            let body = '"' + computerSerial + '"';
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/' + 'DeleteComputerRegistration', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.DeleteComputerRegistration);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    AddComputerRegistration(computerSerial, computerComponentsData) {
        try {
            let body = {
                SerialNumber: computerSerial,
                Components: computerComponentsData,
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/' + 'AddComputerRegistration', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.AddComputerRegistration);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    GetComputerRegistration(start, end) {
        try {
            let body = {
                from: start,
                to: end,
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/' + 'GetComputerRegistrations', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetComputerRegistrations);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    GetManufacturingOrderOverview(orderId) {
        try {
            let body = {
                orderId
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/' + 'GetManufacturingOrderOverviewByOrderId', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetManufacturingOrderOverviewByOrderId);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    GetManufacturingOrderOverviewRange(start, end) {
        try {
            let body = {
                FromSerialNumber: start,
                ToSerialNumber: end,
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/' + 'GetManufacturingOrderOverview', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetManufacturingOrderOverview);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getTransactionsTypes(artIds = null, currencyCode) {
        try {
            if (artIds === null || artIds == 'all') {
                artIds = [-1];
            } else {
                artIds = [artIds];
            }
            let body = {
                artIds: artIds,
                from: moment().subtract(6, "days"),
                to: moment(),
                currencyCode: currencyCode
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetTransactionsTypes', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetTransactionsTypes);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getConnectedTerminalsCount(artIds = null) {
        try {
            if (artIds === null || artIds == 'all') {
                artIds = [-1];
            } else {
                artIds = [artIds];
            }
            let body = {
                artIds: artIds,
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetConnectedTerminalsCount', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetConnectedTerminalsCount);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getLoggedInOperatorsCount(artIds = null) {
        try {
            if (artIds === null || artIds == 'all') {
                artIds = [-1];
            } else {
                artIds = [artIds];
            }
            let body = {
                artIds: artIds,
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetLoggedInOperatorsCount', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetLoggedInOperatorsCount);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getSoftwareVersionEntries(artIds = null) {
        try {
            if (artIds === null || artIds == 'all') {
                artIds = [-1];
            } else {
                artIds = [artIds];
            }
            let body = {
                artIds: artIds,
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetSoftwareVersionEntries', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetSoftwareVersionEntriesGroup);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getSoftwareVersionOsEntries(artIds = null) {
        try {
            if (artIds === null || artIds == 'all') {
                artIds = [-1];
            } else {
                artIds = [artIds];
            }
            let body = {
                artIds: artIds,
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetOSSoftwareVersionEntries', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetOSSoftwareVersionEntries);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getDonationsCountAndTotal(artIds = null, currencyCode) {
        try {
            if (artIds === null || artIds === 'all') {
                artIds = [-1];
            } else {
                artIds = [artIds];
            }
            let body = {
                artIds: artIds,
                currencyCode: currencyCode
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetDonationsCountAndTotal', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetOSSoftwareVersionEntries);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getTransactionCount(artIds = null, currencyCode) {
        try {
            if (artIds === null || artIds === 'all') {
                artIds = [-1];
            } else {
                artIds = [artIds];
            }
            let body = {
                artIds: artIds,
                currencyCode: currencyCode
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetTransactionsCount', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetTransactionsCount);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getTransactionTotal(artIds = null, currencyCode) {
        try {
            if (artIds === null || artIds === "all") {
                artIds = [-1];
            } else {
                artIds = [artIds];
            }
            let body = {
                artIds: artIds,
                currencyCode: currencyCode,
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetTransactionsTotal', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetTransactionsTotal);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getTransactionAverage(artIds = null, currencyCode) {
        try {
            if (artIds === null || artIds === "all") {
                artIds = [-1];
            } else {
                artIds = [artIds];
            }
            let body = {
                artIds: artIds,
                currencyCode: currencyCode,
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetTransactionsAverage', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetTransactionsAverage);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getDetailedTransactionCount(from, to, artIds = null, currencyCode) {
        try {
            if (artIds === null || artIds === 'all') {
                artIds = [];
            } else {
                artIds = [artIds];
            }
            let body = {
                artIds: artIds,
                from: from,
                to: to,
                currencyCode: currencyCode
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetDetailedTransactionsCount', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetDetailedTransactionsCount);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getDetailedTransactionTotal(from, to, artIds = null, currencyCode) {
        try {
            if (artIds === null || artIds === 'all') {
                artIds = [];
            } else {

            }
            let body = {
                artIds: artIds,
                from: from,
                to: to,
                currencyCode: currencyCode,
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetDetailedTransactionsTotal', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetDetailedTransactionsTotal);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getConfigurationEntries() {
        try {
            let body = {};
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetConfigurationEntries', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetConfigurationEntries);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    setConfigurationEntries(configurationEntries) {
        try {
            let body = configurationEntries;
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/SetConfigurationEntries', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.SetConfigurationEntries);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getArtInstallations() {
        try {
            let body = {};
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetArtInstallations', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetArtInstallations);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getGeoCoordinates() {
        try {
            let body = {};
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetGeoCoordinates', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetGeoCoordinates);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getLicenseKeyUsageOverview() {
        try {
            let body = {};
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetLicenseKeyUsageOverview', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetLicenseKeyUsageOverview);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    GetLifecycleRecords(serialNumber) {
        try {
            let body = '"' + serialNumber + '"';
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetLifecycleRecords', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetLifecycleRecords);
                }));
        } catch (error) {
            console.log(error);
        }

    }

    SetTerminalState(blockList) {
        try {
            let body = blockList;
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Administration/BulkManufacturerBlocking', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.SetTerminalState);
                }));
        } catch (error) {
            console.log(error);
        }

    }

    SearchByPartNumber(partNumber) {
        try {
            let body = '"' + partNumber + '"';
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/SearchByPartNumber', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.SearchByPartNumber);
                }));
        } catch (error) {
            console.log(error);
        }

    }

    GetTerminalRegistrations(from, to) {
        try {
            let body = {
                artId: [null],
                from: from,
                to: to
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetTerminalRegistrations', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetTerminalRegistrations);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getArtInstallationDetails(ARTID) {
        try {
            let body = '"' + ARTID + '"';
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetArtInstallationDetails', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetArtInstallationDetails);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getMaintenanceOverviews(artIds = null) {
        try {
            if (artIds === null) {
                artIds = [];
            } else {
                artIds = [artIds];
            }
            let body = {
                artIds: artIds
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetMaintenanceOverviews', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetMaintenanceOverviews);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getMonthlyTransactionTotals(from, to, artIds) {
        try {
            let body = {
                From: from,
                To: to,
                ArtIds: artIds
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetMonthlyTransactionTotals', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetMonthlyTransactionTotals);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getMonthlyUtilizationOverviews(from, to, artIds) {
        try {
            let body = {
                from: from,
                to: to,
                artIds: artIds
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetMonthlyUtilizationOverviews', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetMonthlyUtilizationOverviews);
                }));
        } catch (error) {
            console.log(error);
        }
    }

    getUserSettings() {
        try {
            let body = {};
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Administration/' + 'GetUserSettings', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetUserSettings);
                }));
        } catch (e) {

        }
    }

    setUserSettings(settingsValue: string) {
        try {
            let body = {
                d: settingsValue,
            }
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Administration/' + 'SetUserSettings', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.SetUserSettings);
                }));
        } catch (e) {

        }
    }

    getFillLevelEntries(artIds = null) {
        try {
            if (artIds === null) {
                artIds = [];
            } else {
                artIds = [artIds];
            }
            let body = {
                artIds: artIds
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/GetFillLevelEntries', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.SetUserSettings);
                }));
        } catch (e) {

        }
    }

    getManufacturingOrders(from, to) {
        try {
            let body = {
                From: from,
                To: to
            };
            let httpOptionsData = this.buildHeaderInformation();
            return this.http.post(this.globalCacheVariable.ARTGroupServerBaseIP + 'Acquisition/' + 'GetManufacturingOrders', body, httpOptionsData)
                .pipe(map(data => {
                    return GroupServerService.extractBridge(data, ApiCallType.GetManufacturingOrders);
                }));
        } catch (error) {
            console.log(error);
        }
    }

}
