<p-table [value]="transactionLog" [scrollable]="true" [style]="{width:'auto'}" class="transaction-log-table content-table" scrollHeight="flex" *ngIf="transactionLogView">
    <ng-template pTemplate="colgroup">
        <colgroup>
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
            <col style="width:150px">
        </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-log>
        <tr>
            <th class="tb-left">{{'LOGS.TRANSACTION.TABLE.FLAGS' | translate}}</th>
            <th class="tb-right">#</th>
            <th class="tb-left">{{'BASE.DATE' | translate}}</th>
            <!--
            <th class="tb-left" [pSortableColumn]="log.TransactionType">Type
                <p-sortIcon [field]="log.TransactionType"></p-sortIcon></th>
            -->
            <th class="tb-left">{{'BASE.TYPE' | translate}}</th>
            <th class="tb-right">{{'LOGS.TRANSACTION.TABLE.MEDIA_NUMBER' | translate}}</th>
            <th class="tb-right">{{'LOGS.TRANSACTION.TABLE.TOTAL_IN' | translate}}</th>
            <th class="tb-right">{{'LOGS.TRANSACTION.TABLE.TOTAL_OUT' | translate}}</th>
            <th class="tb-left">{{'BASE.OPERATOR' | translate}}</th>
            <th class="tb-center">{{'BASE.ACTION' | translate}}</th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-log>
        <tr (click)="openTransactionDetailDialog(log.TransactionID, log.TerminalID)">
            <td class="tb-left">
                <div *ngFor="let flag of log.FlagArray" class="transaction-flag-bubble {{flag}}"></div>
            </td>

            <td class="tb-right">{{log.TransactionNumber}}</td>
            <td class="tb-left">{{log.TransactionDate | date: globalCacheVariable.globalFormatSettings.dateFormatLong}}</td>
            <td class="tb-left">{{'ENUMS.TRANSACTION_TYPE.'+(log.TransactionType | transactionType) | rewriteEnumEntriesForTranslation : 'ENUMS.TRANSACTION_TYPE.'| uppercase | translate }}</td>
            <td class="tb-left">{{log.MediaNumber}}</td>
            <td class="tb-right">{{log.TotalIn | artCurrency:log.SystemCurrency:'code'}}</td>
            <td class="tb-right">{{log.TotalOut | artCurrency:log.SystemCurrency:'code'}}</td>
            <td class="tb-left">{{log.OperatorName}}</td>
            <td class="tb-center"><a (click)="openTransactionDetailDialog(log.TransactionID, log.TerminalID)" class="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill" title="{{'LOGS.TRANSACTION.TABLE.SHOW_DETAILS' | translate}}"><i class="la la-edit"></i></a><!--<a href="#" class="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" title="Delete"><i class="la la-trash"></i></a>--></td>
        </tr>
    </ng-template>


</p-table>

<p-dialog styleClass="transaction-dialog-view" appendTo="body" [baseZIndex]=10000 [closeOnEscape]="true" [resizable]="false" [draggable]="false"
          [closable]="false" [(visible)]="displayTransactionLogDetail" *ngIf="transactionLogDetailView === true"
          [modal]="true">
    <p-header>{{currentTransactionLogDetailTerminalID | terminalName}}
        <i class="close-button fa fa-times" (click)="displayTransactionLogDetail=false"></i></p-header>
    <transaction-log-detail [transactionID]='currentTransactionID' [entryPoint]="entryPoint"></transaction-log-detail>
</p-dialog>
