import {
    Component,
    OnInit,
    ViewEncapsulation,
    SimpleChanges,
    Input,
    OnChanges,
    Output,
    EventEmitter, NgZone
} from '@angular/core';
import { GlobalCacheVariable } from "../../../../../../auth/_globals/globals";
import { EffectiveAddressPipe } from "../../../../../../auth/_pipes/effectiveAddress.pipe";
import { AddressExt } from "../../../../../../auth/_models/addressExt";
import { Address } from "../../../../../../auth/_models/address";
import { Person } from "../../../../../../auth/_models/person";
import { GeneralRewritePipe } from '../../../../../../auth/_pipes/generalRewrite.pipe';
import { IArtInstallationDetails } from '../../../../../../auth/_models/group/artInstallationDetails';
import { ITableColumn } from '../../../../../../auth/_models/tableColumn';
import {
    IArtDetailsTerminalDetailsView, IArtDetailsTerminalState,
    IArtDetailsTerminalView
} from '../../../../../../auth/_models/group/artDetailsTerminalView';
import { DatePipe } from '@angular/common';
import { IProperty } from '../../../../../../auth/_models/group/property';
import { ITerminalDetailsLabels } from '../../../../../../auth/_models/group/artIntallationDetailsLabels';
import { GroupServerService } from "../../../../../../auth/_services/groupserver.service";
import { ViewTypeBlockUnblock } from "../../../../../../auth/_enums/group/viewTypeBlockUnblock";
import { DateFormat } from "../../../../../../auth/_enums/general/date";
import { CurrentGroupUser } from "../../../../../../auth/_models/group/currentGroupUser";
import { MenuItem } from "primeng/api";
import { MachineStatus } from "../../../../../../auth/_enums/machineStatus";
import { TerminalState } from "../../../../../../auth/_enums/terminalState";
import { ToastSeverity } from "../../../../../../auth/_enums/messages/toastSeverity";
import { MessageType } from "../../../../../../auth/_enums/messages/messageType";
import { state } from "@angular/animations";


@Component({
    selector: "art-installation-terminal-block-unblock",
    templateUrl: "./block-unblock-change.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [EffectiveAddressPipe, DatePipe]
})
export class BlockUnblockChangeComponent implements OnInit, OnChanges {

    @Input('artInstallationDetails')
    artInstallationDetails: IArtInstallationDetails;

    @Input('currentTerminalDetails')
    currentTerminalDetails: IArtDetailsTerminalDetailsView;

    @Input('viewReadyBlockUnblock')
    viewReady: boolean;

    @Output()
    closeTerminalBlockUnblock: EventEmitter<object> = new EventEmitter<object>();

    viewKind: number;
    //viewReady: boolean = false;
    reason: string = "";
    currentTime: any;
    currentUser: CurrentGroupUser;
    blockMenuItems: MenuItem[];

    labels: ITerminalDetailsLabels;
    localeId: string = 'STATISTICS.ART_OVERVIEW.MODALS';
    constructor(
        public cache: GlobalCacheVariable,
        private generalRewrite: GeneralRewritePipe,
        private datePipe: DatePipe,
        private groupServerService: GroupServerService,
        private zone: NgZone,
    ) {

    }

    ngOnInit(): void {
        this.initLocalization();
        this.initGenerals();
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.currentTerminalDetails.currentValue !== changes.currentTerminalDetails.previousValue) {
            //this.openTerminalDetail(this.currentTerminalDetails);
        }

    }

    initGenerals(): void {
        this.viewKind = ViewTypeBlockUnblock.Terminal;

        this.currentTime = this.generalRewrite.getDate(DateFormat.Now);
        this.currentUser = this.cache.getCurrentGroupUser();
    }

    initLocalization(): void {
        this.labels = {
            terminalState: {
                title: this.generalRewrite.translate('TERMINAL.BASE.FILTER.TERMINAL_STATE.TITLE'),
                connected: this.generalRewrite.translate('TERMINAL.BASE.FILTER.TERMINAL_STATE.CONNECTED'),
                main: this.generalRewrite.translate('TERMINAL.BASE.FILTER.TERMINAL_STATE.MAIN'),
                componentState: this.generalRewrite.translate('TERMINAL.BASE.FILTER.TERMINAL_STATE.COMPONENT_STATE'),
            },
            terminalStatistics: {
                title: this.generalRewrite.translate(this.localeId + '.TERMINAL_STATISTICS.TITLE'),
                firstSeen: this.generalRewrite.translate(this.localeId + '.TERMINAL_STATISTICS.FIRST_SEEN'),
                lastSeen: this.generalRewrite.translate(this.localeId + '.TERMINAL_STATISTICS.LAST_SEEN'),
                lifeCycleStep: this.generalRewrite.translate(this.localeId + '.TERMINAL_STATISTICS.LIFE_CYCLE_STEP'),
            },
            terminalConfigLabels: {
                title: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.TITLE'),
                id: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.ID'),
                type: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.TYPE'),
                name: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.NAME'),
                timeZone: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.TIMEZONE'),
                macAddress: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.MAC_ADDRESS'),
                serialNr: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.SERIAL_NUMBER'),
                systemCurrency: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.SYSTEM_CURRENCY'),
                distributorNr: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.DISTRIBUTOR_NUMBER'),
                customerNr: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.CUSTOMER_NUMBER'),
                propertyNr: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.PROPERTY_NUMBER'),
                locationId: this.generalRewrite.translate(this.localeId + '.TERMINAL_CONFIGURATION.LOCATION_ID'),
            },
            terminalVersionLabel: this.generalRewrite.translate(this.localeId + '.TERMINALS.VERSIONS.TITLE',)
        }
    }


    blockUnblockTerminal(state) {
        let reason = this.reason;
        let blockList = [];


        blockList.push({
            SerialNumber: this.currentTerminalDetails.SerialNumber,
            MachineStatus: state,
            Reason: reason
        });

        this.groupServerService.SetTerminalState(blockList)
            .subscribe(data => {
                this.zone.run(() => {
                    if (data == 2) {
                        GlobalCacheVariable.toast.next({ severity: ToastSeverity.Success, text: 'Block/Unblock successful', title: 'Block/Unblock successful', life: 5000, type: MessageType.Toast })
                        if (state == MachineStatus.ManufacturerBlock) {
                            this.currentTerminalDetails.ManufacturerBlocked = true;
                        } else {
                            this.currentTerminalDetails.ManufacturerBlocked = false;
                        }
                        this.closeTerminalBlockUnblockModal(state);
                    } else {
                        GlobalCacheVariable.toast.next({ severity: ToastSeverity.Error, text: 'Block/Unblock unsuccessful', title: 'Block/Unblock unsuccessful', life: 5000, type: MessageType.Toast })
                    }
                    console.log("data", data);
                    //this.loadTable(data);
                });
            });
    }

    closeTerminalBlockUnblockModal(state) {
        this.closeTerminalBlockUnblock.emit(state);
        this.viewReady = false;
    }

    protected readonly ViewTypeBlockUnblock = ViewTypeBlockUnblock;
    protected readonly MachineStatus = MachineStatus;
    protected readonly TerminalState = TerminalState;
}
