import {
    Component,
    OnInit,
    ViewEncapsulation,
    NgZone,
    Input,
    AfterViewInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { ScriptLoaderService } from '../../../_services/script-loader.service';
import { LoggingService } from '../../../auth/_services/log.service';
import { TransActionLogDetail } from '../../../auth/_models/transactionlogdetail';
import { TransactionType } from '../../../auth/_enums/transactionType';
import { TransactionFlag } from '../../../auth/_enums/transactionFlag';
import { TransactionAdditionalInformationValue } from '../../../auth/_enums/transactionAdditionalInformation';
import { GlobalCacheVariable } from '../../../auth/_globals/globals';

import { Location, UpperCasePipe } from "@angular/common";
import { Helpers } from "../../../helpers";
import { Operator } from "../../../auth/_models/operator";
import { InfoType } from "../../../auth/_enums/infoType";
import { TransactionTypes } from "../../../auth/_helpers/transactionType";
import { ImageType } from "../../../auth/_enums/imageType";
import { GenderPipe } from "../../../auth/_pipes/gender.pipe";
import { IdentityDocumentTypePipe } from "../../../auth/_pipes/identityDocumentType.pipe";
import { RewriteEnumEntriesForTranslationPipe } from "../../../auth/_pipes/rewriteEnumEntriesForTranslation.pipe";
import { TranslatePipe } from "@ngx-translate/core";
import * as moment from "moment";
import { SelectItem } from "primeng/api";
import { TerminalService } from "../../../auth/_services";
import { ToastService } from "../../../auth/_services/toast.service";
import { ArtRight } from "../../../auth/_enums/artRight";

@Component({
    selector: "transaction-log-detail",
    templateUrl: "./transaction-log-detail.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [GenderPipe, IdentityDocumentTypePipe, RewriteEnumEntriesForTranslationPipe, TranslatePipe, UpperCasePipe]
})

export class TransactionLogDetailComponent implements OnInit, AfterViewInit, OnChanges {


    detailTransactionData: TransActionLogDetail;
    displayTransactionLogDetail: boolean = false;
    viewDetail: boolean = false;
    @Input('transactionID') transactionID: number;
    @Input('entryPoint') entryPoint: any;
    //Images Modal
    transactionLogDetailImagesView: boolean = false;
    displayTransactionLogDetailImages: boolean = false;
    //Additional Information Modal
    transactionLogDetailAdditionalInfoView: boolean = false;
    displayTransactionLogDetailAdditionalInfo: boolean = false;
    transactionPicsNavigation: any;
    showTransactionPics: boolean = false;
    currentShownTransactionPictureIndex: number = 0;
    currentShownTransactionPicture: any;
    previousTransactionPics: any;
    nextTransactionPics: any;

    //additional Information
    currentPlayerInformationForAdditional: any;
    currentPOSJournalForAdditional: any;
    currentAdditionalViewState: number;

    //LogFilesView
    displayLogfilesView: boolean = false;
    logfilesView: boolean = false;
    currentLogfilesTerminal: number;
    types: SelectItem[];
    selectedType: string;
    dateFromLogfiles = moment().subtract(2, 'hours').minutes(0).seconds(0).milliseconds(0).toDate();
    dateToLogfiles = moment().add(1, 'hours').minutes(0).seconds(0).milliseconds(0).toDate();
    currentTerminalName: string = '';
    currentTerminalID: number = 0;
    transactionLogImageRights: boolean = false;

    constructor(private _script: ScriptLoaderService,
        private loggingService: LoggingService,
        private location: Location,
        public globalCacheVariable: GlobalCacheVariable,
        private zone: NgZone,
        public transactionTypes: TransactionTypes,
        private genderPipe: GenderPipe,
        private identityDocumentTypePipe: IdentityDocumentTypePipe,
        private rewriteEnumEntriesForTranslation: RewriteEnumEntriesForTranslationPipe,
        private upperCase: UpperCasePipe,
        private translate: TranslatePipe,
        public terminalService: TerminalService,
        private _toastService: ToastService,
    ) {
    }

    ngOnInit(): void {
        if (this.globalCacheVariable.checkARTRight(ArtRight.ViewTransactionPictures)) {
            this.transactionLogImageRights = true;
        } else {
            this.transactionLogImageRights = false;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.transactionID.currentValue !== changes.transactionID.previousValue) {
            this.openTransactionLogDetail(changes.transactionID.currentValue);
        }
    }

    ngAfterViewInit(): void {
    }


    openLogfiles(terminalID, time) {
        this.openLogfilesView(terminalID, time)
    }

    openLogfilesView(terminalID, time) {

        this.dateFromLogfiles = moment.unix(time / 1000).subtract(1, 'hours').minutes(0).seconds(0).milliseconds(0).toDate();
        this.dateToLogfiles = moment.unix(time / 1000).add(1, 'hours').minutes(0).seconds(0).milliseconds(0).toDate();
        for (let i = 0; i < this.globalCacheVariable.Terminals.length; i++) {
            if (this.globalCacheVariable.Terminals[i].TerminalID === terminalID) {
                this.currentTerminalName = this.globalCacheVariable.Terminals[i].TerminalName;
                break;
            }
        }
        this.currentTerminalID = terminalID;
        this.currentLogfilesTerminal = terminalID;
        this.displayLogfilesView = true;
        this.logfilesView = true;
    }

    requestLogfiles(terminalID) {
        if (this.dateToLogfiles > this.dateFromLogfiles) {
            this.terminalService.getLogfiles(terminalID, this.dateFromLogfiles, this.dateToLogfiles)
                .subscribe(data => {
                    this.zone.run(() => { // <== added
                        Helpers.setLoading(false);
                        if (data != null && data != undefined) {

                            this.globalCacheVariable.requestedLogfilesArray.push(
                                data
                            );
                            this.displayLogfilesView = false;
                            this.logfilesView = false;
                        }
                    });
                });
        } else {
            this._toastService.warning('Please choose a "from" date lower than the "to" date.', 'Invalid timespan', 'modal', 3000);
        }
    }

    cancelLogfiles() {
        this.displayLogfilesView = false;
        this.logfilesView = false;
    }

    openAdditionalInformationModal(kind, AI, data) {
        switch (kind) {
            case 1: //Playerinformations

                let messageText: string = '';
                let message: any;
                let playerInfo: any;

                message = AI.InfoValue.split(",");
                playerInfo = message[4].split("#");

                if (this.transactionTypes.isTransactionTypePayment(data.TransactionType)) {
                    messageText = playerInfo[0] + ' ' + playerInfo[1];
                } else {
                    messageText = playerInfo[0] + ' ' + playerInfo[1] + ' ' + playerInfo[5] + ' ' + playerInfo[6];
                }

                this.currentPlayerInformationForAdditional = {
                    GivenName: playerInfo[0],
                    Surname: playerInfo[1],
                    Gender: this.genderPipe.transform(playerInfo[2]),
                    Nationality: playerInfo[3],
                    IDType: this.translate.transform(
                        this.upperCase.transform(
                            this.rewriteEnumEntriesForTranslation.transform(
                                'ENUMS.IDENTITY_DOCUMENT_TYPE.' + this.identityDocumentTypePipe.transform(playerInfo[4]), 'ENUMS.IDENTITY_DOCUMENT_TYPE.')
                        )
                    ),
                    IDNumber: playerInfo[5],
                    Birthday: playerInfo[6],
                    MediaNumber: playerInfo[8],
                    Status: "Status",
                }
                this.currentAdditionalViewState = 1;
                //this.currentPlayerInformationForAdditional = data;
                break;
            case 2: //POS Journal
                this.currentPOSJournalForAdditional = AI.InfoString;

                this.currentAdditionalViewState = 2;
                break;
        }

        this.displayTransactionLogDetailAdditionalInfo = true;
        this.transactionLogDetailAdditionalInfoView = true;
    }

    closeAdditionalInformationModal() {
        this.displayTransactionLogDetailAdditionalInfo = false;
        this.transactionLogDetailAdditionalInfoView = false;
    }

    openImagesModal() {
        this.displayTransactionLogDetailImages = true;
        this.transactionLogDetailImagesView = true;
    }

    closeImagesModal() {
        this.displayTransactionLogDetailImages = false;
        this.transactionLogDetailImagesView = false;

    }

    showDialogMaximized(event, dialog) {
        dialog.maximize();
    }

    openTransactionLogDetail(transactionID) {
        //this.transactionDetailView = true;
        Helpers.setLoading(true, this.translate.transform('BASE.LOADING.TITLE'));
        //let terminalData = -1;
        this.loggingService.getTransactionLogsDetail(transactionID)
            .subscribe(data => {

                data.FlagArray = this.getTransactionFlag(data);

                this.zone.run(() => { // <== added
                    this.currentShownTransactionPicture = {};
                    this.transactionPicsNavigation = [];
                    this.currentShownTransactionPictureIndex = 0;
                    if (this.globalCacheVariable.artConfigurationEntries.get("Basics/MaskAllMediaNumbers") !== undefined) {
                        if (this.globalCacheVariable.artConfigurationEntries.get("Basics/MaskAllMediaNumbers").Value) {
                            for (let i = 0; i < data.MediaIn.length; i++) {
                                if (data.MediaIn[i].Identifier !== '' && data.MediaIn[i].Identifier !== undefined && data.MediaIn[i].Identifier !== null) {
                                    data.MediaIn[i].Identifier = '*******' + data.MediaIn[i].Identifier.substring(data.MediaIn[i].Identifier.length - 4, data.MediaIn[i].Identifier.length);
                                }
                            }
                            for (let i = 0; i < data.MediaOut.length; i++) {
                                if (data.MediaOut[i].Identifier !== '' && data.MediaOut[i].Identifier !== undefined && data.MediaOut[i].Identifier !== null) {
                                    data.MediaOut[i].Identifier = '*******' + data.MediaOut[i].Identifier.substring(data.MediaOut[i].Identifier.length - 4, data.MediaOut[i].Identifier.length);
                                }
                            }
                            for (let i = 0; i < data.MediaReject.length; i++) {
                                if (data.MediaReject[i].Identifier !== '' && data.MediaReject[i].Identifier !== undefined && data.MediaReject[i].Identifier !== null) {
                                    data.MediaReject[i].Identifier = '*******' + data.MediaReject[i].Identifier.substring(data.MediaReject[i].Identifier.length - 4, data.MediaReject[i].Identifier.length);
                                }
                            }

                            if (data.MediaIn.MediaNumber !== '' && data.MediaIn.MediaNumber !== undefined && data.MediaIn.MediaNumber !== null) {
                                data.MediaIn.MediaNumber = '*******' + data.MediaIn.MediaNumber.substring(data.MediaIn.MediaNumber.length - 4, data.MediaIn.MediaNumber.length);
                            }
                            this.detailTransactionData = data;
                        } else {
                            this.detailTransactionData = data;
                        }
                    } else {
                        this.detailTransactionData = data;
                    }

                    for (let i = 0; i < data.AdditionalInfos.length; i++) {

                        if (data.AdditionalInfos[i].InfoType !== '' && data.AdditionalInfos[i].InfoType !== undefined && data.AdditionalInfos[i].InfoType !== null && (data.AdditionalInfos[i].InfoType === InfoType.TicketOutMediaNumber || data.AdditionalInfos[i].InfoType === InfoType.TicketInMediaNumber)) {
                            data.AdditionalInfos[i].InfoValue = '*******' + data.AdditionalInfos[i].InfoValue.substring(data.AdditionalInfos[i].InfoValue.length - 4, data.AdditionalInfos[i].InfoValue.length);
                        }
                        //TODO additional Informations
                        //data.AdditionalInfos[i].InfoValue = this.prepareTransactionAdditionalInformation(data.AdditionalInfos[i], data);
                        data.AdditionalInfos[i].InfoString = this.prepareTransactionAdditionalInformation(data.AdditionalInfos[i], data);
                        data.AdditionalInfos[i].AdditionalInfoStringAI = data.AdditionalInfos[i];
                        data.AdditionalInfos[i].AdditionalInfoStringData = data;
                    }

                    if (data.TransactionPictures.length > 0) {
                        let currentImageClass = '';
                        let currentImageTitle = '';

                        switch (data.TransactionPictures[0].ImageType) {
                            case ImageType.TransactionPicture:
                                currentImageClass = "transaction-pic-type-transaction-pic";
                                currentImageTitle = "Transaction Pic";
                                break;

                            case ImageType.IDScan:
                                currentImageClass = "transaction-pic-type-id-scan";
                                currentImageTitle = "ID Scan";
                                break;

                            case ImageType.Invoice:
                                currentImageClass = "transaction-pic-type-invoice";
                                currentImageTitle = "Invoice";
                                break;

                            case ImageType.Check:
                                currentImageClass = "transaction-pic-type-check";
                                currentImageTitle = "Check";
                                break;
                        }


                        this.transactionPicsNavigation = [];
                        for (let tp = 0; tp < data.TransactionPictures.length; tp++) {
                            let currentImageClass = '';
                            let currentImageTitle = '';
                            switch (data.TransactionPictures[tp].ImageType) {
                                case ImageType.TransactionPicture:
                                    currentImageClass = "transaction-pic-type-transaction-pic";
                                    currentImageTitle = "Transaction Pic";
                                    break;

                                case ImageType.IDScan:
                                    currentImageClass = "transaction-pic-type-id-scan";
                                    currentImageTitle = "ID Scan";
                                    break;

                                case ImageType.Invoice:
                                    currentImageClass = "transaction-pic-type-invoice";
                                    currentImageTitle = "Invoice";
                                    break;

                                case ImageType.Check:
                                    currentImageClass = "transaction-pic-type-check";
                                    currentImageTitle = "Check";
                                    break;
                            }

                            this.transactionPicsNavigation.push({
                                "imageSrc": "data:image/png;base64," + data.TransactionPictures[tp].ImageData,
                                "title": currentImageTitle,
                                "class": currentImageClass,
                                "index": tp,
                                "identifier": data.TransactionPictures[tp].Identifier,
                                "imageType": data.TransactionPictures[tp].ImageType,
                                "storageLocation": data.TransactionPictures[tp].StorageLocation,
                                "terminalID": data.TerminalID,
                                "transactionNumber": data.TransactionNumber,
                            });

                            if (tp === 0) {
                                this.currentShownTransactionPictureIndex = 0;
                                this.currentShownTransactionPicture = {
                                    "imageSrc": "data:image/png;base64," + data.TransactionPictures[tp].ImageData,
                                    "class": currentImageClass,
                                    "title": currentImageTitle,
                                    "index": tp,
                                    "identifier": data.TransactionPictures[tp].Identifier,
                                    "imageType": data.TransactionPictures[tp].ImageType,
                                    "storageLocation": data.TransactionPictures[tp].StorageLocation,
                                    "terminalID": data.TerminalID,
                                    "transactionNumber": data.TransactionNumber,
                                }
                            }
                            if (tp === 1) {
                                this.nextTransactionPics = {
                                    "imageSrc": "data:image/png;base64," + data.TransactionPictures[tp].ImageData,
                                    "class": currentImageClass,
                                    "title": currentImageTitle,
                                    "index": tp,
                                    "identifier": data.TransactionPictures[tp].Identifier,
                                    "imageType": data.TransactionPictures[tp].ImageType,
                                    "storageLocation": data.TransactionPictures[tp].StorageLocation,
                                    "terminalID": data.TerminalID,
                                    "transactionNumber": data.TransactionNumber,
                                }
                            }
                        }
                    } else {
                        this.currentShownTransactionPictureIndex = -1;
                    }
                    this.detailTransactionData = data;

                    this.showTransactionPics = true;
                    this.viewDetail = true;
                    this.displayTransactionLogDetail = true;
                    Helpers.setLoading(false);
                });

            });
    }

    showTransactionLogPicture(index, transactionPics) {
        if (index > 2) {
            this.loggingService.GetTransactionPicture(transactionPics.terminalID, transactionPics.transactionNumber, transactionPics.identifier, transactionPics.imageType, transactionPics.storageLocation)
                .subscribe(data => {
                    let currentImageClass = '';
                    let currentImageTitle = '';
                    switch (data.ImageType) {
                        case ImageType.TransactionPicture:
                            currentImageClass = "transaction-pic-type-transaction-pic";
                            currentImageTitle = "Transaction Pic";
                            break;

                        case ImageType.IDScan:
                            currentImageClass = "transaction-pic-type-id-scan";
                            currentImageTitle = "ID Scan";
                            break;

                        case ImageType.Invoice:
                            currentImageClass = "transaction-pic-type-invoice";
                            currentImageTitle = "Invoice";
                            break;

                        case ImageType.Check:
                            currentImageClass = "transaction-pic-type-check";
                            currentImageTitle = "Check";
                            break;
                    }

                    this.zone.run(() => {
                        this.currentShownTransactionPictureIndex = index;
                        this.currentShownTransactionPicture = {
                            "imageSrc": "data:image/jpeg;base64," + data.ImageData,
                            "class": currentImageClass,
                            "title": currentImageTitle,
                            "index": index,
                            "identifier": data.Identifier,
                            "imageType": data.ImageType,
                            "storageLocation": data.StorageLocation,
                            "terminalID": data.TerminalID,
                            "transactionNumber": data.TransactionNumber,
                        }
                    });
                });

        } else {
            this.currentShownTransactionPicture = transactionPics;
            this.currentShownTransactionPictureIndex = index;
        }
        //this.currentShownTransactionPicture = index;
        this.showTransactionPics = true;

    }

    showNextTransactionLogPicture() {
        if ((this.currentShownTransactionPictureIndex + 2) <= this.transactionPicsNavigation.length) {
            this.nextTransactionPics = this.transactionPicsNavigation[this.currentShownTransactionPictureIndex + 2];
        }
        this.previousTransactionPics = this.currentShownTransactionPicture;
        this.currentShownTransactionPicture = this.transactionPicsNavigation[this.currentShownTransactionPictureIndex + 1];
        this.currentShownTransactionPictureIndex = this.currentShownTransactionPictureIndex + 1;
        this.showTransactionLogPicture(this.currentShownTransactionPictureIndex, this.transactionPicsNavigation[this.currentShownTransactionPictureIndex]);


    }

    showPreviousTransactionLogPicture() {
        if ((this.currentShownTransactionPictureIndex - 2) >= 0) {
            this.previousTransactionPics = this.transactionPicsNavigation[this.currentShownTransactionPictureIndex - 2];
        }

        this.nextTransactionPics = this.currentShownTransactionPicture;
        this.currentShownTransactionPicture = this.transactionPicsNavigation[this.currentShownTransactionPictureIndex - 1];
        this.currentShownTransactionPictureIndex = this.currentShownTransactionPictureIndex - 1;
        this.showTransactionLogPicture(this.currentShownTransactionPictureIndex, this.transactionPicsNavigation[this.currentShownTransactionPictureIndex]);
    }

    prepareTransactionAdditionalInformation(AI, FLM) {
        /*
        if (data.AdditionalInfos[i].InfoType !== '' && data.AdditionalInfos[i].InfoType !== undefined && data.AdditionalInfos[i].InfoType !== null && (data.AdditionalInfos[i].InfoType === InfoType.TicketOutMediaNumber || data.AdditionalInfos[i].InfoType === InfoType.TicketInMediaNumber)) {
            data.AdditionalInfos[i].InfoValue = '*******' + data.AdditionalInfos[i].InfoValue.substring(data.AdditionalInfos[i].InfoValue.length - 4, data.AdditionalInfos[i].InfoValue.length);
        }
        */
        switch (AI.InfoType) {
            case TransactionAdditionalInformationValue.ApprovingOperatorID:
            case TransactionAdditionalInformationValue.AtiJackpotTicketNumber:
            case TransactionAdditionalInformationValue.AtmCurrentBankSequenceNumber:
            case TransactionAdditionalInformationValue.AtmCurrentBankTermID:
            case TransactionAdditionalInformationValue.AtmCurrentBankTransNumber:
            case TransactionAdditionalInformationValue.AtmRbsLynkSequenceNumber:
            case TransactionAdditionalInformationValue.AtmRbsLynkDateTime:
            case TransactionAdditionalInformationValue.BillAcceptorRank:
            case TransactionAdditionalInformationValue.CecTransactionID:
            case TransactionAdditionalInformationValue.ComponentMessage:
            case TransactionAdditionalInformationValue.CreditCardHolder:
            case TransactionAdditionalInformationValue.DecliningOperatorID:
            case TransactionAdditionalInformationValue.DevSITrackingNr:
            case TransactionAdditionalInformationValue.HopperEmptyMode:
            case TransactionAdditionalInformationValue.JackpotName:
            case TransactionAdditionalInformationValue.JackpotTransactionNumber:
            case TransactionAdditionalInformationValue.SlotNumber:
            case TransactionAdditionalInformationValue.TerminalInfo:
            case TransactionAdditionalInformationValue.TerminalSequenceNumber:
            case TransactionAdditionalInformationValue.TicketOutMediaNumber:
            case TransactionAdditionalInformationValue.Warning:
                if (this.globalCacheVariable.artConfigurationEntries.get("Basics/MaskAllMediaNumbers") !== undefined) {
                    if (this.globalCacheVariable.artConfigurationEntries.get("Basics/MaskAllMediaNumbers").Value) {
                        if (AI.InfoType !== '' && AI.InfoType !== undefined && AI.InfoType !== null && (AI.InfoType === InfoType.TicketOutMediaNumber || AI.InfoType === InfoType.TicketInMediaNumber)) {
                            return '*******' + AI.InfoValue.substring(AI.InfoValue.length - 4, AI.InfoValue.length);
                        } else {
                            return AI.InfoValue;
                        }
                    } else {
                        return AI.InfoValue;
                    }
                } else {
                    return AI.InfoValue;
                }
                break;

            case TransactionAdditionalInformationValue.AtmAccount:
            case TransactionAdditionalInformationValue.AtmAcquirerResponseCode:
            case TransactionAdditionalInformationValue.EmvAtmApplicationID:
            case TransactionAdditionalInformationValue.EmvAtmApplicationName:
            case TransactionAdditionalInformationValue.EmvAtmAuthCode:
            case TransactionAdditionalInformationValue.EmvAtmAuthorizationResponseCode:
            case TransactionAdditionalInformationValue.EmvAtmTransactionType:
                //TODO
                AI.InfoString = AI.InfoValue;
                break;

            case TransactionAdditionalInformationValue.PinAlert:
                //TODO
                AI.InfoString = AI.InfoValue;
                break;

            case TransactionAdditionalInformationValue.PlayerMediaData:
                let messageText: string = '';
                let message: any;
                let playerInfo: any;

                message = AI.InfoValue.split(",");
                playerInfo = message[4].split("#");

                if (this.transactionTypes.isTransactionTypePayment(FLM.TransactionType)) {
                    messageText = playerInfo[0] + ' ' + playerInfo[1];
                } else {
                    messageText = playerInfo[0] + ' ' + playerInfo[1] + ' ' + playerInfo[5] + ' ' + playerInfo[6];
                }
                return messageText;

            case TransactionAdditionalInformationValue.PosJournal:
                //TODO implement Modal
                return AI.InfoValue;

        }
    }

    getTransactionFlag(transaction: any) {
        //let mediumType = MediumType[value];

        let error = false;
        let cssClass = '';
        let transactionFlagArray = new Array();

        let creditCleared = (transaction.TransactionType == TransactionType.OpenCreditCleared);
        if (transaction.TotalIn != transaction.TotalOut) error = true;

        //if (error) cssClass += "TransRowError";
        //if (transaction.Rounding) cssClass += "TransRowRounding";
        //if (creditCleared) cssClass += "TransRowCreditCleared";

        if (this.hasFlag(transaction.Flags, TransactionFlag.Error)) {
            transactionFlagArray.push({ class: 'trans-error', name: TransactionFlag.Error })
        }
        if (this.hasFlag(transaction.Flags, TransactionFlag.Voided)) {
            transactionFlagArray.push({ class: 'trans-voided', name: TransactionFlag.Voided })
        }
        if (this.hasFlag(transaction.Flags, TransactionFlag.Aborted)) {
            transactionFlagArray.push({ class: 'trans-aborted', name: TransactionFlag.Aborted })
        }
        if (this.hasFlag(transaction.Flags, TransactionFlag.Warning)) {
            transactionFlagArray.push({ class: 'trans-warning', name: TransactionFlag.Warning })
        }
        //if (transaction.Warning) cssClass += "TransRowWarning";
        if (this.hasFlag(transaction.Flags, TransactionFlag.PinAlert)) {
            transactionFlagArray.push({ class: 'trans-pin-alert', name: TransactionFlag.PinAlert })
        }
        if (this.hasFlag(transaction.Flags, TransactionFlag.Adjusted)) {
            transactionFlagArray.push({ class: 'trans-adjusted', name: TransactionFlag.Adjusted })
        }
        if (this.hasFlag(transaction.Flags, TransactionFlag.CurrencyExchange)) {
            transactionFlagArray.push({ class: 'trans-currency-exchange', name: TransactionFlag.CurrencyExchange })
        }
        if (this.hasFlag(transaction.Flags, TransactionFlag.Rounding)) {
            transactionFlagArray.push({ class: 'trans-rounding', name: TransactionFlag.Rounding })
        }
        if (this.hasFlag(transaction.Flags, TransactionFlag.CreditCleared)) {
            transactionFlagArray.push({ class: 'trans-credit-cleared', name: TransactionFlag.CreditCleared })
        }
        //transactionFlagArray
        //console.log("transactionFlagArray -> ", transactionFlagArray);

        return transactionFlagArray;
    }

    hasFlag(flags, flag) {
        return (flags & flag) == flag;
    }
}
