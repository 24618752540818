import { GlobalCacheVariable } from '../_globals/globals'
import { Injectable } from "@angular/core";
import { TimeSpanPipe } from "../_pipes/timeSpan.pipe";
import { Overlay } from "ol";
import { TerminalTypeNamingPipe } from "../_pipes/terminalTypeNaming.pipe";
import { ArtMap } from '../_helpers/art-map';

@Injectable({
    providedIn: 'root',
})

export class TooltipService {
    public currentUser: any;
    public sessionKey: any;

    constructor(
        private cache: GlobalCacheVariable,
        private timeSpanPipe: TimeSpanPipe,
        private typeNamingPipe: TerminalTypeNamingPipe,
    ) { }

    public createMapToolTip(element: HTMLElement, arts: ArtMap[], map: any) {

        let tooltip = new Overlay({
            element: element,
            stopEvent: false
        });

        map.addOverlay(tooltip);
        let self = this;

        map.on("pointermove", function(event) {

            if (map.hasFeatureAtPixel(event.pixel)) {
                map.getViewport().style.cursor = 'pointer';
                let feature = map.getFeaturesAtPixel(event.pixel)[0];
                let coordinate = feature.getGeometry().getCoordinates();
                let position = [coordinate[0] + Math.round(event.coordinate[0] / 360) * 360, coordinate[1]]

                tooltip.setPosition(position);
                let body = feature.get('body');
                let type = feature.get('type');

                switch (type) {
                    case 1:
                        element.innerHTML = showARTInfos(body);
                        break;
                    case 2:
                        element.innerHTML = showPropertyInfos(body);
                        break;
                    case 3:
                        element.innerHTML = showTerminalInfos(body);
                        break;
                }

            }
            else {
                map.getViewport().style.cursor = 'inherit'
                element.innerHTML = null;
            }

        });

        function showARTInfos(art) {
            return '' +
                '<div class="p-grid" style="width: 480px; height: 280px">' +
                '    <div class="p-col-12"></div>' +
                '    <div class="p-col-12">' + '<h3>' + art.name + ' ' + `(${art.id})` + '</h3>' + '</div>' +
                '    <div class="p-col-2">' + 'Address: ' + '</div>' +
                '    <div class="p-col-10">' + art.addressTxt + '</div>' +
                '    <div class="p-col-2">' + 'Properties:' + '<br>' + '(' + art.propertiesCount + ')' + '</div>' +
                '    <div class="p-col-10">' + art.getProperties + '</div>' +
                '    <div class="p-col-2">' + 'Terminals:' + '<br>' + '(' + art.terminalCount + ')' + '</div>' +
                '    <div class="p-col-10">' + art.getTerminals + '</div>' +
                '    <div class="p-col-12"></div>' +
                '</div>';
        }

        function showPropertyInfos(property) {
            return '' +
                '<div class="p-grid" style="width: 480px; height: 220px">' +
                '    <div class="p-col-12"></div>' +
                '    <div class="p-col-12">' + '<h3>' + property.propertyName + ' (' + property.propertyNo + ')' + '</h3>' + '</div>' +
                '    <div class="p-col-3"> ' + 'ART: ' + '</div>' +
                '    <div class="p-col-9"> ' + property.artName + '</div>' +
                '    <div class="p-col-3">' + 'Terminals:' + '<br>' + '(' + property.terminalCount + ')' + '</div>' +
                '    <div class="p-col-9"> ' + property.getTerminals + '</div>' +
                '    <div class="p-col-3"> ' + 'Coordinates: ' + '</div>' +
                '    <div class="p-col-9"> ' + `(${property.coordinates.long.toFixed(2)},
                                                ${property.coordinates.lat.toFixed(2)})` + '</div>' +
                '    <div class="p-col-12"></div>' +
                '</div>';
        }

        function showTerminalInfos(terminal) {
            return '' +
                '<div class="p-grid" style="width: 480px; height: 220px">' +
                '    <div class="p-col-12"></div>' +
                '    <div class="p-col-12">' + '<h3>' + terminal.terminalName + '</h3>' + '</div>' +
                '    <div class="p-col-3"> ' + 'Type: ' + '</div>' +
                '    <div class="p-col-9"> ' + self.typeNamingPipe.transform(terminal.type) + '</div>' +
                '    <div class="p-col-3"> ' + 'ART: ' + '</div>' +
                '    <div class="p-col-9"> ' + terminal.artName + '</div>' +
                '    <div class="p-col-3"> ' + 'Property: ' + '</div>' +
                '    <div class="p-col-9"> ' + terminal.propertyName + '</div>' +
                '    <div class="p-col-3"> ' + 'Coordinates: ' + '</div>' +
                '    <div class="p-col-9"> ' + `(${terminal.coordinates.long.toFixed(2)},
                                           ${terminal.coordinates.lat.toFixed(2)})` + '</div>' +
                '    <div class="p-col-12"></div>' +
                '</div>';
        }

    }

    public createDashboardTooltipContent(widget, configuration, kind, widgetName = ''): any {
        let tooltip: any = '';
        let refreshTime: any;
        let periodTime: any;
        let finalTooltip: any;
        refreshTime = this.timeSpanPipe.transform(this.cache.DashboardConfiguration.WidgetRefreshInterval.TotalSeconds, '');

        if (kind === '3TSwitcher') {
            finalTooltip = '' +
                '<div class="p-grid">' +
                '    <div class="p-col-12"></div>' +
                '    <div class="p-col-12"><h3>' + widgetName + '</h3></div>' +
                '    <div class="p-col-5">' +
                this.cache.dashboardToolTipLocale.DATA_SHOWN_FOR_THE_PAST_TIME + ':' +
                '</div>' +
                '    <div class="p-col-7"> ' +
                this.cache.dashboardToolTipLocale.DAY + ' / ' +
                this.cache.dashboardToolTipLocale.WEEK + ' / ' +
                this.cache.dashboardToolTipLocale.MONTH + '</div> ' +
                '    <div class="p-col-5">' +
                this.cache.dashboardToolTipLocale.REFRESHES_EVERY + ':' +
                '</div>' +
                '    <div class="p-col-7">' + refreshTime + '</div>' +
                '    <div class="p-col-12"></div>' +
                '</div>';
            //finalTooltip = startDiv + title + queryPeriod3T + refreshContainer + endDiv;
        }
        else if (kind === 'Period') {
            for (let i = 0; i < this.cache.DashboardConfiguration.Widgets.length; i++) {
                if (this.cache.DashboardConfiguration.Widgets[i].DashboardWidget === widget) {
                    periodTime = this.cache.DashboardConfiguration.Widgets[i].QueryPeriod.TotalSeconds;
                }
            }
            finalTooltip = '' +
                '<div class="p-grid">' +
                '    <div class="p-col-12"></div>' +
                '    <div class="p-col-12"><h3>' + widgetName + '</h3></div>' +
                '    <div class="p-col-5">' +
                this.cache.dashboardToolTipLocale.DATA_SHOWN_FOR_THE_PAST_TIME + ':' +
                '</div>' +
                '    <div class="p-col-7">' + this.timeSpanPipe.transform(periodTime, "m") + '</div>' +
                '   <div class="p-col-5">' +
                this.cache.dashboardToolTipLocale.REFRESHES_EVERY + ':' +
                '</div>' +
                '    <div class="p-col-7">' + refreshTime + '</div>' +
                '    <div class="p-col-12"></div>' +
                '</div>';
        }
        else if (kind === 'Live') {
            for (let i = 0; i < this.cache.DashboardConfiguration.Widgets.length; i++) {
                if (this.cache.DashboardConfiguration.Widgets[i].DashboardWidget === widget) {
                    periodTime = this.cache.DashboardConfiguration.Widgets[i].QueryPeriod.TotalSeconds;
                }
            }
            finalTooltip = '' +
                '<div class="p-grid">' +
                '    <div class="p-col-12"></div>' +
                '    <div class="p-col-12"><h3>' + widgetName + '</h3></div>' +
                '    <div class="p-col-5">' +
                this.cache.dashboardToolTipLocale.DATA_SHOWN_FOR_THE_PAST_TIME + ':' +
                '</div>' +
                '    <div class="p-col-7">' +
                this.cache.dashboardToolTipLocale.LIVE_DATA +
                '</div>' +
                '   <div class="p-col-5">' +
                this.cache.dashboardToolTipLocale.REFRESHES_EVERY + ':' +
                '</div>' +
                '    <div class="p-col-7">' + refreshTime + '</div>' +
                '    <div class="p-col-12"></div>' +
                '</div>';
        }

        return Promise.resolve(finalTooltip);

    }


}
