<p-dialog styleClass="art-details-terminal-block-unblock-dialog" appendTo="body" [closeOnEscape]="true" [responsive]="true"
          [resizable]="false" [style]="{width: '500vw', 'min-height': '50vh'}"
          [draggable]="false" [closable]="false" *ngIf="viewReady" [(visible)]="viewReady" [modal]="true">

    <p-header >
        {{ artInstallationDetails?.ArtName             }} -
        {{ currentTerminalDetails?.TerminalName     }} -
        {{ currentTerminalDetails?.TerminalTypeName }}
        <i class="close-button fa fa-times" (click)="closeTerminalBlockUnblockModal()"></i>
    </p-header>

    <ng-container *ngIf="viewKind == ViewTypeBlockUnblock.Terminal">
        <div class="p-grid">
            <div class="p-col-4">
                <div class="p-grid">
                    <div class="p-col-12">
                        <p-card header="{{ labels.terminalConfigLabels.title }}">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-5">
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.id             }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.type           }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.name           }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.timeZone       }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.macAddress     }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.serialNr       }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.distributorNr  }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.customerNr     }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.propertyNr     }}</div>
                                        <div class="p-col-12">{{ labels.terminalConfigLabels.locationId     }}</div>
                                    </div>
                                    <div class="p-col-7">
                                        <div class="p-col-12">{{ currentTerminalDetails.TerminalId       }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetails.TerminalTypeName }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetails.TerminalName     }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetails.TimeZone         }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetails.MacAddress       }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetails.SerialNumber     }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetails.DistributorNo    }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetails.CustomerNo       }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetails.PropertyNo       }} &nbsp;</div>
                                        <div class="p-col-12">{{ currentTerminalDetails.LocationId       }} &nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>
                    </div>
                    <div class="p-col-12">
                        <p-card header="Block/Unblock Information">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-3">
                                        <div class="p-col-12">User</div>
                                        <div class="p-col-12">Current Time</div>
                                        <div class="p-col-12">ART Id</div>
                                        <div class="p-col-12">ART Name</div>
                                        <div class="p-col-12">ART Mode</div>
                                        <div class="p-col-12">ART Last Sync.</div>
                                        <div class="p-col-12">Terminal Last Seen</div>
                                    </div>
                                    <div class="p-col-9">
                                        <div class="p-col-12">{{currentUser.Username}}</div>
                                        <div class="p-col-12">{{currentTime}}</div>
                                        <div class="p-col-12">{{artInstallationDetails.ArtId}}</div>
                                        <div class="p-col-12">{{artInstallationDetails.ArtName}}</div>
                                        <div class="p-col-12">{{artInstallationDetails.ArtMode | artInstallationMode}}</div>
                                        <div class="p-col-12">{{artInstallationDetails.LastSync}}</div>
                                        <div class="p-col-12">{{currentTerminalDetails.LastSeen}}</div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>
                    </div>
                </div>
            </div>



            <div class="p-col-4">
                <div class="p-grid">
                    <div class="p-col-12">
                        <p-card header="Block/Unblock Reason">
                            <ng-container>
                                <div class="p-grid">
                                    <div class="p-col-3">
                                        <div class="p-col-12">Reason</div>
                                    </div>
                                    <div class="p-col-9">
                                        <div class="p-col-12"><textarea pInputTextarea [(ngModel)]="reason" [style]="{width: '100%', color:'000000'}" [rows]="17"></textarea></div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-card>
                    </div>
                </div>
            </div>

            <div class="p-col-4">
                <div class="p-grid">
                    <div class="p-col-12">
                        <p-card header="History">
                        </p-card>
                    </div>
                </div>
            </div>

        </div>
    </ng-container>
    <p-footer>
        <p-toolbar>
            <div class="p-toolbar-group-left"></div>
            <div class="p-toolbar-group-right">
                <button type="button" class="send-button" pButton icon="pi pi-times" (click)="closeTerminalBlockUnblockModal()"
                        label="Cancel"></button>
                <ng-container *ngIf="currentTerminalDetails.TerminalState != TerminalState.ManufacturedBlocked && !currentTerminalDetails.ManufacturerBlocked">
                    <button type="button" class="send-button" pButton icon="pi pi-thumbs-down" (click)="blockUnblockTerminal(MachineStatus.ManufacturerBlock)"
                            label="Block"></button>
                </ng-container>
                <ng-container *ngIf="currentTerminalDetails.TerminalState == TerminalState.ManufacturedBlocked || currentTerminalDetails.ManufacturerBlocked">
                    <button type="button" class="send-button" pButton icon="pi pi-thumbs-up"  (click)="blockUnblockTerminal(MachineStatus.ManufacturerUnblock)"
                            label="Unblock"></button>
                </ng-container>

            </div>
        </p-toolbar>
    </p-footer>
</p-dialog>
