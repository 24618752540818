export enum ComponentType {
    None = 0,    /** Global (0). */
    MachineIntegrity = 90,
    SensorBoard = 360,
    Sensor = 370,
    CoinAcceptor = 190,
    CoinDispenser = 200,
    HopperChannel = 240,
    CombinedAcceptor = 250,
    FingerprintReader = 230,  /** Fingerprint Reader (230). */
    BillAcceptor = 130,
    AcceptorBox = 100,
    BillDispenser = 140,
    RejectBox = 340,
    Cassette = 180,
    TicketIssuer = 260,
    TicketAcceptor = 390,
    TicketPrinter = 400,
    CardSystem = 270,
    CardReader = 170,
    CaptureBin = 150,
    BarcodeScanner = 280,
    ManualInput = 290,
    Sam = 350,
    CardDispenser = 160,
    CardStacker = 380,
    ReceiptPrinter = 330,
    Keyboard = 310,
    TowerLight = 410,
    Ups = 420,  /** Uninterruptible Power Supply (420). */
    Atm = 120,  /** Automated Teller Machine (120). */
    Pos = 320,  /** Point of Sale (320). */
    ArtServer = 110,
    OnlineSystem = 210,
    JackpotSystem = 300,
    BonusSystem = 530,
    SignInSignUpSystem = 540,  /** Sign-In / Sign-Up System (540). */
    BillPaySystem = 550,  /** Bill Pay System (550). */
    CheckCashingSystem = 560,  /** Check Cashing System (560). */
    MobileTopUpSystem = 570,  /** Mobile Top-Up System (570). */
    WireTransferSystem = 580,  /** Wire Transfer System (580). */
    GiftCardSaleSystem = 590,  /** Gift Card Sale System (590). */
    Vsi = 430,  /** Video Surveillance Interface (430). */
    MonitoringSystem = 440,
    OnlineCasino = 450,
    Display = 460,
    CableTree = 470,
    AtmProxyConnection = 500,
    IdsConnection = 501,  /** IDS Connection (501). */
    PinKey = 510,
    MasterKey = 520,
    Ats = 600,  /** Cash-N-Go ATS (600). */
    RewardSystem = 610,
    CosProxyModule = 700,
    CosProxyConnection = 710,
    TimeSource = 720,
    DispenserShutter = 730,
    FiscalConnection = 750,
    AdvertisingServer = 760,
    TransactionApproval = 770,
    Emv = 780,  /** EMV Module - ATM replacement (780). */
    EmvProxy = 790,  /** The connection to the EMV Proxy (790). */
    EmvPinKey = 800,  /** The PIN key stored in the crypto-keyboard - EMV (800). */
    EmvMasterKey = 810,  /** The master key stored in the crypto-keyboard - EMV (810). */
    IDScanner = 820,  /** Identity Document Scanner (820). */
    Handheld = 830,
    EmvBankLink = 840,  /** The connection from the EMV Proxy to the acquiring host (840). */
    EWallet = 850,
    CryptoKeys = 860,
    Camera = 880,
    ConfigurationValidity = 890,
    PalmScanner = 920,  /** Palm Vein Scanner (920). */
    ProcessControlModule = 930,  /** Process Control Module (930). */
    GiftCardBuySystem = 970,  /** Gift Card Buy System (970). */
    CryptoCurrencySystem = 980,  /** Crypto Currency System (980). */
    PrepaidCardSystem = 990,  /** Prepaid Card System (990). */
    EWalletSystem = 1000, /** EWallet System (as SPC) (1000). */
    PowerSupply = 1010, /** The actual power supply of the terminal (1010). */
    Monitor = 1020, /** The actual display of the terminal (1020). */
    MainController = 1030, /** The main computing unit of the terminal (PC) (1030). */
    Mainboard = 1040, /** The mainboard (1040). */
    CentralProcessingUnit = 1050, /** The central processing unit (CPU) (1050). */
    MemoryModule = 1060, /** A memory module (RAM) (1060). */
    SolidStateDisk = 1070, /** A solid state disk (SSD) (1070). */
    CpuFan = 1080, /** A CPU fan (1080). */
    CaseFan = 1090, /** A case fan (1090). */
    FaceRecognition = 1100, /** A face recognition security module (1100). */
    TcdSystem = 1110, /** Teller Cash Dispenser System (1110). */
    AdvertisingStreamPlayer = 1120, /** Advertising Stream Player (1120). */
    NetworkConnectivity = 1130, /** Summary state for network interfaces/connectivity (1130). */
    NetworkInterface = 1140, /** A single network interface (1140). */
    DispenseRequestServiceModule = 1160, /** Dispense Request Service Module (1160). */
    MacMasterKey = 1170, /** The master key stored in the crypto keyboard to decrypt working keys for MAC calculations (1170). */
    MacWorkingKey = 1180, /** The working key stored in the crypto keyboard for MAC calculations (1180). */
    EmvMacWorkingKey = 1190, /** The encrypted working key to be stored into the crypto keyboard being received (1190). */
    CardPosition = 1200,
    TaxControllingSystem = 1210,
    TableSystem = 1220

}
