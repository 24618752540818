import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import { GlobalCacheVariable } from '../../../../../../auth/_globals/globals';
import { GroupServerService } from '../../../../../../auth/_services/groupserver.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { TerminalTypeNamingPipe } from '../../../../../../auth/_pipes/terminalTypeNaming.pipe';
import { TerminalType } from '../../../../../../auth/_enums/terminalType';
import { ArtInstallationMode } from '../../../../../../auth/_enums/group/artInstallationMode';
import { ResponseType } from '../../../../../../auth/_enums/group/responseType';
import { VersionType } from '../../../../../../auth/_enums/versionType';
import { TerminalState } from '../../../../../../auth/_enums/terminalState';
import { TerminalStateClass } from '../../../../../../auth/_helpers/componentState';
import { TerminalConnectionType } from '../../../../../../auth/_enums/terminalConnectionType';
import { SortService } from '../../../../../../auth/_services/sort.service';
import { IModuleLicense } from '../../../../../../auth/_models/group/artInstallation';
import { GeneralRewritePipe } from '../../../../../../auth/_pipes/generalRewrite.pipe';
import { ITableColumn } from '../../../../../../auth/_models/tableColumn';
import {
    IArtDetailsTerminalState,
    IArtDetailsTerminalView
} from '../../../../../../auth/_models/group/artDetailsTerminalView';
import { IArtDetailsIPropertyView } from '../../../../../../auth/_models/group/property';
import { Dialog } from 'primeng/dialog';
import { IArtInstallationDetails } from '../../../../../../auth/_models/group/artInstallationDetails';
import { ModuleLicense } from '../../../../../../auth/_enums/moduleLicense';
import { TerminalStatusPipe } from '../../../../../../auth/_pipes/terminalStatus.pipe';
import { TerminalStatesPipe } from '../../../../../../auth/_pipes/terminalStates.pipe';
import {
    IContactInfosLabel,
    IContactPersonLabel,
    ILocationLabel
} from '../../../../../../auth/_models/group/locationL';
import { IArtInstallationDetailsLabels } from '../../../../../../auth/_models/group/artIntallationDetailsLabels';
import { Helpers } from '../../../../../../helpers';

@Component({
    selector: "art-installation-details",
    templateUrl: "./art-installation-details.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [GeneralRewritePipe, TerminalTypeNamingPipe, DatePipe, TerminalStatesPipe, TerminalStatusPipe]
})
export class ArtInstallationDetailsComponent implements OnInit, OnChanges {

    @Input('currentARTDetailsID')
    currentARTID: string;

    @Output()
    closeARTDetails: EventEmitter<object> = new EventEmitter<object>();

    artInstallationDetails: IArtInstallationDetails;
    columns: ITableColumn[];
    columnsProperty: { field: string, header: string, class: string }[];
    viewReady: boolean = false;
    terminalListTableView: boolean = false;
    terminalList: IArtDetailsTerminalView[];
    propertyListTableView: boolean = false;
    propertyList: IArtDetailsIPropertyView[];

    // TerminalDetails
    currentTerminalDetailsID: number;

    // TerminalDetails Dialog
    artDetailsTerminalView: boolean = false;
    currentTerminalDetails: IArtDetailsTerminalView;
    terminalCount: number = 0;
    terminalCountMax: number = 0;

    // PropertyDetails Dialog
    artDetailsPropertyView: boolean = false;
    displayArtDetailsProperty: boolean = false;
    currentPropertyDetails: IArtDetailsIPropertyView;
    propertyDetailTableView: boolean;
    propertyCount: number = 0;
    propertyCountMax: number = 0;

    // PropertyDetails
    currentPropertyDetailsID: number;

    // ARTInstallationEdit Dialog
    currentArtInstallationEdit: IArtInstallationDetails;
    displayArtDetailsEdit: boolean = false;
    artDetailsEditView: boolean = false;
    artInstallationModes: { label: string, value: ArtInstallationMode, disabled: boolean, class: string }[];

    // editDialogHeader
    editDialogHeader: string

    // Labels
    goToWebsiteLabel: string;
    locationLabel: ILocationLabel;
    contactInfosLabel: IContactInfosLabel;
    contactPersonLabel: IContactPersonLabel;
    artDetailsLabel: IArtInstallationDetailsLabels;
    localeID: string = 'STATISTICS.ART_OVERVIEW.MODALS';

    // Data
    registeredD: string;
    terCountD: string;
    clientCountD: string
    artInstModeD: string
    terminalCountD: string;
    operatorsCountD: string;
    moduleLicensesD: { label: string, class: string, state: string }[] = [];

    constructor(
        private groupServerService: GroupServerService,
        private zone: NgZone,
        public globalCacheVariable: GlobalCacheVariable,
        private generalRewritePipe: GeneralRewritePipe,
        private terminalTypeNamingPipe: TerminalTypeNamingPipe,
        private _router: Router,
        private terminalStates: TerminalStateClass,
        private sortService: SortService,
        private datePipe: DatePipe,
        private terminalStatusPipe: TerminalStatusPipe,
        private terminalStatesPipe: TerminalStatesPipe,
    ) {
        /* Labels */
        // Address
        this.locationLabel = {
            title: generalRewritePipe.translate(this.localeID + '.CARD_TITLES.LOCATION'),
            street: generalRewritePipe.translate(this.localeID + '.LOCATION.STREET'),
            address: generalRewritePipe.translate(this.localeID + '.LOCATION.ADDRESS_EXTENDED'),
            zipCode: generalRewritePipe.translate(this.localeID + '.LOCATION.ZIP_CODE'),
            city: generalRewritePipe.translate(this.localeID + '.LOCATION.CITY'),
            county: generalRewritePipe.translate(this.localeID + '.LOCATION.COUNTY'),
            state: generalRewritePipe.translate(this.localeID + '.LOCATION.STATE'),
            country: generalRewritePipe.translate(this.localeID + '.LOCATION.COUNTRY'),
            timeZone: generalRewritePipe.translate(this.localeID + '.CONTACT_INFORMATION.TIMEZONE'),

        }
        // Contact infos
        this.contactInfosLabel = {
            title: generalRewritePipe.translate(this.localeID + '.CONTACT_INFORMATION.TITLE'),
            email: generalRewritePipe.translate(this.localeID + '.CONTACT_INFORMATION.EMAIL_ADDRESS'),
            phone: generalRewritePipe.translate(this.localeID + '.CONTACT_INFORMATION.PHONE_NUMBER'),
        }
        // Contact person
        this.contactPersonLabel = {
            title: generalRewritePipe.translate(this.localeID + '.CONTACT_PERSON.TITLE'),
            name: generalRewritePipe.translate(this.localeID + '.CONTACT_PERSON.NAME'),
            email: generalRewritePipe.translate(this.localeID + '.CONTACT_INFORMATION.EMAIL_ADDRESS'),
            phone: generalRewritePipe.translate(this.localeID + '.CONTACT_INFORMATION.PHONE_NUMBER'),
        }

        // ART Statistics
        this.artDetailsLabel = {
            artStatisticsLabels: {
                title: generalRewritePipe.translate(this.localeID + '.CARD_TITLES.ART_STATISTICS'),
                terminalCount: generalRewritePipe.translate(this.localeID + '.ART_STATISTICS.TERMINAL_COUNT'),
                operatorsCount: generalRewritePipe.translate(this.localeID + '.ART_STATISTICS.OPERATORS_COUNT'),
                firstSync: generalRewritePipe.translate(this.localeID + '.ART_STATISTICS.FIRST_SYNCHRONISATION'),
                lastSync: generalRewritePipe.translate(this.localeID + '.ART_STATISTICS.LAST_SYNCHRONISATION'),
            },
            artConfigLabels: {
                title: generalRewritePipe.translate(this.localeID + '.CARD_TITLES.ART_CONFIGURATION'),
                id: generalRewritePipe.translate(this.localeID + '.ART_CONFIGURATION.ID'),
                installationMode: generalRewritePipe.translate('STATISTICS.ART_OVERVIEW.BUTTONS.ART_INSTALLATION_MODE'),
                version: generalRewritePipe.translate(this.localeID + '.ART_CONFIGURATION.VERSION'),
                currencyCode: generalRewritePipe.translate(this.localeID + '.ART_CONFIGURATION.CURRENCY_CODE'),
            },
            environmentInformation: {
                title: generalRewritePipe.translate(this.localeID + '.CARD_TITLES.ENVIRONMENT_INFORMATION'),
                runTime: generalRewritePipe.translate(this.localeID + '.ENVIRONMENT_INFORMATION.RUNTIME_VERSION'),
                dotNetVersion: generalRewritePipe.translate(this.localeID + '.ENVIRONMENT_INFORMATION.DOT_NET_VERSION'),
                osBuildVersion: generalRewritePipe.translate(this.localeID + '.ENVIRONMENT_INFORMATION.OS_BUILD_VERSION'),
                osName: generalRewritePipe.translate(this.localeID + '.ENVIRONMENT_INFORMATION.OS_NAME'),
                osVersion: generalRewritePipe.translate(this.localeID + '.ENVIRONMENT_INFORMATION.OS_VERSION'),
                sqlServerVersion: generalRewritePipe.translate(this.localeID + '.ENVIRONMENT_INFORMATION.SQL_SERVER_VERSION'),
                computerName: generalRewritePipe.translate(this.localeID + '.ENVIRONMENT_INFORMATION.COMPUTER_NAME'),
                ipAddress: generalRewritePipe.translate(this.localeID + '.ENVIRONMENT_INFORMATION.IP_ADDRESS'),
            },
            baseLicenseLabels: {
                title: generalRewritePipe.translate(this.localeID + '.CARD_TITLES.BASE_LICENSES'),
                registered: generalRewritePipe.translate(this.localeID + '.BASE_LICENSES.REGISTERED'),
                terminalCount: generalRewritePipe.translate(this.localeID + '.BASE_LICENSES.TERMINAL_COUNT'),
                artClientCount: generalRewritePipe.translate(this.localeID + '.BASE_LICENSES.ART_CLIENT_COUNT'),
                propertyNumber: generalRewritePipe.translate(this.localeID + '.BASE_LICENSES.PROPERTY_NUMBER'),
                baseLicenseNumber: generalRewritePipe.translate(this.localeID + '.BASE_LICENSES.BASE_LICENSE_NUMBER'),
            },
            titleModuleLicense: generalRewritePipe.translate(this.localeID + '.CARD_TITLES.MODULE_LICENSES'),
            tableLabels: {
                properties: generalRewritePipe.translate('BASE.PROPERTIES'),
                findButton: generalRewritePipe.translate('BASE.SEARCH.FIND'),
                terminals: generalRewritePipe.translate('BASE.TERMINALS'),
                editArtInstallation: generalRewritePipe.translate(this.localeID + '.BUTTONS.EDIT_ART_INSTALLATION'),
                saveButton: generalRewritePipe.translate('BASE.BUTTONS.SAVE'),
                cancelButton: generalRewritePipe.translate('BASE.BUTTONS.CANCEL'),
            }
        }

        this.artInstallationModes = [
            {
                label: generalRewritePipe.translate('ENUMS.ART_INSTALLATION_MODE.IN_DEVELOPMENT'),
                value: ArtInstallationMode.InDevelopment,
                disabled: false,
                class: "--orange-color"
            },
            {
                label: generalRewritePipe.translate('ENUMS.ART_INSTALLATION_MODE.IN_QUALITY_ASSURANCE'),
                value: ArtInstallationMode.InQualityAssurance,
                disabled: false,
                class: "--orange-color"
            },
            {
                label: generalRewritePipe.translate('ENUMS.ART_INSTALLATION_MODE.IN_TEST_LAB_USE'),
                value: ArtInstallationMode.InTestLabUse,
                disabled: false,
                class: "--orange-color"
            },
            {
                label: generalRewritePipe.translate('ENUMS.ART_INSTALLATION_MODE.IN_PRODUCTION_USE'),
                value: ArtInstallationMode.InProductionUse,
                disabled: false,
                class: "--green-color"
            },
            {
                label: generalRewritePipe.translate('ENUMS.ART_INSTALLATION_MODE.DECOMMISSIONED'),
                value: ArtInstallationMode.Decommissioned,
                disabled: false,
                class: "--red-color"
            },
            {
                label: generalRewritePipe.translate('ENUMS.ART_INSTALLATION_MODE.FACTORY'),
                value: ArtInstallationMode.Factory,
                disabled: false,
                class: "--red-color"
            },
            {
                label: generalRewritePipe.translate('ENUMS.ART_INSTALLATION_MODE.NEW'),
                value: ArtInstallationMode.New,
                disabled: false,
                class: "--yellow-color"
            },
            {
                label: generalRewritePipe.translate('ENUMS.ART_INSTALLATION_MODE.SHOWROOM'),
                value: ArtInstallationMode.Showroom,
                disabled: false,
                class: "--yellow-color"
            },
        ];

        this.columnsProperty = [
            { field: 'PropertyNo', header: generalRewritePipe.translate('BASE.ID'), class: 'tb-right' },
            { field: 'TerminalCount', header: generalRewritePipe.translate('BASE.TERMINALS'), class: 'tb-right' },
            { field: 'PropertyName', header: generalRewritePipe.translate('BASE.PROPERTY'), class: 'tb-left' },
            { field: 'PropertyDescription', header: generalRewritePipe.translate('BASE.DESCRIPTION'), class: 'tb-left' },
        ];

        this.columns = [
            {
                field: 'Connected.text',
                header: generalRewritePipe.translate('TERMINAL.BASE.FILTER.TERMINAL_STATE.CONNECTED'),
                class: 'tb-center'
            },
            {
                field: 'TerminalId',
                header: generalRewritePipe.translate('BASE.ID'),
                class: 'tb-right'
            },
            {
                field: 'TerminalName',
                header: generalRewritePipe.translate('BASE.TERMINAL'),
                class: 'tb-left'
            },
            {
                field: 'TerminalSCVersion',
                header: generalRewritePipe.translate(this.localeID + '.TERMINALS.TABLE.SCVERSION'),
                class: 'tb-left'
            },
            {
                field: 'TerminalType',
                header: generalRewritePipe.translate('TERMINAL.BASE.FILTER.TERMINAL_TYPE.MAIN'),
                class: 'tb-left'
            },
            {
                field: 'TerminalState.text',
                header: generalRewritePipe.translate('TERMINAL.BASE.FILTER.TERMINAL_STATE.MAIN'),
                class: 'tb-right'
            },
        ];

    }

    ngOnInit(): void {
        this.getARTInstallationDetails();
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.currentARTID.currentValue !== changes.currentARTID.previousValue) {
            this.getARTInstallationDetails();
        }

    }

    setUnlimitedText(value: number): string {

        if (value === 0) {
            return this.generalRewritePipe.translate(this.localeID + '.BASE_LICENSES.UNLIMITED');
        }
        else {
            return value.toString();
        }

    }

    onFilterTerminal(event) {
        this.terminalCount = event.filteredValue.length;
    }

    onFilterProperty(event) {
        this.propertyCount = event.filteredValue.length;
    }

    closeARTDetailsModal() {
        this.closeARTDetails.emit();
    }

    closeTerminalDetails() {
        this.artDetailsTerminalView = false;
    }

    closePropertyDetails() {
        this.artDetailsPropertyView = false;
    }

    openPropertyDetail(property: IArtDetailsIPropertyView) {
        this.currentPropertyDetails = property;
        this.currentPropertyDetailsID = property.PropertyNo;
        this.displayArtDetailsProperty = true;
        this.artDetailsPropertyView = true;
        this.propertyDetailTableView = true;
    }

    openTerminalDetail(terminal: IArtDetailsTerminalView) {
        this.currentTerminalDetails = terminal;
        this.currentTerminalDetailsID = terminal.TerminalId;
        this.artDetailsTerminalView = true;
    }

    openEditArtInstallationModal(artInstallation: IArtInstallationDetails) {
        this.currentArtInstallationEdit = artInstallation;
        this.editDialogHeader = this.currentArtInstallationEdit.ArtName !== '' ?
            this.currentArtInstallationEdit.ArtName + ' - ' + this.currentArtInstallationEdit.ArtId : this.currentArtInstallationEdit.ArtId
        this.displayArtDetailsEdit = true;
        this.artDetailsEditView = true;
    }

    saveEditArtInstallationModal() {

        this.groupServerService.updateArtInstallationMode(this.currentArtInstallationEdit.ArtId, this.currentArtInstallationEdit.ArtMode)
            .subscribe(res => {

                this.zone.run(() => {

                    if (res === ResponseType.Updated) {
                        this.displayArtDetailsEdit = false;
                        this.artDetailsEditView = false;
                    }

                });

            });

    }

    getARTInstallationDetails() {
        Helpers.setLoading(true, this.generalRewritePipe.translate('BASE.LOADING.TITLE'));

        this.groupServerService.getArtInstallationDetails(this.currentARTID)
            .subscribe((artInstallationDetails: IArtInstallationDetails) => {

                this.zone.run(() => {
                    this.artInstallationDetails = artInstallationDetails;

                    this.goToWebsiteLabel = this.generalRewritePipe.translate(
                        this.localeID + '.GO_TO_WEBSITE', { Website: this.artInstallationDetails.ArtWebClientUri }
                    );

                    this.artInstallationDetails.FirstSync = this.datePipe.transform(
                        this.artInstallationDetails.FirstSync, this.globalCacheVariable.globalFormatSettings.dateFormatLong
                    );

                    this.artInstallationDetails.Timestamp = this.datePipe.transform(
                        this.artInstallationDetails.Timestamp, this.globalCacheVariable.globalFormatSettings.dateFormatLong
                    );
                    // Art Mode Data
                    this.artInstModeD = this.generalRewritePipe.enumTranslation(
                        ArtInstallationMode, this.artInstallationDetails.ArtMode, 'ENUMS.ART_INSTALLATION_MODE.'
                    );
                    // Terminal Count Data
                    this.terminalCountD = this.generalRewritePipe.translate(
                        this.localeID + '.ART_STATISTICS.TERMINAL_COUNT_DATA',
                        {
                            ConnectedTerminalsCount: this.artInstallationDetails.ConnectedTerminalsCount,
                            TerminalsCount: this.artInstallationDetails.TerminalsCount
                        }
                    );
                    // Operators Count Data
                    this.operatorsCountD = this.generalRewritePipe.translate(
                        this.localeID + '.ART_STATISTICS.OPERATORS_COUNT_DATA',
                        {
                            LoggedInOperatorsCount: this.artInstallationDetails.LoggedInOperatorsCount,
                            OperatorsCount: this.artInstallationDetails.OperatorsCount,
                            CurrentSessionsCount: this.artInstallationDetails.CurrentSessionsCount
                        }
                    );
                    //TODO check if "istrial" have "content" (data)
                    this.registeredD = this.artInstallationDetails.LicenseInformation.IsTrialLicense ?
                        this.generalRewritePipe.translate(this.localeID + '.BASE_LICENSES.TRIAL_LICENSE') :
                        this.generalRewritePipe.translate(this.localeID + '.BASE_LICENSES.REGISTERED');

                    this.terCountD = this.setUnlimitedText(this.artInstallationDetails.LicenseInformation.TerminalCount);
                    this.clientCountD = this.setUnlimitedText(this.artInstallationDetails.LicenseInformation.ArtClientCount);

                    this.moduleLicensesD = [];

                    for (const license of this.artInstallationDetails.LicenseInformation.ModuleLicenses) {

                        let licenseClass = this.getLicenseClass(license);
                        this.moduleLicensesD.push({
                            label: licenseClass.label,
                            class: licenseClass.class,
                            state: licenseClass.state,
                        });

                    }

                    this.viewReady = true;
                    this.preparePropertyList();
                    this.prepareTerminalList();

                    Helpers.setLoading(false);
                });

            });

    }

    getLicenseClass(license: IModuleLicense) {
        let label: string = this.generalRewritePipe.enumTranslation(
            ModuleLicense, license.LicenseType, 'ENUMS.MODULE_LICENSE.'
        );
        let success = 'pi pi-check-circle success';
        let error = 'pi pi-times-circle error';

        switch (license.LicenseType) {
            case ModuleLicense.Advertising:
            case ModuleLicense.Replication:
            case ModuleLicense.CardBurning:
            case ModuleLicense.CurrencyCounting:

                if (license.Count == -1) {
                    return {
                        label: label,
                        class: success,
                        state: this.generalRewritePipe.translate(this.localeID + '.MODULE_LICENSES.STATES.ACTIVE'),
                    }
                }
                else if (license.Count == 0) {
                    return {
                        label: label,
                        class: error,
                        state: this.generalRewritePipe.translate(this.localeID + '.MODULE_LICENSES.STATES.NOT_ACTIVE'),
                    }
                }

                break;
            case ModuleLicense.PropertyManagement:
                return {
                    label: label,
                    class: success,
                    state: this.setUnlimitedText(license.Count) + " " +
                        this.generalRewritePipe.translate(this.localeID + '.MODULE_LICENSES.STATES.PROPERTIES')
                }
            case ModuleLicense.ShopManagement:
                return {
                    label: label,
                    class: license.Count == 0 ? error : success,
                    state: this.generalRewritePipe.translate(
                        this.localeID + '.MODULE_LICENSES.STATES.SHOPS', { x: license.Count })
                }
            case ModuleLicense.FaceRecognition:
                return {
                    label: label,
                    class: license.Count == 0 ? error : success,
                    state: this.generalRewritePipe.translate(
                        this.localeID + '.MODULE_LICENSES.STATES.ALLOWED_TERMINALS',
                        { x: license.Count }),
                }
            case ModuleLicense.GrccPaging:
            case ModuleLicense.TerminalLicensing:
            case ModuleLicense.MultiCurrency:
            case ModuleLicense.RemoteAccess:
            case ModuleLicense.DebitAccounts:
            case ModuleLicense.PreventiveMaintenance:
                return {
                    label: label,
                    class: license.Count == 0 ? error : success,
                    state: this.datePipe.transform(license.ExpirationDate)
                }
            case ModuleLicense.FactorySupport:
            case ModuleLicense.ArtConnect:
                return {
                    label: label,
                    class: success,
                    state: this.generalRewritePipe.translate(this.localeID + '.MODULE_LICENSES.STATES.ACTIVE'),
                }
            default:
                return {
                    label: this.generalRewritePipe.translate('BASE.UNKNOWN_TYPE', { type: license.LicenseType }),
                    class: success,
                    state: null,
                }
        }

    }

    preparePropertyList() {
        this.propertyList = [];

        for (let property of this.artInstallationDetails.Properties) {
            let currentTerminalCount: number = 0;

            for (let terminal of this.artInstallationDetails.Terminals) {

                if (terminal.PropertyNo == property.PropertyNo) {
                    currentTerminalCount++;
                }

            }

            this.propertyList.push({
                PropertyNo: property.PropertyNo,
                TerminalCount: currentTerminalCount,
                PropertyName: property.PropertyName,
                PropertyDescription: property.PropertyDescription,
                FullPropertyInformation: property,
            });

            currentTerminalCount = 0;
        }

        let propertyCount: number = this.propertyList.length;

        this.propertyCount = propertyCount;
        this.propertyCountMax = propertyCount;
        this.propertyListTableView = true;
    }

    showDialogMaximized(dialog: Dialog) {
        dialog.maximize();
    }

    prepareTerminalList() {
        this.terminalList = [];

        for (const terminal of this.artInstallationDetails.Terminals) {
            let currentTerminalState: TerminalState = TerminalState.OutOfService;
            let softwareVersion: string = '';
            let cLibVersion: string = '';
            let SCVersion: string = 'N/A / N/A';

            for (const terminalVersions of terminal.TerminalVersions) {

                if (terminalVersions.Type === VersionType.Software) {
                    softwareVersion = terminalVersions.Version;
                }

                if (terminalVersions.Type === VersionType.SoftwareLib) {
                    cLibVersion = terminalVersions.Version;
                }

            }

            if (softwareVersion !== '' && cLibVersion !== '') {
                SCVersion = softwareVersion + ' / ' + cLibVersion;
            }
            else if (softwareVersion !== '' && cLibVersion === '') {
                SCVersion = softwareVersion + ' / N/A';
            }
            else if (softwareVersion === '' && cLibVersion !== '') {
                SCVersion = 'N/A / ' + cLibVersion;
            }

            //TODO Problems with states
            if (terminal?.Connected) {

                if (terminal?.TerminalState == TerminalState.Ready || terminal?.TerminalState == TerminalState.InTransaction) {
                    currentTerminalState = TerminalState.Ready;
                }
                else {
                    currentTerminalState = terminal?.TerminalState;
                }

            }

            let connected: TerminalConnectionType
                = terminal.Connected ? TerminalConnectionType.Connected : TerminalConnectionType.NotConnected;

            let terminalConnected: IArtDetailsTerminalState = {
                class: this.terminalStates.connected.get(connected),
                text: this.generalRewritePipe.translate(this.terminalStatusPipe.transform(connected)),
            };

            let terminalState: IArtDetailsTerminalState = {
                class: this.terminalStates.states.get(currentTerminalState),
                text: this.generalRewritePipe.translate(this.terminalStatesPipe.transform(currentTerminalState)),
            };

            this.terminalList.push({
                TerminalState: terminalState,
                ManufacturedBlocked: terminal.ManufacturerBlocked,
                TerminalId: terminal.TerminalId,
                TerminalName: terminal.TerminalName,
                TerminalSCVersion: SCVersion,
                TerminalType: this.terminalTypeNamingPipe.transform(TerminalType[terminal.TerminalType]),
                TerminalVersions: terminal.TerminalVersions,
                TerminalFull: terminal,
                Connected: terminalConnected,
                Components: terminal.Components,
            });

        }

        let terminalCount: number = this.terminalList.length
        this.terminalCount = terminalCount;
        this.terminalCountMax = terminalCount;

        this.terminalListTableView = true;
    }

}
