import { Pipe, PipeTransform } from '@angular/core';
import { DeviceType } from '../_enums/deviceType';
@Pipe({
    name: 'deviceType'
})
export class DeviceTypePipe implements PipeTransform {

    transform(value: number): string {

        //let mediumType = MediumType[value];

        return DeviceType[value];
    }
}
