import { Pipe, PipeTransform } from '@angular/core';
import { FeatureState } from '../_enums/featureState';
@Pipe({
    name: 'featureState'
})
export class FeatureStatePipe implements PipeTransform {

    transform(value: number): string {
        return FeatureState[value];
    }
}
