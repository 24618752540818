export enum ApiCallType {
    //Authentication
    Login = 1,
    Logout = 2,
    //Balancing
    UpdateMoneyService = 100,
    GetBalanceReportTypes = 101,
    GetMoneyServices = 102,
    GetBalanceSnapshots = 103,
    GetBalanceSnapshotsForReportType = 104,
    GetBalancingReport = 105,
    GetBalancingReportForMoneyService = 106,

    //Dashboard
    Heartbeat = 200,
    GetDowntime = 201,
    SetDashboardSettings = 202,
    GetDashboardSettings = 203,
    GetDashboardConfiguration = 204,
    GetSoftwareVersionEntries = 205,
    GetOsSoftwareVersionEntries = 206,
    GetTerminalUptimeCategories = 207,
    GetTerminalUptimeExtendedInfo = 208,
    GetKioskTransactionCounts = 209,
    GetDonationCounts = 210,
    GetKioskTransactionTotals = 211,
    GetKioskTransactionAverages = 212,
    GetKioskUptime = 213,
    GetKioskUptimeTimeslotInfo = 214,
    GetKioskTransactionTypes = 215,
    GetKioskMoneyServiceCount = 216,
    GetPropertyTransactionCounts = 217,
    GetPropertyTransactionTotals = 218,
    GetPropertyTransactionAverages = 219,
    GetPropertyUptime = 220,
    GetPropertyUptimeDetails = 221,
    GetPropertyTransactionTypes = 222,
    GetPropertyTerminalIssuesCount = 223,
    GetPropertyConnectedTerminalCount = 224,
    GetPropertyMoneyServiceCount = 225,
    GetInstallationTransactionCounts = 226,
    GetInstallationTransactionTotals = 227,
    GetInstallationTransactionAverages = 228,
    GetInstallationUptime = 229,
    GetInstallationUptimeDetails = 230,
    GetInstallationTransactionTypes = 231,
    GetInstallationTerminalIssuesCount = 232,
    GetInstallationConnectedTerminalCount = 233,
    GetLoggedInOperatorCount = 234,
    GetInstallationMoneyServiceCount = 235,
    //General
    GetArtServerVersionNumber = 300,

    //Jackpot
    GetJackpotForms = 400,
    GetJackpotSystemJackpots = 401,
    JackpotSystemPreLogin = 402,
    JackpotSystemLogin = 403,
    SearchForJackpotForms = 404,
    CheckTinListMatch = 405,
    ValidateJackpot = 406,
    ReprintJackpotForm = 407,
    UpdateJackpotSettlementStatus = 408,
    GetJackpotFormDetails = 409,
    RemoveJackpotForm = 410,
    ExtractBarcodeData = 411,
    AddJackpotForm = 412,
    //Logs
    SearchForTransactions = 500,
    GetTransactionPicture = 501,
    GetTransactionDetails2 = 502,
    GetTransactionDetails = 503,
    GetTransactions = 504,
    GetRecentTransactions = 505,
    GetErrorLogEntries = 510,
    GetRecentErrorLogEntries = 511,
    GetConfigurationChangeLogEntries = 520,
    GetRecentConfigurationChangeLogEntries = 521,
    GetOperatorAccessLogEntries = 530,
    GetRecentOperatorAccessLogEntries = 531,
    GetMaintenanceLogEntries = 540,
    GetRecentMaintenanceLogEntries = 541,
    GetMediaLogEntries = 550,
    GetRecentMediaLogEntries = 551,
    GetEventLogEntries = 560,
    GetRecentEventLogEntries = 561,
    GetArtEventLogEntries = 570,
    //Operator
    GetUserSettings = 600,
    SetUserSettings = 601,
    GetConfigurationEntries = 602,
    GetPlayers = 603,
    UpdateUserPassword = 604,
    AddOperator = 605,
    UpdateOperator = 606,
    DeleteOperator = 607,
    GetOperators = 608,
    GetUserSessions = 609, //TODO: move into Basics
    GetOperatorsFull = 610,
    //Payment Kiosk
    GetPaymentReport = 700,
    GetCustomers = 701,
    GetCustomerDetails = 702,
    DeleteCustomer = 703,
    BlockCustomer = 704,
    SearchForCustomers = 705,
    //Quarterly Dashboard

    //Statistics
    GetUtilizationReportStatistics = 900,
    GetMediaCardTurnoverStatistics = 901,
    GetMediaChipTurnoverStatistics = 902,
    GetChipTurnoverDetails = 903,
    GetMediaOnlineSystemStatistics = 904,
    GetMediaOnlineSystemDetailsStatistics = 905,
    GetMediaCardStatistics = 906,
    GetMediaTicketStatistics = 907,
    GetMediaCashStatistics = 908,
    GetTransactionCountStatistics = 909,
    GetTransactionReportStatistics = 910,
    GetTerminalAvailability = 911,
    GetTerminalState = 912,
    GetDonationReportStatistics = 913,
    GetDonationAccounts = 914,
    GetEndOfShiftReportStatistics = 915,
    GetOpeningInventoryEntries = 916,
    //Terminal
    GetTerminalList = 1000,
    GetTerminal = 1001,
    GetTerminalIdentifierDataForTerminal = 1002,
    GetTerminalIdentifierData = 1003,
    ApproveTransaction = 1004,
    GetTerminalListData = 1005,
    GetStockForTerminal = 1006,
    GetVersionEntries = 1007,
    GetFillLevelData = 1008,
    GetFeatureOverviewEntries = 1009,
    GetFeatureMonitorEntries = 1010,
    GetTerminalComponentEntries = 1011,
    GetComponentMonitorEntries = 1012,
    GetComponentMonitorDetails = 1013,
    SetTerminalStatus = 1014,
    StartRemoteSession = 1015,
    ShutDownRemoteSession = 1016,
    GetLogFiles = 1017,
    UpdateTerminal = 1018,
    GetTimeZoneInfos = 1019,
    GetCountryInfos = 1020,
    GetTTerminalStock = 1021,
    //User




    //Group Server
    GetUserSessionsGroup = 1200,
    GetUsers = 1201,
    UpdateUserPasswordGroup = 1202,
    AddUser = 1203,
    UpdateUser = 1204,
    DeleteUser = 1205,
    SynchronizeManufacturersOrders = 1206,
    UpdateWebClient = 1207,
    UpdateArtInstallationMode = 1208,
    BasicSearch = 1209,
    DeleteComputerRegistration = 1210,
    AddComputerRegistration = 1211,
    GetComputerRegistrations = 1212,
    GetManufacturingOrderOverviewByOrderId = 1213,
    GetManufacturingOrderOverview = 1214,
    GetTransactionsTypes = 1215,
    GetConnectedTerminalsCount = 1216,
    GetLoggedInOperatorsCount = 1217,
    GetSoftwareVersionEntriesGroup = 1218,
    GetOSSoftwareVersionEntries = 1219,
    GetTransactionsCount = 1220,
    GetTransactionsTotal = 1221,
    GetTransactionsAverage = 1222,
    GetDetailedTransactionsCount = 1223,
    GetDetailedTransactionsTotal = 1224,
    GetConfigurationEntriesGroup = 1225,
    SetConfigurationEntries = 1226,
    GetArtInstallations = 1227,
    GetLicenseKeyUsageOverview = 1228,
    GetLifecycleRecords = 1229,
    GetTerminalRegistrations = 1230,
    GetArtInstallationDetails = 1231,
    GetMaintenanceOverviews = 1232,
    GetMonthlyTransactionTotals = 1233,
    GetMonthlyUtilizationOverviews = 1234,
    GetGeoCoordinates = 1235,
    SearchByPartNumber = 1236,
    GetManufacturingOrders = 1237,
    SetTerminalState = 1238,
    GetKioskRegistrations = 1239,
    AddKioskRegistration = 1240,
    GetKioskRegistrationsRangeUpd2 = 1241,
    GetArtGroupServerVersion = 1242,
}
