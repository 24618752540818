import { Pipe, PipeTransform } from '@angular/core';
import { Gender } from '../_enums/gender';
@Pipe({
    name: 'gender'
})
export class GenderPipe implements PipeTransform {

    transform(value: number): string {

        return Gender[value];
    }
}
