import { Pipe, PipeTransform } from '@angular/core';
import { IdentityDocumentType } from '../_enums/identityDocumentType';
@Pipe({
    name: 'identityDocumentState'
})
export class IdentityDocumentTypePipe implements PipeTransform {

    transform(value: number): string {

        return IdentityDocumentType[value];
    }
}
