import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalCacheVariable } from '../_globals/globals';
import { catchError, map } from 'rxjs/operators';
import { ApiCallType } from "../_enums/apiCallType";
import { ApiDataConverter } from "../_helpers/api-data-converter";
//define TransactionTypeGroups

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class DashboardService {

    constructor(
        private http: HttpClient,
        public cache: GlobalCacheVariable) {
    }

    private static extractBridge(res, apiType) {
        let response = ApiDataConverter.extractData(res, apiType);
        return response || {};
    }

    public handleError(errorMessage: any, apiType) {
        ApiDataConverter.errorHandling(errorMessage, apiType);
        return observableThrowError(errorMessage);
    }

    /*Common START*/

    heartBeat(currentTime) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                clientTimeStamp: currentTime,
            };
            return this.http.post(this.cache.ARTApi + 'Heartbeat', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.Heartbeat);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.Heartbeat);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getDowntime(terminalID, dateStart, dateEnd) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = this.getAllTerminalId();
            } else {
                terminalIDsCache = [Number(terminalID)];
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalIDs: terminalIDsCache,
                start: dateStart,
                end: dateEnd
            };
            return this.http.post(this.cache.ARTApi + 'GetDowntime', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetDowntime);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetDowntime);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    setDashboardSettings(settings, scope) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                settings: settings,
            }
            return this.http.post(this.cache.ARTApi + 'SetDashboardSettings', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.SetDashboardSettings);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.SetDashboardSettings);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getDashboardSettings() {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
            }
            return this.http.post(this.cache.ARTApi + 'GetDashboardSettings', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetDashboardSettings);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetDashboardSettings);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getDashboardConfigurations() {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
            };
            return this.http.post(this.cache.ARTApi + 'GetDashboardConfiguration', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetDashboardConfiguration);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetDashboardConfiguration);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getSoftwareVersionEntries(scope, itemID) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                scope: scope,
                itemID: itemID,
            };
            return this.http.post(this.cache.ARTApi + 'GetSoftwareVersionEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetSoftwareVersionEntries);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetSoftwareVersionEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getSoftwareVersionOsEntries(scope, itemID) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                scope: scope,
                itemID: itemID,
            };
            return this.http.post(this.cache.ARTApi + 'GetOsSoftwareVersionEntries', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetOsSoftwareVersionEntries);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetOsSoftwareVersionEntries);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getTerminalUptimeCategories(itemID, scope) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                itemID: itemID,
                scope: scope
            };
            return this.http.post(this.cache.ARTApi + 'GetTerminalUptimeCategories', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetTerminalUptimeCategories);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetTerminalUptimeCategories);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getUtilizationReportStatistics(terminalIDs, start, end) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalIDs: terminalIDs,
                start: start,
                end: end
            };
            return this.http.post(this.cache.ARTApi + 'GetUtilizationReportStatistics', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetUtilizationReportStatistics);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetUtilizationReportStatistics);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getTerminalUptimeExtendedInfo(itemID, scope) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                scope: scope,
                itemID: itemID,
                includeOperatorTime: true,
            };
            return this.http.post(this.cache.ARTApi + 'GetTerminalUptimeExtendedInfo', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetTerminalUptimeExtendedInfo);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetTerminalUptimeExtendedInfo);
                    }));
        } catch (error) {
            console.log(error);
        }
    }
    /*Common END*/

    /*KIOSK START*/
    getKioskTransactionCounts(terminalID) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = this.getAllTerminalId();
            } else {
                terminalIDsCache = terminalID;
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalIDsCache,
            };
            return this.http.post(this.cache.ARTApi + 'GetKioskTransactionCounts', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetKioskTransactionCounts);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetKioskTransactionCounts);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getDonationCounts(itemID, scope) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                scope: scope,
                itemID: itemID,
            };
            return this.http.post(this.cache.ARTApi + 'GetDonationCounts', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetDonationCounts);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetDonationCounts);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getKioskTransactionTotals(terminalID) {
        try {
            let terminalIDsCache = null;
            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = this.getAllTerminalId();
            } else {
                terminalIDsCache = terminalID;
            }
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalIDsCache,
            };
            return this.http.post(this.cache.ARTApi + 'GetKioskTransactionTotals', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetKioskTransactionTotals);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetKioskTransactionTotals);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getKioskTransactionAverages(terminalID) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
            };
            return this.http.post(this.cache.ARTApi + 'GetKioskTransactionAverages', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetKioskTransactionAverages);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetKioskTransactionAverages);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getKioskTerminalUptime(terminalID) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
                includeOperatorTime: true,
            };
            return this.http.post(this.cache.ARTApi + 'GetKioskUptime', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetKioskUptime);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetKioskUptime);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getKioskTerminalUptimeDetails(terminalID) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
                includeOperatorTime: true,
            };
            return this.http.post(this.cache.ARTApi + 'GetKioskUptimeTimeslotInfo', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetKioskUptimeTimeslotInfo);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetKioskUptimeTimeslotInfo);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getKioskTransactionTypes(terminalID) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
            };
            return this.http.post(this.cache.ARTApi + 'GetKioskTransactionTypes', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetKioskTransactionTypes);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetKioskTransactionTypes);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getKioskMoneyService(terminalID) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalID: terminalID,
            };
            return this.http.post(this.cache.ARTApi + 'GetKioskMoneyServiceCount', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetKioskMoneyServiceCount);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetKioskMoneyServiceCount);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    /*KIOSK END*/

    /*PROPERTY START*/
    getPropertyTransactionCounts(propertyNumber) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                propertyNumber: propertyNumber,
            };
            return this.http.post(this.cache.ARTApi + 'GetPropertyTransactionCounts', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetPropertyTransactionCounts);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetPropertyTransactionCounts);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getPropertyTransactionTotals(propertyNumber) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                propertyNumber: propertyNumber,
            };
            return this.http.post(this.cache.ARTApi + 'GetPropertyTransactionTotals', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetPropertyTransactionTotals);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetPropertyTransactionTotals);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getPropertyTransactionAverages(propertyNumber) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                propertyNumber: propertyNumber,
            };
            return this.http.post(this.cache.ARTApi + 'GetPropertyTransactionAverages', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetPropertyTransactionAverages);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetPropertyTransactionAverages);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getPropertyTerminalUptime(propertyNumber) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                propertyNumber: propertyNumber,
                includeOperatorTime: true,
            };
            return this.http.post(this.cache.ARTApi + 'GetPropertyUptime', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetPropertyUptime);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetPropertyUptime);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getPropertyTerminalUptimeDetails(propertyNumber) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                propertyNumber: propertyNumber,
                includeOperatorTime: true,
            };
            return this.http.post(this.cache.ARTApi + 'GetPropertyUptimeDetails', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetPropertyUptimeDetails);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetPropertyUptimeDetails);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getPropertyTransactionTypes(propertyNumber) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                propertyNumber: propertyNumber,
            };
            return this.http.post(this.cache.ARTApi + 'GetPropertyTransactionTypes', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetPropertyTransactionTypes);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetPropertyTransactionTypes);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getPropertyTerminalIssues(propertyNumber) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                propertyNumber: propertyNumber,
            };
            return this.http.post(this.cache.ARTApi + 'GetPropertyTerminalIssuesCount', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetPropertyTerminalIssuesCount);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetPropertyTerminalIssuesCount);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getPropertyTerminalsConnected(propertyNumber) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                propertyNumber: propertyNumber,
            };
            return this.http.post(this.cache.ARTApi + 'GetPropertyConnectedTerminalCount', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetPropertyConnectedTerminalCount);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetPropertyConnectedTerminalCount);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getPropertyMoneyService(propertyNumber) {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                propertyNumber: propertyNumber,
            };
            return this.http.post(this.cache.ARTApi + 'GetPropertyMoneyServiceCount', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetPropertyMoneyServiceCount);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetPropertyMoneyServiceCount);
                    }));
        } catch (error) {
            console.log(error);
        }
    }
    /*PROPERTY END*/

    /*ART START*/
    getArtTransactionCounts() {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
            };
            return this.http.post(this.cache.ARTApi + 'GetInstallationTransactionCounts', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetInstallationTransactionCounts);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetInstallationTransactionCounts);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getArtTransactionTotals() {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
            };
            return this.http.post(this.cache.ARTApi + 'GetInstallationTransactionTotals', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetInstallationTransactionTotals);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetInstallationTransactionTotals);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getArtTransactionAverages() {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
            };
            return this.http.post(this.cache.ARTApi + 'GetInstallationTransactionAverages', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetInstallationTransactionAverages);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetInstallationTransactionAverages);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getArtTerminalUptime() {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                includeOperatorTime: true,
            };
            return this.http.post(this.cache.ARTApi + 'GetInstallationUptime', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetInstallationUptime);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetInstallationUptime);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getArtTerminalUptimeDetails() {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
                includeOperatorTime: true,
            };
            return this.http.post(this.cache.ARTApi + 'GetInstallationUptimeDetails', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetInstallationUptimeDetails);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetInstallationUptimeDetails);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getArtTransactionTypes() {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
            };
            return this.http.post(this.cache.ARTApi + 'GetInstallationTransactionTypes', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetInstallationTransactionTypes);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetInstallationTransactionTypes);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getArtTerminalIssues() {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
            };
            return this.http.post(this.cache.ARTApi + 'GetInstallationTerminalIssuesCount', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetInstallationTerminalIssuesCount);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetInstallationTerminalIssuesCount);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getArtTerminalsConnected() {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
            };
            return this.http.post(this.cache.ARTApi + 'GetInstallationConnectedTerminalCount', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetInstallationConnectedTerminalCount);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetInstallationConnectedTerminalCount);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getArtOperators() {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
            };
            return this.http.post(this.cache.ARTApi + 'GetLoggedInOperatorCount', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetLoggedInOperatorCount);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetLoggedInOperatorCount);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    getArtMoneyService() {
        try {
            let body = {
                sessionKey: this.cache.getSessionKey(),
            };
            return this.http.post(this.cache.ARTApi + 'GetInstallationMoneyServiceCount', body, httpOptions)
                .pipe(
                    catchError(data => {
                        return this.handleError(data, ApiCallType.GetInstallationMoneyServiceCount);
                    }),
                    map(data => {
                        return DashboardService.extractBridge(data, ApiCallType.GetInstallationMoneyServiceCount);
                    }));
        } catch (error) {
            console.log(error);
        }
    }

    /*ART END*/

    getDashboardLasttest(url, terminalID) {
        try {

            let terminalIDsCache = null;

            if (terminalID === 'all' || terminalID === -1) {
                terminalIDsCache = this.getAllTerminalId();
            } else {
                terminalIDsCache = [terminalID];
            }

            let body = {
                sessionKey: this.cache.getSessionKey(),
                terminalIDs: terminalIDsCache,
            };

            return this.http.post(this.cache.ARTApi + url, body, httpOptions)
                .pipe(
                    map(DashboardService.extractDashboardLasttestData)
                );

        } catch (error) {
            console.log(error);
        }

    }

    private static extractDashboardLasttestData(res: Response) {
        const bodyRaw = JSON.parse(JSON.stringify(res));
        const body = bodyRaw.d;
        return body || {};
    }

    //Utils
    getAllTerminalId() {
        let terminalArray = this.cache.Terminals;
        let terminalArrayRequest = [];
        for (let i = 0; i < terminalArray.length; i++) {
            terminalArrayRequest.push(terminalArray[i].TerminalID);
        }
        return terminalArrayRequest;
    }

}

