// Germany
export const locale = {
    "lang": "de",
    "data": {
        "TRANSLATOR": {
            "SELECT": "Wähle deine Sprache"
        },
        "MENU": {
            "MONITORING": {
                "MAIN": "Überwachung",
                "CHILD": {
                    "DASHBOARD": "Dashboard",
                    "DASHBOARD_DOWNTIME": "Dashboard Außer Betrieb",
                    "DASHBOARD_LASTTEST": "Dashboard Load-Test",
                    "TERMINAL": "Terminal",
                    "TERMINALS": "Terminals",
                    "COMPONENT_OVERVIEW": "Komponentenübersicht",
                    "MAINTENANCE_OVERVIEW": "Wartungsübersicht",
                    "TERMINAL_IDENTIFIER": "Terminal-Bezeichner"
                }
            },
            "LOGS": {
                "MAIN": "Protokolle",
                "CHILD": {
                    "TRANSACTION": "Transaktionen",
                    "ERROR": "Fehler",
                    "OPERATOR_ACCESS": "Bedienerzugriffe",
                    "CONFIGURATION_CHANGE": "Konfigurationsänderungen",
                    "EVENT": "Ereignisse",
                    "MEDIA": "Medien",
                    "ART_EVENT": "ART-Ereignisse",
                    "MAINTENANCE": "Wartungen"
                }
            },
            "STATISTICS": {
                "MAIN": "Statistiken",
                "CHILD": {
                    "MEDIA_STATISTICS": "Medien Statistiken",
                    "TERMINAL_STATISTICS": "Terminal Statistiken",
                    "BALANCING_REPORTS": "Abrechnungsbericht",
                    "UTILIZATION_REPORT": "Verwendung",
                    "TRANSACTION_COUNT": "Transaktionszahlen",
                    "TRANSACTION_STATISTICS": "Transaktionsstatistik",
                    "TERMINAL_AVAILABILITY": "Terminalverfügbarkeit",
                    "TERMINAL_STATE": "Terminal-Status",
                    "MEDIA_CASH": "Bargeld-Statistiken",
                    "MEDIA_CARD": "Karten-Statistiken",
                    "MEDIA_CARD_TURNOVER": "Kartenumsatz",
                    "MEDIA_CHIP_TURNOVER": "Chip-Umsatz",
                    "MEDIA_TICKET_OPERATOR": "Ticket Statistiken für Bediener",
                    "MEDIA_TICKET_PERIOD": "Ticket Statistiken im Zeitraum",
                    "MEDIA_ONLINE_SYSTEMS": "Online-System-Statistiken",
                    "ART_OVERVIEW": "ART Übersicht",
                    "QUARTERLY_DASHBOARD": "Quartals-Dashboard",
                    "MAP_VIEW": "Kartenansicht",
                    "STOCK_OVERVIEW": "Bestandsübersicht",
                    "COMPONENT_OVERVIEW": "Komponentenübersicht",
                    "USER_MANAGEMENT": "Benutzer",
                    "MAINTENANCE_OVERVIEW": "Wartungsübersicht",
                    "PAYMENT_REPORTS": "Payment-Berichte",
                    "DONATIONS": "Spenden-Berichte",
                    "END_OF_SHIFT": "Schichtende-Berichte"
                }
            },
            "BALANCING": {
                "MAIN": "Abrechnungen",
                "CHILD": {
                    "BALANCING_REPORTS": "Abrechnungsbericht",
                }
            },
            "MANAGEMENT": {
                "MAIN": "Verwaltung",
                "CHILD": {
                    "OPERATORS": "Bediener",
                    "USER_MANAGEMENT": "Benutzer",
                    "PLAYER_INFORMATION": "Spieler",
                    "PAYMENT_CUSTOMER": "Kunden",
                    "TERMINAL_LIFECYCLE": "Terminal-Lebenslaufakte",
                    "COMPUTER_SERIAL": "Computer-Registrierung",
                    "LICENSE_KEY_USAGE_OVERVIEW": "Lizenzschlüsselübersicht",
                    "JACKPOT_PAYOUTS": "Jackpot-Formulare",
                    "MANUFACTURING_ORDER_OVERVIEW": "Fertigungsauftragsübersicht",
                    "MANUFACTURING_ORDERS": "Fertigungsaufträge",
                    "KIOSK_REGISTRATION": "Kiosk-Registrierung"
                }
            },
            "SETTINGS": {
                "MAIN": "Einstellungen",
                "GENERAL_SETTINGS": "Allgemeine Einstellungen",
                "CHILD": {
                    "CONFIGURATION_MANAGEMENT": "Konfigurationseinträge"
                },
            },
            "USER": {
                "TERMINAL_CHOOSER": "Terminals auswählen"
            }
        },
        "AUTH": {
            "GENERAL": {
                "OR": "Oder",
                "SUBMIT_BUTTON": "einreichen",
                "NO_ACCOUNT": "Hast du kein Konto?",
                "SIGNUP_BUTTON": "Registrieren",
                "SIGN_IN_BUTTON": "Anmelden",
                "FORGOT_BUTTON": "Passwort vergessen",
                "BACK_BUTTON": "Zurück",
                "PRIVACY": "Privatsphäre",
                "LEGAL": "Legal",
                "CONTACT": "Kontakt",
                "TITLE": "Am ART Server anmelden",
                "TITLE_GROUP_SERVER": "Am ART Group Server anmelden",
            },
            "LOGIN": {
                "TITLE": "Create Account",
                "BUTTON": "Sign In",
                "RESPONSE": {
                    "WEB_CLIENT": {
                        "LOGIN_NOT_POSSIBLE": {
                            "SUMMARY": "Anmeldung nicht möglich",
                            "DETAIL": "Am System konnte sich nicht angemeldet werden."
                        },
                        "LOGIN_DENIED": {
                            "SUMMARY": "Anmeldung verweigert",
                            "DETAIL": "Anmeldung wurde verweigert."
                        },
                        "INVALID_PASSWORD": {
                            "SUMMARY": "Anmeldung fehlerhaft",
                            "DETAIL": "Ungültiges Passwort."
                        },
                        "INVALID_USERNAME": {
                            "SUMMARY": "Anmeldung fehlerhaft",
                            "DETAIL": "Ungültiger Benutzername."
                        },
                        "LOGIN_DISABLED": {
                            "SUMMARY": "Anmeldung deaktiviert",
                            "DETAIL": "Anmeldung ist deaktiviert."
                        },
                        "NOT_LOGGED_IN": {
                            "SUMMARY": "Nicht eingeloggt",
                            "DETAIL": "Du bist nicht eingeloggt."
                        },
                        "SESSION_COUNT_EXCEEDED": {
                            "SUMMARY": "Session-Anzahl erreicht",
                            "DETAIL": "Erlaubte Anzahl an gleichzeitigen Sitzungen ist erreicht."
                        },
                        "ACCOUNT_TYPE_NOT_SUPPORTED": {
                            "SUMMARY": "Kontotyp nicht unterstützt",
                            "DETAIL": "Dein Kontotyp wird nicht unterstützt."
                        },
                    },
                    "GROUP_WEB_CLIENT": {
                        "LOGIN_DENIED": {
                            "SUMMARY": "Anmeldung verweigert",
                            "DETAIL": "Anmeldung wurde verweigert."
                        },
                        "LOGIN_DISABLED": {
                            "SUMMARY": "Anmeldung deaktiviert",
                            "DETAIL": "Anmeldung ist deaktiviert."
                        },
                        "LOGIN_INCORRECT": {
                            "SUMMARY": "Anmeldung fehlerhaft",
                            "DETAIL": "Anmeldung war nicht richtig."
                        },
                        "LOGIN_CORRECT": {
                            "SUMMARY": "Anmeldung erfolgreich",
                            "DETAIL": "Anmeldung war erfolgreich."
                        },
                        "INVALID_PASSWORD": {
                            "SUMMARY": "Anmeldung fehlerhaft",
                            "DETAIL": "Ungültiges Passwort."
                        },
                        "INVALID_USERNAME": {
                            "SUMMARY": "Anmeldung fehlerhaft",
                            "DETAIL": "Ungültiger Benutzername."
                        },
                    },
                }
            },
            "FORGOT": {
                "TITLE": "Passwort vergessen?",
                "DESC": "Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen"
            },
            "REGISTER": {
                "TITLE": "Sign Up",
                "DESC": "Geben Sie Ihre Daten ein, um Ihr Konto zu erstellen",
                "SUCCESS": "Ihr Konto wurde erfolgreich registriert. Bitte melden Sie sich mit Ihrem registrierten Konto an."
            },
            "INPUT": {
                "EMAIL": "E-Mail",
                "FULLNAME": "Nutzername",
                "PASSWORD": "Passwort",
                "CONFIRM_PASSWORD": "Bestätige Passwort"
            },
            "VALIDATION": {
                "INVALID": "{{name}} ist ungültig",
                "REQUIRED": "{{name}} ist erforderlich",
                "MIN_LENGTH": "{{name}} minimale Länge ist {{min}}",
                "AGREEMENT_REQUIRED": "Die Annahme der Allgemeinen Geschäftsbedingungen ist erforderlich",
                "NOT_FOUND": "Der angeforderte {{name}} wurde nicht gefunden",
                "INVALID_LOGIN": "Die Anmeldedaten sind falsch",
                "TOAST": {
                    "INSUFFICIENT_RIGHTS": "Unzureichende Rechte – {{component}} darf nicht angezeigt werden",
                    "ACCESS_DENIED": "Zugriff abgelehnt!",
                }
            }
        },
        "BASE": {
            "TERMINAL": "Terminal",
            "TERMINALS": "Terminals",
            "PROPERTY": "Filiale",
            "PROPERTIES": "Filialen",
            "TERMINALS_ALL": "Alle Terminals",
            "DATE": "Datum",
            "OPERATOR": "Bediener",
            "STATE": "Status",
            "TYPE": "Typ",
            "DETAILS": "Details",
            "DESCRIPTION": "Beschreibung",
            "CURRENCY": "Währung",
            "NAME": "Name",
            "ACTION": "Aktion",
            "AMOUNT": "Betrag",
            "ID": "ID",
            "AVERAGE": "Durchschnitt",
            "ENTRIES": "Einträge",
            "ENTRIES_COUNT": "Einträge: {{count}}",
            "ENTRIES_COUNT_2": "Einträge: {{filteredCount}}/{{totalCount}}",
            "SURNAME": "Nachname",
            "LAST_USED": "zuletzt genutzt",
            "PHONE_NUMBER": "Telefonnummer",
            "BIRTHDAY": "Geburtstag",
            "CATEGORY": "Kategorie",
            "TIME": "Zeit",
            "COUNT": "Anzahl",
            "PERCENTAGE": "Prozent",
            "VERSION": "Version",
            "NOT_AVAILABLE": "n. a.",
            "SAVE_AS": "Speichern als...",
            "UNKNOWN_TYPE": "Unbekannter Typ ({{type}})",
            "UNKNOWN_TRANSLATION": "Fehlende Übersetzung ({{text}})",
            "PIECES": "Anzahl",
            "SUM": "Summe",
            "TERMINAL_BLOCK_UNBLOCK": "Terminal Blockieren/Entblockieren",
            "HISTORY": "Vergangene Vorgänge",
            "MONTHS": {
                "JANUARY": "Januar",
                "FEBRUARY": "Februar",
                "MARCH": "März",
                "APRIL": "April",
                "MAY": "Mai",
                "JUNE": "Juni",
                "JULY": "Juli",
                "AUGUST": "August",
                "SEPTEMBER": "September",
                "OCTOBER": "Oktober",
                "NOVEMBER": "November",
                "DECEMBER": "Dezember"
            },
            "WEEK_DAY": {
                "MONDAY": "Montag",
                "TUESDAY": "Dienstag",
                "WEDNESDAY": "Mittwoch",
                "THURSDAY": "Donnerstag",
                "FRIDAY": "Freitag",
                "SATURDAY": "Samstag",
                "SUNDAY": "Sonntag,"
            },
            "BUTTONS": {
                "LOAD": "Laden",
                "SELECT_ALL": "Alle auswählen",
                "SELECT_NONE": "Keinen auswählen",
                "OK": "OK",
                "REFRESH": "Erneuern",
                "EDIT": "Bearbeiten",
                "SAVE": "Speichern",
                "ADD": "Hinzufügen",
                "UPDATE": "Aktualisieren",
                "CANCEL": "Abbrechen",
                "LEGEND": "Legende anzeigen",
                "SEARCH": "Suchen",
                "TRANSACTION_SEARCH": "Transaktionssuche",
                "DELETE": "Löschen",
                "BLOCK_USER": "Nutzer sperren",
                "UNBLOCK_USER": "Nutzer entsperren",
                "REGISTRATION_DATE": "Registrierungsdatum",
                "DOWNLOAD": "Herunterladen",
                "DOWNLOAD_PDF": "PDF Herunterladen",
                "DOWNLOAD_EXCEL": "Excel Herunterladen",
                "PRINT": "Drucken",
                "CLOSE": "Schließen",
                "SYNC_V_TRADE": "Synchronisiere VTrade",
                "YES": "Ja",
                "NO": "Nein",
                "AUTO_CYCLE": "Automatischer Zyklus",
                "AUTO_REFRESH": "Automatische Aktualisierung",
                "GENERATE": "Generieren",
                "COPY": "Kopieren",
                "COPY_TO_CLIPBOARD": "In die Zwischenablage kopieren",
                "MAXIMIZE": "Maximieren",
                "TABLE": "Tabelle"
            },
            "FILTER": {
                "TITLE": "Filtern",
                "CASINO_DAY": "Kasinotag",
                "DATE": "Datum & Zeit (Von/Bis)",
                "TERMINALS": "Terminals",
                "OPERATOR": "Bediener",
                "CURRENCY": "Währung",
                "ALL": "Alle",
                "PERIOD": "Zeitraum",
                "DAY": "Tag",
                "WEEK": "Woche",
                "MONTH": "Monat",
                "YEAR": "Jahr",
                "TIME": "Zeit",
                "TERMINAL": "Terminal",
                "FROM": "Von",
                "TO": "Bis",
                "SUMMARY_ONLY": "Nur Zusammenfassung",
                "UTC": "Datum am Terminal",
                "PAGE": "Seite",
                "ENTRIES_PER_PAGE": "Einträge pro Seite",
                "OF": "von"
            },
            "SEARCH": {
                "FIND": "Finde",
                "EXCLUDE": "Ausschließen",
            },
            "LOADING": {
                "TITLE": "Daten werden geladen..."
            },
            "MESSAGES": {
                "WEBSOCKET_ERROR": "Der Websocket ist noch nicht verbunden. Der Aufbau einer Verbindung wird im Hintergrund durchgeführt. Live-Änderungen werden derzeit nicht angezeigt.",
            },
            "TOASTS": {
                "EXPORT": {
                    "NO_VALID_TYPE": {
                        "TITLE": "Kein gültiger Export-Typ",
                        "TEXT": "Kein gültiger Export-Typ"
                    }
                },
                "TIMESPAN": {
                    "INVALID": {
                        "TITLE": "Ungültige Zeitspanne",
                        "TEXT": "Bitte wählen Sie ein Startdatum welches vor dem Enddatum liegt."
                    }
                }
            }
        },
        "USER": {
            "MENU": {
                "ART_WEB_CLIENT_VERSION": "ART Web Client",
                "ART_SERVER_VERSION": "ART Server",
                "ART_GROUP_SERVER_VERSION": "ART Group Server",
                "ART_SERVER_URL": "ART Server URL",
                "ART_GROUP_WEB_CLIENT_VERSION": "ART Group Web Client",
                "LOGOUT": "Ausloggen",
                "CHOOSE_LANGUAGE": "Sprache auswählen...",
                "CURRENT_USER_SESSIONS": "Aktuell eingeloggte Benutzer",
                "ART_ABOUT": "Über ART",
                "USER_PROFILE": "Benutzerprofil",
                "WEBSITE": "Webseite",
                "UPDATE_GROUP_CLIENT": "Group Client aktualisieren",
            },
            "MODAL": {
                "UPDATE": "ART Group Web Client aktualisieren",
                "SELECT": "Bitte wählen Sie die ART Group Web Client Update-Datei (*.zip)",
                "START_UPDATE": "Update starten",
                "SUCCESS_UPDATED": "ART Group Web Client erfolgreich aktualisiert.",
                "UPDATE_SUCCESS": "Aktualisierung erfolgreich",
                "SERVER_EXCEPTION": "Serverausnahme, bitte versuchen Sie es erneut.",
                "UPDATE_EXCEPTION": "Updateausnahme",
            },
            "CURRENT_OPERATOR_SESSIONS": {
                "REFRESH": "Aktualisieren",
                "ID": "ID",
                "OPERATOR": "Bediener",
                "LOGGED_IN_SINCE": "Angemeldet seit",
                "CLIENT_TYPE": "Client-Typ",
                "ENDPOINT": "Endpunkt",
                "COMPUTER": "Computer"
            },
            "USER_PROFILE": {
                "TITLE": "Profil",
                "USER_INFORMATION": {
                    "GROUP": {
                        "TITLE": "Benutzerinformationen",
                        "USERNAME": "Nutzername",
                    },
                    "TITLE": "Bedienerinformationen",
                    "OPERATOR_ID": "Bediener ID",
                    "OPERATOR_NAME": "Bedienername"
                },
                "PASSWORD": {
                    "TITLE": "Passwort",
                    "OLD_PASSWORD": "Altes Passwort",
                    "NEW_PASSWORD": "Neues Passwort",
                    "CONFIRM_PASSWORD": "Bestätige Passwort",
                    "BUTTON": {
                        "SAVE_PASSWORD": "Speichere Passwort"
                    }
                },
                "INDIVIDUAL_CONFIGURATION": {
                    "TITLE": "Anpassungen",
                    "ENTRY_PAGE": "Einstiegsseite",
                    "SELECT_AN_ENTRY_PAGE_PLACEHOLDER": "Wähle eine Einstiegsseite aus",
                    "LANGUAGE": "Sprache",
                    "CURRENCY": "Währung",
                    "CURRENCY_SYMBOL": "Währungssymbol",
                    "NUMBER_FORMAT": "Zahlenformat",
                    "DATE_LONG": "Datumsformat-lang",
                    "DATE_MIDDLE": "Datumsformat-mittel",
                    "DATE_SHORT": "Datumsformat-kurz",
                    "DATE_HOUR": "Datumsformat-Stunde",
                    "LANGUAGE_CHANGED": "Sprache wurde geändert",
                    "ENTRY_PAGE_CHANGED": "Einstiegsseite wurde geändert",
                }
            }
        },
        "MONITORING": {
            "MAP_OVERVIEW": {
                "TITLE": "Überblick über die ART-Verteilung",
                "FILTER": {
                    "PROPERTY_NAME": "Fillialenname"
                },
                "BUTTONS": {
                    "INSTALLATIONS": "Installationen",
                    "ART_INSTALLATIONS": "ART-Installationen",
                    "TERMINAL_TYPE": "Terminaltyp"
                },
                "BOARD": {
                    "TITLE": "Terminals pro Installation"
                },
                "SETTINGS": {
                    "TITLE": "Einstellungen für die Kartenübersicht",
                    "SHOW_ICONS": "Symbole anzeigen",
                    "SATELLITE": "Natürliche Ansicht",
                    "SHOW_COLOR_BOARD": "Farbtafel anzeigen",
                    "SHOW_INSTALLATIONS": "ART-Installationen anzeigen",
                    "SHOW_PROPERTIES": "Filialen anzeigen",
                    "SHOW_TERMINALS": "'Terminals' anzeigen",
                    "SHOW_MODE": "Anzeige-Modus",
                }
            },
            "STOCK_OVERVIEW": {
                "TITLE": "Bestandsübersicht",
                "TABLE": {
                    "TOTAL": "Gesamt",
                    "TERMINAL_ID": "Terminal-ID",
                    "TERMINAL": "Terminal",
                    "INSTALLATION": "ART-Installation",
                    "STOCK_DATE": "Bestandsdatum",
                    "MEDIA_IN": "Tickets-Rein",
                    "MEDIA_OUT": "Tickets-Raus",
                    "MEDIA_REJECT": "Ablehnungsfeld",
                    "COIN_ACCEPTOR_TOTAL": "Münzakzeptor gesamt",
                    "COIN_DISPENSER_TOTAL": "Münzausgeber gesamt",
                    "BILL_ACCEPTOR_TOTAL": "Scheinakzeptor gesamt",
                    "BILL_DISPENSER_TOTAL": "Scheinausgeber gesamt",
                    "COIN_ACCEPTOR_PER_DENO": "Münzakzeptor Nennwerte",
                    "COIN_DISPENSER_PER_DENO": "Münzausgeber Nennwerte",
                    "BILL_ACCEPTOR_PER_DENO": "Scheinakzeptor Nennwerte",
                    "BILL_DISPENSER_PER_DENO": "Scheinausgeber Nennwerte",
                }
            },
            "COMPONENT_OVERVIEW": {
                "TITLE": "Komponentenübersicht",
                "TABLE": {
                    "SOURCE": "Quelle",
                    "DATE": "Datum",
                    "SEVERITY": "Schweregrad",
                    "EVENT": "Event"
                },
                "LABEL": {
                    "TERMINALS_WITH_ISSUES": "Terminals mit Fehlern ",
                    "TERMINALS_WITHOUT_ISSUES": "Terminals ohne Fehler ",
                    "TERMINAL_WITH_ISSUES": "Terminal mit Fehlern ",
                    "TERMINAL_WITHOUT_ISSUES": "Terminal ohne Fehler "
                },
                "EVENT": {
                },
                "SEVERITY": {
                    "WARNING": "Warnung",
                    "ERROR": "Fehler",
                    "INFORMATION": "Information",
                    "FATAL": "Fatal"
                },
                "SOURCE": {
                    "COMPONENT": "Komponente",
                },
                "FILTER_BAR": {
                    "SINCE_LAST_STARTUP": "Nur seit letztem Hochfahren",
                    "INCLUDE_GLOBAL_EVENTS": "Globale Ereignisse einbeziehen",
                    "INCLUDE_SUBCOMPONENT_EVENT": "Unterkomponenten-Ereignisse einbeziehen",
                },
            },
            "DASHBOARD": {
                "TOOLTIP": {
                    "DATA_SHOWN_FOR_THE_PAST_TIME": "Zeitraum der angezeigten Daten",
                    "REFRESHES_EVERY": "Aktualisierungsintervall",
                    "LIVE_DATA": "Live Daten",
                    "DAY": "Tag",
                    "WEEK": "Woche",
                    "MONTH": "Monat",
                    "PRESENTATION_MODE": "Presentationsmodus"
                },
                "WIDGET": {
                    "TITLE": "Widget",
                    "MONEY_SERVICE": {
                        "MODAL": {
                            "TABLE": {
                                "TERMINAL_NAME": "Terminalname",
                                "MONEY_SERVICE_COUNT": "Anzahl Bestandsaufnahmen",
                                "FIRST_MONEY_SERVICE": "Erste Bestandsaufnahme - Letzte 7 Tage",
                                "LAST_MONEY_SERVICE": "Letzte Bestandsaufnahme - Letzte 7 Tage"
                            }
                        }
                    },
                    "MONEY_SERVICE_ACTION_REQUIRED": {
                        "MODAL": {
                            "TABLE": {
                                "TERMINAL_NAME": "Terminalname",
                                "REJECT_BOX": "Rückweisungen",
                                "CASSETTE": "Kassette",
                                "COIN_HOPPER": "Münzausgeber",
                                "BILL_ACCEPTOR": "Akzeptor",
                                "CARD_STACKER": "Kartenmagazin",
                                "EMPTY": "Leer",
                                "LOW": "Niedrig",
                                "OK": "Ok",
                                "THRESHOLD": "Schwellwert",
                                "FULL": "Voll"
                            }
                        }
                    },
                    "TERMINAL_OUT_OF_OPERATION": {
                        "MODAL": {
                            "TABLE": {
                                "NONE": "keine",
                                "YES": "Ja",
                                "OUT_OF_OPERATION": "Außer Betrieb",
                                "OFFLINE_BY_OPERATOR": "Offline durch Bediener",
                                "COMPONENT_IN_ERROR_STATE": "Komponente in Fehlerstatus",
                                "MONEY_SERVICE_ACTION_REQUIRED": "Geld-Service erforderlich"
                            }
                        }
                    },
                    "TRANSACTION_TYPES": {
                        "SWITCHER": {
                            "TOOLBAR": "Transaktionstypen gruppieren",
                        }
                    },
                    "TERMINAL_ISSUES": {
                        "MODAL": {
                            "TABLE": {
                                "TERMINAL_NAME": "Terminal",
                                "COMPONENT_WARNINGS": "Komponentenwarnung",
                                "COMPONENT_ERRORS": "Komponentenfehler"
                            }
                        }
                    },
                    "TERMINAL_UPTIME": {
                        "MODAL": {
                            "TITLE": "Terminal-Verfügbarkeit für ",
                            "GRAPH": "Graph",
                            "TERMINAL_UPTIME": "Terminal-Verfügbarkeit",
                            "ART_INSTALLATION_AVERAGE_UPTIME": "Durchschnittliche ART-Installation-Verfügbarkeit",
                            "PROPERTY_AVERAGE_UPTIME": "Durchschnittliche Fillial-Verfügbarkeit"
                        }
                    },
                    "SOFTWARE_DISTRIBUTION": {
                        "OTHERS": "Andere",
                        "BUTTONS": {
                            "MAXIMIZE_WIDGET": "Widget maximieren"
                        },
                        "SWITCHER": {
                            "TOOLBAR": "Nur Top {{x}} anzeigen",
                        },
                    },
                    "SOFTWARE_DISTRIBUTION_OS": {
                        "BUTTONS": {
                            "MAXIMIZE_WIDGET": "Widget maximieren"
                        },
                        "SWITCHER": {
                            "TOOLBAR": "Nur Top {{x}} anzeigen",
                        },
                    }
                }
            },
            "DASHBOARD_DOWNTIME": {
                "TITLE": "Dashboard - Ausfallzeit",
                "TABLE": {
                    "KIOSK": "Kiosk",
                    "COMPONENT_DOWNTIME": "Komponenten-Ausfallzeit",
                    "OPERATIONAL_DOWNTIME": "Betriebsbedingte Ausfallzeit",
                    "TOTALS": "Gesamt",
                    "STOCK": "Bestand",
                    "TERMINAL_NAME": "Terminalname",
                    "TERMINAL_TYPE": "Terminaltyp",
                    "HARDWARE_ERRORS": "Technische Fehler",
                    "SET_OFFLINE": "Außer Betrieb setzen",
                    "LOGGED_IN": "Eingeloggt",
                    "MONEY_SERVICE_REQUIRED": "Geld-Service erforderlich",
                    "PERFORMING_MONEY_SERVICE": "Geld-Service durchführen",
                    "TURNED_OFF": "Ausgeschaltet",
                    "DOWNTIME": "Außer Betrieb",
                    "UPTIME": "In Betrieb",
                    "LOW_ON_STOCK": "Wenig Bestand",
                    "SITE_AVERAGE": "Standort Durchschnitt",
                    "MONEY_SERVICE": "Geld-Service"
                }
            }
        },
        "TERMINAL": {
            "BASE": {
                "VIEW_SWITCH": {
                    "LIST": "Terminal-Liste",
                    "ICON": "Terminal-Symbole",
                    "FILL_LEVEL": "Füllstände"
                },
                "FILTER": {
                    "CONNECTION": {
                        "MAIN": "Verbindung"
                    },
                    "TERMINAL_STATE": {
                        "TITLE": "Terminal-Status",
                        "MAIN": "Status",
                        "CONNECTED": "Verbunden",
                        "NOT_CONNECTED": "Nicht verbunden",
                        "FAILURE": "Fehler",
                        "COMPONENT_STATE": "Komponentenstatus",
                    },
                    "PROPERTY": {
                        "MAIN": "Filiale",
                    },
                    "TERMINAL": {
                        "MAIN": "Terminal",
                    },
                    "TERMINAL_TYPE": {
                        "MAIN": "Typ",
                    },
                    "BUTTONS": {
                        "CLEAR": "Zurücksetzen"
                    },
                    "OF": "von"
                },
                "TERMINAL_STATUS": {
                    "CONNECTED": "Verbunden",
                    "DISCONNECTED": "Nicht verbunden"
                }
            },
            "CONTEXT_MENU": {
                "SHOW_TERMINAL_DASHBOARD": "Zeige Terminal Dashboard",
                "SHOW_COMPONENT_OVERVIEW": "Gehe zu Komponentübersicht",
                "SHOW_FEATURE_OVERVIEW": "Gehe zu Funktionsübersicht",
                "SET_ONLINE": "Setze Online",
                "SET_OFFLINE": "Setze Offline",
                "SOFTWARE_RESTART": "Software Neustart",
                "TERMINAL_RESTART": "Terminal Neustart",
                "SHUT_DOWN": "Herunterfahren",
                "SHOW_TRANSACTIONS": "Transaktionen anzeigen",
                "LOGFILES": "Protokolldateien anfordern...",
                "REMOTE_ACCESS": "Fernzugriff starten",
                "REMOTE_ACCESS_OTK": "Fernzugriff mit OTK",
                "REMOTE_ACCESS_SSL_REQUIRED": "Fernzugriff starten (SSL erforderlich)"
            },
            "DETAILS": {
                "NO_OPERATOR_LOGGED_IN": "Kein Bediener ist eingeloggt",
                "OPERATOR_LOGGED_IN": "Bediener ist eingeloggt",
                "SHOW_STOCK_FOR": "Zeige Beträge für",
                "BUTTONS": {
                    "CLOSE_DETAILS": "Details schließen",
                    "SHOW_DETAILS": "Details anzeigen",
                    "SHOW_MORE": "Weitere anzeigen"
                },
                "VERSION": {
                    "MAIN": {
                        "TITLE": "Versionen",
                        "SOFTWARE": "Software",
                        "CONFIGURATION": "Konfiguration",
                        "COS": "COS",
                        "ICOS": "I-COS"
                    }
                },
                "STOCK": {
                    "MAIN": "Bestände",
                    "BILL_ACCEPTOR": "Akzeptor",
                    "BILL_DISPENSER": "Ausgeber",
                    "COIN_ACCEPTOR": "Hopper",
                    "COIN_DISPENSER": "Hopper Ausgeber",
                    "TOTAL_CASH": "Gesamt",
                    "TICKETS_IN": "Tickets Ein",
                    "TICKETS_OUT": "Tickets Aus",
                    "CARDS_IN": "Karten Ein",
                    "CARDS_OUT": "Karten Aus",
                    "CHIPS_IN": "Chips Ein",
                    "CHIPS_OUT": "Chips Aus",
                    "NOTES_IN": "Scheine Ein"
                },
                "TITA_CATA": {
                    "TITLES": {
                        "TICKETS": "Tickets",
                        "CHIPS": "Chips",
                        "COUPONS": "Coupons",
                        "MARKERS": "Markers",
                        "BILLS": "Scheine",
                        "BILL_ACCEPTOR": "Scheinakzeptor",
                        "CARDS": "Karten"
                    },
                    "WITHHELD": "Zurückgehalten",
                    "PAID": "Bezahlt"
                },
                "BILL_ACCEPTOR": {
                    "MAIN": "Scheinakzeptor",
                    "TOTAL_BILLS": "Gesamt",
                    "TOTAL_ACCEPTOR": "Gesamt Akz.",
                    "TICKETS_IN": "Tickets ein",
                    "TICKETS_OUT": "Tickets aus"
                },
                "BILL_DISPENSER": {
                    "MAIN": "Scheinausgeber",
                    "TOTAL_BILLS": "Gesamt",
                    "REJECT": "Rückweisungen"
                },
                "COIN_DISPENSER": {
                    "MAIN": "Münzausgeber",
                    "TOTAL_COINS": "Gesamt"
                },
                "CARDS": {
                    "MAIN": "Karten",
                    "STACKER": "Ablage",
                    "CATCHING_BIN": "Auffangbehälter"
                },
                "SAM": {
                    "MAIN": "SAM",
                    "VALUE": "Wert"
                },
                "ROUNDING_DIFFERENCES": {
                    "MAIN": "Rundungsdifferenzen für",
                    "TICKETS": "Tickets",
                    "PLAYER_CARDS": "Spielerkarten",
                    "BONUS_CARDS": "Bonuskarten",
                    "JACKPOT": "Jackpot",
                    "BILLS": "Scheine",
                    "TOTAL": "Gesamt"
                },
                "MEDIA": {
                    "MAIN": "Medien",
                    "WITHHELD": "Zurückgehalten",
                    "PAID": "Bezahlt",
                    "DIFFERENCES": "Differenz"
                },
                "MEDIA_DEPOSIT": {
                    "MAIN": "Medien Einzahlung",
                    "WITHHELD": "Zurückgehalten",
                    "PAID": "Bezahlt",
                    "DIFFERENCES": "Differenz"
                },
                "COIN_ACCEPTOR": {
                    "MAIN": "Münzakzeptor",
                    "TOTAL_COINS": "Gesamt"
                },
                "TRANSACTIONS": {
                    "MAIN": "Transaktionen"
                }
            },
            "SINGLE_DETAILS": {
                "TABS": {
                    "STATUS": "Status",
                    "SETTINGS": "Einstellungen",
                    "ADDRESS": "Adresse",
                    "TRANSACTIONS": "Transaktionen",
                    "ERRORS": "Fehler",
                    "EVENTS": "Ereignisse",
                    "MEDIA": "Medien",
                    "MAINTENANCE": "Wartung",
                    "OPERATOR_ACCESS": "Bedienerzugriffe",
                    "CONFIGURATION_CHANGES": "Konfigurationsänderungen",
                    "STOCK_VERSION": "Bestände / Versionen",
                    "IDENTIFIERS": "Bezeichner"
                }
            },
            "GENERAL": {
                "REQUEST_LOG_FILES": "Protokolldateien (Fehlerbericht)"
            },
            "TERMINAL_ICON": {
                "GENERAL": {
                    "OPEN_DETAIL": "Öffne die Detailansicht",
                },
                "MODULE": {
                    "JACKPOT_ONLINE_READY": "Modul ist verbunden und bereit für die Nutzung",
                    "JACKPOT_NOT_CONNECTED": "Modul ist nicht verbunden",
                    "ATM_ONLINE_READY": "Modul ist verbunden und bereit für die Nutzung",
                    "ATM_NOT_CONNECTED": "Modul ist nicht verbunden",
                    "ATM_CONNECTED_NOT_LOGGED_IN": "Modul is verbunden aber nicht eingeloggt",
                    "ATM_CONNECTED_NO_KEYS": "Modul ist eingeloggt, die Schlüssel wurden noch nicht ausgetauscht",
                    "ATM_DEACTIVATED": "ATM Funktionalität ist deaktiviert",
                    "ATM_NO_INFORMATION": "Es ist keine Information über die ATM Funktionalität verfügbar",
                    "COS_ONLINE_READY": "Modul ist verbunden und bereit für die Nutzung",
                    "COS_NOT_CONNECTED": "Modul ist nicht verbunden"
                },
                "CONNECTION": {
                    "TERMINAL_OFFLINE": "Terminal ist nicht mit dem ART verbunden",
                    "TERMINAL_CONNECTED": "Terminal ist mit dem ART verbunden"
                },
                "STATUS": {
                    "TERMINAL_TECHNICIAN": "Aktuell ist ein Servicetechniker eingeloggt",
                    "TERMINAL_OPERATOR": "Aktuell ist ein Bediener eingeloggt"
                },
                "TRANSACTION": {
                    "ACTIVE": "Aktuell ist eine Transaktion aktiv"
                }
            },
            "TERMINAL_IDENTIFIER": {
                "TITLE": "Terminal-Bezeichner",
                "EMERGENCY_PIN": "Notfall-PIN",
                "TABLE": {
                    "TERMINAL_ID": "Terminal ID",
                    "IP_ADDRESS": "IP-Adresse",
                    "SERIAL_NUMBER": "Serien-Nr.",
                    "MAC_ADDRESS": "MAC-Adresse",
                    "CPU_ARCH_HARDWARE": "CPU-Arch-Hardware",
                    "CPU_ARCH_SOFTWARE": "CPU-Arch-Software",
                    "NAME": "Name",
                    "LOCATION": "Standort",
                    "TIME_ZONE": "Zeitzone",
                    "SYSTEM_CURRENCY": "Systemwährung",
                    "PROPERTY_NUMBER": "Filial-Nr.",
                    "BANK_TERMINAL_ID": "Bank-Terminal-ID",
                    "KIOSK_ID": "Kiosk-ID",
                    "OPERATING_SYSTEM": "Betriebssystem",
                    "RUNTIME_ENVIRONMENT": "Laufzeitumgebung",
                    "SOFTWARE_VERSION": "Software-Version",
                    "CONFIG_VERSION": "Konfigurationsversion",
                    "ONLINE_SYSTEM": "Online-System",
                    "DISTRIBUTOR_NUMBER": "Distributor-Nr.",
                    "CUSTOMER_NUMBER": "Kunden-Nr.",
                    "LOCATION_ID": "Standort-ID",
                    "VPN_STATE": "VPN-Status"
                }
            },
            "TERMINAL_LIST": {
                "TABLE": {
                    "NAME": "Name",
                    "COS": "COS",
                    "JOS": "JOS",
                    "ATM": "Bank",
                    "STATUS": "Status",
                    "TOTAL": "Total",
                    "COIN_ACCEPTOR": "Münzakzeptor",
                    "COIN_DISPENSER": "Münzausgeber",
                    "ACCEPTOR": "Akzeptor",
                    "NOTES_IN": "Scheine Ein",
                    "TICKETS_IN": "Tickets Ein",
                    "DISPENSER": "Ausgeber",
                    "REJECT_BOX": "Rückweisungen",
                    "MEDIA_IN": "Medien Ein",
                    "MEDIA_OUT": "Medien Aus",
                    "MEDIA_DIFF": "Mediendiff.",
                    "DEPOSIT": "Pfand"
                }
            },
            "TERMINAL_ADDRESS": {
                "SPECIFIC_LOCATION": {
                    "TITLE": "Standort anpassen",
                    "LOCATION_NAME": "Standortname"
                },
                "SPECIFIC_POSTAL_ADDRESS": {
                    "TITLE": "Anschrift anpassen",
                    "ADDRESS_LINE_2": "Adresszeile 2",
                    "ADDRESS_LINE_1": "Adresszeile 1",
                    "COUNTY": "Landkreis",
                    "CITY": "Stadt",
                    "COUNTRY": {
                        "TITLE": "Land",
                        "NOT_SPECIFIED": "(Keine Angabe)"
                    },
                    "STATE": "Bundesland",
                    "ZIP_CODE": "PLZ"
                },
                "SPECIFIC_CONTACT_INFORMATION": {
                    "TITLE": "Kontaktinformation anpassen",
                    "PHONE": "Telefonnr.",
                    "EMAIL_ADDRESS": "E-Mail-Adresse"
                },
                "SPECIFIC_CONTACT_PERSON": {
                    "TITLE": "Kontaktperson anpassen",
                    "CONTACT_NAME": "Kontaktname"
                },
                "SPECIFIC_TIME_ZONE": {
                    "TITLE": "Zeitzone anpassen",
                    "TIME_ZONE": "Zeitzone"
                },
            }
        },
        "MANAGEMENT": {
            "JACKPOT_PAYOUTS": {
                "TITLE": "Jackpot Formulare",
                "MODAL": {
                    "ADD_NEW_JACKPOT_PAYOUT": "Jackpotauszahlung Formular W-2G hinzufügen",
                    "ADD_NEW_JACKPOT_PAYOUT_1042": "Jackpotauszahlung Formular 1042-S hinzufügen",
                    "ADD_NEW_JACKPOT_PAYOUT_SIGNATURE": "Jackpotauszahlung Unterschrift hinzufügen",
                    "ADD_NEW_JACKPOT_PAYOUT_CHECK": "Jackpot bestätigen",
                    "ADD_NEW_JACKPOT_PAYOUT_GENERAL_INFORMATION": "Jackpotauszahlung Allgemeine Informationen",
                    "VIEW_JACKPOT_PAYOUT": "Jackpotauszahlung ansehen",
                    "ADD_NEW_JACKPOT_PAYOUT_TIN_CHECK": "TIN Check fehlgeschlagen",
                    "ADD_NEW_JACKPOT_PAYOUT_JACKPOT_SYSTEM_LOGIN": "Light & Wonder Login",
                    "ADD_NEW_JACKPOT_PAYOUT_JACKPOT_SYSTEM_LIST": "Light & Wonder Jackpot-Liste",
                    "ADD_NEW_JACKPOT_PAYOUT_VALIDITY_CHECK_ERROR": "Fehler bei der Jackpot-Validierung",
                    "ADD_NEW_JACKPOT_PAYOUT_VALIDITY_CHECK": "Zusätzliche Validierungsparameter",
                },
                "BUTTONS": {
                    "TAKE_PICTURE": "Bild aufnehmen",
                    "SWITCH_CAMERA": "Kamera wechseln",
                    "PICK_PICTURE": "Bild auswählen",
                    "SIGNATURE_NEW": "Unterschrift hinzufügen",
                    "SIGNATURE_CLEAR": "Unterschriftsfeld leeren",
                    "CLOSE": "Schließen",
                    "SAVE": "Speichern",
                    "CONFIRM_SAVE": "Bestätigen und Speichern",
                    "CANCEL": "Abbrechen",
                    "NEXT": "Weiter",
                    "CHECK": "Überprüfen",
                    "SCAN": "Barcode scannen",
                    "CHECK_TIN": "Starte TIN Überprüfung"
                },
                "FIELDS": {
                    "GENERAL": {
                        "KEYBACK_AMOUNT": "Keyback-Betrag",
                        "PAY_AS_TICKET_AMOUNT": "Bezahlen als Ticket-Betrag",
                        "PAY_AS_CHECK_AMOUNT": "Zahlen als Scheckbetrag",
                        "PAY_AS_WALLET_AMOUNT": "Bezahlen als Wallet-Betrag",
                        "PAY_AS_WIRE_ACH_AMOUNT": "Bezahlen als Wire/ACH Betrag",
                        "LICENSE_FRONT": "Lizenz Vorderseite",
                        "LICENSE_REAR": "Lizenz Rückseite",
                        "WINNING_COMBINATION": "Gewinnende Kombination",
                        "SECONDARY_ID_FRONT": "Sekundär-ID Vorderseite",
                        "SECONDARY_ID_REAR": "Sekundär-ID Rückseite",
                        "SIGNATURE": "Unterschrift"
                    },
                    "W2G": {
                        "REPORTABLE_WINNINGS": "Meldepflichtige Gewinne",
                        "DATE_WON": "Datum des Gewinns",
                        "TYPE_OF_WAGER": "Art des Einsatzes",
                        "FEDERAL_INCOME_TAX_WITHHELD": "Einbehaltene Bundeseinkommensteuer",
                        "TRANSACTION": "Transaktion",
                        "RACE": "Rasse",
                        "WINNINGS_FROM_IDENTICAL_WAGERS": "Gewinne aus identischen Einsätzen",
                        "CASHIER": "Kassierer",
                        "WINNERS_TAXPAYER_IDENTIFICATION_NUMBER": "Steuerzahler-Identifikationsnummer des Gewinners",
                        "WINDOW": "Fenster",
                        "FIRST_IDENTIFICATION": "Erste Identifizierung",
                        "SECOND_IDENTIFICATION": "Zweite Identifizierung",
                        "STATE_PAYERS_STATE_IDENTIFICATION_NUMBER": "Staat/Staatsangehöriger Identifikationsnummer des Zahlers",
                        "STATE_WINNINGS": "Staatsgewinne",
                        "STATE_INCOME_TAX_WITHHELD": "Einbehaltene staatliche Einkommensteuer",
                        "LOCAL_WINNINGS": "Örtliche Gewinne",
                        "LOCAL_INCOME_TAX_WITHHELD": "Einbehaltene örtliche Einkommensteuer",
                        "NAME_OF_LOCALITY": "Name der Örtlichkeit",
                        "NAME": "Name",
                        "NUMBER_STREET": "Nummer/Straße",
                        "CITY_OR_TOWN": "Stadt",
                        "STATE": "Bundesstaat",
                        "ZIP_CODE": "Postleitzahl",
                        "FEDERAL_IDENTIFICATION_NUMBER": "Bundesidentifikationsnummer",
                        "TELEPHONE_NUMBER": "Telefonnummer",
                        "EMAIL": "E-Mail-Adresse"
                    }
                },
                "HEADLINES": {
                    "GENERAL_INFORMATION": "Allgemeine Informationen",
                    "PAYERS_INFORMATION": "Informationen des Zahlers",
                    "WINNERS_INFORMATION": "Information des Gewinners",
                    "SETTLEMENT_INFORMATION": "Informationen zur Abrechnung",
                    "DOCUMENT_INFORMATION": "Informationen zu Dokumenten"
                },
                "STEPS": {
                    "GENERAL": {
                        "GENERAL": "Allgemein",
                        "TAX": "Steuern",
                        "AMOUNTS": "Beträge",
                        "DOCUMENTS": "Dokumente"
                    },
                    "1042": {
                        "GENERAL_INFORMATION": "General",
                        "WITHHOLDING_INFORMATION": "Withholding",
                        "RECIPIENT_INFORMATION": "Recipient",
                        "PRIMARY_WITHHOLDING_INFORMATION": "Primary withholding",
                        "INTERMEDIARY_INFORMATION": "Intermediary",
                        "PAYER_INFORMATION": "Payer",
                        "ADDITIONAL_INFORMATION": "Additional",
                        "CONFIRM": "Bestätigen"
                    },
                    "W2G": {
                        "GENERAL_INFORMATION": "Allgemein",
                        "PAYER_INFORMATION": "Auszahler",
                        "WINNER_INFORMATION": "Gewinner",
                        "ADDITIONAL_INFORMATION": "Erweiterte ID",
                        "CONFIRM": "Bestätigen"
                    }
                }
            },
            "MANUFACTURING_ORDER_OVERVIEW": {
                "TITLE": "Fertigungsauftragsübersicht",
                "FILTER": {
                    "SERIAL_NUMBER_START": "Seriennummer von",
                    "SERIAL_NUMBER_END": "Seriennummer bis",
                    "ORDER_ID": "Auftragsnummer",
                    "ORDERS": "Aufträge",
                    "ORDER": "Auftrag",
                    "MODE": "Auswahl Abfragemodus",
                    "FIND_BY_SERIAL_NUMBER": "Suche anhand der Terminal-Seriennummer",
                    "FIND_BY_ORDER_ID": "Suche anhand der Auftragsnummer",
                },
                "TOOLBAR": {
                    "SUCCESSFUL": "Erfolgreich",
                    "INCOMPLETE": "Unvollständig"
                },
                "TABLE": {
                    "LAST_TEST_RUN_RESULT": "Ergebnis des letzten Testdurchläufe",
                },
            },
            "MANUFACTURING_ORDERS": {
                "TABLE": {
                    "ART_ID": "Fabrik",
                    "PRODUCTION_DATE": "Produktionsdatum",
                    "PRODUCTION_ID": "Produktion-ID",
                    "ORDER_NUMBER": "Auftragsnummer",
                    "PART_NUMBER": "Teilenummer",
                    "PART_DESCRIPTION": "Teilebeschreibung",
                    "SERIAL_NUMBER_FROM": "Seriennummern von",
                    "SERIAL_NUMBER_TO": "Seriennummern bis",
                    "SERIAL_NUMBERS": "Seriennummern",
                    "PARTS": {
                        "TITLE": "Teile",
                        "ID": "Teile-ID",
                        "NUMBER": "Teile-Nr.",
                    },
                    "FILES": {
                        "TITLE": "Dateien",
                        "FILE_ID": "Datei-ID",
                        "FILE_TYPE": "Dateityp",
                        "FILE_NAME": "Dateiname",
                        "INSTALLATION_PRIORITY": "Installationspriorität",
                        "LOAD_NEWEST": "Neueste laden",
                    },
                    "VALUE": "Wert",
                    "PROPERTIES": "Eigenschaften",
                },
                "DETAILS": "Auftragsdetails",
            },
            "COMPUTER_REGISTRATION": {
                "TITLE": "Computer-Registrierung",
                "TABLE": {
                },
                "MODALS": {
                    "ADD_COMPUTER": "Computer registrieren",
                    "COMPUTER": "Computer",
                    "COMPONENT": "Komponente"
                }
            },
            "KIOSK_REGISTRATION": {
                "ADD": {
                    "TERMINAL_SERIAL_NUMBER": "Terminal-Seriennummer",
                    "TERMINAL_HARD_DISK_I_D": "Terminal-Hard-Disk-ID",
                    "TERMINAL_M_A_C_ADDRESS": "Terminal-MAC-Adresse",
                    "DISTRIBUTOR_NUMBER": "Distributor-Nummer",
                    "TERMINAL_REGISTRATION_TYPE": "Registrierungstyp",
                    "REGISTRATION_TIMESTAMP": "Registrierungszeitpunkt",
                    "TERMINAL_REGISTRATION_VALIDITY_AFTER_DATE": "Registrierung gültig von",
                    "TERMINAL_REGISTRATION_VALIDITY_UNTIL_DATE": "Registrierung gültig bis",
                    "TERMINAL_TYPE": "Terminal Type",
                    "COMMENTS": "Kommentare"
                },
                "TOOLBAR": {
                    "PREFIX": "Prefix",
                    "SERIAL_NUMBER": "Seriennummer",
                    "SERIAL_NUMBER_FROM": "Seriennummer von",
                    "SERIAL_NUMBER_TO": "Seriennummer bis",
                }
            },
            "USER_MANAGEMENT": {
                "TITLE": "Benutzer",
                "TABLE": {
                    "FIRST_NAME": "Vorname",
                    "SECOND_NAME": "Nachname",
                    "EMAIL": "E-Mail-Adresse",
                    "LOGIN_NAME": "Anmeldename",
                    "RIGHTS": "Rechte",
                    "ACTIONS": "Aktionen",
                    "LAST_UPDATE": "Letzte Anpassung",
                    "CREATION_DATE": "Erstellungsdatum"
                },
                "MODALS": {
                    "ADD_USER_TITLE": "Benutzer hinzufügen",
                    "EDIT_USER_TITLE": "Benutzer bearbeiten",
                    "EDIT_PASSWORD_TITLE": "Passwort bearbeiten",
                    "PASSWORD": "Passwort",
                    "PASSWORD_CONFIRM": "Passwort bestätigen"
                },
                "BUTTONS": {
                    "ADD_USER": "Benutzer hinzufügen",
                    "DELETE_USER": "Benutzer löschen",
                    "EDIT_USER": "Benutzer bearbeiten",
                    "EDIT_PASSWORD": "Passwort bearbeiten",
                    "CANCEL": "Abbrechen",
                    "SAVE": "Speichern"
                },
                "TOOLTIPS": {
                    "SAVE_MODE_ACTIVE": "Bitte speichern Sie zuerst die geöffneten Passwortbereiche"
                },
                "TOAST": {
                    "DO_YOU_WANT_TO_DELETE_USER": "Möchten Sie den Benutzer '{{firstName}} {{lastName}}' wirklich löschen?",
                    "CONFIRM_TO_PROCEED": "Bestätigen Sie, um fortzufahren",
                    "USER_ADDED": {
                        "TITLE": "Benutzer hinzugefügt",
                        "DETAILS": "Benutzer '{{username}}' wurde hinzugefügt!"
                    },
                    "USER_UPDATED": {
                        "TITLE": "Benutzer aktualisiert",
                        "DETAILS": "Benutzer '{{username}}' wurde aktualisiert!",
                    },
                    "USER_DELETED": {
                        "TITLE": "Benutzer gelöscht",
                        "DETAILS": "Benutzer '{{username}}' wurde gelöscht!"
                    },
                    "PASSWORD": {
                        "SUCCESSFUL": "Passwort erfolgreich aktualisiert",
                        "UPDATED": "Passwort wurde aktualisiert!",
                        "NOT_SUCCESSFUL": "Passwort Aktualisierung war nicht erfolgreich",
                    },
                    "NOT_SUCCESSFUL": {
                        "TITLE": "Benutzer {{state}} war nicht erfolgreich",
                        "DETAILS": {
                            "USER_KEY_NOT_VALID": "Benutzer '{{username}}' - Ihr Sitzungsschlüssel ist ungültig",
                            "USER_HAS_NO_ENOUGH_RIGHTS": "Benutzer '{{username}}' - Sie haben nicht genügend Rechte für '{{state}}' neue Benutzer!",
                            "USERNAME_REQUIRED": "Benutzer '{{username}}' - Benutzername erforderlich!",
                            "FIRSTNAME_REQUIRED": "Benutzer '{{username}}' - Vorname erforderlich!",
                            "LASTNAME_REQUIRED": "Benutzer '{{username}}' - Nachname erforderlich!",
                            "EMAIL_INVALID": "Benutzer '{{username}}' - E-Mail-Adresse ist ungültig!",
                            "USERNAME_ALREADY_EXIST": "Benutzer '{{username}}' - Der Benutzername existiert bereits!",
                            "PASSWORD_REQUIRED": "Benutzer '{{username}}' - Passwort erforderlich!",
                            "PASSWORD_TOO_SHORT": "Benutzer '{{username}}' - Passwort zu kurz!",
                            "PASSWORD_IS_RECENT_PASSWORD": "Benutzer '{{username}}' - Das Passwort ist das aktuelle Passwort!",
                            "ADMIN_CANT_BE_DELETED": "Benutzer '{{username}}' - Der Administrator kann nicht gelöscht werden!",
                            "ADMIN_MUST_HAVE_ADMIN_RIGHTS": "Benutzer '{{username}}' - Der Administrator muss das Recht 'Admin' haben!"
                        }
                    },
                },
            },
            "OPERATORS": {
                "TITLE": "Bediener",
                "FILTER": {
                    "OPERATOR_ROLE": "Rolle",
                },
                "EXPORT": {
                    "EXPORT_ALL_OPERATOR": "Alle Bediener exportieren",
                    "ADD_NEW_OPERATOR": "Bediener hinzufügen",
                    "EXPORT_SELECTED_OPERATOR": "Ausgewählte Bediener exportieren"
                },
                "PASSWORD_RULES": {
                    "HELP": "Aktuelle Passwortbestimmungen:",
                    "STANDARD": "Kein leeres Passwort",
                    "STRONG": "Minimum eine Zahl und ein Spezialzeichen (! # $ % ' ( ) + , - . / : = ? @ €)",
                    "DOD": "Minimum eine Zahl, ein Spezialzeichen (! # $ % ' ( ) + , - . / : = ? @ €), ein Großbuchstabe und ein Kleinbuchstabe"
                },
                "TOASTS": {
                    "PASSWORD": {
                        "MATCHES": {
                            "TITLE": "Passwort stimmt überein",
                            "TEXT": "Das Passwort stimmt überein."
                        },
                        "CHANGE_SUCCESS": {
                            "TITLE": "Passwort stimmt überein",
                            "TEXT": "Das Passwort ist im Operator gespeichert. Bitte speichern Sie den Operator, wenn Sie bereit sind."
                        },
                        "TO_LOW": {
                            "TITLE": "Passwort zu schwach",
                            "TEXT": "Bitte überprüfen Sie Ihr Passwort erneut. Die Sicherheitsstufe oder Komplexität ist zu niedrig."
                        },
                        "INVALID": {
                            "TITLE": "Passwort ungültig",
                            "TEXT": "Das Passwort ist zu schwach oder stimmt nicht überein."
                        },
                    }
                },
                "TABLE": {
                    "LOGIN_NAME": "Anmeldename",
                    "OPERATOR_ROLE": "Bediener Rolle",
                    "ENTRY_CREATED": "Eintrag erstellt",
                    "LAST_MODIFIED": "Letzte Änderung",
                    "BUTTONS": {
                        "EDIT": "Editiere diesen Bediener",
                        "DELETE": "Lösche diesen Bediener"
                    }
                },
                "MODAL": {
                    "ASSIGNED_PROPERTIES": "Zugewiesene Filialen",
                    "ART_RIGHTS": "ART Rechte",
                    "ART_RIGHTS_X": "erweiterte ART-Rechte",
                    "RIGHTS": "Geräterechte",
                    "ADD_NEW_OPERATOR": "Bediener hinzufügen",
                    "EDIT_OPERATOR": "Bediener bearbeiten",
                    "DESCRIPTION": "Beschreibung",
                    "NAME": "Name",
                    "NR": "Nr",
                    "DELETE_OPERATOR": "Bediener löschen",
                    "DELETE_OPERATORS": "Bediener löschen",
                },
                "BUTTONS": {
                    "SAVE": "Speichern",
                    "DELETE": "Löschen",
                    "CANCEL": "Abbrechen",
                    "ADD": "Hinzufügen",
                    "DELETE_OPERATOR_LIST": "Ausgewählte Bediener löschen"
                },
                "EXPANDED": {
                    "DASHBOARD_RIGHTS": {
                        "TITLE": "Dashboard Rechte",
                        "PROFILE": "Dashboard Rechteprofile"
                    },
                    "OPERATOR_SETTINGS": {
                        "TITLE": "Bediener-Einstellungen",
                        "LOGIN_ALLOWED": "Anmeldung erlaubt",
                        "LOGIN_NAME": "Anmeldename",
                        "EMAIL": "E-Mail-Adresse",
                        "OPERATOR_ROLE": "Bedienerrolle",
                        "ID": "ID",
                        "NAME": "Name",
                        "POSITION_TITLE": "Position",
                        "PAGER": "Pager",
                        "ONLINE_SYSTEM": "Online-System",
                        "PASSWORD": "Passwort",
                        "PASSWORD_CONFIRMATION": "Passwort bestätigen",
                        "PASSWORD_LAST_CHANGE": "Passwort zuletzt geändert",
                        "PASSWORD_CHANGE_REQUIRED": "Passwortänderung nötig",
                        "EXTERNAL_ID": "Externe ID",
                        "EXTERNAL_ID_TYPE": "Externer-ID-Typ",
                        "BUTTONS": {
                            "CHANGE_PASSWORD": "Passwort ändern",
                            "ART_RIGHTS": "ART Rechte",
                            "ASSIGNED_PROPERTIES": "Zugewiesene Filialen",
                            "SAVE_PASSWORD": "Passwort speichern"
                        }
                    },
                    "LOGIN_SETTINGS": "Anmeldung",
                    "OPERATOR_DEVICE_SETTINGS": {
                        "TITLE": "Bediener-Geräteeinstellungen",
                        "OPERATOR_LEVEL": "Bediener-Stufe",
                        "SEPARATE_PASSWORD_DEVICE": "Separates Passwort für Geräte",
                        "SAFE_DOOR_PIN": "Tresortür-PIN",
                        "PIN": "PIN",
                        "PIN_CONFIRMATION": "PIN bestätigen",
                        "FINGERPRINTS_NONE": "Keine registrierten Fingerabdrücke!",
                        "PALMSCANS_NONE": "Keine registrierten Handflächen-Scans!",
                        "FINGERPRINTS_SUCCESS": "",
                        "PALMSCANS_SUCCESS": "",
                        "BUTTONS": {
                            "EDIT": "Bearbeiten",
                            "REASSIGN": "Neu zuweisen",
                            "RIGHTS": "Rechte",
                            "SAVE_PIN": "PIN speichern"
                        }
                    }
                }
            },
            "PLAYER_INFORMATION": {
                "TITLE": "Spieler",
                "DETAILS": {
                    "NAME": "Name",
                    "SURNAME": "Nachname",
                    "GENDER": "Geschlecht",
                    "NATIONALITY": "Nationalität",
                    "ID_TYPE": "ID-Typ",
                    "ID_NUMBER": "ID Nr.",
                    "BIRTHDAY": "Geburtstag",
                    "REGISTRATION_DATE": "Registrierungsdatum",
                    "LAST_USED": "zuletzt genutzt",
                    "MEDIA_NUMBER": "Mediennummer",
                    "STATE": "Status",
                    "NO_DATA": "Keine Spielerdetaildaten",
                },
            },
            "PAYMENT_CUSTOMER": {
                "TITLE": "Kunden",
                "CUSTOMER_BLOCK_TITLE": "Diesen Kunden sperren?",
                "CUSTOMER_UNBLOCK_TITLE": "Diesen Kunden entsperren?",
                "CUSTOMER_DELETE_TITLE": "Diesen Kunden löschen?",
                "FIRST_NAME": "Vorname",
                "MIDDLE_NAME": "Mittlerer Name",
                "LAST_NAME": "Nachname",
                "GENDER": "Geschlecht",
                "BIRTHDAY": "Geburtstag",
                "EYE_COLOR": "Augenfarbe",
                "HAIR_COLOR": "Haarfarbe",
                "PHONE_NUMBER": "Telefonnummer",
                "ADDRESS": "Adresse",
                "EXTERNAL_ID": "Externe IDs",
                "PROVIDER": "Anbieter",
                "ID": "ID #",
                "ID_TYPE": "ID-Typ",
                "IDENTITY_DOCUMENT": "Ausweisdokument",
                "TYPE": "Typ",
                "NUMBER": "Nummer",
                "ISSUER": "Aussteller",
                "ISSUE_DATE": "Ausstellungsdatum",
                "EXPIRATION_DATE": "Ablaufdatum",
                "NO_CUSTOMER_DETAIL_DATA": "Keine Kundendetaildaten vorhanden",
                "REGISTRATION_DATE": "Registrierungsdatum",
                "REGISTERED_AT": "Registriert an",
                "CUSTOMER_SEARCH": "Kundensuche",
                "SSN": "SSN",
                "COUNTRY": "Land",
                "COUNTY": "Bundesland",
                "TOWN": "Stadt",
                "STREET": "Straße, Hausnr.",
                "ZIP_CODE": "Postleitzahl",
                "ADDRESS_EXTRA": "Adresszusatz",
                "EMAIL": "E-Mail-Adresse"
            },
            "TERMINAL_LIFECYCLE": {
                "TITLE": "Terminal Lebenslaufakte",
                "FILTER": {
                    "SERIAL_NUMBER": "Seriennummer",
                    "PART_NUMBER": "Artikelnummer",
                },
                "CARD": {
                    "MACHINE_TYPE": "Maschinentyp",
                    "FACTORY": "Fabrik",
                    "CUSTOMER": "Kunde",
                    "LIFECYCLE_CREATED": "Lebenszyklus erstellt",
                    "RECORD_TYPE": "Aufnahmetyp",
                    "ART_INSTALLATION": "ART-Installation",
                    "DEVICE_OVERVIEW": "Geräteübersicht",
                    "DEVICES": "Geräte",
                    "BUILD_LOCATION": "Build-Standort",
                    "TEST_RUN": "Testdurchläufe",
                    "SHOW_TEST_RUN": "Testdurchläufe anzeigen ({{nr}})",
                },
                "TABLE": {
                    "ART_INSTALLATION_ID": "ART-Installation-ID",
                    "LIFECYCLE_CREATED": "Eintrag angelegt",
                    "LIFECYCLE_TYPE": "Lebenslaufstatus",
                    "TEST_RUN_MAIN": {
                        "TEST_RUN_TYPE": "Testläufe-Typ",
                        "CREATED": "Erstellt",
                        "COMPLETED": "Fertiggestellt",
                        "OPERATOR": "Bediener",
                        "PREVIOUS_OPERATOR": "Vorheriger Bediener",
                        "SEALED": "Versiegelt",
                        "TEST_RESULT": "Testergebnis",
                        "MESSAGES": "Mitteilungen",
                        "TEST_CASES": "Testfälle",
                    },
                    "TEST_RUN_MESSAGES": {
                        "MESSAGE_CODE": "Nachrichten-Code",
                        "MESSAGE_ID": "Nachrichten-ID",
                        "CREATED": "Erstellt",
                        "SEVERITY": "Schweregrad",
                        "MESSAGE_ENG": "Nachricht (EN)",
                    },
                    "TEST_CASES": {
                        "NAME": "Name",
                        "TEST_RESULT": "Testergebnis",
                        "STARTED": "Gestartet",
                        "COMPLETED": "Fertiggestellt",
                        "DEVICE_IDENTIFIER": "Gerätekennung",
                        "DEVICE_ID": "Geräte-ID",
                        "COMPONENT_ID": "Komponenten-ID",
                        "DEVICE_INFORMATION": "Geräteinformation",
                        "DEVICE_NAME": "Gerätename",
                        "DEVICE_CONNECTION_TYPE": "Geräteverbindungstyp",
                        "DEVICE_DETAILS": "Geräte-Details",
                        "TEST_CASE_STEPS": "Testfallschritte",
                        "MESSAGES": "Mitteilungen",
                    },
                    "TEST_CASES_STEPS": {
                        "NAME": "Name",
                        "TEST_RESULT": "Testergebnis",
                        "STARTED": "Gestartet",
                        "COMPLETED": "Fertiggestellt",
                        "DEVICE_IDENTIFIER": "Gerätekennung",
                        "OPERATOR": "Bediener",
                        "MESSAGES": "Mitteilungen",
                    }
                },
                "DIALOG": {
                    "TITLE": "Lebenslaufakte für Maschine"
                },
                "TOAST": {
                    "NO_ENTRIES_FOUND": {
                        "TITLE": "Keine Einträge gefunden",
                        "BODY": "Für die angegebene Seriennummer wurden keine Einträge gefunden."
                    },
                }
            },
            "LICENSE_KEY_USAGE_OVERVIEW": {
                "TABLE": {
                    "LICENSE_KEY": "Lizenzschlüssel",
                    "ART_INSTALLATIONS_COUNT": "Anzahl Installationen",
                    "INSTALLATION_NAME": "Installationsname",
                    "TERMINAL_COUNT": "Anzahl Terminals",
                },
            }
        },
        "LOGS": {
            "TRANSACTION": {
                "TITLE": "Transaktionsprotokoll",
                "FILTER": {
                    "TRANSACTION": "Transaktionsart",
                    "TRANSACTION_CATEGORY": {
                        "BY_OPERATOR": "von Bedienern",
                        "BY_CUSTOMER": "von Kunden",
                        "ALL_ATM_TRANSACTIONS": "nur GA-Transaktionen",
                        "ALL_JACKPOT_TRANSACTIONS": "nur Jackpot-Transaktionen",
                        "ALL_MAGNETIC_CARD_TRANSACTIONS": "nur Magnetkartentransaktionen",
                        "ALL_SMART_CARD_TRANSACTIONS": "nur Chipkartentransaktionen",
                        "ALL_TICKET_TRANSACTIONS": "nur Ticket-Transaktionen",
                        "ALL_LOANS_TRANSACTIONS": "nur Darlehen-Transaktionen",
                        "ALL_E_WALLET_TRANSACTIONS": "nur eWallet-Transaktionen",
                        "ALL_PAYMENT_TRANSACTIONS": "nur Payment-Transaktionen"
                    },
                    "FOOTER": {
                        "MEDIA_NUMBER": "Medien Nr.",
                        "CURRENCY_ALL": "Alle Währungen",
                        "CLEAR_FILTER": "Zurücksetzen",
                        "FULL_PERIOD": "Volle Periode"
                    }
                },
                "CONTEXT_MENU": {
                    "SHOW_DETAILS": "Details anzeigen",
                    "SHOW_EVENTS": "Ereignisse anzeigen"
                },
                "TABLE": {
                    "MEDIA_NUMBER": "Medien#",
                    "TOTAL_IN": "Gesamt ein",
                    "TOTAL_OUT": "Gesamt aus",
                    "ACTION": "Aktion",
                    "FLAGS": "Markierungen",
                    "SHOW_DETAILS": "Details anzeigen",
                    "SEARCH_TRANSACTION_BY": "Suche Transaktion mittels"
                },
                "MODAL": {
                    "GENERAL_INFO": {
                        "TITLE": "Allgemein",
                        "TERMINAL": "Terminal",
                        "DATE": "Datum",
                        "TRANSACTION_TYPE": "Transaktionstyp",
                        "TRANSACTION_NUMBER": "Transaktionsnummer",
                        "TOTAL_MEDIA_IN": "Eingenommene Werte",
                        "TOTAL_MEDIA_OUT": "Ausgegebene Werte",
                        "OPERATOR": "Bediener",
                        "FLAGS": "Kennzeichen"
                    },
                    "MEDIA_IN": {
                        "TITLE": "Eingenommene Werte",
                        "TABLE": {
                            "MEDIUM": "Medium",
                            "MEDIA_NUMBER": "Medien #",
                            "PIECES": "Stück",
                            "AMOUNT": "Gesamt"
                        }
                    },
                    "MEDIA_OUT": {
                        "TITLE": "Ausgegebene Werte",
                        "TABLE": {
                            "MEDIUM": "Medium",
                            "MEDIA_NUMBER": "Medien #",
                            "PIECES": "Stück",
                            "AMOUNT": "Gesamt"
                        }
                    },
                    "ADDITIONAL_INFO": {
                        "TITLE": "Additional Info",
                        "TABLE": {
                            "VALUE": "Wert",
                            "TYPE": "Typ"
                        },
                        "MODAL": {
                            "GIVEN_NAME": "Vorname",
                            "SURNAME": "Nachname",
                            "BIRTHDAY": "Geburtstag",
                            "MEDIA_NUMBER": "Mediennummer",
                            "I_D_NUMBER": "ID-Nummer",
                            "I_D_TYPE": "ID-Type",
                            "GENDER": "Geschlecht",
                            "NATIONALITY": "Nationalität",
                            "STATUS": "Status"
                        }
                    },
                    "EXCHANGE_RATE": {
                        "TITLE": "Wechselkurse",
                        "TABLE": {
                            "SOURCE": "Quelle",
                            "TARGET": "Ziel",
                            "EXCHANGE_RATE": "Wechselkurs"
                        }
                    },
                    "IMAGES": "Bilder"
                },
                "SEARCH": {
                    "TITLE": "Transaktionssuche",
                    "TABLE": {
                        "TRANSACTION_NUMBER": "Transaktions-Nr."
                    },
                    "CRITERION": {
                        "TRANSACTION_NUMBER": "Transaktions-Nr.",
                        "MEDIA_NUMBER": "Medien-Nr.",
                        "MEDIA_NUMBER_MULTI_MEDIA": "Medien-Nr. (Multi Medien)",
                        "MEDIA_NUMBER_MULTI_TICKET_IN": "Medien-Nr. (Multi-Ticket ein)",
                        "MEDIA_NUMBER_MULTI_TICKET_OUT": "Medien-Nr. (Multi-Ticket aus)",
                        "SLOT_MACHINE_NUMBER": "Slot-Machinen-Nr.",
                        "ONLINE_TRANSACTION_NUMBER": "Online-Transaktions-Nr.",
                        "JACKPOT_TRANSACTION_NUMBER": "Jackpot-Transaktions-Nr.",
                        "JACKPOT_TICKET_NUMBER": "Jackpot-Ticket-Nr.",
                        "CEC": "CEC-Transaktions-Nr.",
                        "BANK_TRANSACTION_NUMBER": "Bank-Transaktions-Nr.",
                        "ISSUER": "Aussteller",
                        "EXTERNAL_TRANSACTION_NUMBER": "Externe Transaktions-Nr."
                    }
                }
            },
            "ERROR": {
                "TITLE": "Fehlerprotokoll",
                "FILTER": {},
                "TABLE": {},
            },
            "OPERATOR_ACCESS": {
                "TITLE": "Bedienerzugriffsprotokoll",
                "FILTER": {},
                "TABLE": {
                    "OPERATOR_ID": "Bediener-ID",
                    "OPERATOR_NAME": "Bedienername",
                    "ACCESS_FROM": "Zugriff von",
                    "ACCESS_ON": "Zugriff am"
                }
            },
            "CONFIGURATION_CHANGE": {
                "TITLE": "Konfigurationsänderungsprotokoll",
                "FILTER": {
                    "GENERAL_CONFIGURATION": "Allgemeine Konfiguration",
                    "CUSTOM_SELECTION": "angepasste Auswahl"
                },
                "TABLE": {
                    "CONFIGURATION": "Konfiguration",
                    "CONFIGURATION_KEY": "Konfig.schlüssel",
                    "OLD_VALUE": "Alter Wert",
                    "NEW_VALUE": "Neuer Wert",
                    "CHANGE_DATE": "Änderungsdatum",
                    "RESPONSIBLE_OPERATOR_ID": "Verantwortl. Bediener (ID)",
                    "RESPONSIBLE_OPERATOR": "Verantwortl. Bediener"
                },
                "TERMINALS_ALL": "Alle",
                "TERMINAL_UNDEFINED": "Unbekannt"
            },
            "EVENT": {
                "TITLE": "Ereignisprotokoll",
                "FILTER": {
                    "EVENT_TYPE": "Event type:",
                    "SOURCE": "Quelle",
                    "SEVERITY": "Schweregrad"
                },
                "TABLE": {
                    "MESSAGE": "Nachricht",
                    "SOURCE": "Quelle",
                    "CODE": "Code",
                    "LEVEL": "Schweregrad"
                }
            },
            "MEDIA": {
                "TITLE": "Medienprotokoll",
                "FILTER": {},
                "TABLE": {
                    "TRANSACTION_NUMBER": "Transaktionsnr.",
                    "AMOUNT": "Betrag",
                    "CURRENCY": "Währung",
                    "MEDIA": "Medium",
                    "MEDIA_NUMBER": "Mediumnr.",
                    "DIRECTION": "Richtung"
                },
                "MEDIUM_ACTION": {
                    "NONE": " ",
                    "REDEEMED": "Eingelöst",
                    "STACKED": "Einbehalten",
                    "REJECTED": "Abgelehnt",
                    "DISPENSED": "Ausgegeben",
                    "ISSUED": "Ausgestellt",
                    "UNABLE_TO_PAY": "Nicht zahlbar"
                }
            },
            "ART_EVENT": {
                "TITLE": "ART Ereignisprotokoll",
                "FILTER": {
                    "EVENT_TYPE": {
                        "TITLE": "Schweregrad",
                        "INFORMATION": "Informationen",
                        "WARNING": "Warnung",
                        "FAILURE": "Fehler"
                    },
                    "EVENT_SOURCE": {
                        "TITLE": "Ereignisquelle"
                    }
                },
                "TABLE": {
                    "DATE": "Ereignisdatum",
                    "EVENT": "Ereignis",
                    "SOURCE": "Ereignisquelle",
                    "SEVERITY": "Schweregrad",
                    "LEVEL": "Level"
                }
            },
            "MAINTENANCE": {
                "TITLE": "Wartungsprotokoll",
                "FILTER": {},
                "TABLE": {
                    "ACTIVITY": "Aktivität",
                    "COMPONENT": "Komponente",
                    "RANK": "Rang",
                    "COMMENT": "Kommentar"
                }
            }
        },
        "SETTINGS": {
            "CONFIGURATION_MANAGEMENT": {
                "TITLE": "Konfigurationseinträge",
                "TABLE": {
                    "CONFIGURATION_KEY": "Konfigurationsschlüssel",
                    "CONFIGURATION_VALUE": "Konfigurationswert",
                    "DEFAULT_VALUE": "Standardwert",
                    "CATEGORY": "Kategorie"
                },
                "BUTTONS": {
                    "RESET_ALL_TO_DEFAULT": "Änderungen auf Standard zurücksetzen",
                    "SAVE_ALL_CHANGES": "Alle Änderungen speichern",
                    "RESET_ALL_CHANGES": "Alle Änderungen zurücksetzen"
                },
                "MODALS": {
                    "TITLE": "Konfigurationsänderung bestätigen",
                    "TABLE": {
                        "CONFIGURATION_KEY": "Konfigurationsschlüssel",
                        "CONFIGURATION_VALUE_OLD": "alter Wert",
                        "CONFIGURATION_VALUE_NEW": "neuer Wert",
                        "DEFAULT_VALUE": "Standardwert",
                        "ACTION": "Aktion"
                    },
                    "BUTTONS": {
                        "CANCEL": "Abbrechen",
                        "RESET_ALL_CHANGES": "Alle Änderungen zurücksetzen",
                        "SAVE_ALL_CHANGES": "Alle Änderungen speichern"
                    }
                }
            }
        },
        "BALANCING": {
            "TITLE": "Abrechnungsbericht",
            "FILTER": {},
            "SNAPSHOT_TYPES": {
                "MODAL": {
                    "TITLE": "Wähle Bilanzierungsendpunkt"
                }
            },
            "MONEY_SERVICE_LIST": {
                "TITLE": "Bestandsaufnahmen",
                "FULL": "Voll"
            },
            "MONEY_SERVICE_REMOVAL": {
                "DATE": "Datum",
                "SAVED_BY": "Gespeichert von",
                "OF": "von",
                "HOPPERS": "Münzausgeber",
                "DISPENSER_BOXES": "Ausgabe Boxen",
                "ACCEPTOR": "Akzeptor",
                "REJECT_BOX": "Rückweisungen",
                "ENTRY": "Eintrag",
                "COUNT": "Anzahl",
                "TOTAL": "Gesamt"
            },
            "REPORT": {
                "OPENING_BALANCE": "Eröffnungsbilanz",
                "CLOSING_BALANCE": "Schlussbilanz",
                "CUSTOMER_TURNOVER": "Kundenumsatz",
                "OPERATOR_TURNOVER": "Bedienerumsatz",
                "CASH_BALANCE": "Bargeldbilanz",
                "CARD_FLOW": "Kartenumsatz",
                "REMOVED_FROM_MACHINE": "Aus Maschine entnommen",
                "INSERTED_TO_MACHINE": "Maschine befüllt mit",
                "OPENING": "Eröffnungsbilanz",
                "BALANCE": "Bilanz",
                "CLOSING": "Schlussbilanz",
                "COIN_DISPENSER": "Münzausgeber",
                "COIN_ACCEPTOR": "Münzakzeptor",
                "BILL_DISPENSER": "Scheinausgeber",
                "CASSETTES": "Kassetten",
                "REJECT_BOX": "Rückweisungen",
                "BILL_ACCEPTOR": "Scheinakzeptor",
                "BILLS": "Scheine",
                "TICKETS": "Tickets",
                "TOTAL": "Gesamt",
                "COINS": "Münzen",
                "TOTAL_CASH": "Bargeld gesamt",
                "MEDIA_CHARGED": "Medien aufgeladen",
                "DEPOSIT_WITHHELD": "Pfand einbehalten",
                "CREDITS_CLEARED": "Kredit gelöscht",
                "ROUNDING_DIFFERENCES": "Rundungsdifferenzen",
                "ATM": "ATM",
                "ATM_FEES": "ATM-Gebühren",
                "POS_FEES": "POS-Gebühren",
                "IOU": "IOU",
                "DONATIONS": "Spenden",
                "LOANS_PAID_BACK": "Zurückgez. Darlehen",
                "TAX_WITHHELD": "Steuer einbehalten",
                "DEPOSIT_PAID": "Pfand ausgezahlt",
                "JACKPOT": "Jackpot",
                "JACKPOT_OTHER": "Jackpot (andere)",
                "POS": "POS",
                "CEC": "CEC",
                "COINS_DISPENSER": "Münzen (Ausgeber)",
                "COINS_ACCEPTOR": "Münzen (Akzeptor)",
                "BILLS_DISPENSER": "Scheine (Ausgeber)",
                "BILLS_ACCEPTOR": "Scheine (Akzeptor)",
                "HOPPER_DIFFERENCE": "Differenz Münzausgeber",
                "CUSTOMER_INSERTED": "Kunde eingezahlt",
                "CUSTOMER_REMOVED": "Kunde ausgezahlt",
                "OPERATOR_INSERTED": "Bediener befüllt",
                "OPERATOR_REMOVED": "Bediener entnommen",
                "COUNT_DIFF": "Zähldifferenz",
                "CARDS_IN_STACKER": "Karten im Magazin",
                "CARDS_IN_BIN": "Karten in Auffangbox",
                "OPENING_STOCK": "Eröffnungsbestand",
                "INSERTED_TO_BIN": "In Auffangbox",
                "REMOVED_FROM_BIN": "Aus Auffangbox",
                "INSERTED_TO_STACKER": "Ins Magazin",
                "REMOVED_FROM_STACKER": "Aus Magazin"
            },
        },
        "STATISTICS": {
            "ART_OVERVIEW": {
                "TITLE": "ART Übersicht",
                "ICONS": {
                    "TERMINAL_COUNT": "Terminalanzahl",
                    "LAST_SYNCHRONISATION": "Letzte Synchronisation",
                    "ADDRESS": "Adresse",
                    "OPERATORS_COUNT": "Benutzeranzahl",
                    "PROPERTIES_COUNT": "Filialanzahl",
                    "INSTALLATION_MODE": "Installationsmodus",
                },
                "BUTTONS": {
                    "QUARTERLY_DASHBOARD": "Quartals- Dashboard",
                    "ART_DETAILS": "ART Details",
                    "ART_INSTALLATION_MODE": "Installations-Modus",
                    "CONNECTION": "Verbindung"
                },
                "ART_CONNECTION": {
                    "RECENTLY_SEEN": "Kürzlich gesehen",
                    "RECENTLY_SYNCHRONIZED": "Kürzlich synchronisiert"
                },
                "SWITCHER": {
                    "CARD_VIEW": "Kartenansicht",
                    "TABLE_VIEW": "Tabellenansicht"
                },
                "ART_INSTALLATION_MODE": {
                    "IN_DEVELOPMENT": "In Entwicklung",
                    "IN_QUALITY_ASSURANCE": "In Qualitätssicherung",
                    "IN_TEST_LAB_USE": "In Test-Labor",
                    "IN_PRODUCTION_USE": "In Produktion",
                    "DECOMMISSIONED": "Außer Betrieb genommen",
                    "NEW": "Neu",
                    "SHOWROOM": "Ausstellung",
                    "FACTORY": "Fabrik"
                },
                "MONTHLY": {
                    "TOTAL": "Gesamt",
                    "TOTAL_BILL_BREAK": "Gesamt Notenwechsel",
                    "TOTAL_REDEMPTION": "Gesamt Auszahlung",
                    "TOTAL_CHARGE": "Gesamt Aufgeladen",
                    "TOTAL_CLEARED_CREDIT": "Gesamt Offene Kredite gelöscht",
                    "TOTAL_DONATIONS": "Gesamt Spenden"
                },
                "MODALS": {
                    "GO_TO_WEBSITE": "Zur Website gehen: {{ Website }}",
                    "BUTTONS": {
                        "EDIT_ART_INSTALLATION": "ART-Installation bearbeiten",
                        "SHOW_WEBSITE": "Website anzeigen"
                    },
                    "CARD_TITLES": {
                        "LOCATION": "Standort",
                        "ART_STATISTICS": "ART Statistiken",
                        "ART_CONFIGURATION": "ART Konfigurationen",
                        "ENVIRONMENT_INFORMATION": "Umgebungsvariablen",
                        "BASE_LICENSES": "Basislizenz",
                        "MODULE_LICENSES": "Modullizenzen",
                    },
                    "LOCATION": {
                        "ADDRESS": "Adresse",
                        "COUNTRY": "Land",
                        "STATE": "Bundesland",
                        "ZIP_CODE": "PLZ",
                        "STREET": "Adresse 1",
                        "CITY": "Stadt",
                        "ADDRESS_EXTENDED": "Adresse 2",
                        "COUNTY": "Landkreis"
                    },
                    "ART_STATISTICS": {
                        "TERMINAL_COUNT": "Terminalanzahl",
                        "TERMINAL_COUNT_DATA": '{{ ConnectedTerminalsCount }} / {{ TerminalsCount }} Terminals',
                        "OPERATORS_COUNT": "Benutzeranzahl",
                        "OPERATORS_COUNT_DATA": "{{ LoggedInOperatorsCount }} / {{ OperatorsCount }} Benutzer ({{ CurrentSessionsCount }} Sitzungen)",
                        "LAST_SYNCHRONISATION": "Letzte Synchronisation",
                        "FIRST_SYNCHRONISATION": "Erste Synchronisation",
                        "OPERATORS": "Benutzer",
                        "SESSIONS": "Verbindungen"
                    },
                    "ART_CONFIGURATION": {
                        "VERSION": "Version",
                        "CURRENCY_CODE": "Währung",
                        "ID": "ID"
                    },
                    "ENVIRONMENT_INFORMATION": {
                        "RUNTIME_VERSION": "Laufzeitversion",
                        "DOT_NET_VERSION": ".NET-Version",
                        "OS_BUILD_VERSION": "OS Build-Version",
                        "OS_NAME": "OS-Name",
                        "OS_VERSION": "OS-Version",
                        "SQL_SERVER_VERSION": "SQL-Server-Version",
                        "COMPUTER_NAME": "Computername",
                        "IP_ADDRESS": "IP-Adresse"
                    },
                    "BASE_LICENSES": {
                        "REGISTERED": "Registriert",
                        "TERMINAL_COUNT": "Terminalanzahl",
                        "ART_CLIENT_COUNT": "ART Client-Zahl",
                        "PROPERTY_NUMBER": "Filial-Nr.",
                        "BASE_LICENSE_NUMBER": "Basislizenz #",
                        "UNLIMITED": "Unbegrenzt",
                        "TRIAL_LICENSE": "Test-Lizenz"
                    },
                    "MODULE_LICENSES": {
                        "ADVERTISING": "Werbung",
                        "PREVENTIVE_MAINTENANCE": "Vorbeugende Wartung",
                        "PROPERTY_MANAGEMENT": "Filialverwaltung",
                        "CARD_BURNING": "Karten brennen",
                        "SHOP_MANAGEMENT": "Shopverwaltung",
                        "CURRENCY_COUNTING": "Währungszählung",
                        "MULTI_CURRENCY": "Mehrere Währungen",
                        "REMOTE_ACCESS": "Fernzugriff",
                        "DEBIT_ACCOUNTS": "Debit-Konten",
                        "FACE_RECOGNITION": "Gesichtserkennung",
                        "STATES": {
                            "ACTIVE": "Aktiv",
                            "NOT_ACTIVE": "Inaktiv",
                            "PROPERTIES": "{{x}} Filialen",
                            "SHOPS": "Verkaufsstellen",
                            "ALLOWED_TERMINALS": "{{x}} erlaubte Terminals",
                        }
                    },
                    "TERMINALS": {
                        "TABLE": {
                            "ID": "ID",
                            "TERMINAL": "Terminal",
                            "TERMINAL_TYPE": "Terminaltyp",
                            "VERSIONS": "Versionen",
                            "SCVERSION": "Software Version / Revisionsnr."
                        },
                        "VERSIONS": {
                            "TITLE": "Versionen",
                            "TABLE": {
                                "NAME": "Name",
                                "VERSION_TYPE": "Versionstyp",
                                "VERSION": "Version"
                            },
                        }
                    },
                    "TERMINAL_STATISTICS": {
                        "TITLE": "Terminalstatistiken",
                        "FIRST_SEEN": "Zuerst Gesehen",
                        "LAST_SEEN": "Zuletzt Gesehen",
                        "LIFE_CYCLE_STEP": "Lebenszyklus"
                    },
                    "TERMINAL_CONFIGURATION": {
                        "TITLE": "Terminalkonfiguration",
                        "ID": "ID",
                        "TYPE": "Typ",
                        "NAME": "Name",
                        "TIMEZONE": "Zeitzone",
                        "MAC_ADDRESS": "MAC-Adresse",
                        "SERIAL_NUMBER": "Seriennummer",
                        "SYSTEM_CURRENCY": "Systemwährung",
                        "DISTRIBUTOR_NUMBER": "Distributor-Nr.",
                        "CUSTOMER_NUMBER": "Kunden-Nr",
                        "PROPERTY_NUMBER": "Filial-Nr.",
                        "LOCATION_ID": "Standort-ID",
                    },
                    "CONTACT_INFORMATION": {
                        "TITLE": "Kontaktinformationen",
                        "EMAIL_ADDRESS": "E-Mail-Adresse",
                        "PHONE_NUMBER": "Telefonnummer",
                        "TIMEZONE": "Zeitzone",
                        "LOCATION_NAME": "Standortname"
                    },
                    "CONTACT_PERSON": {
                        "TITLE": "Kontaktperson",
                        "NAME": "Name",
                    },
                    "PROPERTY_INFORMATION": {
                        "TITLE": "Filialinformationen",
                        "NO": "Nummer",
                        "NAME": "Name",
                        "DESCRIPTION": "Beschreibung",
                        "DEFAULT": "Standard"
                    }
                }
            },
            "CHIP_TURNOVER": {
                "TITLE": "Chip-Umsatz",
                "MAIN_TABLE": {
                    "TERMINAL": "Terminal",
                    "START_TIME": "Startzeit",
                    "END_TIME": "Endzeit",
                    "WIN_OR_LOSS": "Gewinn/Verlust",
                    "OPENING_INV": "Anfangsbestand",
                    "FILLS": "Befüllungen",
                    "CREDITS": "Entnahmen",
                    "CLOSING_INV": "Schlussbestand",
                    "TOTAL": "Gesamt"
                },
                "DETAILS": {
                    "TABLE": {
                        "ENTRY_DATE": "Datum/Zeit",
                        "TYPE": "Typ",
                        "VALUES": "Werte",
                        "SUM": "Summe",
                        "RESPONSIBLE_OPERATOR_ID": "Verantw. Bediener",
                        "ENTRY_SOURCE": "Quelle"
                    }
                }
            },
            "MAINTENANCE_OVERVIEW": {
                "TITLE": "Wartungsübersicht",
                "CLEANINGS": "Reinigungen",
                "MAINTENANCES": "Wartungen",
                "CLEANING": "Reinigung",
                "MAINTENANCE": "Wartung",
                "TERMINALS": "Terminals",
                "TERMINALS_COUNT": "Terminalanzahl",
                "SHOW_DETAILS": "Details",
                "DETAILS": {
                    "DAYS_SINCE_MAINTENANCE": "Tage seit letzter Wartung",
                    "TIME_WARNING": "Zeit Warnung",
                    "TIME_MANDATORY": "Zeit Obligatorisch",
                    "METER_VALUE": "Laufzeitwerte Wert",
                    "METER_WARNING": "Laufzeitwerte Warnung",
                    "METER_MANDATORY": "Laufzeitwerte Obligatorisch"
                }
            },
            "QUARTERLY_DASHBOARD": {
                "TITLE": "Quartals-Dashboard",
                "TABLE": {
                    "UNIT": "Einheiten",
                    "INSTALLATION": "ART"
                },
                "TITLES": {
                    "UPTIME": "Terminalverfügbarkeit für ",
                    "TOTAL_TRANSACTIONS": "Gesamte Transaktionen für",
                    "AVERAGE_TRANSACTIONS": "Durchschnittliche Transaktionen für"
                },
                "SWITCHER": {
                    "YEAR": "Jahr",
                    "UPTIME": "Verfügbarkeit",
                    "TRANSACTION_TOTALS": "Gesamte Transaktionen",
                    "TRANSACTION_AVERAGES": "Durchschnittliche Transaktionen"
                },
                "BUTTONS": {
                    "LOAD": "Laden"
                }
            },
            "ONLINE_SYSTEM_STATISTICS": {
                "TITLE": "Online-System-Statistiken",
                "MAIN_TABLE": {
                    "TITLE": "Summen pro Online-System",
                    "REDEEMED_TICKETS_COUNT": "Anzahl eingelöster Tickets",
                    "REDEEMED_TICKETS_AMOUNT": "Betrag eingelöster Tickets",
                    "ISSUED_TICKETS_COUNT": "Anzahl ausgestellter Tickets",
                    "ISSUED_TICKETS_AMOUNT": "Betrag ausgestellter Tickets",
                    "REDEEMED_CARDS_COUNT": "Anzahl eingelöster Karten",
                    "REDEEMED_CARDS_AMOUNT": "Betrag eingelöster Karten",
                    "CHARGED_CARDS_COUNT": "Anzahl aufgeladener Karten",
                    "CHARGED_CARDS_AMOUNT": "Betrag aufgeladener Karten"
                },
                "DETAIL_TABLE": {
                    "TITLE": "Medien",
                    "TRANSACTION_NUMBER": "Trans.- Nr.",
                    "TRANSACTION_DATE": "Datum",
                    "MEDIUM_TYPE": "Medium",
                    "MEDIA_NUMBER": "Medien-Nr.",
                    "AMOUNT": "Betrag",
                    "MEDIUM_DIRECTION": "Medium Richtung"
                }
            },
            "TRANSACTION_COUNTS": {
                "TITLE": "Transaktionszahlen",
                "TABLE": {
                    "TERMINAL": "Terminal",
                    "COUNT_OF_TRANSACTIONS": "Zahl der Transaktionen",
                    "DURATION_FULL_TIME": "Gesamtmittelwert",
                    "DAYS_FULL_TIME": "Tage gesamt",
                    "DURATION_ACTIVE_TIME": "Aktivmittelwert",
                    "DAYS_ACTIVE": "Tage aktiv",
                }
            },
            "TRANSACTION_STATISTIC": {
                "TITLE": "Transaktionsstatistiken",
                "BUTTONS": {
                    "GENERAL": "Allgemein",
                    "JACKPOT": "Jackpot",
                    "CUSTOMER_TRANSACTIONS": "Kundentransaktionen",
                    "MONEY_SERVICE": "Bestandsaufnahme",
                    "OPERATOR_TRANSACTIONS": "Bedienertransaktionen"
                },
                "TABLE": {
                    "TITLE": "Summen pro Transaktionskategorie/-typ",
                    "TYPE": "Transaktionstyp",
                    "QTY": "Anzahl",
                    "INSERTIONS": "Einnahmen",
                    "REMOVALS": "Ausgaben",
                },
                "CARDS": {
                    "INSERTIONS_TYPES": "Einnahmen - Typen",
                    "REMOVALS_TYPES": "Ausgaben - Typen",
                    "INSERTIONS_CUSTOMER_TRANSACTIONS": "Einnahmen - Kundentransaktionen",
                    "REMOVALS_CUSTOMER_TRANSACTIONS": "Ausgaben - Kundentransaktionen",
                    "INSERTIONS_JACKPOTS": "Einnahmen - Jackpot",
                    "REMOVALS_JACKPOTS": "Ausgaben - Jackpot",
                    "INSERTIONS_MONEY_SERVICE": "Einnahmen - Bestandsaufnahme",
                    "REMOVALS_MONEY_SERVICE": "Ausgaben - Bestandsaufnahme",
                    "INSERTIONS_OPERATOR_TRANSACTIONS": "Einnahmen - Bedienertransaktionen",
                    "REMOVALS_OPERATOR_TRANSACTIONS": "Ausgaben - Bedienertransaktionen"
                },
                "CATEGORIES": {
                    "NONE": "Nicht kategorisiert",
                    "CUSTOMER_TRANSACTIONS": "Kundentransaktionen",
                    "JACKPOT": "Jackpot",
                    "MONEY_SERVICE": "Bestandsaufnahme",
                    "OPERATOR_TRANSACTIONS": "Bedienertransaktionen",
                    "INTERNAL": "Intern"
                }
            },
            "TERMINAL_AVAILABILITY": {
                "TITLE": "Terminal-Verfügbarkeit",
                "FILTER": {
                    "GRAPH_FOR_EVERY_TERMINAL": "Zeige Graphen für einzelne Terminals",
                    "GRAPH_SEPARATED_OPERATOR": "Separate Reihe für Bedienernutzung",
                    "PERIOD_DAY": "Wähle den Tag"
                },
                "GRAPH": {
                    "AVAILABLE": "Verfügbar",
                    "ERROR": "Fehler",
                    "OFF": "Aus",
                    "REFERENCE": "Referenz",
                    "AVERAGE": "Durchschnitt",
                    "OPERATOR": "Bediener"
                }
            },
            "TERMINAL_STATE": {
                "TITLE": "Terminal-Status"
            },
            "MEDIA_CASH": {
                "TITLE": "Bargeld Statistiken",
                "FILTER": {
                    "WITH_OPERATOR_TRANSACTION": "inklusive Bedienertransaktionen"
                },
                "CARD_TITLES": {
                    "BILLS_PAID_OUT": "Noten Ausgezahlt",
                    "BILLS_KEPT_IN": "Noten Einbehalten",
                    "COINS_PAID_OUT": "Münzen Ausgezahlt",
                    "COINS_KEPT_IN": "Münzen Einbehalten"
                },
                "TABLE": {
                    "CURRENCY": "Währung",
                    "DENOMINATION": "Stückelung",
                    "PIECES": "Stück",
                }
            },
            "MEDIA_CARD": {
                "TITLE": "Karten Statistiken",
                "CARD_TITLES": {
                    "MAGNETIC_CARD": "Magnetkarte",
                    "SMART_CARD": "Chipkarte",
                    "RFID_CARDS": "RFID-Karten",
                    "PREPAID_CARDS": "Prepaid-Karten",
                },
                "TABLE": {
                    "STATUS": "Status",
                    "COUNT": "Anzahl",
                    "AMOUNT": "Wert",
                    "CURRENCY": "Währung",
                }
            },
            "CARD_TURNOVER": {
                "TITLE": "Kartenumsatz",
                "TABLE": {
                    "TERMINAL": "Terminal",
                    "START_TIME": "Startzeit",
                    "END_TIME": "Endzeit",
                    "CARD_IN_DIFF": "Karte ein Diff.",
                    "CARD_OUT_DIFF": "Karte aus Diff.",
                    "DEPOSIT_IN_DIFF": "Pfand ein Diff.",
                    "DEPOSIT_OUT_DIFF": "Pfand aus Diff."
                }
            },
            "TICKET_STATISTICS_OPERATOR": {
                "TITLE": "Ticket Statistiken für Bediener"
            },
            "TICKET_STATISTICS_PERIOD": {
                "TITLE": "Ticket Statistiken im Zeitraum"
            },
            "PAYMENT_KIOSK": {
                "REPORTS": {
                    "TITLE": "Payment-Berichte",
                    "TOOLBAR": {
                        "REPORT_TYPE": "Berichtstyp",
                        "MODE": "Modus"
                    },
                    "FILE": {
                        "REPORT": "Bericht"
                    }
                }
            },
            "UTILIZATION_REPORT": {
                "TITLE": "Verwendungsstatistiken"
            },
            "DONATIONS_REPORT": {
                "TITLE": "Spenden Berichte",
                "TABLE": {
                    "ACCOUNT": "Konto",
                    "ACCOUNT_NAME": "Kontoname",
                }
            },
            "END_OF_SHIFT_REPORTS": {
                "TITLE": "Schichtende-Berichte",
                "PRINTED_BY": "Gedruckt von",
                "PIECES": "Stück",
                "TOTAL": "Gesamt",
                "TABLE": {
                    "BILL_ACCEPTOR_EMPTIED": "Geldscheinprüfer geleert",
                    "BILL_DISPENSER_EMPTIED": "Geldscheinaugeber geleert",
                    "BILL_DISPENSER_REFILLED": "Geldscheinausgeber befüllt",
                    "BILLS_ACCEPTED": "Akzeptierte Scheine",
                    "BILLS_DISPENSED": "Ausgegebene Scheine",
                    "BILLS_REJECTED": "Zurückgew. Scheine",
                    "COIN_ACCEPTOR_EMPTIED": "Münzprüfer geleert",
                    "COIN_DISPENSER_EMPTIED": "Münzausgeber geleert",
                    "COIN_DISPENSER_REFILLED": "Münzausgeber befüllt",
                    "COINS_ACCEPTED": "Akzeptierte Münzen",
                    "COINS_DISPENSED": "Ausgegebene Münzen",
                    "REJECT_BOX_EMPTIED": "Rückweisungen geleert",
                    "TICKETS_ACCEPTED": "Akzeptierte Tickets",
                    "TICKETS_EMPTIED": "Tickets geleert",
                    "TICKETS_PRINTED": "Tickets gedruckt"
                }
            }
        },
        "ENUMS": {
            "ACCEPTOR_MEDIUM": {},
            "ACCESS_POINT": {},
            "ACCESS_STATE": {
                "NONE": "Unbekannt (Keine)",
                "INVALID_LOGIN_NAME": "Ungültiger Benutzername",
                "INVALID_PASSWORD": "Ungültiges Passwort",
                "ACCOUNT_LOCKED": "Account gesperrt",
                "SESSION_COUNT_EXCEEDED": "Sitzungsanzahl überschritten",
                "ACCOUNT_TYPE_NOT_SUPPORTED": "Kontotyp wird nicht unterstützt",
                "LOGGED_IN": "Eingeloggt",
                "LOGGED_OUT": "Ausgeloggt",
                "RFID_CARD": "RFID-Karte",
                "REMOTE_ACCESS": "Fernzugriff"
            },
            "ACCESS_TYPE": {
                "NONE": "",
                "LOGIN_NAME": "Nutzername",
                "OPERATOR_CARD": "Bediener Karte",
                "FINGERPRINT": "Fingerabdruck",
                "PALM_SCAN": "Palm Scan",
                "USB_STICK": "USB Stick",
                "USER_I_D_GIVEN": "Benutzer ID",
                "FACE_SCAN": "Gesicht-Scan",
                "RFID_CARD": "RFID-Karte",
            },
            "ART_CLIENT_TYPE": {
                "ART_CLIENT": "ART Client",
                "ART_WEB_CLIENT": "ART Web Client",
                "TERMINAL": "Terminal"
            },
            "ART_EVENT_SEVERITY": {
                "INFORMATION": "Information",
                "WARNING": "Warnung",
                "ERROR": "Fehler"
            },
            "ART_EVENT_SOURCE": {
                "BIN_FILE_IMPORT": "Bank-BIN-Listen-Import",
                "RECON_FILE_IMPORT": "Bank-Abgleichdatei-Import",
                "EZ_PAY_TICKET_INTERFACE": "EZPay-Ticket-Schnittstelle",
                "ART_BACKUP_TASK": "ART-Backup-Modul",
                "ART_CLEANUP_TASK": "ART-Aufräum-Modul",
                "ART_PROPERTY_MANAGEMENT": "ART-Filialverwaltung",
                "ART_REPLICATION": "ART-Replikation",
                "ADJUSTMENT_FILE_EXPORT": "Bank-Korrekurdatei-Export",
                "ART_NOTIFICATION_SERVICE": "ART-Benachrichtigungsdienst",
                "ATRONIC_JACKPOT_INTERFACE": "ATRONIC Jackpot-Schnitstelle",
                "OPERATOR_MANAGEMENT": "Bediener-Verwaltung",
                "SAFE_ACCESS_PERIODS_TRANSFER": "Tresorzugriffszeiten-Übermittlung",
                "USER_INTERFACE": "Benutzerschnittstelle",
                "ATRONIC_OPERATOR_SYNC": "ATRONIC Bedienerabgleich",
                "EXCHANGE_RATES_SYNC": "Wechselkursabgleich",
                "TERMINAL_MANAGEMENT": "Terminal-Verwaltung",
                "PLAYER_MANAGEMENT": "Spielermanagement",
                "ART_ONLINE_SYSTEM": "ART Online-System",
                "FILE_TRANSFER": "Dateitransfer",
                "CUSTOMER_MANAGEMENT": "Kundenverwaltung",
                "ART_SERVER_LIFECYCLE": "ART Server Lebenslaufakte"
            },
            "ART_EVENT_TYPE": {
                "BIN_IMPORT_SUCCEEDED": "Bank-BIN-Datei-Import erfolgreich!",
                "BIN_MAXIMUM_EXCEEDED": "Bank-BIN-Datei-Import Maximum überschritten!",
                "BIN_FILE_READ_ERROR": "Bank-BIN-Datei kann nicht gelesen werden!",
                "BIN_FILE_DOWNLOAD_ERROR": "Bank-BIN-Datei kann nicht heruntergeladen werden!",
                "EZ_PAY_IMPORT_SUCCESS": "EZPay Ticketabgleich-Import erfolgreich!",
                "EZ_PAY_IMPORT_ERROR": "EZPay Ticketabgleich-Import fehlgeschlagen!",
                "ART_BACKUP_SUCCESS": "ART Server-Backup beendet!",
                "ART_BACKUP_RECENT": "ART Server-Backup muss nicht erstellt werden!",
                "ART_BACKUP_ERROR": "ART Server-Backup fehlgeschlagen!",
                "ART_BACKUP_CLEANUP_FAILED": "Aufräumen der ART Server-Backups fehlgeschlagen!",
                "ART_CLEANUP_SUCCESS": "ART Systempflege beendet!",
                "ART_CLEANUP_ERROR": "ART Systempflege fehlgeschlagen!",
                "ART_CLEANUP_PARTIAL": "ART Systempflege teilweise beendet!",
                "ART_CLEANUP_SUB_TASK_ERROR": "ART Systempflege-Unterpunkt fehlgeschlagen!",
                "RECON_IMPORT_SUCCEEDED": "Bankabgleich-Import erfolgreich!",
                "RECON_IMPORT_ERROR": "Bankabgleich-Import fehlgeschlagen!",
                "RECON_FTP_SERVER_ERROR": "Bankabgleich FTP-Server-Fehler!",
                "RECON_FILE_INVALID": "Ungültige Bankabgleich-Datei!",
                "ADJUSTMENT_FILE_CREATE_ERROR": "Bankkorrektur-Datei konnte nicht erstellt werden!",
                "ADJUSTMENT_FILE_SEND_ERROR": "Bankkorrektur-Datei konnte nicht gesendet werden!",
                "ADJUSTMENT_FILE_UPDATE_ERROR": "Bankkorrektur-Datei konnte Datenbank nicht aktualisieren!",
                "NOTIFICATION_SENT": "Benachrichtigung für Ereignis {0} an {1} Bediener gesendet!",
                "COULD_NOT_SEND_NOTIFICATIONS": "Benachrichtigung konnte nicht an {0} gesendet werden! Grund: {1}.",
                "ATRONIC_SYNC_SUCCESS": "ATRONIC Jackpotabgleich erfolgreich!",
                "ATRONIC_SYNC_ERROR": "ATRONIC Jackpotabgleich fehlgeschlagen!",
                "ATRONIC_SYNC_PROPERTY_FAILED": "ATRONIC Jackpotabgleich Filialfehler!",
                "OPERATORS_MODIFIED": "Bediener wurden geändert!",
                "OPERATORS_RECEIVED": "{0} Bediener wurden von Terminal {1} empfangen!",
                "OPERATORS_SENT": "{0} Bediener wurden an Terminal {1} gesendet!",
                "SAFE_ACCESS_PERIODS_MODIFIED": "{0} Tresorzugriffszeiten wurden durch {1} geändert!",
                "SAFE_ACCESS_PERIODS_SENT": "{0} Tresorzugriffszeiten wurden an Terminal {1} gesendet!",
                "VIEW_IDLE": "Keine Nutzer-Interaktion",
                "OPERATOR_ACCOUNT_LOCKED": "Bedienerkonto gesperrt (Passwortversuche überschritten)!",
                "EXCHANGE_RATES_SYNC_SUCCESS": "Wechselkursabgleich erfolgreich!",
                "EXCHANGE_RATES_SYNC_ERROR": "Wechselkursabgleich fehlgeschlagen!",
                "DYNAMIC_DATA_RESET": "Bewegungsdaten zurückgesetzt!",
                "ATRONIC_OPERATOR_SYNC_SUCCESS": "ATRONIC Bedienerabgleich erfolgreich!",
                "ATRONIC_OPERATOR_SYNC_PROPERTY_FAILED": "ATRONIC Bedienerabgleich Filiale fehlgeschlagen!",
                "ATRONIC_OPERATOR_SYNC_ERROR": "ATRONIC Bedienerabgleich Fehler!",
                "ATRONIC_OPERATOR_SYNC_NO_UPDATES": "ATRONIC Bedienerabgleich keine aktualisierten Daten!",
                "PLAYER_IS_BANNED": "Spieler ist gebannt",
                "ONLINE_SYSTEM_HASH_MISMATCH": "Online-System Hash nicht übereinstimmend",
                "FILE_TRANSFER_MINIMUM_REQUIRED_SPEED_NOT_AVAILABLE": "Dateitransfer minimmal benötigte Geschwindigkeit nicht verfügbar",
                "CUSTOMER_DELETED": "Der Kunde wurde gelöscht!",
                "ART_SERVICE_STARTED": "ART-Dienst gestartet!",
                "ART_SERVICE_FORCED_RESTART": "ART-Dienst erzwungener Neustart!",
                "ART_SERVICE_STOPPED": "ART-Dienst gestoppt!",
                "SMS_DELIVERY_FAILED": "SMS-Versand fehlgeschlagen!"
            },
            "GROUP_ART_SELECTION": {
                "TITLE": "ART Gruppe",
            },
            "ART_INSTALLATION_MODE": {
                "IN_DEVELOPMENT": "In Entwicklung",
                "IN_QUALITY_ASSURANCE": "In Qualitätssicherung",
                "IN_TEST_LAB_USE": "In Test-Labor",
                "IN_PRODUCTION_USE": "In Produktion",
                "DECOMMISSIONED": "Außer Betrieb genommen",
                "NEW": "Neu",
                "SHOW_ROOM": "Ausstellung",
                "FACTORY": "Fabrik",
            },
            "ART_RIGHT": {
                "ADMINISTRATION": "Administration",
                "CONFIGURATION": "Konfiguration",
                "ACCOUNTING": "Abrechnung",
                "MANAGE_ADVERTISEMENTS": "Werbungen verwalten",
                "LOGS": "Logs",
                "LOGIN": "Anmeldung erlaubt",
                "SECURITY": "Sicherheit",
                "TERMINAL_OUT_OF_ORDER": "Betriebszustand ändern",
                "OPERATOR_MANAGEMENT": "Bediener verwalten",
                "SUSPEND_NOTIFICATIONS": "Terminal-Benachrichtigungen aussetzen",
                "VIEW_TRANSACTION_PICTURES": "Transaktionsbilder ansehen",
                "REQUEST_ONE_TIME_MAINTENANCE_ACCESS": "Einmaliger Zugriff",
                "SHOW_PIN_ALERT": "Pin Alarm",
                "REMOTE_CONTROL": "Fernsteuerung",
                "APPROVE_TRANSACTIONS": "Transaktionsbestätigung",
                "TERMINAL_RESTART": "Terminal-Neustart/Herunterfahren",
                "ADJUST_TRANSACTIONS": "Transaktionskorrektur",
                "REMOTE_ACCESS": "Fernzugriff",
                "MANAGE_DEBIT_ACCOUNTS": "Debit-Konten verwalten",
                "DONATION_MANAGEMENT": "Spenden verwalten",
                "VIEW_OPERATOR_ACCESSES": "Bedienerzugriffe ansehen",
                "PLAYER_MANAGEMENT": "Spieler verwalten",
                "VIEW_I_D_SCANS": "ID-Scans ansehen",
                "EXCHANGE_RATE_MANAGEMENT": "Wechselkurse verwalten",
                "MANAGE_ONLINE_SYSTEM_MEDIA": "Online-System-Medien verwalten",
                "MANAGE_CUSTOMERS": "Kunden verwalten",
                "VIEW_COMMISSION_REPORT": "Kommissionsbericht ansehen",
                "VIEW_DISTRIBUTOR_REPORT": "Distributorbericht ansehen",
                "VIEW_STORE_REPORT": "Ladenbericht ansehen",
                "VIEW_INSTALLATION_DASHBOARD": "ART Dashboard ansehen"
            },
            "ART_RIGHT_X": {
                "GLOBAL": "Global",
                "TERMINAL_OPERATION_STATE": "Terminal Operation State",
                "TERMINAL_RESTART": "Terminal Restart",
                "VIEW_SECURITY_ALERTS": "View Security Alerts",
                "SUSPEND_NOTIFICATIONS": "Suspend Notifications",
                "MONITORING": "Überwachung",
                "DASHBOARD": "Dashboard",
                "DASH_INSTALLATION": "Bereich 'Installation'",
                "DI_TRANSACTION_COUNTS": "",
                "DI_TRANSACTION_AVERAGES": "",
                "DI_TRANSACTION_TOTALS": "",
                "DI_TRANSACTION_TYPES": "",
                "DI_DONATION_COUNTS": "",
                "DASH_PROPERTY": "Bereich 'Filiale'",
                "DP_TRANSACTION_COUNTS": "",
                "DP_TRANSACTION_AVERAGES": "",
                "DP_TRANSACTION_TOTALS": "",
                "DP_TRANSACTION_TYPES": "",
                "DP_DONATION_COUNTS": "",
                "DASH_KIOSK": "Bereich 'Kiosk'",
                "DK_TRANSACTION_COUNTS": "",
                "DK_TRANSACTION_AVERAGES": "",
                "DK_TRANSACTION_TOTALS": "",
                "DK_TRANSACTION_TYPES": "",
                "DK_DONATION_COUNTS": "",
                "DASHBOARD_DOWNTIME": "Dashboard Außer Betrieb",
                "TERMINALS": "Terminals",
                "TERM_ICON_VIEW": "Ansicht 'Terminal-Symbole'",
                "TERM_LIST_VIEW": "Ansicht 'Terminal-Liste'",
                "TERM_FILL_LEVEL_VIEW": "Ansicht 'Füllstände'",
                "COMPONENT_OVERVIEW": "Komponentenübersicht",
                "TERMINAL_IDENTIFIERS": "Terminal-Bezeichner",
                "STOCK_OVERVIEW": "Bestandsübersicht",
                "TERMINAL_HARDWARE": "Terminal-Hardware",
                "MAINTENANCE_OVERVIEW": "Wartungsübersicht",
                "LOG_VIEWS": "Protokolle",
                "LV_TRANSACTION_LOG": "Transaktionprotokoll",
                "LV_ERROR_LOG": "Fehlerprotokoll",
                "LV_EVENT_LOG": "Ereignisprotokoll",
                "LV_MEDIA_LOG": "Medienprotokoll",
                "LV_MAINTENANCE_LOG": "Wartungsprotokoll",
                "LV_OPERATOR_ACCESS_LOG": "Bedienerzugrissprotokoll",
                "LV_CONFIGURATION_CHANGE_LOG": "Konfigurationsänderungsprotokoll",
                "LV_ART_EVENT_LOG": "ART-Ereignisprotokoll",
                "BALANCING": "Abrechnung",
                "BALANCE_REPORT": "Abrechnungsbericht",
                "ATM_RECONCILIATION": "Bankabgleich",
                "TICKET_RECONCILIATION": "Ticketabgleich",
                "STATISTICS": "Statistiken",
                "TRANSACTION_STATISTICS": "Transaktionsstatistiken",
                "TRANSACTION_COUNTS": "Transaktionszahlen",
                "TRANSACTION_REPORT": "Transaktionsbericht",
                "TERMINAL_STATISTICS": "Terminal-Statistiken",
                "UTILIZATION_REPORT": "Verwendungsbericht",
                "TERMINAL_AVAILABILITY": "Terminal-Verfügbarkeit",
                "SLA_REPORT": "SLA-Bericht",
                "MACHINE_REPORT": "Maschinenbericht",
                "LIFETIME_METERS": "Laufzeitwerte",
                "TERMINAL_STATE": "Terminal-Status",
                "OPERATOR_STATISTICS": "Bediener-Statistiken",
                "OPERATOR_ACCOUNT_LOCKS": "Bediener-Kontensperren",
                "OPERATOR_LOGOUTS": "Bediener-Abmeldungen",
                "DEBIT_SHIFTS": "Debit-SChichten",
                "PLAYER_STATISTICS": "Spieler-Statistiken",
                "PLAYER_TURNOVER": "Spielerumsatz",
                "PLAYER_BANS": "Spielersperrungen",
                "MEDIA_STATISTICS": "Medien-Statistiken",
                "CASH_STATISTICS": "Bargeld-Statistiken",
                "CARD_STATISTICS": "Karten-Statistiken",
                "CARD_TURNOVER": "Kartenumsatz",
                "CHIP_TURNOVER": "Chip-Umsatz",
                "ONLINE_SYSTEM_STATISTICS": "Online-System-Statistiken",
                "DONATION_REPORT": "Spendenstatistiken",
                "CURRENCY_EXCHANGE_PROFIT_REPORT": "Währungsumtauschgewinne",
                "END_OF_SHIFT_REPORT": "Schichtende-Berichte",
                "TICKET_STATISTICS_PER_OPERATOR": "Ticket-Statistiken für Bediener",
                "TICKET_STATISTICS_PER_PERIOD": "Ticket-Statistiken für Zeitraum",
                "PAYMENT_STATISTICS": "Payment-Statistiken",
                "PS_COMMISSION_REPORT": "Kommissionsbereicht",
                "PS_DISTRIBUTOR_REPORT": "Distributorbericht",
                "PS_STORE_REPORT": "Ladenbericht",
                "PS_TRANSACTION_REPORT": "Transaktionsbericht",
                "PS_CUSTOMER_REPORT": "Kundenbericht",
                "PS_ABORTED_TRANSACTIONS_REPORT": "Abgebrochene Transaktionen",
                "KIOSK_STATISTICS": "Kiosk-Statistiken",
                "KS_WAGERING_INSTRUMENT_REPORT": "Wagering Instrument Transaction Report",
                "KS_FILL_REPORT": "Fill Report",
                "KS_CASH_OUT_REPORT": "Cash Out Report",
                "KS_DROP_REPORT": "Drop Report",
                "KS_EXCEPTION_REPORT": "Exception Report",
                "KS_JACKPOT_OPEN_CREDIT_REPORT": "Jackpot Open Credit Report",
                "KS_TICKET_LIABILITY_REPORT": "Ticket Liability Report",
                "KS_TICKET_DROP_VARIANCE_REPORT": "Ticket Drop Variance Report",
                "MANAGEMENT": "Verwaltung",
                "OPERATOR_MANAGEMENT": "Bedienerverwaltung",
                "PLAYER_MANAGEMENT": "Spielerverwaltung",
                "CUSTOMER_MANAGEMENT": "Kundenverwaltung",
                "JACKPOT_FORMS_MANAGEMENT": "Jackpot-Formular-Verwaltung",
                "ADVERTISING": "Werbung",
                "SHOP_MANAGEMENT": "Laden-Verwaltung",
                "ONLINE_SYSTEM_MEDIA": "Online-System-Medien",
                "BONUS_SYSTEM_MEDIA": "Bonus-System-Medien",
                "JACKPOT_SYSTEM_JACKPOTS": "Jackpot-System-Jackpots",
                "WORK_ACCOUNT_MANAGEMENT": "Arbeitskonten-Verwaltung",
                "PRODUCTION_ORDERS": "Produktionsaufträge",
                "LIFECYCLE_RECORDS": "Lebenslaufakte",
                "ONLINE_SYSTEM_SERVER_STATISTICS": "Online System Server-Statistiken",
                "OS_TICKET_LIABILITY_REPORT": "Ticket Liability-Bericht",
                "OS_TICKET_DROP_VARIANCE_REPORT": "Ticket Drop Variance-Bericht",
                "OS_EGM_REVENUE_REPORT": "EGM-Gewinn-Bericht"
            },
            "ATM_STATUS": {
                "UNKNOWN": "ATM status not defined!",
                "ATM_DISABLED": "ATM disabled. Proxy: {0}",
                "ATM_ENABLED_BUT_NOT_CONNECTED": "Terminal is not connected to the ATM Server! Proxy: {0}",
                "ATM_CONNECTED": "Terminal connected to the ATM Server. Waiting for login and key exchange! Proxy: {0}",
                "ATM_LOGGED_IN": "Terminal connected and logged into the ATM Server. Waiting for key exchange! Proxy: {0}",
                "ATM_KEY_EXCHANGED": "Terminal connected, logged in and exchanged keys with the ATM Server. FULLY CONNECTED! Proxy: {0}",
                "NO_ATM": ""
            },
            "BALANCING_TYPE": {
                "NONE": "None",
                "MONEY_SERVICE": "Bestandsaufnahme",
                "FULL_SERVICE": "Full Service",
                "PREDEFINED_SERVICE": "Predefined Service",
                "MANUAL_SERVICE": "Manual Service"
            },
            "BALANCE_SNAPSHOT_TYPE": {
                "HOURLY": "Stündlicher Bestand",
                "REPORT1": "Bericht 1",
                "REPORT2": "Bericht 2",
                "MONEY_SERVICE_START": "Bestandsaufnahme Start",
                "MONEY_SERVICE_MID": "Bestandsaufnahme Mitte",
                "MONEY_SERVICE_END": "Bestandsaufnahme Ende",
                "CASINO_DAY_END": "Kasinotag Ende",
                "MONEY_SERVICE_FULL": "Volle Bestandsaufnahme",
                "FILL_LEVEL": "Füll-Level gedruckt",
                "START_OF_SHIFT": "Schichtbeginn",
                "HOURLY_SNAPSHOT": "Stündlicher Bestand"
            },
            "CASHIO_RIGHTS": {
                "ARTBACKUPSUCCES": "",
                "COIN_HOPPER": "Münzausgeber",
                "BILL_ACCEPTOR": "Akzeptor",
                "BILL_DISPENSER": "Ausgeber",
                "CARD_READER": "Kartenleser",
                "ADMINISTRATION": "Administration",
                "CONFIGURATION": "Konfiguration",
                "REPORTS": "Berichte",
                "MONEY_SERVICE": "Bestandsverwaltung",
                "SHUTDOWN": "Herunterfahren",
                "LOGIN_WITHOUT_PASSWORD": "Anmeldung ohne Passwort",
                "MAINTENANCE": "Wartung",
                "LOST_MONEY": "Fundgeld",
                "BURN_CARD": "Karten brennen",
                "VIEW_TRANSACTION_PICTURES": "Transaktionsbilder",
                "CHANGE_ATM_BANK_KEY": "Bankschlüssel ändern",
                "SAFE_DOOR": "Tresortür",
                "SAFE_EMERGENCY": "Tresortür-Notfall",
                "TICKET_HAND_PAY": "Ticketauszahlung manuell",
                "COUNT_ONLY": "Geldzähler",
                "DEBIT_ACCOUNT": "Debit-Konto",
                "CASHIER": "Kassierer",
                "APPROVE_TRANSACTIONS": "Transaktionen bestätigen",
                "RDP_ACCESS": "RDP-Zugriff"
            },
            "CCS_RIGHT": {
                "CONFIGURATION": "Konfiguration",
                "ADMINISTRATION": "Administration",
                "END_OF_SHIFT": "Schichtende",
                "BALANCING": "Abrechnung"
            },
            "COMPONENT_SEVERITY": {
                "NONE": "Nichts",
                "INFORMATION": "Information",
                "WARNING": "Warnung",
                "ERROR": "Fehler",
                "FATAL": "Fatal",
            },
            "COMPONENT_DEVICE_STATE": {
                "NONE": "(Keine Angabe)",
                "START_UP": "Initialisierung",
                "WORKING": "Funktioniert",
                "WARNING": "Warnung",
                "ERROR": "Fehler",
            },
            "COMPONENT_TYPE": {
                "NONE": "Globales Ereignis",
                "MACHINE_INTEGRITY": "Maschinenintegrität",
                "ACCEPTOR_BOX": "Akzeptor-Box",
                "ART_SERVER": "ART Server",
                "ATM": "ATM",
                "BILL_ACCEPTOR": "Scheinakzeptor",
                "BILL_DISPENSER": "Scheinausgeber",
                "CAPTURE_BIN": "Auffangbox",
                "CARD_DISPENSER": "Kartenausgeber",
                "CARD_READER": "Kartenleser",
                "CASSETTE": "Kassette",
                "COIN_ACCEPTOR": "Münzakzeptor",
                "COIN_DISPENSER": "Münzausgeber",
                "ONLINE_SYSTEM": "Online System",
                "FINGERPRINT_READER": "Fingerabdruckleser",
                "HOPPER_CHANNEL": "Münzbehälter",
                "COMBINED_ACCEPTOR": "Schein-/Ticket-Akzeptor",
                "TICKET_ISSUER": "Ticketausgeber",
                "CARD_SYSTEM": "Kartensystem",
                "BARCODE_SCANNER": "Barcode-Scanner",
                "JACKPOT_SYSTEM": "Jackpot System",
                "KEYBOARD": "Tastatur",
                "POS": "POS",
                "RECEIPT_PRINTER": "Belegdrucker",
                "REJECT_BOX": "Rückweisungen",
                "SAM": "SAM",
                "SENSOR_BOARD": "Sensor-Board",
                "SENSOR": "Sensor Rang 0",
                "CARD_STACKER": "Kartenmagazin",
                "TICKET_ACCEPTOR": "Ticketakzeptor",
                "TICKET_PRINTER": "Ticket-Drucker",
                "TOWER_LIGHT": "Tower-Light",
                "UPS": "USV",
                "VSI": "Video Surveillance Interface",
                "MONITORING_SYSTEM": "Monitoring System",
                "ONLINE_CASINO": "Online Casino",
                "DISPLAY": "Anzeige",
                "ATM_PROXY_CONNECTION": "ATM Proxy-Verbindung",
                "IDS_CONNECTION": "IDS-Verbindung",
                "PIN_KEY": "PIN-Schlüssel",
                "MASTER_KEY": "Master-Schlüssel",
                "BONUS_SYSTEM": "Bonus System",
                "ATS": "Cash-N-Go ATS",
                "REWARD_SYSTEM": "Belohnungssystem",
                "COS_PROXY_MODULE": "COS Proxy-Modul",
                "COS_PROXY_CONNECTION": "COS Proxy-Verbindung",
                "TIME_SOURCE": "Zeitgeber",
                "DISPENSER_SHUTTER": "Scheinausgeberverschluss",
                "FISCAL_CONNECTION": "Fiskal-Verbindung",
                "ADVERTISING_SERVER": "Werbe-Server",
                "TRANSACTION_APPROVAL": "Transaktionsbestätigung",
                "EMV": "EMV",
                "EMV_PROXY": "EMV Proxy-Verbindung",
                "EMV_PIN_KEY": "EMV-PIN-Schlüssel",
                "EMV_MASTER_KEY": "EMV-Master-Schlüssel",
                "I_D_SCANNER": "ID-Scanner",
                "HANDHELD": "Handheld",
                "EMV_BANK_LINK": "EMV-Bank-Verbindung",
                "PAYMENT_SYSTEM": "Bezahlsystem",
                "FACE_RECOGNITION": "Gesichtserkennung",
                "CONFIGURATION_VALIDITY": "Konfigurationsgültigkeit",
                "PALM_SCANNER": "Handflächen-Scanner",
                "CABLE_TREE": "Kabelbaum",
                "CRYPTO_KEYS": "Krypto-Schlüssel",
                "SIGN_IN_SIGN_UP_SYSTEM": "Sign-In-Sign-Up-System",
                "BILL_PAY_SYSTEM": "Rechnung bezahlen",
                "CHECK_CASHING_SYSTEM": "Scheck einlösen",
                "MOBILE_TOP_UP_SYSTEM": "Mobil Aufladesystem",
                "GIFT_CARD_SALE_SYSTEM": "Geschenkkartenverkaufssystem",
                "MANUAL_INPUT": "Manuelle Einzahlung",
                "WIRE_TRANSFER_SYSTEM": "Banküberweisungssystem",
                "GIFT_CARD_BUY_SYSTEM": "Geschenkkartenkaufsystem",
                "CRYPTO_CURRENCY_SYSTEM": "Crypto-Währungssystem",
                "PREPAID_CARD_SYSTEM": "Prepaid-Karten-System",
                "E_WALLET": "eWallet",
                "E_WALLET_SYSTEM": "EWallet-System",
                "TCD_SYSTEM": "Teller-Cash-Dispenser-System",
                "OTHERS": "Weitere Komponenten",
                "UNDEFINED": " Undefiniert",
                "MAINBOARD": "Mainboard",
                "CENTRAL_PROCESSING_UNIT": "Prozessor (CPU)",
                "MEMORY_MODULE": "Arbeitsspeicher (RAM)",
                "SOLID_STATE_DISK": "Solid State Disk (SSD)",
                "CPU_FAN": "Prozessor-Lüfter",
                "CASE_FAN": "Gehäuse-Lüfter",
                "CAMERA": "Kamera",
                "POWER_SUPPLY": "Stromversorgung",
                "MONITOR": "Bildschirm",
                "MAIN_CONTROLLER": "Main Controller",
                "NETWORK_CONNECTIVITY": "Netzwerk-Konnektivität",
                "NETWORK_INTERFACE ": "Netzwerk-Schnittstelle",
                "ADVERTISING_STREAM_PLAYER": "Abspieler für Werbung (Streaming)",
                "DISPENSE_REQUEST_SERVICE_MODULE": "Auszahlungsanfrage-Dienst-Modul",
                "MAC_MASTER_KEY": "MAC-Hauptschlüssel",
                "MAC_WORKING_KEY": "MAC-Arbeitsschlüssel",
                "EMV_MAC_WORKING_KEY": "EMV MAC-Arbeitsschlüssel",
                "CARD_POSITION": "Kartenposition",
                "TAX_CONTROLLING_SYSTEM": "Steuerverwaltungssystem",
                "TABLE_SYSTEM": "Tischverwaltungssystem"
            },
            "DASHBOARD_WIDGET": {
                "TRANSACTION_COUNTS": "Transaktionsanzahl",
                "TRANSACTION_AVERAGES": "Transaktionensdurchschnitt",
                "TRANSACTION_TOTALS": "Transaktionssummen",
                "TRANSACTION_TYPES": "Transaktionstypen",
                "TERMINALS_CONNECTED": "Terminals verbunden",
                "TERMINAL_ISSUES": "Terminal-Warnungen",
                "TERMINAL_UPTIME": "Terminal-Verfügbarkeit",
                "SOFTWARE_DISTRIBUTION": "Softwareversion",
                "SOFTWARE_UPDATES": "Softwareaktualisierungen",
                "DISK_USAGE": "Festplattennutzung",
                "TERMINAL_LOCATIONS": "Terminal Standort",
                "TERMINAL_UPTIME_TIME_SLOT": "Terminal Verfügbarkeit Slots",
                "TERMINAL_COMPONENT_MONITOR": "Terminal Komponentenübersicht",
                "TERMINAL_UPTIME_EXTENDED": "Terminal Außer Betrieb",
                "LOGGED_IN_OPERATOR": "Angemeldete Bediener",
                "MONEY_SERVICE_COUNT": "Durchgeführte Bestandsverwaltungen",
                "MAINTENANCE_CALLS": "Wartungsrufe",
                "REPLACED_PARTS": "Teile ausgetauscht",
                "NEXT_MAINTENANCE": "Nächste Wartungen",
                "DONATION_COUNTS": "Spenden",
                "SOFTWARE_DISTRIBUTION_OS": "Softwareversion OS",
                "TOTAL_UPTIME": "Gesamtbetriebszeit",
                "TOTAL_DOWNTIME": "Gesamtausfallzeit",
                "DOWNTIME": "Ausfallzeit",
                "DOWNTIME_TECHNICAL_ERRORS": "(Technische Fehler)",
                "DOWNTIME_OPERATIONAL": "(Betriebsbereit)",
                "REGISTERED_CUSTOMERS": "Registrierte Kunden",
                "REGISTERED_PLAYERS": "Registrierte Spieler",
                "TERMINAL_OUT_OF_OPERATION": "Außer Betrieb",
                "MONEY_SERVICE_ACTION_REQUIRED": "Bestandsverwaltung benötigt",
                "MODALS": {
                    "TERMINALS_DISCONNECTED": "Nicht verbundene Terminals",
                    "TERMINALS_ISSUES": "Terminals mit Problemen",
                    "TERMINALS_UPTIME": "Terminalverfügbarkeit",
                    "MONEY_SERVICES": "Bestandsaufnahmen",
                    "CURRENT_OPERATORS": "Aktuell eingeloggte Bedienersitzungen",
                    "TERMINALS_OUT_OF_OPERATION": "Terminals außer Betrieb",
                    "MONEY_SERVICE_ACTION_REQUIRED": "Bestandsverwaltungsübersicht"
                },
            },
            "DEVICE_INFORMATION_TYPE": {
                "SERIAL_NUMBER": "Seriennummer",
                "FIRMWARE_VERSION": "Firmware Version",
                "COM_PORT": "COM-Port",
                "ERROR_INFORMATION": "Fehlerinformation",
                "UNKNOWN": "Unbekannt",
                "UNDEFINED": "Unbekannt",
                "OTHER": "Andere"
            },
            "DEVICE_TYPE": {
                "UNDEFINED": "Undefiniert",
                "NONE": "",
                "DEBUG": "Debug",
                "BILL_ACCEPTOR_WBA": "JCM",
                "BILL_ACCEPTOR_SANWA": "Sanwa",
                "BILL_ACCEPTOR_WBA_IRQ": "JCM IRQ",
                "BILL_ACCEPTOR_CASHFLOW": "MEI Cashflow-SC/SCN",
                "BILL_ACCEPTOR_NV200": "Inno.Tech. NV200",
                "BILL_ACCEPTOR_CASHCODE": "CashCode",
                "BILL_ACCEPTOR_JCM_I_PRO": "JCM iPro",
                "BILL_ACCEPTOR_JCM_I_VIZION": "JCM iVizion",
                "BILL_ACCEPTOR_JCM_TBV": "JCM TBV",
                "BILL_DISPENSER_ITAUTEC": "Itautec",
                "BILL_DISPENSER_DELARUE_NMD100": "Glory NMD100",
                "BILL_DISPENSER_DELARUE_NMD50": "Glory NMD50",
                "BILL_DISPENSER_DELARUE_MULTIMECH": "Glory MultiMech",
                "BILL_DISPENSER_FUJITSU_F610": "Fujitsu F610",
                "BILL_DISPENSER_FUJITSU_F53": "Fujitsu F53",
                "BILL_DISPENSER_GRG_CDM8240": "GRG CDM8240",
                "COIN_ACCEPTOR_CCTALK": "CCTalk",
                "COIN_ACCEPTOR_PRO_COIN": "ProCoin",
                "COIN_DISPENSER_ITAUTEC": "Itautec",
                "COIN_DISPENSER_PERCONTA": "Perconta",
                "COIN_DISPENSER_CCTALK": "CCTalk",
                "COIN_DISPENSER_SMART": "Smart Hopper",
                "CASSETTE_ITAUTEC": "Itautec",
                "CASSETTE_DELARUE_NMD100": "Glory NMD100",
                "CASSETTE_DELARUE_NMD50": "Glory NMD50",
                "CASSETTE_DELARUE_MULTIMECH": "Glory MultiMech",
                "CASSETTE_FUJITSU_F610": "Fujitsu F610",
                "CASSETTE_FUJITSU_F53": "Fujitsu F53",
                "CASSETTE_GRG_CDM8240": "GRG CDM8240",
                "REJECT_BOX_ITAUTEC": "Itautec",
                "REJECT_BOX_DELARUE_NMD100": "Glory NMD100",
                "REJECT_BOX_DELARUE_NMD50": "Glory NMD50",
                "REJECT_BOX_DELARUE_MULTIMECH": "Glory MultiMech",
                "REJECT_BOX_FUJITSU_F610": "Fujitsu F610",
                "REJECT_BOX_FUJITSU_F53": "Fujitsu F53",
                "REJECT_BOX_GRG_CDM8240": "GRG CDM8240",
                "SHUTTER_GRG": "Shutter GRG",
                "HOPPER_ITAUTEC": "Itautec",
                "HOPPER_PERCONTA": "Perconta",
                "HOPPER_CCTALK": "CCTalk",
                "HOPPER_SMART": "Smart Hopper",
                "TICKET_PRINTER_FUTURELOGIC_GEN2": "FutureLogic GEN2",
                "TICKET_PRINTER_TRANSACT_EPIC950": "Transact EPIC950",
                "CARD_READER_ICM300": "Itautec ICM-300",
                "CARD_READER_CIM1000": "CIM-1000",
                "CARD_READER_CRT288": "Quio CRT-288",
                "CARD_READER_KYT6_XXX": "KYT6XXX",
                "CARD_READER_HS862": "HS862t",
                "CARD_READER_ICM330": "Sankyo ICM-330",
                "CARD_READER_HS910": "HS910",
                "CARD_READER_MAGTEK_SWIPE": "MagTek Swipe",
                "CARD_READER_HS855": "HS855",
                "CARD_READER_KYTRONICS_CHR1000": "Kytronics CHR-1000",
                "CARD_READER_SANKYO_ICM370": "Sankyo ICM-370",
                "CONTROLLER_ITAUTEC": "Itautec",
                "CONTROLLER_SENSOR_ONLY": "Sensors Only",
                "UPS_PULSAR": "Pulsar",
                "UPS_APC": "APC",
                "UPS_EATON": "Eaton USB",
                "UPS_EATON_HID": "Eaton HID",
                "KEYBOARD_STANDARD": "Standard",
                "KEYBOARD_ITAUTEC": "TripleDES Itautec",
                "KEYBOARD_SAGEM4220": "Sagem 4220",
                "KEYBOARD_SAGEM4242": "Sagem 4242",
                "KEYBOARD_CRYPTERA1215": "Cryptera 1215",
                "TOUCHSCREEN3_M": "3M",
                "TOUCHSCREEN_ELO": "Elo TouchSystems",
                "TOUCHSCREEN_PERCONTA": "Perconta",
                "RECEIPT_PRINTER_TG1280": "tg-1280",
                "RECEIPT_PRINTER_TG2480": "tg-2480",
                "RECEIPT_PRINTER_FUTURELOGIC_GEN2": "FutureLogic GEN2",
                "RECEIPT_PRINTER_TRANSACT_EPIC950": "Transact EPIC950",
                "RECEIPT_PRINTER_FISCAL_BOX": "Fiscal Box",
                "BIOMETRIC_DEVICE_TOUCH_CHIP": "Eikon TouchChip",
                "BIOMETRIC_DEVICE_PALM_SECURE": "Fujitsu PalmSecure",
                "VIDEO_TEXT_STANDARD": "Standard",
                "VIDEO_TEXT_GTD": "GTD",
                "REAR_CONTROL_STANDARD": "Standard",
                "REAR_CONTROL_EXTENDED": "Extended",
                "BARCODE_SCANNER_STANDARD": "Standard",
                "BARCODE_SCANNER_HID": "HID",
                "POS_INGENICO": "Ingenico",
                "POS_CCV": "CCV",
                "BADGE_GD32_CUSTOM": "GD32 Custom",
                "BADGE_SSD1322": "SSD 1322",
                "FISCAL_CONNECTION_PAY_ONLINE": "Fiscal Box PayOnline",
                "I_D_SCANNER_ARH_SMART_KIOSK": "ARH SmartKiosk",
                "HANDHELD_PT7003": "PT7003",
                "HANDHELD_GT_S7500": "GT-S7500",
                "HANDHELD_SM_J320_FN": "SM-J320FN",
                "TICKET_ACCEPTOR_AM_TOTE": "AmTote",
                "BARCODE_SCANNER_DATALOGIC_GRYPHON": "Datalogic Gryphon",
                "I_D_SCANNER_PERTECH6000": "Pertech 6000",
                "FINGERPRINT_READER_TOUCH_CHIP": "Fingerprint Reader Touch Chip",
                "PALM_SCANNER_PALM_SECURE": "Palm Scanner Palm Secure",
                "GENERIC_CASHIO_POWER_SUPPLY": "Generic",
                "CASHIO_POWER_SUPPLY12_V": "12V",
                "CASHIO_POWER_SUPPLY24_V": "24V",
                "GENERIC_CASHIO_MONITOR": "Generic",
                "GENERAL_TOUCH_MONITOR": "General Touch",
                "GREEN_TOUCH_MONITOR": "Green Touch",
                "GENERIC_CASHIO_MAIN_CONTROLLER": "Generic",
                "BILL_DISPENSER_FUJITSU_F510": "Fujitsu F510",
                "BILL_DISPENSER_GRG_CDM6240": "GRG CDM6240",
                "CASSETTE_FUJITSU_F510": "Fujitsu F510",
                "CASSETTE_GRG_CDM6240": "GRG CDM6240",
                "CARD_READER_EFLOOR_CR7": "eFLOOR CR7",
                "CARD_READER_ICT3_V5": "ICT3V5",
                "CARD_READER_ACR39": "ACR 39",
                "KEYBOARD_CRYPTERA1215_PCI5": "Cryptera 1215 PCI5"
            },
            "ERROR_TYPE": {
                "OPEN_WITHOUT_LOGIN": "Offen ohne Anmeldung",
                "LOGIN_FAILED_WRONG_PASSWORD": "Anmeldung fehlgeschlagen (3 mal falsches Passwort eingegeben)",
                "COIN_ACCEPTOR_NOT_IN_END_POSITION": "Münzakzeptor ist nicht in Endposition.",
                "COIN_DISPENSER_NOT_IN_END_POSITION": "Münzausgeber ist nicht in Endposition.",
                "ACCEPTOR_REMOVED_WHILE_DOOR_CLOSED": "Akzeptor entfernt bei geschlossener Tür.",
                "DISPENSER_REMOVED_WHILE_DOOR_CLOSED": "Ausgeber entfernt bei geschlossener Tür.",
                "HOPPER_UNDER_RUNS_MINIMAL_VALUE": "Münzausgeber unterschreitet minimalen Wert.",
                "DISPENSER_UNDER_RUNS_MINIMAL_VALUE": "Ausgeber unterschreitet minimalen Wert.",
                "ACCEPTOR_EXCEEDS_MAXIMUM_CAPACITY": "Akzeptor überschreitet maximaler Kapazität.",
                "TIME_SYNC_TOO_OFTEN": "Zeit wurde mehr als 3 mal innerhalb von 24 Stunden gesetzt.",
                "ALREADY_ASSIGNED_TERMINAL_ID": "Terminal versucht sich mit einer bereits vergebenen ID anzumelden.",
                "MAXIMUM_NUMBER_OF_TERMINALS_REACHED": "Maximale Anzahl erlaubter Terminals überschritten.",
                "COMMUNICATION_PROBLEM": "Kommunikationsproblem",
                "DOOR_OPENED": "Tür geöffnet",
                "SERVER_TIME_AND_TERMINAL_TIME_DIFFER": "Server- und Terminal-Zeit unterscheiden sich mehr 12 Stunden. Bitte anpassen oder Abrechnung kann inkorrekt sein.",
                "INVALID_PROPERTY_NUMBER": "Ungültige Filialnummer",
                "PIN_ALERT": "Pin Alarm",
                "TRANSACTION_APPROVAL_REQUIRED": "Transaktionsbestätigung erforderlich",
                "TRANSACTION_APPROVED": "Transaktion wurde bestätigt",
                "REMOTE_ACCESS_CONFIRMATION": "Fernzugriff-Bestätigung",
                "MAGNETICAL_CARD_READER_WRITE_ERROR": "Kartenleserfehler",
                "CARD_JAMMED": "Karte verklemmt",
                "ATTENDANCE_REQUIRED": "Bediener benötigt"
            },
            "EVENT_SEVERITY": {
                "NONE": "Nichts",
                "INFORMATION": "Information",
                "WARNING": "Warnung",
                "ERROR": "Fehler",
                "FATAL": "Fatal"
            },
            "FEATURE_TYPE": {
                "NONE": "None",
                "BILL_BREAK": "Scheinstückelung",
                "TICKET_CREATION": "Ticketerstellung",
                "TICKET_REDEMPTION": "Ticketauszahlung",
                "PLAYER_CARD_CREATION": "Erstellen von Spielerkarten",
                "PLAYER_CARD_REDEMPTION": "Auszahlung von Spielerkarten",
                "PLAYER_CARD_CHARGING": "Aufladen von Spielerkarten",
                "EMPTY_CARD_DISPENSE": "Ausgabe von Leerkarten",
                "ATM": "ATM",
                "POS": "POS",
                "CEC": "CEC",
                "JACKPOT": "Jackpot",
                "CASH_CHANGE": "Geldwechsel",
                "VOUCHER_REDEMPTION": "Gutscheinauszahlung",
                "MARKER_REDEMPTION": "Markerauszahlung",
                "CURRENCY_EXCHANGE": "Währungsumtausch",
                "DEBIT_ACCOUNT_RETURN": "Debit-Auszahlung",
                "DEBIT_ACCOUNT_PAYOUT": "Debit-Rückzahlung",
                "CASHIER_CASH_PAYOUT": "Kassierer-Barauszahlung",
                "CASHIER_CASH_RETURN": "Kassierer-Barrückzahlung",
                "ADVERTISING_SERVER": "Werbe-Server",
                "ADVERTISING": "Werbung",
                "TRANSACTION_APPROVAL": "Transaktionsbestätigung",
                "REWARD_SYSTEM": "Prämiensystem",
                "POS_TO_TICKET": "POS-zu-Ticket",
                "POS_TO_CARD": "POS-zu-Karte",
                "E_WALLET_WITHDRAWAL": "eWallet-Auszahlung",
                "E_WALLET_DEPOSIT": "eWallet-Einzahlung",
                "CRYPTO_KEYS": "Krypto-Schlüssel",
                "CASH_TO_TICKET": "Bargeld-zu-Ticket",
                "BONUS_CARD_CREATION": "Erstellen von Bonuskarten",
                "BONUS_CARD_REDEMPTION": "Auszahlung von Bonuskarten",
                "BONUS_CARD_CHARGING": "Aufladen von Bonuskarten",
                "SIGN_IN": "Anmeldung",
                "SIGN_UP": "Registrierung",
                "BILL_PAY": "Rechnungsbegleichung",
                "CHECK_CASHING": "Scheckeinlösung",
                "GIFT_CARD_SALE": "Geschenkkartenverkauf",
                "MOBILE_TOP_UP": "Mobile Top-up",
                "WIRE_TRANSFER": "Geldüberweisung",
                "GIFT_CARD_BUY": "Geschenkkartenkauf",
                "CRYPTO_CURRENCY_BUY": "Crypto-Währungskauf",
                "PREPAID_CARD": "Prepaid-Karten",
                "CRYPTO_CURRENCY_SALE": "Crypto-Währungsverkauf",
                "ADVERTISING_STREAM": "Werbung (Streaming)",
                "TABLE_SYSTEM": "Tischverwaltungssystem"
            },
            "FILL_LEVEL_DEVICE": {
                "NOTE_CASSETTE": "Kassette",
                "COIN_HOPPER": "Hopper",
                "BILL_ACCEPTOR": "Scheinakzeptor",
                "COIN_ACCEPTOR": "Münzakzeptor",
                "REJECT_BOX": "Rückweisungen",
                "CARD_STACKER": "Kartenmagazin",
                "CARD_CATCHING_BIN": "Kartenauffangbehälter"
            },
            "FILL_LEVEL_STATE": {},
            "GENDER": {
                "NONE": "Keines",
                "UNKNOWN": "Unbekannt",
                "MALE": "männlich",
                "FEMALE": "weiblich",
                "DIVERSE": "Divers"
            },
            "IDENTITY_DOCUMENT_TYPE": {
                "NONE": "keine",
                "IDENTITY_CARD": "Personalausweis",
                "PASSPORT": "Pass",
                "DRIVERS_LICENSE": "Führerschein",
                "ID_BOOK_SA": "ID Book SA",
                "MEXICAN_CONSULAR_ID": "Mexikanische Konsular-ID"
            },
            "INFO_TYPE": {
                "ATM_RBS_LYNK_SEQUENCE_NUMBER": "Bank-Sequenz-Nr.",
                "ATM_RBS_LYNK_DATE_TIME": "Bank-Datum",
                "ATM_CURRENT_BANK_TERM_I_D": "Bank-Terminal-ID",
                "SLOT_NUMBER": "Slot-Nr.",
                "JACKPOT_TRANSACTION_NUMBER": "Jackpot-System-ID",
                "DEV_S_I_TRACKING_NR": "Online-System-Nr.",
                "ATM_CURRENT_BANK_TRANS_NUMBER": "Bank-Transaktions-Nr.",
                "ATM_CURRENT_BANK_SEQUENCE_NUMBER": "Bank Sequence #",
                "TERMINAL_SEQUENCE_NUMBER": "Terminal-Sequenz-Nr.",
                "CARDS_TO_STACKER": "Karten ins Magazin",
                "CARDS_TO_BIN": "Karten in die Auffangbox",
                "CARDS_FROM_STACKER": "Karten aus Magazin",
                "CARDS_FROM_BIN": "Karten aus Auffangbox",
                "TICKET_OUT_MEDIA_NUMBER": "Ticket aus Medien-Nr.",
                "TICKET_IN_MEDIA_NUMBER": "Ticket ein Medien-Nr.",
                "WARNING": "Terminal-Meldung",
                "ATI_JACKPOT_FILL_I_D": "Jackpot-Fill-ID",
                "ATI_JACKPOT_TICKET_NUMBER": "Jackpot Ticket #",
                "CEC_TRANSACTION_I_D": "CEC-Transaktions-Nr.",
                "CREDIT_CARD_HOLDER": "Karteninhaber",
                "PIN_ALERT": "Pin Alarm",
                "APPROVING_OPERATOR_I_D": "Bestätigt durch",
                "BILL_ACCEPTOR_RANK": "Scheinakzeptor-Rang",
                "HOPPER_EMPTY_MODE": "Hopper-Leerungs-Modus",
                "JACKPOT_NAME": "Jackpot-Name",
                "TERMINAL_INFO": "Terminal-Information",
                "COMPONENT_MESSAGE": "Komponentennachricht",
                "PLAYER_MEDIA_DATA": "Spielerinformationen",
                "POS_JOURNAL": "POS-Journal",
                "EMV_ATM_TRANSACTION_TYPE": "EMV-ATM-Transaktionstyp",
                "ATM_ACQUIRER_RESPONSE_CODE": "ATM-Acquirer-Antwort-Code",
                "ATM_ACCOUNT": "ATM-Konto",
                "EMV_ATM_APPLICATION_I_D": "EMV-ATM-Anwendungs-ID",
                "EMV_ATM_APPLICATION_NAME": "EMV-ATM-Anwendungsname",
                "EMV_ATM_AUTHORIZATION_RESPONSE_CODE": "EMV-ATM-Autorisierungs-Antwort-Code",
                "EMV_ATM_AUTH_CODE": "EMV-ATM-Autorisierungs-Code",
                "ATM_SEQUENCE_NUMBER": "ATM-Sequenz-Nr.",
                "TRANSACTION_ABORT_STEP": "Transaktionsabbruchschritt",
                "EMV_KIOSK_TRANSACTION_OUTCOME": "EMV-Kiosk-Transaktionsergebnis",
                "EXTERNAL_TERMINAL_I_D": "External Terminal-ID",
                "JACKPOT_ADDITIONAL_INFO": "Jackpot-ZusatzInfo",
                "GROSS_AMOUNT_IN": "Bruttobetrag ein"
            },
            "LIFE_CYCLE_RECORD_TYPE": {
                "NONE": "None",
                "MACHINE_ACCEPTANCE_RESULTS": "Ergebnisse der Maschinenabnahme",
                "COMPONENT_REPLACED": "Komponente ersetzt",
                "PRODUCTION_ORDER": "Produktionsauftrag",
                "SYSTEM_INFORMATION": "System-Informationen",
                "MANUFACTURER_BLOCKING": "Sperrung durch Hersteller"
            },
            "LIFE_CYCLE_STEP": {
                "NONE": "Kein Status Gesetzt",
                "ASSEMBLED": "Zusammengebaut",
                "COMPONENTS_TESTED": "Komponenten Getestet",
                "READY_FOR_PRODUCTION": "Bereit für Produktion",
                "IN_TEST_LAB_USE": "In Testlabor",
                "IN_PRODUCTION_USE": "In Produktion",
                "SUSPENDED": "Gesperrt",
                "DECOMMISSIONED": "Außer Betrieb Genommen"
            },
            "LOGIN_STATE": {
                "NO_LOGIN": "No operator logged in",
                "CARD_INSERTED": "Operator {0} card inserted",
                "LOGGED_IN": "Operator {0} logged in",
                "INVALID_PASSWORD": "Operator {0} password incorrect",
                "SERVICE_LOGIN": "Service login",
                "NONE": "Operator {0} state N/A"
            },
            "MACHINE_STATE": {},
            "MAINTENANCE_TYPE": {
                "CLEANING": "Reinigung",
                "MAINTENANCE": "Wartung"
            },
            "MEDIA_COUNT_SOURCE": {
                "NONE": " ",
                "TERMINAL": "Terminal",
                "SERVER": "Server"
            },
            "MEDIA_COUNT_TYPE": {
                "None": " ",
                "OPENING": "Anfangsbestand",
                "CLOSING": "Schlussbestand",
                "CURRENT": "Aktuell",
                "FILLS": "Befüllungen",
                "CREDIT": "Entnahmen",
                "ROLL": "Übergabe"
            },
            "MEDIA_DIRECTION": {
                "IN": "Ein",
                "OUT": "Aus"
            },
            "MEDIUM_STATE": {
                "NONE": "Unbekannt",
                "MEDIA_ISSUED": " Registriert",
                "MEDIA_CHARGED": "Media charged",
                "MEDIA_REDEEMED": "Media redeemed",
                "MEDIA_CAPTURED": "Einbehalten",
            },
            "MEDIUM_TYPE": {
                "NONE": "Unbekannt",
                "REJECTED": "Rückweisung",
                "COIN": "Münze",
                "BILL": "Schein",
                "TICKET": "Ticket",
                "MAGNETIC_CARD": "Magnetkarte",
                "SMART_CARD": "Chipkarte",
                "RFID_CARD": "RFID-Karte",
                "CHIP": "Chip",
                "ATM": "ATM",
                "ATM_FEE1": "ATM-Gebühr-1",
                "ATM_FEE2": "ATM-Gebühr-2",
                "ATM_FEE3": "ATM-Gebühr-3",
                "DEPOSIT": "Pfand",
                "ROUNDING": "Rundung",
                "SAM": "SAM",
                "COUPON": "Coupon",
                "CEC": "CEC",
                "CEC_CASINO_FEE": "CEC-Casino-Gebühr",
                "CEC_PROVIDER_FEE": "CEC-Anbieter-Gebühr",
                "DONATION": "Spende",
                "LOAN_VALUE": "Darlehen aufgenommen",
                "LOAN_REPAY_VALUE": "Darlehen zurückgezahlt",
                "CREDIT_CARD": "Kreditkarte",
                "CLEARED_CREDIT": "Gelöschter Kredit",
                "MARKER": "Marker",
                "TAX": "Steuer",
                "POS": "POS",
                "POS_MERCHANT_FEE": "POS-Händler-Gebühr",
                "POS_PROVIDER_FEE": "POS-Anbieter-Gebühr",
                "E_WALLET_VALUE": "eWallet",
                "BILL_PAY": "Rechnung",
                "BILL_PAY_FEE": "Rechnungsgebühr",
                "INVOICE": "Rechnung",
                "CHECK": "Scheck",
                "CHECK_FEE": "Scheckgebühr",
                "GIFT_CARD": "Geschenkkarte",
                "GIFT_CARD_FEE": "Geschenkkarte-Gebühr",
                "MOBILE_TOP_UP": "Mobile Top-Up",
                "MOBILE_TOP_UP_FEE": "Mobile Top-Up-Gebühr",
                "WIRE_TRANSFER": "Geldüberweisung",
                "WIRE_TRANSFER_FEE": "Geldüberweisung-Gebühr",
                "FEE": "Gebühr",
                "PREPAID_CARD": "Prepaid-Karte",
                "CRYPTO_CURRENCY": "Kryptowährung",
                "CASH_ADVANCE_RECEIPT": "Cash-Advance-Beleg",
                "JACKPOT_VALUE": "Jackpot",
                "IOU_RECEIPT": "IOU-Beleg"
            },
            "MODULE_LICENSE": {
                "ADVERTISING": "Werbung",
                "GRCC_PAGING": "Grcc Ausruf",
                "PROPERTY_MANAGEMENT": "Filialverwaltung",
                "REPLICATION": "Replikation",
                "CARD_BURNING": "Karten brennen",
                "SHOP_MANAGEMENT": "Shopverwaltung",
                "CURRENCY_COUNTING": "Währungszählung",
                "TERMINAL_LICENSING": "Terminallizenzierung",
                "MULTI_CURRENCY": "Mehrere Währungen",
                "REMOTE_ACCESS": "Fernzugriff",
                "DEBIT_ACCOUNTS": "Debit-Konten",
                "PREVENTIVE_MAINTENANCE": "Vorbeugende Wartung",
                "FACE_RECOGNITION": "Gesichtserkennung",
                "ART_CLIENT_LICENSING": "ART-Client-Lizenzierung",
                "FACTORY_SUPPORT": "Fabrikunterstützung",
                "ART_CONNECT": "ART Connect",
            },
            "ONLINE_SYSTEM": {
                "NONE": "Keines",
                "MANUAL": "Manuell",
                "ATRONIC": "Atronic"
            },
            "OPERATOR_ROLE": {
                "OPERATOR": "Bediener",
                "COUNT_ONLY": "Geldzähler",
                "POINT_OF_SALE": "Verkaufspersonal",
                "AD_MANAGEMENT": "Werbeverwalter",
                "CASHIER": "Kassierer",
                "PIT_BOSS": "Pit Boss",
                "BROKER": "Broker",
            },
            "PLAYER_MEDIUM_STATE": {
                "NONE": "Unbekannt",
                "REGISTERED": "Registriert",
                "CAPTURED": "Einbehalten",
                "ONE_TIME_USE": "Einmalnutzung",
            },
            "PAYMENT_KIOSK_REPORT_TYPE": {
                "COMMISSION": "Kommission",
                "TRANSACTION": "Transaktionen",
                "CUSTOMER": "Kunden",
                "SIMPLE_TRANSACTION": "Transaktionen",
                "DISTRIBUTOR": "Distributor",
                "STORE": "Laden"
            },
            "REGISTRATION_TYPE": {
                "UNKNOWN": "Unbekannt",
                "SELF_OWNED": "Eigenbesitz",
                "RENTED": "Gemietet"
            },
            "SENSOR_RANK": {
                "UPPER_DOOR": "Obere Tür",
                "SAFE_DOOR": "Tresortür",
                "COIN_DISPENSER": "Münzausgeber",
                "BILL_DISPENSER": "Scheinausgeber",
                "BILL_ACCEPTOR": "Scheinakzeptor",
                "CARD_READER": "Kartenleser",
                "RECEIPT_PRINTER": "Belegdrucker",
                "SAFE_DOOR_KEY": "Tresortür Schlüssel"
            },
            "SPLIT_AMOUNT_TYPE": {
                "CASH_PAYOUT_AMOUNT": "Barauszahlungsbetrag",
                "KEYBACK_AMOUNT": "Keyback-Auszahlungsbetrag",
                "TICKET_PAYOUT_AMOUNT": "Ticketauszahlungsbetrag",
                "CHECK_PAYOUT_AMOUNT": "Scheckauszahlungsbetrag",
                "WALLET_PAYOUT_AMOUNT": "Wallet-Auszahlungsbetrag",
                "WIRE_ACH_PAYOUT_AMOUNT": "Wire/ACH-Auszahlungsbetrag"
            },
            "TERMINAL_CONNECTION_STATE": {
                "ALL": "Alle",
                "CONNECTED": "Verbunden",
                "NOT_CONNECTED": "Getrennt",
                "RECENTLY_SEEN": "Kürzlich Gesehen"
            },
            "TERMINAL_EVENT": {
                "OPEN_WITHOUT_LOGIN": "Door opened without login",
                "LOGIN_FAILED_WRONG_PASSWORD": "Login failed with wrong password",
                "COIN_ACCEPTOR_NOT_IN_END_POSITION": "Coin acceptor not in end position",
                "COIN_DISPENSER_NOT_IN_END_POSITION": "Coin dispenser not in end position",
                "ACCEPTOR_REMOVED_WHILE_DOOR_CLOSED": "Acceptor removed while door was closed",
                "DISPENSER_REMOVED_WHILE_DOOR_CLOSED": "Dispenser removed while door was closed",
                "HOPPER_UNDER_RUNS_MINIMAL_VALUE": "Hopper under runs minimal value",
                "DISPENSER_UNDER_RUNS_MINIMAL_VALUE": "Dispenser under runs minimal value",
                "ACCEPTOR_EXCEEDS_MAXIMUM_CAPACITY": "Acceptor exceeds maximum capacity",
                "TIME_SYNC_TO_OFTEN": "Time sync to often",
                "ALREADY_ASSIGNED_TERMINAL_ID": "Already assigned terminal ID",
                "MAXIMUM_NUMBER_OF_TERMINALS_REACHED": "Maximum number of terminals reached",
                "COMMUNICATION_PROBLEM": "Communication problem",
                "DOOR_OPENED": "Door opened",
                "SERVER_TIME_AND_TERMINAL_TIME_DIFFER": "Server time and terminal time has are different",
                "INVALID_PROPERTY_NUMBER": "Invalid property number",
                "PIN_ALERT": "Pin alert",
                "TRANSACTION_APPROVAL_REQUIRED": "Transaction approval is required",
                "TRANSACTION_APPROVED": "Transaction approved",
                "REMOTE_ACCESS_CONFIRMATION": "Remote access confirmation",
                "PLAYER_IS_BANNED": "Player is banned",
                "PORT_FORWARDING_STATUS": "Port forwarding status",
                "ONLINE_SYSTEM_MEDIA_INVALID_HASH": "Online system media invalid hash",
                "MAGNETICAL_CARD_READER_WRITE_ERROR": "Magnetical card reader write error",
                "CARD_JAMMED": "Card jammed"
            },
            "TERMINAL_STATE": {
                "OUT_OF_SERVICE": "Außer Betrieb",
                "READY": "Bereit",
                "IN_TRANSACTION": "In Transaktion",
                "FAILURE": "Störung",
                "FAILURE_DUE_OPERATOR": "Störung durch Bediener",
                "BLOCKED": "Gesperrt",
                "MANUFACTURED_BLOCKED": "Gesperrt durch Hersteller",
                "SHUT_OFF": "Ausgeschaltet",
                "ERROR": "Fehler",
                "NONE": "unbekannt",
            },
            "TERMINAL_UTILIZATION": {
                "NO_DATA": "keine Daten",
                "IDLE": "Leerlauf",
                "CUSTOMER_USAGE": "Kundennutzung",
                "CALL_OPERATOR": "Bediener erforderlich",
                "OUT_OF_OPERATION": "Außer Betrieb",
                "OPERATOR_USAGE": "Bedienernutzung",
                "MONEY_SERVICE": "Bestandsaufnahme",
                "JACKPOT_PAYOUT": "Jackpotauszahlung",
                "SHUT_OFF": "Ausgeschaltet",
                "OUT_OF_OPERATION_DUE_ERROR": "Außer Betrieb (Fehler)",
                "OUT_OF_OPERATION_DUE_OPERATOR": "Außer Betrieb (Bediener)",
                "OUT_OF_OPERATION_ERROR_DEV_MAINT_REQUIRED": "Gerätewartung erf.",
                "OUT_OF_OPERATION_ERROR_PRIMARY_CARD_READER": "Primärer Kartenleser",
                "OUT_OF_OPERATION_ERROR_SECONDARY_CARD_READER": "Sekundärer Kartenleser",
                "OUT_OF_OPERATION_ERROR_I_O_CONTROLLER": "I/O Controller",
                "OUT_OF_OPERATION_ERROR_BILL_DISPENSER": "Scheinausgeber",
                "OUT_OF_OPERATION_ERROR_COIN_DISPENSER": "Münzausgeber",
                "OUT_OF_OPERATION_ERROR_MONEY_SERVICE_REQUIRED": "Bestandsaufnahme erf.",
                "OUT_OF_OPERATION_ERROR_BILL_ACCEPTOR": "Scheinakzeptor",
                "OUT_OF_OPERATION_ERROR_NETWORK_COMMUNICATION": "Netzwerkkommunikation",
                "OUT_OF_OPERATION_ERROR_ONLINE_SYSTEM": "Online System",
                "OUT_OF_OPERATION_ERROR_ART_SERVER": "ART Server",
                "OUT_OF_OPERATION_OPERATOR_PUT_OFFLINE": "Maschine abgeschaltet",
                "OUT_OF_OPERATION_OPERATOR_LOGGED_IN": "Angemeldet",
                "REWARD_SYSTEM": "Belohnungssystem"
            },
            "TERMINAL_UTILIZATION_DASHBOARD": {
                "MONEY_SERVICE": "Bestandsaufnahme durchgeführt",
                "OUT_OF_OPERATION_OPERATOR_PUT_OFFLINE": "Maschine abgeschaltet",
                "SHUT_OFF": "Ausgeschaltet",
            },
            "TITA_RIGHT": {
                "LOGIN": "Anmelden",
                "CONFIGURATION": "Konfiguration",
                "APPROVE_TRANSACTIONS": "Transaktion bestätigen",
                "ADMINISTRATION": "Administration",
                "MAINTENANCE": "Wartung"
            },
            "TRANSACTION_FLAG": {
                "ROUNDING": "Rundung",
                "WARNING": "Warnung",
                "ERROR": "Fehler",
                "CREDIT_CLEARED": "Offener Kredit gelöscht",
                "PIN_ALERT": "Pin Alarm",
                "ADJUSTED": "Korrigiert",
                "ABORTED": "Abgebrochen",
                "CURRENCY_EXCHANGE": "Währungswechsel",
                "VOIDED": "Ungültig",
                "DECLINED": "Abgelehnt"
            },
            "TRANSACTION_TYPE": {
                // special entries for transactions categories
                "NON_CATEGORIZED": "Nicht kategorisiert",
                "CUSTOMER_TRANSACTIONS": "Kundentransaktionen",
                "JACKPOT": "Jackpot",
                "MONEY_SERVICE": "Bestandsaufnahme",
                "OPERATOR_TRANSACTIONS": "Bedienertransaktionen",
                "INTERNAL": "Intern",
                // individual entries for transaction types
                "UNDEFINED": "Undefined",
                "NONE": "Unbekannt",
                "COIN_REDEMPTION": "Münzeinnahme",
                "BILL_BREAKER": "Notenwechsel",
                "TICKET_REDEMPTION": "Ticketauszahlung",
                "MAGNETIC_CARD_REDEMPTION": "Magnetkartenauszahlung",
                "SMART_CARD_REDEMPTION": "Chipkartenauszahlung",
                "JACKPOT_PAYOUT": "Jackpotauszahlung",
                "FILL_BAG_FRONT": "vorderer Beutel gefüllt",
                "EMPTY_HOPPER": "Münzausgeber geleert",
                "JACKPOT_REAR": "Jackpot hinten",
                "FILL_BACK_REAR": "hinterer Beutel gefüllt",
                "CASSETTE_REMOVED": "Kassette entnommen",
                "CASSETTE_INSERTED": "Kassette eingesetzt",
                "BILL_VALIDATOR_EMPTIED": "Akzeptor geleert",
                "COINS_INSERTED": "Münzen eingesetzt",
                "REJECT_BOX_EMPTIED": "Rückweisungen geleert",
                "OPEN_CREDIT_CLEARED": "Offener Kredit gelöscht",
                "TEST_HOPPER": "Hoppertest",
                "TICKET_PRINTED": "Ticket ausgegeben",
                "MAGNETIC_CARD_CHARGED": "Magnetkarte aufgeladen",
                "SMART_CARD_CHARGED": "Chipkarte aufgeladen",
                "COINS_DISPENSED": "Münzauszahlung",
                "MEDIA_OUT_EMPTIED": "Medien aus geleert",
                "MEDIA_IN_EMPTIED": "Medien ein geleert",
                "CASH_DIFFERENCE": "Bargelddifferenz",
                "CASHBOX_FILLED": "Cashbox gefüllt",
                "CASHBOX_WITHDRAWN": "Cashbox geleert",
                "CASHBOX_RELOAD": "Cashbox neu geladen",
                "ATM_WITHDRAWAL": "ATM Abbuchung",
                "ATM_CASH_ADVANCE": "ATM Cash Advance",
                "MEDIA_REFILL": "Medienbefüllung",
                "ATM_BALANCE_INQUIRY": "ATM Kontostandsabfrage",
                "DEPOSIT_WITHHELD": "Pfand eingenommen",
                "DEPOSIT_PAID": "Pfand ausgezahlt",
                "ROUNDING_DIFFERENCE_CLEARED": "Rundungsdifferenz gelöscht",
                "SAM_TO_SUPER_SAM": "SAM-SuperSAM Transfer",
                "JACKPOT_CANCEL_CREDIT": "Jackpot Kundenguthaben",
                "JACKPOT_SHORT_PAY": "Jackpot Unterzahlung",
                "G_M_HOPPER_REFILL": "Münzauffüllung",
                "PROGRESSIVE_JACKPOT": "Progressive Jackpot",
                "CAPTURE_BIN_EMPTIED": "Auffangbehälter geleert",
                "STACKER_STOCK_ADDED": "Magazin gefüllt",
                "PRINTED_TICKETS_CLEARED": "Gedruckte Tickets gelöscht",
                "LOST_MONEY": "Fundgeld",
                "LOST_MONEY_CLEARED": "Fundgeld gelöscht",
                "JACKPOT_ADDITIONAL": "Jackpot zusätzlich",
                "COIN_ACCEPTOR_EMPTIED": "Münzakzeptor geleert",
                "POS_WITHDRAWAL": "POS Abbuchung",
                "CHIP_REDEMPTION": "Chipauszahlung",
                "POS_CLEARED": "POS gelöscht",
                "CHIPS_PAID_OUT": "Bargeld eingenommen",
                "COUPON_REDEMPTION": "Couponauszahlung",
                "CHECK_CASHING": "CEC Check Cashing",
                "CHECK_CASHING_CLEARED": "CEC Check Cashing gelöscht",
                "ATM_CLEARED": "ATM gelöscht",
                "TICKET_HAND_PAY_CLEARED": "Manuelle Ticket-Auszahlungen gelöscht",
                "DROP_BOX_EMPTIED": "Drop-Box geleert",
                "LOAN_RAISED": "Darlehen aufgenommen",
                "LOAN_REPAID_CASH": "Darlehen zurückgezahlt bar",
                "LOAN_REPAID_CARD": "Darlehen zurückgezahlt mit Karte",
                "LOANS_CLEARED": "Darlehen gelöscht",
                "DONATIONS_CLEARED": "Spenden gelöscht",
                "MARKER_REDEMPTION": "Markerauszahlung",
                "TAX_WITHHELD_CLEARED": "Steuereinbehalt gelöscht",
                "DEBIT_PAYOUT": "Debit-Auszahlung",
                "DEBIT_RETURN": "Debit-Rückzahlung",
                "CASHIER_CASH_PAYOUT": "Kassierer-Barauszahlung",
                "CASHIER_CASH_RETURN": "Kassierer-Barrückzahlung",
                "STACKER_EMPTIED": "Magazin geleert",
                "E_WALLET_WITHDRAWAL": "eWallet Abbuchung",
                "E_WALLET_DEPOSIT": "eWallet Aufladung",
                "E_WALLET_WITHDRAWAL_CLEARED": "eWallet Abbuchung gelöscht",
                "E_WALLET_DEPOSIT_CLEARED": "eWallet Pfand gelöscht",
                "REJECTED_BILL": "Zurückgewiesener Schein",
                "REJECTED_TICKET": "Zurückgewiesenes Ticket",
                "REJECTED_CARD": "Zurückgewiesene Karte",
                "TICKET_RECREATED": "Ticket neu erstellt",
                "BONUS_CARD_REDEMPTION": "Bonuskartenauszahlung",
                "BONUS_CARD_CHARGED": "Bonuskarte aufgeladen",
                "BILL_PAY": "Rechnung bezahlt",
                "CHECK_CASHING_PAYMENT": "Scheckeinlösung",
                "MOBILE_TOP_UP": "Mobile top-up",
                "GIFT_CARD_SALE": "Geschenkkartenverkauf",
                "BILL_PAY_CLEARED": "Rechnung bezahlt gelöscht",
                "CHECK_CASHING_PAYMENT_CLEARED": "Scheckeinlösung gelöscht",
                "MOBILE_TOP_UP_CLEARED": "Mobile top-up gelöscht",
                "GIFT_CARD_SALE_CLEARED": "Geschenkkartenverkauf gelöscht",
                "WIRE_TRANSFER": "Geldüberweisung",
                "WIRE_TRANSFER_CLEARED": "Geldüberweisung gelöscht",
                "DEBIT_CARD_SALE": "Debitkartenverkauf",
                "DEBIT_CARD_SALE_CLEARED": "Debitkartenverkauf gelöscht",
                "GIFT_CARD_BUY": "Geschenkkartenkauf",
                "CRYPTO_CURRENCY_BUY": "Kryptowährengskauf",
                "PREPAID_CARD_CHARGED": "Prepaidkartenaufladung",
                "GIFT_CARD_BUY_CLEARED": "Geschenkkartenkauf gelöscht",
                "CRYPTO_CURRENCY_BUY_CLEARED": "Kryptowährungskauf gelöscht",
                "PREPAID_CARD_CHARGED_CLEARED": "Prepaidkartenaufladung gelöscht",
                "I_D_SCANNER_CLEARED": "ID Scanner gelöscht",
                "CRYPTO_CURRENCY_SALE": "Kryptowährungsverkauf",
                "CRYPTO_CURRENCY_SALE_CLEARED": "Kryptowährungsverkauf gelöscht",
                "BOOKING_ACCOUNT_CLEARED": "Buchungskonto gelöscht",
                "PREPAID_CARD_ISSUED": "Prepaidkarte ausgegeben",
                "FEE_WITHHELD_CLEARED": "Gebühreneinbehalt gelöscht",
                "POS_CASH_ADVANCE": "POS-Cash-Advance",
                "IOU_RECEIPT_REDEMPTION": "IOU-Beleg-Auszahlung",
                "PRINTED_IOU_RECEIPTS_CLEARED": "Gedruckte IOU-Belege gelöscht",
                "RFID_CARD_REDEMPTION": "RFID-Kartenauszahlung",
                "RFID_CARD_CHARGED": "RFID-Karte aufgeladen",
            },
            "UPDATE_TERM_VIEW_EVENT_TYPE": {},
            "UPDATE_VIEW_EVENT_TYPE": {},
            "USER_RIGHTS": {
                "ADMINISTRATION": "Administration",
                "ART_OVERVIEW": "ART-Übersicht",
                "USERS_VIEW": "Benutzerverwaltung",
                "CONFIGURATION_VIEW": "Konfigurationseinträge",
                "MAINTENANCE_OVERVIEW": "Wartungsübersicht",
                "DASHBOARD_VIEW": "Dashboard",
                "QUARTERLY_DASHBOARD_VIEW": "Quartals-Dashboard",
                "TERMINAL_LIFECYCLE_VIEW": "Terminal-Lebenslaufakte",
                "COMPUTER_REGISTRATION_VIEW": "Computer-Registrierung",
                "MANUFACTURING_ORDER_OVERVIEW": "Fertigungsauftragsübersicht",
                "LICENSE_KEY_USAGE_OVERVIEW": "Lizenzschlüsselübersicht",
                "MAP_VIEW": "Kartenansicht",
                "LOGIN_ENABLED": "Anmeldung aktiviert",
                "STOCK_OVERVIEW": "Bestandsübersicht",
                "COMPONENT_OVERVIEW": "Komponentenübersicht",
                "FILL_LEVEL_VIEW": "Füllstandsansicht",
                "MANUFACTURING_ORDERS": "Fertigungsaufträge",
                "KIOSK_REGISTRATION": "Kiosk-Registrierung",
            },
            "VERSION": {
                "NONE": "kein",
                "SOFTWARE": "Software",
                "CONFIGURATION": "Konfiguration",
                "SURVEILLANCE_PROTOCOL": "Überwachungsprotokoll",
                "COS_INTERFACE": "COS-Schnittstelle",
                "COS": "COS",
                "JOS_INTERFACE": "JOS-Schnittstelle",
                "JOS": "JOS",
                "MON_INTERFACE": "MON-Schnittstelle",
                "MON": "MON",
                "SOFTWARE_LIB": "CLib",
                "OPERATING_SYSTEM": "Betriebsystem",
                "RUNTIME_ENVIRONMENT": "Laufzeitumgebung",
                "TABLE_I_D": "Table-ID",
                "ATM_PROXY": "ATM-Proxy",
                "COS_PROXY": "COS-Proxy",
                "COS_MOD_COS_INTERFACE": "COSMOD-COS-Schnittstelle",
                "COMPONENT_TEST": "Komponenten-Test",
                "REMOTE_DESKTOP_SERVICE": "Remote Desktop-Dienst",
                "SERVICE_CARDS_FILE": "Service-Karten-Datei"
            }
        },
        "DEVMESSAGES": {
            "MSG_1": "Maschine gestartet",
            "MSG_2": "Kundenanimation gestartet",
            "MSG_3": "Maschine außer Betrieb",
            "MSG_4": "Bedieneranwesenheit erforderlich",
            "MSG_5": "Bediener angemeldet ({0}, {1})",
            "MSG_6": "Wechsel in die Bestandsaufnahme",
            "MSG_7": "Bestandsaufnahme verlassen",
            "MSG_8": "Wechsel in die Wartung",
            "MSG_9": "Wartung verlassen",
            "MSG_10": "Bediener abgemeldet",
            "MSG_11": "Maschine wieder betriebsbereit",
            "MSG_12": "Maschine nicht betriebsbereit",
            "MSG_13": "Software-Neustart erforderlich",
            "MSG_14": "Terminal-Neustart erforderlich",
            "MSG_15": "Aufforderung zum Herunterfahren",
            "MSG_16": "Stromversorgung unterbrochen",
            "MSG_17": "Software-Aktualisierung eingespielt",
            "MSG_18": "Maschine heruntergefahren",
            "MSG_19": "Transaktion gestartet",
            "MSG_20": "Transaktion Ende Einnahmen",
            "MSG_21": "Transaktion Ende Ausgaben",
            "MSG_22": "Transaktion geschlossen",
            "MSG_23": "Transaktion abgebrochen",
            "MSG_24": "Transaktionstyp geändert in {0}",
            "MSG_27": "Personal benötigt",
            "MSG_28": "Wartung erforderlich",
            "MSG_998": "Kommandoparameter ungültig",
            "MSG_999": "Unbekannter Fehler",
            "MSG_1000": "Keine Kommunikation mit dem Gerät",
            "MSG_1001": "Kommunikationsfehler",
            "MSG_1002": "Unbekannter Steuerbefehl",
            "MSG_1003": "Kommunikationsfenster-Fehler",
            "MSG_1004": "Gerät ist beschäftigt",
            "MSG_1005": "Kommunikation wieder hergestellt",
            "MSG_1006": "Gerät nicht gefunden",
            "MSG_1007": "Datei nicht gefunden ({0})",
            "MSG_1008": "Externer Programmfehler ({0})",
            "MSG_1009": "Kommunikation hergestellt mit {0} ({1})",
            "MSG_1010": "Abhängige Komponente {0} hat ihren Status geändert nach {1}",
            "MSG_1011": "{0}",
            "MSG_1012": "Kommunikationsfehler mit {0}",
            "MSG_1013": "Gerät nicht initialisiert",
            "MSG_1100": "Akzeptor ist in Start-Modus",
            "MSG_1101": "Start mit Schein in Akzeptor",
            "MSG_1102": "Start mit Schein einbehalten",
            "MSG_1103": "Kommunikationsfehler während Start",
            "MSG_1104": "Stacking-Fehler. Schein oder Ticket wahrscheinlich zurückgegeben: {0:X2}",
            "MSG_1105": "Stacking-Fehler. Schein oder Ticket wahrscheinlich einbehalten: {0:X2}",
            "MSG_1106": "Stacker-Box voll",
            "MSG_1107": "Stacker-Box entfernt",
            "MSG_1108": "Stau in Akzeptor",
            "MSG_1109": "Stau in Stacker",
            "MSG_1110": "Akzeptor getauscht",
            "MSG_1120": "Stack-Motor Fehlfunktion",
            "MSG_1121": "Transport-Motor Geschwindigkeitsfehler",
            "MSG_1122": "Transport-Motor Fehlfunktion",
            "MSG_1123": "Geld-Box Fehlfunktion",
            "MSG_1124": "Prüfer-Kopf entfernt",
            "MSG_1125": "Boot ROM Fehlfunktion",
            "MSG_1126": "Externer ROM Fehlfunktion",
            "MSG_1127": "ROM Fehlfunktion",
            "MSG_1128": "Externer ROM Schreibfehler",
            "MSG_1129": "Geänderte Firmware. Bitte Banknoten-Referenzen prüfen und Software neu starten",
            "MSG_1130": "Akzeptor-Kopf ist offen",
            "MSG_1440": "Scheine in der Bündelausgabeeinheit",
            "MSG_1453": "Fehler im Umleiter",
            "MSG_1454": "Defekt beim Entnehmen aus Kassette",
            "MSG_1455": "Übertragungsfehler",
            "MSG_1456": "Ungültiges Kommando oder Kommandosequenz",
            "MSG_1457": "Stau im Scheinqualifizierer",
            "MSG_1458": "Kassette nicht vorhanden oder richtig installiert",
            "MSG_1460": "Keine Scheine eingezogen",
            "MSG_1463": "Rückweisungsbehälter nicht vorhanden oder richtig installiert",
            "MSG_1464": "Fehler bei der Ausgabe",
            "MSG_1465": "Rückweisungsdefekt",
            "MSG_1466": "Zu viele Scheine angefordert",
            "MSG_1467": "Stau im Scheinentnahme-Transport",
            "MSG_1468": "Rückweisungsbehälter fast voll",
            "MSG_1469": "Interner Kassettendefekt",
            "MSG_1470": "Hauptmotordefekt",
            "MSG_1471": "Abgewiesener Cheque",
            "MSG_1473": "Scheinprüfer fehlerhaft",
            "MSG_1474": "Scheinzuführung Ausgangssensorfehler",
            "MSG_1475": "Fehler in der Blende",
            "MSG_1477": "Scheine im Ausgabeschacht",
            "MSG_1478": "Zeitüberschreitung bei der Kommunikation",
            "MSG_1480": "Kassette nicht identifiziert",
            "MSG_1481": "Rückweisungsbehälter voll",
            "MSG_1487": "Fehler im Ausgabeschacht",
            "MSG_1491": "Sensorfehler oder Sensor verdeckt",
            "MSG_1496": "Interner Fehler im NMD",
            "MSG_1497": "Kassettenschloss defekt",
            "MSG_1498": "Fehler im Scheinstapelbereich",
            "MSG_1499": "Modul erfordert Wartung",
            "MSG_1501": "Keine Nachricht zum Nachsenden",
            "MSG_1504": "Fehler im Scheintransport",
            "MSG_1506": "Keine Daten verfügbar",
            "MSG_1601": "Kassette entfernt",
            "MSG_1602": "Kassette eingeführt aber unverschlossen",
            "MSG_1603": "Kassette eingeführt aber blockiert",
            "MSG_1604": "Kassette eingeführt und verschlossen",
            "MSG_1608": "Kassettenstatus in Ordnung",
            "MSG_1609": "Kassettenfüllstand niedrig",
            "MSG_1610": "Kassette leer",
            "MSG_1611": "Fehler im Umleiter",
            "MSG_1612": "Defekt beim Entnehmen",
            "MSG_1613": "Kassette nicht vorhanden",
            "MSG_1614": "Rückweisungsbehälter nicht vorhanden",
            "MSG_1615": "Interner Kassettedefekt",
            "MSG_1616": "Ausgangssensordefekt",
            "MSG_1617": "Kassettenschloss defekt",
            "MSG_1618": "Modul erfordert Wartung",
            "MSG_1619": "Unspezifischer Kassettenfehler",
            "MSG_1620": "Weniger als zwei Kassetten funktionieren",
            "MSG_1621": "Zweitkleinster Schein kann nicht gewechselt werden",
            "MSG_1622": "Zu viele Entnahmefehler, setze internen Fehler",
            "MSG_1623": "Bestandsaufnahme erforderlich",
            "MSG_1624": "Kassette nicht initialisiert",
            "MSG_1631": "Zurückgewiesen in Anlernsequenz",
            "MSG_1632": "Zurückgewiesener Schein wurde doppelt erkannt",
            "MSG_1633": "Zurückgewiesener Schein war zu schief",
            "MSG_1634": "Zurückgewiesener Schein war zu lang am DD Sensor A",
            "MSG_1635": "Zurückgewiesener Schein was zu lang am DD Sensor B",
            "MSG_1636": "Zurückgewiesener Schein was zu kurz am DD Sensor A",
            "MSG_1637": "Zurückgewiesener Schein was zu kurz am DD Sensor B",
            "MSG_1638": "Zurückgewiesener Schein war zu nahe an vorherigem Schein",
            "MSG_1639": "Zurückweisungen während Transaktionsabbruch auf Grund des Status 'Fatal'",
            "MSG_1640": "Zurückgewiesener Schein gesehen am DD aber nicht vom NF-Ausgangssensor",
            "MSG_1641": "Zurückgewiesener Schein wurde erkannt als dünn",
            "MSG_1642": "Scheine zurückgewiesen weil Diverter nicht bewegbar von Rückweisung nach Ausgabe",
            "MSG_1643": "Scheine sind zu nah, beide zurückgewiesen",
            "MSG_1644": "Scheine sind zu nah, beide zurückgewiesen",
            "MSG_1645": "Scheine zurückgewiesen weil Diverter nicht bewegbar von Ausgabe nach Rückweisung",
            "MSG_1646": "Schein zurückgewiesen in Anlernsequenz",
            "MSG_1647": "Scheine zurückgewiesen unbekannt",
            "MSG_1648": "Zurückgewiesener Schein war zu spät am Scheinqualifizierer",
            "MSG_1649": "Zurückgewiesen während einem Kassettenschließkommando",
            "MSG_1650": "Zurückgewiesener Schein lang im Kanal A aber zwei Scheine im Kanal B",
            "MSG_1651": "Zurückgewiesener Schein lang im Kanal B aber zwei Scheine im Kanal A",
            "MSG_1652": "Zurückgewiesener Schein hat den Scheinqualifizierer nicht passiert",
            "MSG_1653": "Zurückgewiesen während eines 'Test Move Forward'-Kommandos",
            "MSG_1654": "Scheine zurückgewiesen unbekannt",
            "MSG_1710": "Kartenmagazin ist leer",
            "MSG_1711": "Kartenmagazin ist benutzbar",
            "MSG_1712": "Kartenstau im Kartenleser",
            "MSG_1713": "Keine Karte im Kartenleser",
            "MSG_1714": "Karte schon im Kartenleser",
            "MSG_1715": "Kartenleser RTC-Fehler",
            "MSG_1716": "Zu viele Karten im Kartenleser",
            "MSG_1717": "Kartenfehler",
            "MSG_1718": "Kartenausgeberfehler",
            "MSG_1719": "Keine Karten im Magazin",
            "MSG_1720": "No MSRW reader unit present",
            "MSG_1721": "MSRW write error",
            "MSG_1722": "MSRW read error",
            "MSG_1723": "IC contact error",
            "MSG_1724": "IC control error",
            "MSG_1725": "IC Chip Kommunikation abgebrochen",
            "MSG_1760": "Atronic card key system mismatch {0}",
            "MSG_1761": "Atronic card currency mismatch {0}",
            "MSG_1762": "Atronic card expired {0}",
            "MSG_1763": "Atronic card locked {0}",
            "MSG_1764": "Atronic card casino ID mismatch {0}",
            "MSG_1765": "Atronic reset casino ID failure {0}",
            "MSG_1766": "Atronic SAM credit failure {0}",
            "MSG_1767": "Atronic SAM debit failure {0}",
            "MSG_1768": "Atronic user card credit failure {0}",
            "MSG_1769": "Atronic user card debit failure {0}",
            "MSG_1770": "Atronic card with no value captured {0}",
            "MSG_1771": "Atronic card amount over limit {0}",
            "MSG_1772": "Atronic card with bonus value returned {0}",
            "MSG_1773": "Atronic card black listed {0}",
            "MSG_1780": "Card amount not payable {0}",
            "MSG_1900": "Hopperfehler",
            "MSG_2000": "Ticketdrucker hat kein Papier",
            "MSG_2001": "Ticketdrucker hat wenig Papier",
            "MSG_2002": "Ticketdrucker hat ausreichend Papier",
            "MSG_2003": "Ticketdrucker Fehlfunktion",
            "MSG_2100": "Kein Papier",
            "MSG_2101": "Wenig Papier",
            "MSG_2102": "Ausreichend Papier",
            "MSG_2103": "Drucker nicht betriebsbereit",
            "MSG_2104": "Drucker ausgeschaltet",
            "MSG_2105": "Drucker nicht verbunden",
            "MSG_2106": "Druckkopf ist offen",
            "MSG_2107": "Drucker Markierung Fehler",
            "MSG_2108": "Drucker Markierung gewählt",
            "MSG_2109": "Drucker Markierung Zeitüberschreitung",
            "MSG_2110": "Drucker Datei E/A Fehler",
            "MSG_2111": "Drucker Datei nicht gefunden",
            "MSG_2112": "Drucker Netz E/A Fehler",
            "MSG_2113": "Drucker Netz-Socket Fehler",
            "MSG_2114": "Drucker Parallel Ereignis Puffer",
            "MSG_2115": "Drucker Parallel Ereignis Fehler",
            "MSG_2116": "Drucker-Port E/A Fehler",
            "MSG_2117": "XML-Drucker nicht gefunden",
            "MSG_2300": "Safetür offen",
            "MSG_2301": "Safetür geschlossen",
            "MSG_2302": "Obere Tür offen",
            "MSG_2303": "Obere Tür geschlossen",
            "MSG_2310": "Hoppermodul herausgezogen",
            "MSG_2311": "Hoppermodul in Position",
            "MSG_2312": "Notenausgeber herausgezogen",
            "MSG_2313": "Notenausgeber in Position",
            "MSG_2314": "Notenakzeptor herausgezogen",
            "MSG_2315": "Notenakzeptor in Position",
            "MSG_2316": "Kartenleser herausgezogen",
            "MSG_2317": "Kartenleser in Position",
            "MSG_2318": "Drucker herausgezogen",
            "MSG_2319": "Drucker in Position",
            "MSG_2320": "Safetür aufgeschlossen",
            "MSG_2321": "Safetür abgeschlossen",
            "MSG_2330": "Kabelbaumkonfiguration ist fehlerhaft",
            "MSG_2340": "Kiosk wegen Standortänderung gesperrt {0} -> {1}",
            "MSG_2341": "Kiosk-Sperrung aufgehoben von {0} (ID: {1})",
            "MSG_2380": "Kein Zeitgeber verfügbar",
            "MSG_2400": "Stromversorgung wiederhergestellt",
            "MSG_2401": "Stromversorgung unterbrochen",
            "MSG_2402": "Batteriestand niedrig",
            "MSG_2500": "Schlüssel ausgetauscht",
            "MSG_2501": "Schlüssel nicht ausgetauscht",
            "MSG_2502": "Schlüssel unsicher, nur ein DES/DES2-Schlüssel wurde übermittelt",
            "MSG_2503": "Leerer/ungültiger Schlüssel von Bank erhalten",
            "MSG_2504": "Krypto-Tastatur nicht aktiviert",
            "MSG_2505": "Krypto-Tastatur nicht pre-aktiviert",
            "MSG_2506": "Krypto-Tastatur: Entfernungssensoren nicht eingerastet",
            "MSG_2507": "Krypto-Tastatur Fehler beim Generieren des neuen DUKPT-Schlüssels auf Anfrage",
            "MSG_2508": "Schlüssel nicht gefunden",
            "MSG_2600": "Keine Casino-ID gesetzt, bitte Software neustarten",
            "MSG_2700": "ATM (EMV) Start fehlgeschlagen",
            "MSG_2701": "ATM (EMV) nicht konfiguriert",
            "MSG_2702": "Acquirer-Verbindung fehlgeschlagen",
            "MSG_2703": "Ungültiger Transaktionsstart",
            "MSG_2704": "Ungültiges Auszahlungsereignis",
            "MSG_2705": "Transaktion abgebrochen bei Quasi Cash Print",
            "MSG_2706": "ATM (EMV) Zeitüberschreitung beim Drucken",
            "MSG_2707": "ATM (EMV) ungewöhnliche Bildschirmfreigabe",
            "MSG_2708": "Unerlaubte Auszahlungsanfrage",
            "MSG_2709": "Transaktion abgebrochen nach Auszahlung",
            "MSG_2710": "ATM-Proxy Anmeldefehler",
            "MSG_2711": "ATM-Proxy Verbindungsfehler",
            "MSG_2712": "Acquirer-Verbindung hergestellt",
            "MSG_2713": "Acquirer-Verbindungsstatus unbekannt",
            "MSG_2714": "Kunde wurde abgelehnt jedoch belastet. Kontaktieren Sie den Acquirer.",
            "MSG_2715": "ATM-Proxy verbunden und angemeldet",
            "MSG_2800": "Transaktion abgelehnt",
            "MSG_2801": "PIN-Pad nicht bereit",
            "MSG_2802": "Kartenleser nicht bereit",
            "MSG_2803": "Wartungsmodus",
            "MSG_2804": "Initialisierung fehlgeschlagen",
            "MSG_2805": "Währung {0} nicht unterstützt",
            "MSG_2806": "Ungültige Karte",
            "MSG_2807": "Ungültige PIN",
            "MSG_2808": "Kundenabbruch",
            "MSG_2809": "Keine Antwort vom Gerät",
            "MSG_2810": "Abrechnung fehlgeschlagen",
            "MSG_2811": "Zu viele Abrechnungsfehler",
            "MSG_2900": "{0} von {1} COSMODs verbunden",
            "MSG_2901": "Kommunikation mit {0} unterbrochen",
            "MSG_2902": "Kommunikation mit {0} hergestellt",
            "MSG_3000": "Unbekannter Fehler",
            "MSG_3001": "Voll",
            "MSG_3002": "Fast voll",
            "MSG_3003": "Fast leer",
            "MSG_3004": "Leer",
            "MSG_3005": "Nicht eingelegt",
            "MSG_3006": "Nicht benutzbar",
            "MSG_3007": "Fehlerzustand berichtet ({0})",
            "MSG_3008": "Auszahlungfehler",
            "MSG_3009": "Maschinenintegrität verletzt",
            "MSG_3010": "Keine SAM gefunden",
            "MSG_3011": "Medien-ID nicht ungültig",
            "MSG_3012": "Offen",
            "MSG_3013": "Bewegt",
            "MSG_3014": "Optischer Fehler (möglicherweise Münzstau)",
            "MSG_3015": "Geschlossen",
            "MSG_3016": "An Endposition",
            "MSG_3200": "Watchdog error",
            "MSG_3201": "(PR)Internal error",
            "MSG_3202": "Internal error",
            "MSG_3203": "Bill pool section home position error",
            "MSG_3204": "Bill pool section upper position error",
            "MSG_3205": "No bill in pool section",
            "MSG_3209": "Error during device reset",
            "MSG_3210": "Medium remain",
            "MSG_3290": "Internal contradiction",
            "MSG_3291": "Not start of DC motor",
            "MSG_3292": "Internal contradiction",
            "MSG_3300": "JAM PSn - BPxS",
            "MSG_3360": "JAM BPS - GSS",
            "MSG_3370": "JAM GSS - CPS",
            "MSG_3380": "JAM GSS - RJS",
            "MSG_3390": "JAM POS - EJS1",
            "MSG_3410": "JAM Bill Retrieval",
            "MSG_3418": "JAM occurred between GSS and RJS",
            "MSG_3420": "Skew error for cassette {0}",
            "MSG_3430": "Long bill error for cassette {0}",
            "MSG_3440": "Short bill error for cassette {0}",
            "MSG_3449": "Thickness abnormal",
            "MSG_3450": "Pick from another safe for cassette {0}",
            "MSG_3460": "Spacing between picked notes is less than specified",
            "MSG_3461": "Bill count mismatch 00",
            "MSG_3462": "Bill count mismatch 01 (CPS was turned ON when there was no bill)",
            "MSG_3463": "Bill count mismatch 02 (number of measured thickness values > number of notes)",
            "MSG_3464": "Bill count mismatch 03 (medium passed through CPS while clearing jam)",
            "MSG_3470": "Potentiometer error {0}",
            "MSG_3471": "Potentiometer fatal error {0}",
            "MSG_3500": "Front shutter open error",
            "MSG_3501": "Front shutter open error (SCS does not change)",
            "MSG_3502": "Front shutter open error (SOS does not change)",
            "MSG_3503": "Front shutter open error (Simultaneous sensor detection)",
            "MSG_3504": "Front shutter close error",
            "MSG_3505": "Front shutter close error (SOS does not change)",
            "MSG_3506": "Front shutter close error (SCS does not change)",
            "MSG_3507": "Front shutter close error (Simultaneous sensor detection)",
            "MSG_3508": "No bills in the ejection opening",
            "MSG_3509": "Open shutter",
            "MSG_3510": "Reject box full",
            "MSG_3520": "RAS command undefined",
            "MSG_3521": "Parameter not registered",
            "MSG_3522": "(PR)Print date error",
            "MSG_3523": "No bill information",
            "MSG_3524": "Specification error",
            "MSG_3525": "Parameter ISO error",
            "MSG_3526": "Bill length/thickness information error",
            "MSG_3527": "Parameter error",
            "MSG_3528": "Command format error",
            "MSG_3540": "BD not set",
            "MSG_3541": "Pulse motor overcurrent",
            "MSG_3542": "DC motor overcurrent",
            "MSG_3543": "Log data checksum error",
            "MSG_3544": "Sensor error",
            "MSG_3545": "Illegal operation error",
            "MSG_3546": "Power off during count",
            "MSG_3547": "Option setup is unusual",
            "MSG_3548": "Command execution is impossible",
            "MSG_3549": "FS error",
            "MSG_3550": "No bills in the rear ejection opening",
            "MSG_3551": "Rear shutter open",
            "MSG_3552": "Illegal operation error due to non-notification of data",
            "MSG_3590": "Rear shutter open error",
            "MSG_3591": "Rear shutter open error (SCSR does not change)",
            "MSG_3592": "Rear shutter open error (SOSR does not change)",
            "MSG_3593": "Rear shutter open error (Simultaneous sensor detection)",
            "MSG_3594": "Rear shutter close error",
            "MSG_3595": "Rear shutter close error (SOSR does not change)",
            "MSG_3596": "Rear shutter close error (SCSR does not change)",
            "MSG_3597": "Rear shutter close error (Simultaneous sensor detection)",
            "MSG_3601": "1st cassette denomination differs, perform bill diagnosis",
            "MSG_3602": "2nd cassette denomination differs, perform bill diagnosis",
            "MSG_3603": "3rd cassette denomination differs, perform bill diagnosis",
            "MSG_3604": "4th cassette denomination differs, perform bill diagnosis",
            "MSG_3605": "5th cassette denomination differs, perform bill diagnosis",
            "MSG_3606": "6th cassette denomination differs, perform bill diagnosis",
            "MSG_3800": "Notes are delivered out when purging the transport",
            "MSG_3801": "Notes are retracted when purging the transport",
            "MSG_3802": "Returned log data is not complete",
            "MSG_3803": "Log data is read",
            "MSG_3804": "Log data is null",
            "MSG_3805": "Fail to write EEPROM when dispensing cash",
            "MSG_3806": "No note at stacking mechanism",
            "MSG_3807": "No note in PCS hold position",
            "MSG_3808": "No note at presenter",
            "MSG_3809": "Note enters the note reject vault when purging the transport",
            "MSG_3810": "Note is removed from exit during retraction",
            "MSG_3811": "FCS1 is dirty",
            "MSG_3812": "FCS2 is dirty",
            "MSG_3813": "FCS3 is dirty",
            "MSG_3814": "FCS4 is dirty",
            "MSG_3815": "FCS5 is dirty",
            "MSG_3816": "FCS6 is dirty",
            "MSG_3817": "SLS is dirty",
            "MSG_3818": "MEDS is dirty",
            "MSG_3819": "SRFS/RFS is dirty",
            "MSG_3820": "TES is dirty",
            "MSG_3821": "BRFS/IRFS is dirty",
            "MSG_3822": "SCS is dirty",
            "MSG_3823": "PCS is dirty",
            "MSG_3824": "BRCS is dirty",
            "MSG_3825": "PES is dirty",
            "MSG_3830": "Internal pointer error",
            "MSG_3831": "Note pointer error",
            "MSG_3832": "Command frame error",
            "MSG_3833": "Invalid command",
            "MSG_3834": "Frame check error",
            "MSG_3835": "Unauthorized",
            "MSG_3836": "Check error of EEPROM data",
            "MSG_3837": "Operation error of EEPROM",
            "MSG_3838": "ID error of Flash chip",
            "MSG_3839": "Error of erasing Flash chip",
            "MSG_3840": "Operation error of Flash chip",
            "MSG_3841": "Error of writing Flash chip",
            "MSG_3842": "DAC chip error",
            "MSG_3843": "Internal error of CDM8240N",
            "MSG_3870": "Invalid ID of cassette, or no cassette in position",
            "MSG_3871": "Cash dispenser internal cassette error",
            "MSG_3875": "Single/retract box is full",
            "MSG_3876": "Bundle/internal reject vault is full",
            "MSG_3880": "Feeding timeout",
            "MSG_3881": "Notes are too close",
            "MSG_3882": "Excessive number of notes retracted in one time",
            "MSG_3883": "Single/retract diverter fails to move up",
            "MSG_3884": "Single/retract diverter fails to move up",
            "MSG_3885": "Note exists in MEDS",
            "MSG_3886": "Note exists in TES",
            "MSG_3887": "Bundle/internal reject diverter fails to move down",
            "MSG_3888": "Bundle/internal reject diverter fails to move up",
            "MSG_3889": "Note delivery/pre-delivery fails",
            "MSG_3890": "Note exists in PES",
            "MSG_3891": "Abnormal note delivery: note exists in PES",
            "MSG_3892": "Error of note presence in PCS",
            "MSG_3893": "Error of note presence in SCS",
            "MSG_3894": "Main motor is jammed",
            "MSG_3895": "Notes are jammed at FCS1",
            "MSG_3896": "Notes are jammed between FCS1 and SLS",
            "MSG_3897": "Notes are jammed at FCS2",
            "MSG_3898": "Notes are jammed between FCS2 and SLS",
            "MSG_3899": "Notes are jammed at FCS3",
            "MSG_3900": "Notes are jammed between FCS3 and SLS",
            "MSG_3901": "Notes are jammed at FCS4",
            "MSG_3902": "Notes are jammed between FCS4 and SLS",
            "MSG_3903": "Notes are jammed at FCS5",
            "MSG_3904": "Notes are jammed between FCS5 and SLS",
            "MSG_3905": "Notes are jammed at FCS6",
            "MSG_3906": "Notes are jammed between FCS6 and SLS",
            "MSG_3907": "Notes are jammed at SLS",
            "MSG_3908": "Notes are jammed between SLS and MEDS",
            "MSG_3909": "Notes are jammed at MTS",
            "MSG_3910": "Notes are jammed at MEDS",
            "MSG_3911": "Notes are jammed between MEDS and TES",
            "MSG_3912": "Notes are jammed at TES",
            "MSG_3913": "Notes are jammed at SRFS/RFS",
            "MSG_3914": "Notes are jammed between MEDS and SRFS/RFS",
            "MSG_3915": "Notes are jammed at BRFS/IRFS",
            "MSG_3916": "Notes are jammed at SCS",
            "MSG_3917": "Notes are jammed at PES",
            "MSG_3918": "Notes are jammed at PCS",
            "MSG_3919": "Stacker motor is jammed",
            "MSG_3920": "Notes are jammed between PES or PCS and BRFS/IRFS",
            "MSG_3921": "Notes are jammed between PCS and PES",
            "MSG_3922": "Notes are jammed between SCS and PCS",
            "MSG_3923": "Notes are jammed between MEDS and BRFS/IRFS",
            "MSG_3924": "Notes are jammed between SCS and MEDS",
            "MSG_3925": "Notes are jammed between PES and PCS",
            "MSG_3926": "Notes are jammed between PCS and MEDS",
            "MSG_3927": "Notes are jammed between MEDS and PCS",
            "MSG_3928": "Notes are jammed at BRCS",
            "MSG_3930": "FCS1 fails",
            "MSG_3931": "FCS2 fails",
            "MSG_3932": "FCS3 fails",
            "MSG_3933": "FCS4 fails",
            "MSG_3934": "FCS5 fails",
            "MSG_3935": "FCS6 fails",
            "MSG_3936": "SLS fails",
            "MSG_3937": "MEDS fails",
            "MSG_3938": "SRFS/RFS fails",
            "MSG_3939": "TES fails",
            "MSG_3940": "BRFS/IRFS fails",
            "MSG_3941": "SCS fails",
            "MSG_3942": "PCS fails",
            "MSG_3943": "BRCS fails",
            "MSG_3944": "PES fails",
            "MSG_3950": "Invalid parameter error",
            "MSG_3951": "Number of notes exceeds the maximum",
            "MSG_3952": "Note dispensing mode is wrong",
            "MSG_3953": "Error of cassette parameter (note width)",
            "MSG_3954": "Error of cassette parameter (note length)",
            "MSG_3955": "Error of cassette parameter (note thickness)",
            "MSG_3960": "Thickness zero value error",
            "MSG_3961": "Self-calibration fails",
            "MSG_3962": "Error of duplicate ID",
            "MSG_3963": "Reset of stacking mechanism fails",
            "MSG_3964": "Error during RFID reading/writing",
            "MSG_3965": "First-level RFID label is damaged",
            "MSG_3966": "Second-level RFID label is damaged",
            "MSG_3967": "Third-level RFID label is damaged",
            "MSG_3968": "Fourth-level RFID label is damaged",
            "MSG_3969": "Fifth-level RFID label is damaged",
            "MSG_3970": "Sixth-level RFID label is damaged",
            "MSG_3971": "Frame format error of infrared sensor 1",
            "MSG_3972": "Frame format error of infrared sensor 2",
            "MSG_3973": "Frame format error of infrared sensor 3",
            "MSG_3974": "Frame format error of infrared sensor 4",
            "MSG_3975": "Frame format error of infrared sensor 5",
            "MSG_3976": "Frame format error of infrared sensor 6",
            "MSG_3977": "Communication timeout of infrared sensor 1",
            "MSG_3978": "Communication timeout of infrared sensor 2",
            "MSG_3979": "Communication timeout of infrared sensor 3",
            "MSG_3980": "Communication timeout of infrared sensor 4",
            "MSG_3981": "Communication timeout of infrared sensor 5",
            "MSG_3982": "Communication timeout of infrared sensor 6",
            "MSG_3983": "Stacking mechanism sensor failure",
            "MSG_4000": "Enable communication of NV times out",
            "MSG_4001": "Enable of NV fails",
            "MSG_4002": "Enable check of NV is wrong",
            "MSG_4003": "Timeout during NV communication",
            "MSG_4004": "Failure during NV communication",
            "MSG_4005": "Check error during NV communication",
            "MSG_4006": "Disable communication of enabling NV times out",
            "MSG_4007": "Disable of enabling NV fails",
            "MSG_4008": "Disable check of enabling NV is wrong",
            "MSG_4009": "NV communication times out",
            "MSG_4010": "Note sequence number does not match with NV",
            "MSG_4011": "Format of command received by NV is wrong",
            "MSG_4012": "Format of enabling command received by NV is wrong",
            "MSG_4013": "Format of disabling command received by NV is wrong",
            "MSG_4014": "Note counting of NV does not match number of dispensed notes",
            "MSG_4015": "An invalid command is received during NV counting",
            "MSG_4016": "Check error of NV counting",
            "MSG_4017": "NV counting times out",
            "MSG_4018": "The NV receives other data during communication",
            "MSG_4019": "NV is not enabled",
            "MSG_4020": "Error in receiving command when resetting NVUSB",
            "MSG_4021": "Return checking error of resetting NVUSB",
            "MSG_4022": "Resetting NVUSB times out",
            "MSG_4023": "Resetting NVUSB fails",
            "MSG_4024": "NV hardware failure (algorithm error)",
            "MSG_4025": "BIT error of SDRAM of NV",
            "MSG_4026": "BIT error of RAM of NV",
            "MSG_4027": "Configuration of AD in NV fails",
            "MSG_4028": "BIT error of OCR template of NV",
            "MSG_4029": "Check error of NV BIT command",
            "MSG_4030": "NV BIT times out",
            "MSG_4031": "An invalid command is received during NV BIT",
            "MSG_4032": "NV reset switch is not closed",
            "MSG_4033": "NV reset switch is not closed",
            "MSG_4034": "Error in setting note mode of NV",
            "MSG_4035": "Invalid data is received in the note mode set in NV",
            "MSG_4036": "Check error in setting note mode of NV",
            "MSG_4037": "Setting note mode of NV times out",
            "MSG_4038": "NV returns sequence number error when dispensing notes",
            "MSG_4039": "Number of rejected notes exceeds the maximum due to NV hardware failure",
            "MSG_4040": "NV parameter setting error",
            "MSG_4100": "Sensor broken",
            "MSG_4101": "Error during open, door still closed",
            "MSG_4102": "Error during open, jam, door half open",
            "MSG_4103": "Safe sensor broken",
            "MSG_4104": "Error during close, motor failure, door completely open",
            "MSG_4105": "Error during close, jam, door half open",
            "MSG_4106": "Error during close, jam, door completely open",
            "MSG_4200": "Calibration failed",
            "MSG_4201": "Calibration failed - payout flap sensor",
            "MSG_4202": "Calibration failed - exit sensor",
            "MSG_4203": "Calibration failed - coil 1 sensor",
            "MSG_4204": "Calibration failed - coil 2 sensor",
            "MSG_4205": "Calibration failed - unit not initialized",
            "MSG_4206": "Calibration failed - checksum error",
            "MSG_4207": "Calibration failed - recalibration by command required",
            "MSG_4208": "Calibration failed - motor opto slot error (1)",
            "MSG_4209": "Calibration failed - motor opto slot error (2)",
            "MSG_4210": "Calibration failed - exit sensor error 2",
            "MSG_4300": "Blockiert aufgrund inkorrekter Eingabe des Steuerinspektor-Passworts",
            "MSG_4301": "Warte auf Datumseingabe",
            "MSG_4302": "Dezimalpunkt-Änderung ist erlaubt",
            "MSG_4303": "Technischer Reset-Modus",
            "MSG_4304": "Testlauf",
            "MSG_4305": "Drucke vollständigen Fiskalbericht",
            "MSG_4306": "Warte auf Laden",
            "MSG_4307": "Schicht ist offen aber 24 Stunden sind verstrichen",
            "MSG_4308": "Schicht ist offen",
            "MSG_4309": "Schicht ist nicht offen",
            "MSG_4310": "Schicht ist geschlossen",
            "MSG_5000": "Waiting for authentication",
            "MSG_5001": "Kiosk functionality enabled",
            "MSG_5002": "Kiosk functionality disabled",
            "MSG_5003": "Message pending, kiosk functionality disabled",
            "MSG_5004": "Not authenticated",
            "MSG_5010": "Waiting for certificate",
            "MSG_5500": "Server / Server Protocol Version or Casino ID does not match the config file.",
            "MSG_6000": "Allgemeiner Fehler",
            "MSG_6001": "Stream läuft nicht",
            "MSG_6002": "Stream startet gerade",
            "MSG_6003": "Stream angefragt",
            "MSG_6004": "Stream läuft",
            "MSG_6005": "Stream läuft nicht. App Neustart nötig",
            "MSG_6006": "Stream läuft nicht. App Neustart jetzt",
            "MSG_13001": "Verbindung zum Werbe-Server konnte nicht hergestellt werden ({0}).",
            "MSG_13002": "Leere Werbeliste empfangen.",
            "MSG_13003": "Werbe-Modul-Lizenz ist abgelaufen (gültig bis: {0}).",
        },
    }
};
