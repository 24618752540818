import { Pipe, PipeTransform } from '@angular/core';
import { AccessPoint } from '../_enums/accessPoint';
import { GlobalCacheVariable } from "../_globals/globals";

@Pipe({
    name: 'accessPoint'
})
export class AccessPointPipe implements PipeTransform {

    constructor(public globalCacheVariable: GlobalCacheVariable) {

    }

    transform(value: number, TerminalID: any, ClientEndpoint: any, accessPointValue: any): any {

        switch (value) {
            case AccessPoint.ArtClient:
                return "ART Client " + ClientEndpoint;
            case AccessPoint.Terminal:
                for (let i = 0; i < this.globalCacheVariable.Terminals.length; i++) {
                    if (this.globalCacheVariable.Terminals[i].TerminalID === TerminalID) {
                        return this.globalCacheVariable.Terminals[i].TerminalName;
                    }
                }
            case AccessPoint.None:
                return;
        }

        //return `${value.substr(0, limit)}${ellipsis}`;
    }


}
